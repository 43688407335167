import { AxiosRequestConfig } from 'axios';
import { get, post } from './config';

/**
 * @description Orders service for placing orders, fetching order status
 */

export const Orders = {
    post: (query: any, config: AxiosRequestConfig | undefined) => {
        return post(`/place-order/`, query, config);
    },
    get: () => {
        return get('/');
    },
    getTotals: (query: any) => {
        return post('/discounts/get-discounted-order-totals', query);
    },
};
