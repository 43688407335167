import log from 'loglevel';
import { useCallback, useContext, useState } from 'react';
import { getDetails } from 'use-places-autocomplete';
import { Concepts } from '../../services/Concepts';
import { AppContext } from '../contexts/app-context';
import { CdpLocationContext } from '../contexts/cdp-location-context';
import { ConceptContext, ConceptContextInitValue } from '../contexts/concept-context';
import { MenuManagerInterface } from '../modules/menu/model';
import { LocationMatchResponse } from '../modules/restaurant/model';
import { placesToAddress } from '../modules/utils/address';
import { getAnonymousId, getLocalTimeISOString } from '../modules/utils/general';
import useSegment from '../modules/utils/segment';

interface ILocationDetails {
    menuInfo: MenuManagerInterface;
    placeDetails: any;
    restaurantInfo: LocationMatchResponse | null;
    locationStatus: LocationStatus | null;
}

interface LocationStatus {
    isOpen: boolean | undefined;
    isNewConcept: boolean | undefined;
    noLocations: boolean | undefined;
}

const defaultValues = {
    conceptDetailsData: ConceptContextInitValue.conceptDetails,
    conceptIsFetching: false,
    isErrorFetching: false,
    prevPlaceId: '',
};

export const UsePlaceToNearbyMenuDetails = () => {
    const [prevPlaceId, setPrevPlaceId] = useState<string>(defaultValues.prevPlaceId);
    const [isErrorFetching, setIsErrorFetching] = useState<boolean>(defaultValues.isErrorFetching);
    const [isConceptFetching, setIsConceptFetching] = useState<boolean>(
        defaultValues.conceptIsFetching,
    );
    const [conceptDetailsData, setConceptDetailsData] = useState<ILocationDetails>(
        defaultValues.conceptDetailsData,
    );

    const { stateIds, template, orderAddress, userProfile } = useContext(AppContext);
    const { updateIsConceptFetching, updateIsErrorFetching, conceptDetails } =
        useContext(ConceptContext);

    const segment = useSegment();
    const cdpLocation = useContext(CdpLocationContext);

    const clearValues = useCallback(() => {
        setIsErrorFetching(defaultValues.isErrorFetching);
        setIsConceptFetching(defaultValues.conceptIsFetching);
        setConceptDetailsData(defaultValues.conceptDetailsData);
        updateIsErrorFetching(false);
        updateIsConceptFetching(false);
    }, [
        setIsErrorFetching,
        setIsConceptFetching,
        setConceptDetailsData,
        updateIsErrorFetching,
        updateIsConceptFetching,
    ]);

    const getPlaceDetails = useCallback(async placeId => {
        log.debug('%c FETCH PLACE DETAILS IN Context', 'background-color: blue', placeId);
        const placeDetailsQuery = getDetails({
            placeId,
            fields: ['address_components', 'formatted_address', 'geometry', 'place_id'],
        });
        const placeDetails = await placeDetailsQuery;
        return placeDetails;
    }, []);

    const getRestaurantInfo = useCallback(
        async (placeDetails: any) => {
            if (placeDetails) {
                log.debug(
                    '%c FETCH RESTAURANT INFO IN HOOK',
                    'background-color: blue',
                    placeDetails,
                );
                const { data: nearByResults } = await Concepts.getNearby({
                    latitude: placeDetails.geometry.location.lat(),
                    longitude: placeDetails.geometry.location.lng(),
                    order_time: getLocalTimeISOString(new Date()),
                    address: placesToAddress(placeDetails),
                    session_id: stateIds.sessionId,
                    brand_id: template?.metadata.brand.id,
                    anonymous_id: getAnonymousId(),
                    user_email: userProfile?.email,
                });
                if (nearByResults?.delivery?.app_id) {
                    // Always print out the matched app id, even in prod, so we easily know exactly what location we were matched with
                    log.debug(`Location: ${nearByResults?.delivery?.app_id}`);
                }
                return nearByResults;
            }
            return null;
        },
        [template, stateIds, userProfile],
    );

    const createConceptDetailsDataBlob = useCallback(
        (restaurantInfo: LocationMatchResponse, placeDetails: any) => {
            const conceptDetailsDataBlob = {
                menuInfo: restaurantInfo.menu,
                restaurantInfo,
                placeDetails,
                locationStatus: {
                    isOpen: isLocationOpen(restaurantInfo),
                    isNewConcept: isNewConceptMatched(
                        restaurantInfo,
                        conceptDetails?.restaurantInfo,
                        orderAddress,
                    ),
                    noLocations: noLocations(restaurantInfo),
                },
            };
            return conceptDetailsDataBlob;
        },
        [orderAddress, conceptDetails],
    );

    const fetchConceptDetails = useCallback(
        async (placeId, isMounted, addressEntered = false) => {
            setIsConceptFetching(true);
            updateIsConceptFetching(true);
            if (!placeId) {
                return clearValues();
            }
            if (placeId === prevPlaceId && isMounted) {
                return conceptDetailsData;
            }
            setPrevPlaceId(placeId);
            try {
                const placeDetailsResults = await getPlaceDetails(placeId);
                const restaurantInfoResults = await getRestaurantInfo(placeDetailsResults);
                if (addressEntered) {
                    segment.addressEntered(placeDetailsResults, cdpLocation);
                    segment.IdentifyAddress(placeDetailsResults, '');
                }
                const conceptDetailsDataTempObject = createConceptDetailsDataBlob(
                    restaurantInfoResults,
                    placeDetailsResults,
                );
                if (isMounted) {
                    setConceptDetailsData(conceptDetailsDataTempObject);
                    return conceptDetailsDataTempObject;
                }
            } catch (err) {
                setIsErrorFetching(true);
                updateIsErrorFetching(true);
                segment.errorShown(`Error fetching Concept details, ${err}`);
                log.error(
                    '%c Error in fetching Concept details Hook',
                    'background-color: red',
                    err,
                );
            } finally {
                setIsConceptFetching(false);
                updateIsConceptFetching(false);
            }
            return null;
        },
        [
            getRestaurantInfo,
            getPlaceDetails,
            createConceptDetailsDataBlob,
            updateIsConceptFetching,
            updateIsErrorFetching,
            clearValues,
            segment,
            conceptDetailsData,
            prevPlaceId,
            cdpLocation,
        ],
    );
    log.debug('%c Fetched Concept details in Hook', 'background-color: blue', conceptDetailsData);

    return {
        isErrorFetching,
        isConceptFetching,
        conceptDetailsData,
        fetchConceptDetails,
        clearValues,
    };
};

const noLocations = (restaurantInfo: LocationMatchResponse) => restaurantInfo?.delivery == null;

const isLocationOpen = (restaurantInfo: LocationMatchResponse) =>
    restaurantInfo?.delivery && restaurantInfo?.delivery?.reopen_at == null;

const isNewConceptMatched = (
    restaurantInfo: LocationMatchResponse,
    currConceptLocation: LocationMatchResponse | null,
    orderAddress: any,
) => {
    return (
        orderAddress &&
        restaurantInfo?.delivery?.app_id &&
        !restaurantInfo?.delivery?.reopen_at &&
        currConceptLocation?.delivery?.app_id !== restaurantInfo?.delivery?.app_id
    );
};
