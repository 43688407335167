import {
    AuditOutlined,
    CheckCircleFilled,
    ClockCircleOutlined,
    HomeOutlined,
} from '@ant-design/icons';
import { Col, Divider, Input, Row, Steps, Typography } from 'antd';
import log from 'loglevel';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { Header } from '../layout/header';
import { ScrollToTopOnMount } from '../menu/ScrollToTopOnMount';
import { ItemList } from '../order-summary/itemlist';
import { ErrorScreen } from '../ui-components/error-screen';
import { futureOrderUtils } from '../ui-components/future-order-components/useFutureOrderPopup';
import { ImageWithFallback } from '../ui-components/image-with-fallback';
import {
    formatAddress,
    getCurrentHours,
    isTestAccount,
    timestampToHours,
    useMobileScreen,
} from '../utils/general';
import request from '../utils/request';
import useSegment from '../utils/segment';
import { UserCheckedOutOptions } from './user-checked-out-options';
import './index.scss';

export const OrderConfirmationContent: FC<OCPageProps> = ({ location: { state = [] } }) => {
    const isStaging = process.env.BUILD_ENV === 'staging';
    const [currentStep, setCurrentStep] = useState(2);
    const [timestamps, setTimestamps] = useState({ 1: '', 2: '', 3: '', 4: '', 5: '' });
    const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState('');
    const [driverCancelled, setDriverCancelled] = useState(false);
    const [noDriver, setNoDriver] = useState(false);
    const [genericError, setGenericError] = useState(false);
    const [paymentCaptureFailed, setPaymentCaptureFailed] = useState(false);
    const [formattedTel, setFormattedTel] = useState<string>('');

    const {
        stateIds,
        cartItems,
        template,
        futureOrderDate,
        isDinnerbell,
        orderAddress,
        orderPlaced,
        updateOrderPlaced,
        updateHandlingOrder,
        orderAddressApt,
    } = useContext(AppContext);
    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);

    const { Title } = Typography;
    const history = useHistory();
    const segment = useSegment();
    const kitchen = restaurantInfo?.delivery;
    const errorScreen = noDriver || genericError || paymentCaptureFailed;

    /* The details read for restaurantInfo payload has been updated the Address portion field names. 
    Keeping for now in case we need to revert back.
    zipcode, state, and phonenumber are now postalcode, region, and phone_number */
    const {
        address1,
        city,
        state: addressState,
        zipcode,
        country,
        postalcode,
        region,
    } = restaurantInfo?.delivery?.address ?? {};
    const restaurantAddr = `${address1}, ${city}, ${addressState || region} ${
        zipcode || postalcode
    }, ${country}`;

    const iconStyles = {
        fontSize: '20px',
        color: '#bbb',
        paddingRight: '.5rem',
    };

    const formatTel = (tel?: string) => {
        if (tel) {
            const parts = tel.split('-');
            return `(${parts[0]}) ${parts[1]}-${parts[2]}`;
        }
        return '';
    };
    useEffect(() => {
        setFormattedTel(formatTel(template?.general?.support?.customer_service_number));
    }, [template]);

    const handleTelPrompt = () => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { customer_service_number } = template?.general?.support || {};
        window.open(`tel:1-${customer_service_number}`);
    };

    const updateLocalState = useCallback(
        (statusUpdates: [], mostRecentStep: number) => {
            let updatedHasError = false;
            let updatedMostRecentStep = mostRecentStep;
            let updatedRecentLogisticEvent = '';
            // eslint-disable-next-line @typescript-eslint/naming-convention
            for (const {
                status,
                estimated_delivery_time: estimatedDeliveryTime,
                logistic_event_time: logisticEventTime,
                date_modified: dateModified,
            } of statusUpdates) {
                if (estimatedDeliveryTime) {
                    setEstimatedDeliveryTime(timestampToHours(estimatedDeliveryTime));
                }
                if (status === 'no_courier_available') {
                    setNoDriver(true);
                    segment.errorShown(status);
                    updatedHasError = true;
                    break;
                } else if (status === 'driver_canceled') {
                    setDriverCancelled(true);
                    segment.errorShown(status);
                    updatedHasError = true;
                    break;
                } else if (status === 'undefined_rejection') {
                    setGenericError(true);
                    segment.errorShown(status);
                    updatedHasError = true;
                    break;
                } else if (status === 'payment_capture_failed') {
                    setPaymentCaptureFailed(true);
                    segment.errorShown(status);
                    updatedHasError = true;
                    break;
                }
                const step = statusToStep[status];
                if (step > mostRecentStep) {
                    updatedMostRecentStep = step;
                    updatedRecentLogisticEvent =
                        logisticEventTime || dateModified || new Date().toISOString();
                }
            }

            if (updatedMostRecentStep > 0 && updatedRecentLogisticEvent) {
                setCurrentStep(updatedMostRecentStep);
                setTimestamps(timestamps => {
                    return {
                        ...timestamps,
                        [updatedMostRecentStep]: timestampToHours(updatedRecentLogisticEvent),
                    };
                });
            }
            return { updatedHasError, updatedMostRecentStep };
        },
        /**
         * TODO: The dependency that is missing here is `segment`
         * Adding `segment` here will cause a infinite loop. useSegment connected to AppContext.
         * I can't find an evidence that this fn manipulates the state of AppContext.
         * But segment will keep being changed/redefined.
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        let timer: any;
        let mostRecentStep = 0;
        function pollForStatusUpdates(orderId: any) {
            let hasError: boolean;
            request
                .get(`/order-status/${orderId}`)
                .then(({ data }) => {
                    const { updatedHasError, updatedMostRecentStep } = updateLocalState(
                        data,
                        mostRecentStep,
                    );
                    hasError = updatedHasError;
                    mostRecentStep = updatedMostRecentStep;
                })
                .then(() => {
                    const notDeliveredYet = mostRecentStep < 5;
                    if (!hasError && notDeliveredYet) {
                        timer = setTimeout(() => pollForStatusUpdates(orderId), 30000);
                    }
                });
        }

        if (isStaging && !isTestAccount(kitchen?.app_id || '')) {
            setCurrentStep(5);
            setTimestamps(prevTimeStamps => {
                return { ...prevTimeStamps, 5: getCurrentHours() };
            });
            setEstimatedDeliveryTime(getCurrentHours());
        } else if (stateIds.orderId) {
            log.debug('%c STATE ', 'background-color: green', state);
            const { updatedHasError } = updateLocalState(state, mostRecentStep);
            if (!updatedHasError) {
                setTimeout(() => pollForStatusUpdates(stateIds.orderId), 30000);
            }
        } else {
            history.push('/address');
        }

        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateIds.orderId, history, isStaging, kitchen?.app_id, updateLocalState]);

    useEffect(() => {
        if (!orderPlaced) {
            updateOrderPlaced(true);
            updateHandlingOrder(false);
        }
        segment.pageViewed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOrderPlaced, orderPlaced, updateHandlingOrder]);

    const steps: StepObj[] = useMemo(() => {
        return [
            {
                title: 'Order received',
                stepNum: 1,
            },
            {
                title: 'Order confirmed by restaurant',
                stepNum: 2,
            },
            {
                title: 'Driver has picked up your food',
                stepNum: 3,
            },
            {
                title: 'Your driver is arriving',
                stepNum: 4,
            },
            {
                title: 'Your food has been delivered',
                stepNum: 5,
            },
        ];
    }, []);

    const confirmationTitle = (
        <Title level={useMobileScreen() ? 3 : 1}>
            Thanks! Keep an eye out for updates on your order.
        </Title>
    );
    return (
        <>
            <ScrollToTopOnMount />
            <Helmet>
                <title>{`Your ${template?.metadata?.brand?.name ?? ''} Order Confirmation`}</title>
                <meta name="Order confirmation page" content="Order and delivery information" />
                <link
                    rel="icon"
                    type="image/png"
                    href={template?.general?.favicon || ''}
                    sizes="32x32"
                ></link>
            </Helmet>
            <div>
                <div className="extend-to-bottom">
                    <Header templateName="default" logoLink="/orders" />
                    <Row className="order-confirmation">
                        <ErrorScreen
                            noDriver={noDriver}
                            genericError={genericError}
                            paymentCaptureFailed={paymentCaptureFailed}
                        />
                        {!errorScreen && (
                            <>
                                <Col xs={24} md={12}>
                                    <ImageWithFallback
                                        className="order-confirmation-img"
                                        data-testid="order-confirmation-img"
                                        src={template?.order_confirmation_page?.hero_image}
                                        resize={1000}
                                    />
                                </Col>
                                <Col xs={24} md={12} style={{ backgroundColor: '#fff' }}>
                                    <div className="order-confirmation-body">
                                        {!driverCancelled && (
                                            <>
                                                <Row>
                                                    <Col span={24}>
                                                        <span data-testid="estimated-delivery-time">
                                                            {confirmationTitle}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Divider />
                                            </>
                                        )}
                                        <Row>
                                            <Col span={24}>
                                                {!isDinnerbell && (
                                                    <h4 className="ordered-detail">
                                                        <span>Ordered from: </span>
                                                        {template?.metadata.brand.name},{' '}
                                                        {restaurantAddr}
                                                    </h4>
                                                )}
                                                <>
                                                    <Row className="ordered-detail">
                                                        <Col span={2}>
                                                            <HomeOutlined style={iconStyles} />
                                                        </Col>
                                                        <Col span={18}>
                                                            {formatAddress(
                                                                orderAddress?.structured_formatting,
                                                                orderAddressApt,
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row className="ordered-detail">
                                                        <Col span={2}>
                                                            <ClockCircleOutlined
                                                                style={iconStyles}
                                                            />
                                                        </Col>
                                                        <Col span={18}>
                                                            {futureOrderDate
                                                                ? `${futureOrderDate.date
                                                                      .startOf('day')
                                                                      .format(
                                                                          'dddd, MMMM D',
                                                                      )} between ${futureOrderUtils.getTimeString(
                                                                      futureOrderDate.date,
                                                                      futureOrderDate.time
                                                                          .startTime,
                                                                  )} - ${futureOrderUtils.getTimeString(
                                                                      futureOrderDate.date,
                                                                      futureOrderDate.time.endTime,
                                                                  )}`
                                                                : `Estimated delivery: ${estimatedDeliveryTime}`}
                                                        </Col>
                                                    </Row>
                                                    <Row className="ordered-detail">
                                                        <Col span={2}>
                                                            <AuditOutlined style={iconStyles} />
                                                        </Col>
                                                        <Col span={18}>
                                                            Order #{stateIds.orderId}
                                                        </Col>
                                                    </Row>
                                                </>
                                                <br />
                                                {futureOrderDate || isDinnerbell ? (
                                                    <section className="item-list-wrapper">
                                                        <ItemList
                                                            pageType="checkout"
                                                            cartItems={cartItems}
                                                            showPrice={false}
                                                            confAlter={true}
                                                        />
                                                    </section>
                                                ) : (
                                                    <Steps
                                                        responsive
                                                        current={currentStep}
                                                        labelPlacement="vertical"
                                                        direction="vertical"
                                                        status={
                                                            driverCancelled ? 'error' : 'process'
                                                        }
                                                    >
                                                        {steps.map(({ title, stepNum }) => {
                                                            const showGreenCheck =
                                                                currentStep > 4 && stepNum > 4;
                                                            const isSuccessfulUpdate =
                                                                currentStep >= stepNum;
                                                            if (
                                                                !driverCancelled ||
                                                                isSuccessfulUpdate
                                                            ) {
                                                                return (
                                                                    <Step
                                                                        key={stepNum}
                                                                        title={title}
                                                                        description={
                                                                            timestamps[stepNum]
                                                                        }
                                                                        icon={(() => {
                                                                            if (showGreenCheck) {
                                                                                return (
                                                                                    <CheckCircleFilled className="delivered-icon" />
                                                                                );
                                                                            } else if (
                                                                                currentStep ===
                                                                                stepNum
                                                                            ) {
                                                                                return (
                                                                                    <CheckCircleFilled className="current-step" />
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })()}
                                                                    />
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                        {driverCancelled && (
                                                            <Step
                                                                title="We are unable to complete your order."
                                                                description="Sorry, the restaurant or delivery partner is currently unavailable at this time."
                                                            />
                                                        )}
                                                    </Steps>
                                                )}
                                                <UserCheckedOutOptions orderId={stateIds.orderId} />
                                            </Col>
                                        </Row>
                                        <Input
                                            type="hidden"
                                            value={currentStep}
                                            data-testid="order-confirmation-currentstep"
                                        />
                                        <Input
                                            type="hidden"
                                            value={driverCancelled ? 1 : 0}
                                            data-testid="order-confirmation-error"
                                        />
                                        {/* <Divider /> */}
                                        <p
                                            className="confirmation-footer cursor-pointer"
                                            onClick={handleTelPrompt}
                                        >
                                            Questions about your order? Reach out to our support
                                            team at {formattedTel}.
                                        </p>
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            </div>
        </>
    );
};

type stepNum = 1 | 2 | 3 | 4 | 5;

interface StepObj {
    title: string;
    stepNum: stepNum;
}

const statusToStep: any = {
    driver_dropped_off: 5,
    driver_confirmed_consumer_arrival: 4,
    driver_picked_up: 3,
    order_confirmed: 2,
};

const { Step } = Steps;

interface OCPageProps {
    location: {
        state: [];
    };
}

export const OrderConfirmationPage: FC<OCPageProps> = props => {
    return (
        <CdpLocationContext.Provider value="Order Confirmation">
            <OrderConfirmationContent {...props} />
        </CdpLocationContext.Provider>
    );
};
