import { Col, Row, Typography } from 'antd';
import moment from 'moment-timezone';
import React, { FC } from 'react';
import { formatAddressDescription, parsePaymentInfoData } from '../../utils/general';
import { COrderDetailsProps } from './models';

const { Text } = Typography;

export const CustomerOrderDetails: FC<COrderDetailsProps> = ({
    placedOrderDate,
    paymentMethodInfo,
    orderStatus,
    droppedOffDateTime,
    deliveryAddress,
    restaurantAdddress,
    futureOrderDeliveryWindow,
}) => {
    return (
        <Row className="order-summary-table width-100" data-testid="customer-order-details">
            <Col>
                <Text type="secondary" strong>
                    Ordered from
                </Text>
            </Col>
            <Col>{`${restaurantAdddress.name}, ${formatAddressDescription(
                restaurantAdddress,
            )}`}</Col>

            {orderStatus === 'Complete' && (
                <>
                    <Col>
                        <Text type="secondary" strong>
                            Delivery address
                        </Text>
                    </Col>
                    <Col>{formatAddressDescription(deliveryAddress)}</Col>
                </>
            )}
            <Col>
                <Text type="secondary" strong>
                    Order placed
                </Text>
            </Col>
            <Col>{moment(placedOrderDate).format('L, LT')}</Col>

            {orderStatus === 'Complete' && (
                <>
                    <Col>
                        <Text type="secondary" strong>
                            Delivered on
                        </Text>
                    </Col>
                    <Col>
                        {futureOrderDeliveryWindow() != null
                            ? futureOrderDeliveryWindow()
                            : moment(droppedOffDateTime).format('L, LT')}
                    </Col>
                </>
            )}
            <Col>
                <Text type="secondary" strong>
                    Payment
                </Text>
            </Col>
            <Col>{parsePaymentInfoData(paymentMethodInfo)}</Col>
        </Row>
    );
};
