import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../../contexts/app-context';
import { ConceptContext } from '../../contexts/concept-context';
import { ScrollToTopOnMount } from '../menu/ScrollToTopOnMount';
import { useMobileScreen } from '../utils/general';
import { ConceptMenuContainer, MenuLoadingSkeleton } from './ConceptMenu';
import { ParentMenu } from './parent-menu';

export const Menu = ({
    headerHeight,
    handleItemDetailPopUp,
}: {
    headerHeight: number;
    handleItemDetailPopUp: any;
}) => {
    const { updateStateId, orderAddress, handlingOrder } = useContext(AppContext);
    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);
    const ldFlags = useFlags();
    const isMobile = useMobileScreen();
    const [isFetchingParentMenu, setFetchingParentMenu] = useState(false);

    log.debug('%c RESTAURANT INFO ', 'background-color: green', restaurantInfo);

    useEffect(() => {
        if (!handlingOrder) {
            updateStateId('orderId', '');
        }
    }, [updateStateId, handlingOrder]);

    if (!restaurantInfo || !orderAddress) {
        return ldFlags.enableLamv ? (
            isFetchingParentMenu ? (
                <MenuLoadingSkeleton isMobile={isMobile} />
            ) : (
                <ParentMenu
                    headerHeight={headerHeight}
                    handleItemDetailPopUp={handleItemDetailPopUp}
                    setFetchingMenu={setFetchingParentMenu}
                />
            )
        ) : (
            <Redirect to="/address" />
        );
    } else {
        return (
            <>
                <ScrollToTopOnMount />
                <ConceptMenuContainer
                    headerHeight={headerHeight}
                    handleItemDetailPopUp={handleItemDetailPopUp}
                />
            </>
        );
    }
};
