import { MinusOutlined, PlusOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Checkbox, Collapse, Divider, Form, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../contexts/app-context';
import { ConceptContext } from '../../contexts/concept-context';
import { getCurrentPage } from '../../router';
import { PaymentMethod } from '../checkout';
import { OSFormProps } from '../menu/model';
import { useMobileScreen } from '../utils/general';
import useSegment from '../utils/segment';
import './index.scss';
import { ItemList } from './itemlist';
import { TipDetails } from './tipdetails';

const { Panel } = Collapse;

export const OrderSummaryForm: FC<OSFormProps> = ({
    onCartItemEdit,
    hideListItems = false,
    tabPosition,
    orderTotals,
}) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const segment = useSegment();
    const isMobile = useMobileScreen();
    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);
    const { cartItems, addUtensils, updateCartItems, updateAddUtensils, setPaymentMethod } =
        useContext(AppContext);
    const [expand, setExpand] = useState<boolean>(false);

    useEffect(() => {
        setExpand(!isMobile);
    }, [isMobile]);

    useEffect(() => {
        if (cartItems.length < 1) {
            const expiryStr = localStorage.getItem('expiry');
            const time = new Date();
            if (expiryStr && time.getTime() < JSON.parse(expiryStr)) {
                const cartItemsStr = localStorage.getItem('cartItems');
                if (cartItemsStr != null) {
                    updateCartItems(JSON.parse(cartItemsStr));
                }
            }
        }
    }, [cartItems.length, segment, updateCartItems]);

    useEffect(() => {
        const addUtensilsStr = localStorage.getItem('addUtensils');
        if (!addUtensils && addUtensilsStr) {
            updateAddUtensils(JSON.parse(addUtensilsStr));
        }
        form.setFieldsValue({
            checkoututensils: addUtensils,
        });
    }, [addUtensils, form, updateAddUtensils]);

    if (restaurantInfo == null || restaurantInfo.delivery == null) {
        return null;
    }

    const onChangeUtensils = (e: CheckboxChangeEvent) => {
        const currentPage = getCurrentPage();
        const wantUtensils = e.target.checked;
        updateAddUtensils(wantUtensils);
        localStorage.setItem('addUtensils', wantUtensils.toString());

        segment.utensilsOptionSelected(wantUtensils ? 'Include' : 'Do Not Include', currentPage);
    };

    const onFinish = (paymentMethod: PaymentMethod) => () => {
        setPaymentMethod(paymentMethod);
        history.push('/order-confirmation');
    };

    const expandIcon = () =>
        expand ? (
            <MinusOutlined
                onClick={() => {
                    setExpand(!expand);
                    segment.expandAndHideOrderSummary('Hide');
                }}
                style={{ fontSize: '18px', paddingRight: '1rem' }}
            />
        ) : (
            <PlusOutlined
                onClick={() => {
                    setExpand(!expand);
                    segment.expandAndHideOrderSummary('Expand');
                }}
                style={{ fontSize: '18px', paddingRight: '1rem' }}
            />
        );

    const AddUtensils = () => (
        <div className="py-10">
            <Form.Item name="checkoututensils" valuePropName="checked" noStyle>
                <Checkbox onChange={onChangeUtensils}>Add utensils to my order</Checkbox>
            </Form.Item>
        </div>
    );

    const OrderSummaryCard = () => (
        <div className="mb-30">
            <div className="checkout-subtitle">ORDER SUMMARY</div>
            <div className="checkout-border-box">
                asdasd
                <Collapse
                    ghost
                    activeKey={expand ? '1' : '0'}
                    className={`${isMobile ? 'shown' : 'hidden'}`}
                    bordered={false}
                    expandIconPosition={'right'}
                    expandIcon={expandIcon}
                >
                    <Panel
                        header={
                            <Space
                                className="mb-20px"
                                onClick={() => setExpand(!expand)}
                                align="center"
                                size={12}
                            >
                                <ShoppingCartOutlined
                                    style={{
                                        fontSize: '28px',
                                        color: '#bbb',
                                    }}
                                />
                                <span style={{ fontSize: '16px' }}>
                                    {cartItems.reduce((s, a) => s + a.quantity, 0)} items
                                </span>
                            </Space>
                        }
                        key="1"
                    >
                        <div className="pt-20" id="checkout-order-summary">
                            <ItemList pageType="checkout" cartItems={cartItems} />
                            <AddUtensils />
                        </div>
                    </Panel>
                </Collapse>
            </div>
        </div>
    );

    return (
        <>
            <Form
                form={form}
                name="checkout-order-summary"
                className="checkout-order-summary mb-30"
                onFinish={onFinish}
                style={{ flexGrow: 1 }}
            >
                <div className="order-summary-container">
                    {cartItems.length < 1 ? (
                        <Form.Item className="checkout-order-empty">
                            <ShoppingCartOutlined />
                            <h3>Your cart is empty</h3>
                            <p>Add items to get started</p>
                        </Form.Item>
                    ) : (
                        !hideListItems && (
                            <div className="pt-10">
                                <ItemList
                                    pageType="checkout"
                                    cartItems={cartItems}
                                    onCartItemEdit={onCartItemEdit}
                                />
                                <AddUtensils />
                                <Divider className="cart-divider" />
                            </div>
                        )
                    )}
                </div>
                {cartItems.length >= 1 ? (
                    <>
                        {isMobile && <OrderSummaryCard />}
                        <TipDetails
                            cartItems={cartItems}
                            initialLoading={orderTotals.subtotal.length < 1}
                            orderTotals={orderTotals}
                            tabPosition={tabPosition}
                        />
                    </>
                ) : (
                    <></>
                )}
            </Form>
        </>
    );
};
