import { Auth0Provider } from '@auth0/auth0-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from './contexts/app-context';
import { ConceptContextProvider } from './contexts/concept-context';
import { DtcDevTools } from './modules/ui-components/DtcDevTools';
import { Router } from './router';

const stripePromise = loadStripe(process.env.STRIPE_API_KEY!);

export const App = () => {
    (async () => {
        log.debug('%c APP rendering', 'color: #00ff00');

        // If build process has defined a static asset root, we're expected to
        // apply that as the routing base root too
        const basename = process.env.REACT_APP_ROOT;

        const fiveMinutes = 5 * 60 * 1000;
        const queryClient = new QueryClient({
            defaultOptions: {
                queries: {
                    staleTime: fiveMinutes,
                },
            },
        });

        const mswEnabled = JSON.parse(localStorage.getItem('mswEnabled') ?? 'false');

        const LDProvider = await asyncWithLDProvider({
            clientSideID: process.env.LAUNCH_DARKLY_CLIENT_ID || '',
            user: {
                key: 'dtc-ld-user',
            },
            options: {
                streaming: process.env.BUILD_ENV === 'prod' || (!window.Cypress && !mswEnabled),
            },
        });

        const reactEl = document.getElementById('react-region');

        // These values are not considered confidential
        // But will be moved to env vars as soon as I have access to configure

        ReactDOM.render(
            <Auth0Provider
                domain={process.env.AUTH0_DOMAIN || ''}
                clientId={process.env.AUTH0_CLIENT_ID || ''}
                redirectUri={window.location.origin}
                cacheLocation="localstorage"
            >
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter basename={basename}>
                        <AppContextProvider>
                            <ConceptContextProvider>
                                <Elements stripe={stripePromise}>
                                    <LdApp LDProvider={LDProvider} />
                                </Elements>
                            </ConceptContextProvider>
                        </AppContextProvider>
                    </BrowserRouter>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </Auth0Provider>,
            reactEl,
        );
    })();
};

// dev tools is disabled in test and prod
const devToolsDisabled = window.Cypress || process.env.BUILD_ENV === 'prod';

const LdApp = ({ LDProvider }: { LDProvider: any }) => {
    const [forceUpdate] = useForceUpdate();
    return (
        <LDProvider>
            {!devToolsDisabled && <DtcDevTools forceUpdate={forceUpdate} />}
            <Router />
        </LDProvider>
    );
};

function useForceUpdate() {
    const [, setValue] = useState(false);
    return [() => setValue(value => !value)]; // update the state to force render
}
