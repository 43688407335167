import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { AppContext, BrandTemplate, FutureOrderDate } from '../contexts/app-context';
import { CdpLocationContext } from '../contexts/cdp-location-context';
import { ConceptContext } from '../contexts/concept-context';
import { DaysOfWeek, MenuManagerInterface, isFutureOrderType } from '../modules/menu/model';
import { LocationMatchResponse, OrderEvent } from '../modules/restaurant/model';
import {
    dayConverter,
    futureOrderUtils,
} from '../modules/ui-components/future-order-components/useFutureOrderPopup';
import {
    generateFutureDeliveryTimeWindows,
    getEstimatedDeliveryWindow,
    getHolidayHourWindows,
    getPauseAdjustedWindows,
} from '../modules/utils/general';
import useSegment from '../modules/utils/segment';

export const findClosestDate = (
    template: BrandTemplate | null,
    menuInfo: MenuManagerInterface | null,
    restaurantInfo: LocationMatchResponse | null,
    updateFutureOrderDate: (param: FutureOrderDate) => void,
    futureOrderDate: FutureOrderDate | null | undefined,
    saveFutureOrderDate: boolean,
    reopenAtTime: string | null | undefined,
) => {
    const timezone = restaurantInfo?.delivery?.timezone;
    if (!menuInfo || !template || !timezone) {
        return '';
    }
    if (futureOrderDate) {
        const selectedDay = futureOrderDate.date;
        const { startTime, endTime } = futureOrderDate.time;
        return `${selectedDay.format('ddd MM/DD')}, ${futureOrderUtils.getTimeString(
            selectedDay,
            startTime,
        )} - ${futureOrderUtils.getTimeString(selectedDay, endTime)}`;
    }
    const futureOrder = template.future_order;
    const asapOrderUnavailable =
        !futureOrder?.supported_order_type?.asap || restaurantInfo?.delivery?.reopen_at;
    if (
        (isFutureOrderType(futureOrder) && asapOrderUnavailable) ||
        (isFutureOrderType(futureOrder) && !saveFutureOrderDate)
    ) {
        const deliveryWindows = generateFutureDeliveryTimeWindows(
            menuInfo?.menus[0].day_parts,
            futureOrder,
            new Date(),
            timezone,
        );
        const deliverableDates = futureOrderUtils.generateDeliverableDates(
            true,
            menuInfo,
            restaurantInfo,
            reopenAtTime,
            futureOrder.delivery_limit,
        );

        for (let i = 0; i < deliverableDates.length; i++) {
            const deliverableDate = deliverableDates[i];
            const { dayOfWeek, disabled, date } = deliverableDate;

            const beforePausedUntilDay = moment(date).isBefore(
                restaurantInfo?.delivery?.paused_until,
                'day',
            );

            const pauseAdjustedWindows = getPauseAdjustedWindows(
                restaurantInfo?.delivery?.paused_until,
                deliverableDate,
                deliveryWindows,
                dayConverter[dayOfWeek],
                template,
                menuInfo,
                restaurantInfo,
            );

            const holidayDayHourWindows = getHolidayHourWindows(
                deliverableDate,
                i,
                restaurantInfo?.delivery?.paused_until,
                restaurantInfo,
                template,
            );

            if (!disabled && deliveryWindows && !beforePausedUntilDay) {
                let key: DaysOfWeek | 'today' = 'today';
                if (i !== 0) {
                    key = dayConverter[dayOfWeek];
                }

                let windows = deliveryWindows[key];

                if (pauseAdjustedWindows) {
                    windows = pauseAdjustedWindows;
                }

                if (holidayDayHourWindows) {
                    windows = holidayDayHourWindows;
                }

                if (windows && windows.length > 0) {
                    const { startTime, endTime } = windows[0];

                    date.set('hour', startTime.hr);
                    date.set('minute', startTime.min);
                    date.set('second', 0);

                    const prettifiedTimeString = `${date.format(
                        'ddd MM/DD',
                    )}, ${futureOrderUtils.getTimeString(
                        date,
                        startTime,
                    )} - ${futureOrderUtils.getTimeString(date, endTime)}`;

                    if (saveFutureOrderDate) {
                        setTimeout(
                            () => updateFutureOrderDate({ date, time: { startTime, endTime } }),
                            0,
                        );
                        return prettifiedTimeString;
                    } else {
                        return {
                            message: prettifiedTimeString,
                            date,
                            window: { startTime, endTime },
                        };
                    }
                }
                if (!saveFutureOrderDate) {
                    return null;
                }
            }
        }
    }

    const estimateDeliveryWindow = getEstimatedDeliveryWindow(restaurantInfo);
    if (!estimateDeliveryWindow) {
        return 'ASAP';
    }
    return `ASAP (${estimateDeliveryWindow.from}-${estimateDeliveryWindow.to} min)`;
};

export const useFutureOrder = () => {
    const {
        conceptDetails: { menuInfo, restaurantInfo },
    } = useContext(ConceptContext);
    const { template, futureOrderDate, updateFutureOrderDate } = useContext(AppContext);
    const cdpLocation = useContext(CdpLocationContext);
    const [isFutureOrderPopoverVisible, setIsFutureOrderVisible] = useState(false);
    const [isAddressPopoverVisible, setIsAddressPopoverVisible] = useState(false);
    const [deliveryTime, setDeliveryTime] = useState(() =>
        findClosestDate(
            template,
            menuInfo,
            restaurantInfo,
            updateFutureOrderDate,
            futureOrderDate,
            true,
            restaurantInfo?.delivery?.reopen_at,
        ),
    );
    const segment = useSegment();
    const supportScheduleOrder = template?.future_order?.supported_order_type.scheduled;
    const estimateDeliveryWindow = getEstimatedDeliveryWindow(restaurantInfo);

    useEffect(() => {
        if (futureOrderDate) {
            const { date, time } = futureOrderDate;
            setDeliveryTime(
                `${date.format('ddd MM/DD')}, ${futureOrderUtils.getTimeString(
                    date,
                    time.startTime,
                )} - ${futureOrderUtils.getTimeString(date, time.endTime)}`,
            );
        } else {
            const estimatedTime = estimateDeliveryWindow
                ? `ASAP (${estimateDeliveryWindow.from}-${estimateDeliveryWindow.to} min)`
                : 'ASAP';

            setDeliveryTime(
                template?.future_order?.supported_order_type.asap
                    ? estimatedTime
                    : findClosestDate(
                          template,
                          menuInfo,
                          restaurantInfo,
                          updateFutureOrderDate,
                          futureOrderDate,
                          true,
                          null,
                      ),
            );
        }
    }, [
        futureOrderDate,
        template,
        restaurantInfo,
        menuInfo,
        estimateDeliveryWindow,
        updateFutureOrderDate,
    ]);

    useEffect(() => {
        const isPopoverOpen = isAddressPopoverVisible || isFutureOrderPopoverVisible;
        const body = document.querySelector('body');
        if (body && isPopoverOpen) {
            body.classList.add('no-scroll');
        } else if (body && !isPopoverOpen) {
            body.classList.remove('no-scroll');
        }
    }, [isAddressPopoverVisible, isFutureOrderPopoverVisible]);

    const toggleFutureOrderPopover = (event: OrderEvent) => {
        if (event.action === 'Cancel' && event.orderType) {
            segment.cancelOrderTimingLinkClicked(event.orderType, cdpLocation);
        } else if (event.action === 'Update' && event.orderType) {
            segment.updateOrderTimingCTAClicked(event.orderType, cdpLocation);
        } else if (event.action === 'Edit') {
            segment.editOrderTimingLinkClicked(cdpLocation);
        }
        setIsFutureOrderVisible(prev => !prev);
    };

    return {
        isFutureOrderPopoverVisible,
        setIsFutureOrderVisible,
        isAddressPopoverVisible,
        setIsAddressPopoverVisible,
        toggleFutureOrderPopover,
        deliveryTime,
        setDeliveryTime,
        supportScheduleOrder,
    };
};
