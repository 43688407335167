import { LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { RedirectLoginResult } from '@auth0/auth0-spa-js';
import { Spin, notification } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import { UserProfile } from '../../../../services/UserProfile';
import { AppContext, IUserProfile } from '../../../contexts/app-context';
import { ConceptContext } from '../../../contexts/concept-context';
import { UsePlaceToNearbyMenuDetails } from '../../../hooks/usePlaceToNearbyMenuDetails';
import { CustomerInfoInterface, DeliveryPreferenceInterface } from '../../menu/model';
import { PageNotFound } from '../../menu/page-not-found';
import { saveAddressToUserProfile } from '../../utils/address';
import { formatAddressDescription, getValueFromLocalStorage } from '../../utils/general';
import useSegment from '../../utils/segment';

const addNameAndNumberToUserProfiile = async (
    userProfile: IUserProfile,
    customerInfo: CustomerInfoInterface,
    updateUserProfile: (input: IUserProfile) => void,
    getAccessTokenSilently: any,
) => {
    const updatedUserProfile = { ...userProfile };
    const shouldUpdateName = !userProfile.name || userProfile.name.includes('@');
    if (shouldUpdateName && customerInfo.name) {
        updatedUserProfile.name = customerInfo.name;
    }
    if (!userProfile.phone_number && customerInfo.phonenumber) {
        updatedUserProfile.phone_number = customerInfo.phonenumber;
    }

    const token = await getAccessTokenSilently();
    await UserProfile.updateUserProfile(token, updatedUserProfile);
    updateUserProfile(updatedUserProfile);
};

export const Loading = () => {
    const { isLoading, isAuthenticated, handleRedirectCallback, getAccessTokenSilently } =
        useAuth0();
    const history = useHistory();
    const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const isMountedRef = useRef<boolean>(false);
    const [defaultAddress, setdefaultAddress] = useState<any>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isRedirectingError, setRedirectingError] = useState<boolean>(false);
    const [redirectLoginResult, setRedirectLoginResult] = useState<null | RedirectLoginResult>(
        null,
    );

    const {
        template,
        orderAddress,
        updateUserProfile,
        userProfile,
        useDefaultAddress,
        customerInfo,
        orderAddressApt,
        updateOrderAddress,
        updateUseDefaultAddress,
        updateDeliveryPreference,
        updateOrderAddressApt,
        updateNoConceptPopoverVisible,
        updateWelcomeModalOpen,
    } = useContext(AppContext);
    const {
        conceptDetails: { restaurantInfo },
        updateRestaurantInfoTempQuery,
        updateConceptDetails,
        updateIsErrorFetching,
    } = useContext(ConceptContext);

    const ldFlags = useFlags();
    const segment = useSegment();
    const isProd = useMemo(() => process.env.BUILD_ENV === 'prod', []);

    const { suggestions, setValue, clearSuggestions } = usePlacesAutocomplete({ debounce: 300 });
    const { fetchConceptDetails, conceptDetailsData, isConceptFetching, isErrorFetching } =
        UsePlaceToNearbyMenuDetails();

    const checkLocationAndSetDefaultInfo = useCallback(() => {
        const { locationStatus, restaurantInfo, placeDetails } = conceptDetailsData;

        if (locationStatus?.isOpen) {
            const place = {
                ...placeDetails,
                ...suggestions?.data?.[0],
            };
            segment.IdentifyAddress(place, '');
            updateOrderAddress({
                ...place,
                service_info_default_address: formatAddressDescription(defaultAddress, true),
            });
            updateOrderAddressApt(defaultAddress?.address2 ? defaultAddress?.address2 : null);
            updateDeliveryPreference({
                preference: defaultAddress?.delivery_preference,
                text: defaultAddress?.special_instructions,
            } as DeliveryPreferenceInterface);
            updateConceptDetails(conceptDetailsData);
            updateUseDefaultAddress(true);
        } else {
            updateRestaurantInfoTempQuery(restaurantInfo);
            updateNoConceptPopoverVisible(true);
            updateUseDefaultAddress(false);
            clearSuggestions();
        }
        setIsFetching(false);
    }, [
        defaultAddress,
        updateDeliveryPreference,
        updateOrderAddress,
        updateUseDefaultAddress,
        updateOrderAddressApt,
        clearSuggestions,
        suggestions,
        conceptDetailsData,
        updateConceptDetails,
        segment,
        updateNoConceptPopoverVisible,
        updateRestaurantInfoTempQuery,
    ]);

    useEffect(() => {
        const isNoConceptMatchedAndSaved =
            !orderAddress &&
            !restaurantInfo &&
            userProfile?.service_info &&
            userProfile?.service_info?.length > 0;

        if (userProfile) {
            const defaultAddress = userProfile?.service_info?.filter(
                address => address.default_address === true,
            )[0];

            if (isNoConceptMatchedAndSaved && useDefaultAddress == null && defaultAddress) {
                setIsFetching(true);
                const addressDescription = formatAddressDescription(defaultAddress, true);
                setdefaultAddress(defaultAddress);
                setValue(addressDescription);
            } else {
                log.debug('Default address either does not exist or need to be set');
                updateUseDefaultAddress(false);
            }
        }
    }, [
        userProfile,
        orderAddress,
        restaurantInfo,
        updateOrderAddress,
        useDefaultAddress,
        setValue,
        updateUseDefaultAddress,
    ]);

    useEffect(() => {
        isMountedRef.current = true;
        if (suggestions?.data[0]?.place_id) {
            fetchConceptDetails(suggestions.data[0].place_id, isMountedRef.current);
        }

        return () => {
            isMountedRef.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestions]);

    useEffect(() => {
        const noConceptMatchedAndSaved =
            !orderAddress && !restaurantInfo && !!userProfile?.service_info?.length;
        if (
            noConceptMatchedAndSaved &&
            conceptDetailsData?.restaurantInfo &&
            useDefaultAddress == null &&
            !isConceptFetching
        ) {
            checkLocationAndSetDefaultInfo();
        }
    }, [
        orderAddress,
        restaurantInfo,
        useDefaultAddress,
        userProfile,
        conceptDetailsData,
        checkLocationAndSetDefaultInfo,
        isConceptFetching,
    ]);

    useEffect(() => {
        log.info(`isAuthenticated: ${isAuthenticated}, isLoading: ${isLoading}`);
        if (ldFlags.enableAccounts && isAuthenticated && !isLoading) {
            const kitchen = restaurantInfo?.delivery;
            getAccessTokenSilently().then(token => {
                UserProfile.getUserProfileData(token)
                    .then(userProfile => {
                        if (!userProfile) {
                            updateWelcomeModalOpen(true);
                            UserProfile.signUpNewUser(
                                token,
                                kitchen?.app_id ?? null,
                                kitchen?.id ?? null,
                                localStorage.getItem('signupTouchpoint'),
                            )
                                .then(userProfile => {
                                    log.debug('signed up new user', userProfile);
                                    updateUserProfile(userProfile);
                                    segment.IdentifySignUp(userProfile);
                                })
                                .catch(err => {
                                    log.debug('sign up new user error', err);
                                    setRedirectingError(true);
                                });
                        } else {
                            log.debug('signed in user', userProfile);
                            updateUserProfile(userProfile);
                            segment.IdentifyLogIn(userProfile);
                            /* Additional check to prevent showing toast if user 
                            is already signed in on every page reload */
                            if (!getValueFromLocalStorage('currentUser')) {
                                const userName =
                                    userProfile?.name && !userProfile?.name?.includes('@')
                                        ? `, ${userProfile?.name.trim()}`
                                        : '';
                                notification.success({
                                    message: `Welcome back${userName}!`,
                                    placement: 'bottomRight',
                                });
                            }
                        }
                    })
                    .catch(err => {
                        log.debug('getUserProfile Error', err);
                        setRedirectingError(true);
                    });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isAuthenticated,
        getAccessTokenSilently,
        updateUserProfile,
        ldFlags.enableAccounts,
        isLoading,
        isProd,
        updateWelcomeModalOpen,
    ]);

    useEffect(() => {
        // Stubs the redirect login result returned from auth0 for Cypress tests
        if (localStorage.getItem('cypressLoginTesting')) {
            setRedirectLoginResult({
                appState: {
                    route: '/orders/',
                },
            });
        } else {
            try {
                handleRedirectCallback()
                    .then(value => setRedirectLoginResult(value))
                    .catch(err => {
                        log.error(`handleRedirectCallback error: ${err}`);
                        setRedirectingError(true);
                    });
            } catch (err) {
                log.debug('Error fetching callback redirect results', err);
            }
        }
    }, [handleRedirectCallback, setRedirectingError]);

    useEffect(() => {
        const saveInfoToUserProfile = async (userProfile: IUserProfile) => {
            if (customerInfo) {
                await addNameAndNumberToUserProfiile(
                    userProfile,
                    customerInfo,
                    updateUserProfile,
                    getAccessTokenSilently,
                );
            }
            segment.accountEmailCaptured(userProfile.email);

            if (orderAddress?.place_id && !userProfile?.service_info?.length) {
                const res = await getDetails({
                    placeId: orderAddress.place_id,
                    fields: ['address_components', 'formatted_address', 'geometry', 'place_id'],
                });

                saveAddressToUserProfile(
                    orderAddressApt,
                    res,
                    userProfile.service_info ?? [],
                    getAccessTokenSilently,
                    updateUserProfile,
                );
            }
        };
        // if there is an error fetching the concept details, we can ignore the default matching and send them to their initial path
        const isErrorFetchingUserDefaults = userProfile && redirectLoginResult && isErrorFetching;
        const isUserDefaultsFinishedFetching = useDefaultAddress != null && !isFetching;

        if (
            (userProfile && redirectLoginResult && isUserDefaultsFinishedFetching) ||
            isErrorFetchingUserDefaults
        ) {
            saveInfoToUserProfile(userProfile);
            let path = redirectLoginResult.appState.route || '/';
            path = path.replace('/orders', '');
            history.push(path);
            updateIsErrorFetching(false);
        }
    }, [
        redirectLoginResult,
        userProfile,
        history,
        template?.url,
        restaurantInfo,
        orderAddress?.place_id,
        orderAddressApt,
        customerInfo,
        getAccessTokenSilently,
        updateUserProfile,
        segment,
        useDefaultAddress,
        isFetching,
        isErrorFetching,
        updateIsErrorFetching,
    ]);

    return (
        <>
            {isRedirectingError && (
                <PageNotFound
                    errorMessage="Oops"
                    supportingMessage={`Looks like we’re having issues loading this page. Don’t let that hold you back, let’s get your order`}
                    redirectText="started."
                />
            )}
            <Spin
                size="default"
                tip="Redirecting..."
                indicator={loadingIcon}
                className="auth-spinner"
            />
        </>
    );
};
