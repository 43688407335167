import { Button, ButtonProps } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import { _ } from '../utils/general';

export const TemplateButton: React.FC<ButtonProps> = props => {
    const { template } = useContext(AppContext);
    const isPrimaryColorAvai =
        !_.isEmpty(template?.general?.primary_color) &&
        !_.isEmpty(template?.general?.secondary_color);
    const primaryStyle = isPrimaryColorAvai
        ? {
              background: !props.disabled
                  ? template?.general?.primary_color
                  : `${template?.general?.primary_color}59`,
              color: template?.general?.secondary_color,
              border: 'none',
          }
        : {};
    const buttonStyle = { ...primaryStyle, ...props.style };

    return (
        <Button {...props} style={buttonStyle}>
            <span>{props.children}</span>
        </Button>
    );
};
