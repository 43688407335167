import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Input, RadioChangeEvent, Space, Typography } from 'antd';
import React, { FC, useContext, useEffect } from 'react';
import { Controller, FieldValues, FormState } from 'react-hook-form';
import { ConceptContext } from '../../contexts/concept-context';
import { PlacesAutocomplete } from '../ui-components/PlacesAutocomplete';
import { RadioButtonGroup } from '../ui-components/RadioButtonGroup';
import { newConceptMatchMessage } from '../utils/error-messages';
import { LocationCLosed } from './location-closed';

const { Title } = Typography;
const { TextArea } = Input;

export const OrderAddressEntries: FC<IOrderAddressEntriesProps> = ({
    isPausedLongerThanAWeek,
    formTitle,
    watchOrderAddress,
    control,
    formState,
    onClearAddress,
    generateDynammicMessage,
    onChangedRadio,
    isInputTouched,
}) => {
    const { isConceptFetching } = useContext(ConceptContext);
    const locationStatus = watchOrderAddress?.conceptDetails?.locationStatus;

    useEffect(() => {
        if (isInputTouched) {
            document.getElementById('orderAddress')?.focus();
        }
    }, [isInputTouched]);

    return (
        <>
            {!isPausedLongerThanAWeek && (
                <>
                    {' '}
                    <Title level={3} className="address-entry-title condensed-txt">
                        {formTitle}
                    </Title>
                    <Controller
                        name="orderAddress"
                        control={control}
                        render={({ field }) => {
                            return (
                                <>
                                    <label htmlFor="orderAddress" className="sr-only">
                                        Order Address
                                    </label>
                                    <PlacesAutocomplete
                                        {...field}
                                        error={
                                            formState?.errors?.orderAddress && !isConceptFetching
                                        }
                                        helperText={formState?.errors?.orderAddress?.message}
                                        placeholder="Address"
                                        id="orderAddress"
                                        onClear={onClearAddress}
                                        isAddressFetching={isConceptFetching}
                                    />
                                </>
                            );
                        }}
                    />
                </>
            )}
            {isConceptFetching && (
                <div className="spin-wrapper">
                    <LoadingOutlined style={{ fontSize: '2em' }} />
                </div>
            )}

            {locationStatus?.isNewConcept && (
                <Alert className="mb-10" message={newConceptMatchMessage} type="warning" showIcon />
            )}

            {isPausedLongerThanAWeek && <LocationCLosed onClearAddress={onClearAddress} />}

            {watchOrderAddress?.conceptDetails && !isConceptFetching && !isPausedLongerThanAWeek && (
                <Space
                    direction="vertical"
                    size={24}
                    style={watchOrderAddress ? { flex: 'none' } : { flex: '1 1 auto' }}
                    className="extended-content width-100"
                >
                    <Controller
                        name="orderAddressApt"
                        control={control}
                        render={({ field }) => (
                            <>
                                <label htmlFor="orderAddressApt" className="sr-only">
                                    Order Address Apartment
                                </label>
                                <Input
                                    id="orderAddressApt"
                                    {...field}
                                    placeholder="Apartment/unit number"
                                    autoComplete="off"
                                    size="large"
                                />
                            </>
                        )}
                    />
                    {locationStatus != null && !locationStatus?.isOpen ? (
                        <Alert message={generateDynammicMessage()} type="warning" showIcon />
                    ) : (
                        <></>
                    )}
                    <Controller
                        name="deliveryInstructions.preference"
                        control={control}
                        render={({ field }) => {
                            return (
                                <>
                                    <label
                                        className="form-label"
                                        htmlFor="deliveryInstructions.preference"
                                    >
                                        <Title level={4} className="condensed-txt">
                                            Delivery preferences
                                        </Title>
                                    </label>
                                    <RadioButtonGroup
                                        {...radioButtonProps}
                                        {...field}
                                        onChange={e => {
                                            onChangedRadio(e);
                                            field.onChange(e);
                                        }}
                                    />
                                </>
                            );
                        }}
                    />
                    <Controller
                        name="deliveryInstructions.text"
                        control={control}
                        render={({ field }) => (
                            <>
                                <label className="form-label" htmlFor="deliveryInstructions.text">
                                    <Title level={4} className="condensed-txt">
                                        Delivery instructions
                                        <span> (optional)</span>
                                    </Title>{' '}
                                </label>
                                <TextArea
                                    {...field}
                                    id="deliveryInstructions.text"
                                    rows={3}
                                    placeholder={`Add any additional notes to help your driver find you (optional)`}
                                />
                            </>
                        )}
                    />
                </Space>
            )}
        </>
    );
};

interface IOrderAddressEntriesProps {
    isPausedLongerThanAWeek: boolean | null | undefined;
    formTitle: string;
    isInputTouched: boolean;
    watchOrderAddress: any;
    control: any;
    formState: FormState<FieldValues>;
    onClearAddress: () => void;
    generateDynammicMessage: () => string | null;
    onChangedRadio: (e: RadioChangeEvent) => void;
}

const radioButtonProps = {
    options: [
        { label: 'Meet the driver at the door', value: 'Meet the driver at the door' },
        {
            label: 'Contactless delivery (leave food at door)',
            value: 'Contactless delivery (leave food at door)',
        },
    ],
};
