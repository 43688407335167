import { CloseOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { AppContext, BrandTemplate } from '../../contexts/app-context';
import { getCurrentPage } from '../../router';
import { useMobileScreen } from '../utils/general';
import './index.scss';

type GlobalBannerType = BrandTemplate['banners']['global'];

interface BannerAvailType {
    addressPage: boolean;
    menuPage: boolean;
    orderConfirmationPage: boolean;
    checkOutPage: boolean;
}

type AvailablePages = 'addressPage' | 'menuPage' | 'orderConfirmationPage' | 'checkOutPage';

const mappingCurrentPageToBannerPage: { [k: string]: string } = {
    'Address Entry': 'addressPage',
    Menu: 'menuPage',
    'Order Confirmation': 'orderConfirmationPage',
    Checkout: 'checkOutPage',
    backup: 'addressPage',
};

export const GlobalBanner = ({ getHeaderHeight }: { getHeaderHeight?: any }) => {
    const { template, stateIds, updateBannerShown } = useContext(AppContext);
    const [bannerContent, setBannerContent] = useState('');
    const isMobile = useMobileScreen();

    const [turnOffBannerThisSession, setTurnOffBannerThisSession] = useState(() => {
        const turnOffBannerSessionId = localStorage.getItem('turnOffBannerSession');
        return stateIds.sessionId === turnOffBannerSessionId;
    });
    const [pagesShowBanner, setPagesShowBanner] = useState<BannerAvailType>({
        addressPage: false,
        menuPage: false,
        orderConfirmationPage: false,
        checkOutPage: false,
    });

    const setBannerAvailabilities = (globalBanner: GlobalBannerType) => {
        if (!globalBanner) {
            return;
        }
        const bannerAvailObj = {
            addressPage: globalBanner.show_banner_pages?.address_entry_page ?? false,
            menuPage: globalBanner.show_banner_pages?.menu_page ?? false,
            orderConfirmationPage: globalBanner.show_banner_pages?.order_confirmation_page ?? false,
            checkOutPage: globalBanner.show_banner_pages?.checkout_page ?? false,
        };
        setPagesShowBanner(bannerAvailObj);
    };

    const currentPage = useMemo(getCurrentPage, []);
    const showBanner = useMemo(() => {
        const pageKey: AvailablePages = mappingCurrentPageToBannerPage[
            currentPage
        ] as AvailablePages;
        const showBannerOnCurrentPage = pagesShowBanner[pageKey];
        return showBannerOnCurrentPage && !turnOffBannerThisSession && !!bannerContent;
    }, [pagesShowBanner, turnOffBannerThisSession, currentPage, bannerContent]);

    const bannerStyles = {
        backgroundColor: template?.banners?.global?.body_color,
        color: template?.banners?.global?.font_color,
        marginTop: isMobile ? '0px' : '20px',
        borderRadius: isMobile ? '0px' : '12px',
    };

    useEffect(() => {
        updateBannerShown(showBanner);
    }, [showBanner, updateBannerShown]);

    useEffect(() => {
        if (template) {
            setBannerContent(template?.banners?.global?.banner_content ?? '');
            setBannerAvailabilities(template?.banners?.global);
        }
    }, [template]);

    useEffect(() => {
        const turnOffBannerSessionId = localStorage.getItem('turnOffBannerSession');
        if (stateIds.sessionId === turnOffBannerSessionId) {
            setTurnOffBannerThisSession(true);
            getHeaderHeight(150);
        }
    }, [stateIds, getHeaderHeight]);

    const turnOffBanner = () => {
        localStorage.setItem('turnOffBannerSession', stateIds.sessionId);
        getHeaderHeight(150);

        setTurnOffBannerThisSession(true);
    };

    return showBanner ? (
        <div className="banner-wrapper" style={bannerStyles}>
            <ReactMarkdown className="banner-markdown">{bannerContent}</ReactMarkdown>
            <CloseOutlined onClick={turnOffBanner} className="close-banner" />
        </div>
    ) : (
        <></>
    );
};
