import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Carousel, Modal } from 'antd';
import parse from 'html-react-parser';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { TemplateButton } from '../ui-components/template-button';
import { withAppRoot } from '../utils/general';
import useSegment from '../utils/segment';

import './index.scss';

interface OnboardingStep {
    image: string;
    title: string;
    description: string;
}

const steps: OnboardingStep[] = [
    {
        image: 'src/assets/img/brand/dinnerbell/Onboarding-1.jpg',
        title: 'Food you can feel good about.',
        description:
            'We nourish busy families with delicious, homestyle, heat-and-serve dinners that even your picky eaters will love.',
    },
    {
        image: 'src/assets/img/brand/dinnerbell/Onboarding-2.jpg',
        title: 'Delivering dinnertime joy.',
        description:
            'Our freshly prepared meals can be ordered ahead and delivered straight to your door any weeknight.',
    },
    {
        image: 'src/assets/img/brand/dinnerbell/Onboarding-3.jpg',
        title: 'Dinner, as good as done.',
        description:
            'All meals come with easy reheating instructions, which means dinner is effortlessly ready when you are.',
    },
];

export const OnboardingModal: FC = () => {
    const carousel = useRef<any>();
    const segment = useSegment();
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [showStepNav, setShowStepNav] = useState<boolean>(true);
    const [buttonClass, setButtonClass] = useState<string>('button-skip');
    const [buttonText, setButtonText] = useState<string>('Skip');
    const [swipe, setSwipe] = useState<boolean>(true);
    const [onboardingComplete, setOnboardingComplete] = useState<boolean>(
        localStorage.getItem('onboardingComplete') === 'true',
    );

    useEffect(() => {
        if (Capacitor.isPluginAvailable('StatusBar') && !onboardingComplete) {
            StatusBar.setStyle({ style: Style.Light });
        }
    }, [onboardingComplete]);

    useEffect(() => {
        if (currentStepIndex === steps.length - 1) {
            setButtonClass('button-continue');
            setButtonText('Get started');
            setShowStepNav(false);
            setSwipe(false);
        } else {
            setButtonClass('button-skip');
            setButtonText('Skip');
            setShowStepNav(true);
        }
        if (!onboardingComplete) {
            segment.flowViewed(currentStepIndex + 1, steps.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStepIndex, onboardingComplete]);

    const goTo = (stepIndex: number) => carousel.current.goTo(stepIndex);

    const activeClass = (stepIndex: number) => (stepIndex === currentStepIndex ? 'active' : '');

    const completeOnboarding = () => {
        localStorage.setItem('onboardingComplete', 'true');
        setOnboardingComplete(true);
        if (Capacitor.isPluginAvailable('StatusBar')) {
            StatusBar.setStyle({ style: Style.Dark });
        }
        if (buttonText === 'Get started') {
            segment.getStartedCTAClicked();
        } else {
            segment.skipOnboardingLinkClicked();
        }
    };

    const StepNav = () => (
        <>
            {showStepNav && (
                <div className="step-nav">
                    {steps.map((step, i) => (
                        <div
                            key={i}
                            className={`step-nav-button ${activeClass(i)}`}
                            onClick={() => goTo(i)}
                        ></div>
                    ))}
                </div>
            )}
        </>
    );

    return (
        <Modal
            className="onboarding-modal"
            centered
            visible={!onboardingComplete}
            closable={false}
            onCancel={completeOnboarding}
            footer={[
                <StepNav />,
                <TemplateButton key="continue" className={buttonClass} onClick={completeOnboarding}>
                    {buttonText}
                </TemplateButton>,
            ]}
        >
            <Carousel
                ref={carousel}
                dots={false}
                infinite={false}
                swipe={swipe}
                beforeChange={(from, to) => setCurrentStepIndex(to)}
            >
                {steps.map((step, i) => (
                    <Fragment key={i}>
                        <div
                            className="step-image"
                            style={{
                                backgroundImage: `url(${withAppRoot(step.image)})`,
                            }}
                        ></div>
                        <div className="step-content">
                            <h2>{parse(step?.title || '')}</h2>
                            <h4>{parse(step?.description || '')}</h4>
                        </div>
                    </Fragment>
                ))}
            </Carousel>
        </Modal>
    );
};
