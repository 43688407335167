import { BrandTemplate } from '../../contexts/app-context';
import { CartItemInterface, CustomerInfoInterface } from '../menu/model';
import { placesToAddress } from './address';
import { buildModGroupPayloadFromItem, getPlatform } from './general';

export const getOrderTotalPayload = (
    orderAddress: any,
    restaurantInfo: any,
    cartItems: CartItemInterface[],
    otherTipValue: number,
    customerInfo: CustomerInfoInterface | null,
    vouchers: string[],
    template: BrandTemplate | null,
    cdpLocation: string,
) => {
    const kitchen = restaurantInfo?.delivery;
    const payload = {
        location_id: kitchen?.id,
        app_id: kitchen?.app_id,
        brand_id: template?.metadata.brand.id,
        vouchers,
        customerInfo,
        customer_address: placesToAddress(orderAddress, '', true),
        kitchen_address: kitchen?.address,
        contents: {
            line_items: cartItems.map((item: CartItemInterface) => {
                return {
                    item_id: item.item_id,
                    name: item.name,
                    price: item.item_price,
                    special_instructions: item.special_instructions,
                    quantity: item.quantity,
                    menu_id: item.menu_id,
                    section_id: item.section_id,
                    modifier_groups: buildModGroupPayloadFromItem(item.modifier_groups),
                };
            }),
        },
        other_tip_value: otherTipValue * 100,
        delivery_fee: restaurantInfo?.delivery?.logistics_configs?.[0]?.delivery_fee || 0,
        apply_discounts: cdpLocation === 'Checkout' ? true : false,
        platform: getPlatform(),
    };
    return payload;
};
