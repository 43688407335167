import { Alert, Col, Divider, Form, List, Row } from 'antd';
import React, { FC, useContext } from 'react';
import ForkKnifePlaceholder from '../../../assets/img/fork-knife-placeholder.svg';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import {
    CartItemInterface,
    LineItemInterface,
    MenuItemInterface,
    MenuSectionInterface,
    ModifierGroupsItemInterface,
    WLPPMenuItemInterface,
} from '../menu/model';
import { ImageWithFallback } from '../ui-components/image-with-fallback';
import MinusPlusCircled from '../ui-components/minus-plus-circled/MinusPlusCircled';
import TrashCircled from '../ui-components/trash-circled/TrashCircled';
import { formatMoneyInteger } from '../utils/general';
import useSegment from '../utils/segment';
import './index.scss';

type PageTypes = 'cart' | 'checkout' | 'order-history' | 'item-unavailable-modal';
interface ItemListProps {
    pageType: PageTypes;
    cartItems: CartItemInterface[];
    showPrice?: boolean;
    confAlter?: boolean;
    onCartItemEdit?: (
        section: MenuSectionInterface,
        item: MenuItemInterface,
        idHierarchyArray: string[],
        price: number,
        via: string,
        cartIndex: number,
        isEdit: boolean,
    ) => void;
    unavailableItemIds?: string[];
    closeDrawer?: (e: any) => void;
}

export const ItemList: FC<ItemListProps> = ({
    pageType,
    // closeDrawer,
    cartItems,
    // onCartItemEdit,
    showPrice = true,
    confAlter = false,
    unavailableItemIds = [],
}) => {
    const segment = useSegment();
    const cdpLocation = useContext(CdpLocationContext);
    const { updateCartItems } = useContext(AppContext);
    const {
        conceptDetails: { menuInfo, restaurantInfo },
    } = useContext(ConceptContext);

    const getItemModifiers = (item: LineItemInterface) => {
        const result: { id: string; text: string }[] = [];
        item.modifier_groups.forEach((modifier: ModifierGroupsItemInterface) => {
            modifier.items?.forEach((modifierItem: WLPPMenuItemInterface) => {
                result.push({
                    id: modifierItem.id,
                    text: `- ${modifierItem.title || modifierItem.name}`,
                });
            });
        });

        return result;
    };

    const onClickRemoveItem = (key: number) => {
        segment.productRemoved(cartItems[key]);
        cartItems.splice(key, 1);
        updateCartItems([...cartItems]);
    };

    // const onClickEditItem = (key: number) => {
    //     console.log('editCartItem', cartItems[key]); // eslint-disable-line no-console
    //     const cartItem = cartItems[key];
    //     const selectedItem = findEntity(menuInfo!, 'item', cartItem.item_id);
    //     const idHierarchy = cartItem.idHierarchy;
    //     const itemPrice = cartItem.item_price;
    //     const sectionId = cartItem.idHierarchy[1];
    //     const selectedSection = findEntity(menuInfo!, 'section', sectionId);

    //     if (closeDrawer) {
    //         closeDrawer({ reason: 'Edit' });
    //     }
    //     onCartItemEdit!(
    //         selectedSection,
    //         selectedItem,
    //         idHierarchy,
    //         itemPrice,
    //         'Menu Item',
    //         key,
    //         true,
    //     );
    //     segment.editItemLinkClicked('Cart');
    // }; awaiting product definition on this

    const onClickDecreaseItemQuantity = (key: number) => {
        if (cartItems[key].quantity > 1) {
            segment.cartItemQuantityChanged(cartItems[key], -1, cdpLocation);
            cartItems[key].quantity -= 1;
            cartItems[key].price -= cartItems[key].item_price;
            updateCartItems([...cartItems]);
        }
    };

    const onClickIncreaseItemQuantity = (key: number) => {
        segment.cartItemQuantityChanged(cartItems[key], 1, cdpLocation);
        cartItems[key].quantity += 1;
        cartItems[key].price += cartItems[key].item_price;
        updateCartItems([...cartItems]);
    };

    if (
        (menuInfo?.app_id == null || restaurantInfo == null || restaurantInfo.delivery == null) &&
        pageType !== 'order-history'
    ) {
        return null;
    }

    const placeholderImageStyling = () => {
        return {
            background: '#F0F0F0',
            width: '100%',
            padding: '5px',
        };
    };

    const getAlert = (isUnavailableItem: boolean, anyUnavailableModItems: boolean) => {
        if (isUnavailableItem) {
            return (
                <Alert
                    type="error"
                    message="Out of stock"
                    showIcon
                    style={{ marginBottom: '10px' }}
                />
            );
        } else if (anyUnavailableModItems) {
            return (
                <Alert
                    type="warning"
                    message="One or more of the customizations you made to this item are unavailable."
                    showIcon
                    style={{ marginBottom: '10px' }}
                />
            );
        }
        return null;
    };

    return (
        <Form.Item className="list-ordered-container" initialValue={cartItems}>
            {cartItems.length > 0 && (
                <List
                    dataSource={cartItems}
                    itemLayout="horizontal"
                    renderItem={(item, key) => {
                        const isUnavailableItem = unavailableItemIds.includes(item.item_id);

                        let anyUnavailableModItems = false;
                        if (!isUnavailableItem) {
                            const modIds = getItemModifiers(item).map(modItem => modItem.id);
                            unavailableItemIds.forEach(itemId => {
                                if (modIds.includes(itemId)) {
                                    anyUnavailableModItems = true;
                                }
                            });
                        }

                        return (
                            <>
                                <List.Item
                                    key={key}
                                    className={`${pageType === 'checkout' ? 'no-border' : ''} ${
                                        pageType === 'item-unavailable-modal' ? 'item-border' : ''
                                    }`}
                                >
                                    {getAlert(isUnavailableItem, anyUnavailableModItems)}
                                    <Row className={`${pageType}-detail`}>
                                        <Col span={6} className="list-item-image-box">
                                            <ImageWithFallback
                                                alt=""
                                                src={item.images?.[0]?.url || ForkKnifePlaceholder}
                                                className={`card-image ${pageType}-detail ${
                                                    isUnavailableItem ? 'unavailable-item' : ''
                                                } `}
                                                style={
                                                    !item.images?.[0]?.url
                                                        ? placeholderImageStyling()
                                                        : {}
                                                }
                                                resize={200}
                                            />
                                        </Col>
                                        <Col span={18}>
                                            <Row className={`${pageType}-detail`}>
                                                <Col span={24}>
                                                    <div
                                                        className={`${pageType}-detail list-item-detail-box`}
                                                    >
                                                        <List.Item.Meta
                                                            title={
                                                                pageType === 'checkout' ||
                                                                pageType ===
                                                                    'item-unavailable-modal' ||
                                                                pageType === 'order-history' ? (
                                                                    <div className="checkout-list-meta">
                                                                        {!confAlter && (
                                                                            <span
                                                                                className={`qty-badge ${
                                                                                    isUnavailableItem
                                                                                        ? 'unavailable-item'
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                {item.quantity}
                                                                            </span>
                                                                        )}
                                                                        <span
                                                                            className={`title ${
                                                                                isUnavailableItem
                                                                                    ? 'unavailable-item'
                                                                                    : ''
                                                                            }`}
                                                                        >
                                                                            {item.name}
                                                                        </span>
                                                                        {showPrice ? (
                                                                            <span
                                                                                className={`price ${
                                                                                    isUnavailableItem
                                                                                        ? 'hide-price'
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                {formatMoneyInteger(
                                                                                    item.price,
                                                                                )}
                                                                            </span>
                                                                        ) : (
                                                                            <span></span>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <span className="cart-title">
                                                                        {item.name}
                                                                        <span className="list-item-price">
                                                                            {formatMoneyInteger(
                                                                                item.price,
                                                                            )}
                                                                        </span>
                                                                    </span>
                                                                )
                                                            }
                                                            description={
                                                                <div
                                                                    className={`${
                                                                        [
                                                                            'checkout',
                                                                            'item-unavailable-modal',
                                                                        ].includes(pageType) &&
                                                                        !confAlter &&
                                                                        ' checkout-item-meta'
                                                                    }`}
                                                                >
                                                                    {confAlter && (
                                                                        <span className="qty">
                                                                            Qty: {item.quantity}
                                                                        </span>
                                                                    )}
                                                                    {getItemModifiers(item).map(
                                                                        (
                                                                            cart: any,
                                                                            index: number,
                                                                        ) => {
                                                                            const isUnavailableModItem =
                                                                                unavailableItemIds.includes(
                                                                                    cart.id,
                                                                                );
                                                                            let modItemClass = '';
                                                                            if (
                                                                                pageType ===
                                                                                'item-unavailable-modal'
                                                                            ) {
                                                                                if (
                                                                                    isUnavailableItem
                                                                                ) {
                                                                                    modItemClass =
                                                                                        'unavailable-item';
                                                                                } else if (
                                                                                    isUnavailableModItem
                                                                                ) {
                                                                                    modItemClass =
                                                                                        'unavailable-mod-item';
                                                                                } else {
                                                                                    modItemClass =
                                                                                        'mod-item';
                                                                                }
                                                                            }

                                                                            return (
                                                                                <div
                                                                                    className={
                                                                                        modItemClass +
                                                                                        'item-description'
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    {cart.text}
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                                    {getItemModifiers(item)
                                                                        .length === 0 &&
                                                                        pageType ===
                                                                            'order-history' && (
                                                                            <>
                                                                                <br />
                                                                                <br />
                                                                            </>
                                                                        )}
                                                                    {getItemModifiers(item)
                                                                        .length === 1 &&
                                                                        pageType ===
                                                                            'order-history' && (
                                                                            <br />
                                                                        )}
                                                                    {item.special_instructions && (
                                                                        <span className="list-item-special_instructions">
                                                                            Special Instructions:{' '}
                                                                            {
                                                                                item.special_instructions
                                                                            }
                                                                            <br />
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            }
                                                        />
                                                        {pageType === 'cart' && (
                                                            <Col span={24}>
                                                                {/* <div className="ant-list-item-buttons edit">
                                                                <Button
                                                                    data-testid="cart-item-edit-btn"
                                                                    type="link"
                                                                    className={`${
                                                                        isMobile
                                                                            ? 'cart-item-edit-btn--mobile'
                                                                            : ''
                                                                    } cart-item-edit-btn no-bg`}
                                                                    onClick={() =>
                                                                        onClickEditItem(key)
                                                                    }
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </div> awaiting product definition */}
                                                                {/* {getItemModifiers(item).map(
                                                                    modifier => (
                                                                        <div className="item-description">
                                                                            {modifier.text}
                                                                        </div>
                                                                    ),
                                                                )} */}

                                                                <div className="ant-list-item-buttons remove">
                                                                    <span>
                                                                        {item.quantity > 1 ? (
                                                                            <MinusPlusCircled
                                                                                className="cart-item-subtract active"
                                                                                sign="minus"
                                                                                onClick={() =>
                                                                                    onClickDecreaseItemQuantity(
                                                                                        key,
                                                                                    )
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <TrashCircled
                                                                                className="cart-item-subtract active"
                                                                                onClick={() =>
                                                                                    onClickRemoveItem(
                                                                                        key,
                                                                                    )
                                                                                }
                                                                            />
                                                                        )}
                                                                        <span className="cart-item-qty">
                                                                            {item.quantity}
                                                                        </span>
                                                                        <MinusPlusCircled
                                                                            className={`cart-item-add`}
                                                                            onClick={() =>
                                                                                onClickIncreaseItemQuantity(
                                                                                    key,
                                                                                )
                                                                            }
                                                                            sign="plus"
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </List.Item>
                                <Divider className="cart-divider-filled" />
                            </>
                        );
                    }}
                />
            )}
        </Form.Item>
    );
};
