import { Button, Col, Row, Typography } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC, useContext, useState } from 'react';
import { AppContext } from '../../../contexts/app-context';
import { useMobileScreen } from '../../utils/general';
import useSegment from '../../utils/segment';
import { AddressItem } from './address-item';
import { AddressList } from './address-list';
import { NoAddress } from './no-address';

// const USER_ID = 'b7b94c15-c38f-4d3b-8e70-96c15f83f376';

export const AccountAddress: FC = () => {
    const [isNewAddress, setIsNewAddress] = useState(false);
    const [expandId, setExpandId] = useState('');

    const { Title } = Typography;
    const isMobile = useMobileScreen();
    const ldFlags = useFlags();
    const segment = useSegment();

    const handleAddClick = () => {
        segment.editProfileAddressClicked('Add Address');
        setIsNewAddress(true);
    };

    const { userProfile } = useContext(AppContext);

    const defaultAddressFormValue = {
        id: '',
        address1: '',
        address2: '',
        city: '',
        region: '',
        postalcode: '',
        country: '',
        default_address: false,
        delivery_preference: '',
        special_instructions: '',
    };
    const NewAddress = () => (
        <div id="address-item">
            <AddressItem
                customerAddress={defaultAddressFormValue}
                setIsNewAddress={setIsNewAddress}
                expandId={expandId}
                setExpandId={setExpandId}
            ></AddressItem>
        </div>
    );
    return (
        ldFlags.enableAccounts && (
            <>
                <Col span={24} className="address-wrapper bg-white">
                    <Row className="address-container width-100">
                        <Row
                            align="bottom"
                            justify="space-between"
                            className="address-header width-100 mb-20"
                        >
                            <Col span={18}>
                                <Title level={isMobile ? 3 : 2} style={{ marginBottom: 0 }}>
                                    Addresses
                                </Title>
                            </Col>
                            <Col span={6}>
                                <Button
                                    type="link"
                                    onClick={handleAddClick}
                                    data-testid="add-address-button"
                                    className="add-link-button"
                                >
                                    Add
                                </Button>
                            </Col>
                        </Row>
                        {isNewAddress && <NewAddress />}
                        {userProfile?.service_info?.length &&
                        userProfile.service_info.length > 0 ? (
                            <AddressList expandId={expandId} setExpandId={setExpandId} />
                        ) : (
                            !isNewAddress && <NoAddress addClick={handleAddClick} />
                        )}
                    </Row>
                </Col>
                <h1></h1>
            </>
        )
    );
};
