import { Divider } from 'antd';
import React, { FC, useContext } from 'react';
import { AppContext } from '../../../contexts/app-context';
import { useMobileScreen } from '../../utils/general';
import { AddressItem } from './address-item';

type AddressListProp = {
    expandId: string;
    setExpandId: (id: string) => void;
};
export const AddressList: FC<AddressListProp> = ({ expandId, setExpandId }) => {
    const { userProfile } = useContext(AppContext);
    const isMobile = useMobileScreen();
    return (
        <>
            <div className="address-list-container" data-testid="address-list">
                {isMobile && <Divider />}
                {userProfile?.service_info?.map(address => {
                    return (
                        <div key={address.id} className="width-100">
                            <div id="address-item" data-testid="address-item">
                                <AddressItem
                                    customerAddress={address}
                                    expandId={expandId}
                                    setExpandId={setExpandId}
                                ></AddressItem>
                            </div>
                            {isMobile && <Divider />}
                        </div>
                    );
                })}
            </div>
        </>
    );
};
