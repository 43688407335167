import { DollarOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputRef, Row, Space, Tabs } from 'antd';
import log from 'loglevel';
import React, {
    ChangeEvent,
    FC,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { AppContext } from '../../contexts/app-context';
import { getCurrentPage } from '../../router';
import { tipPercentages } from '../menu/model';
import { TemplateButton } from '../ui-components/template-button';
import useSegment from '../utils/segment';

const { TabPane } = Tabs;

export const DriverTipSelector: FC<IDriverTipSelctorProps> = ({
    orderTotals,
    tabPosition,
    setTabPosition,
    setOtherTipValue,
    setFetchDiscounts,
}) => {
    const {
        selectedTipOption,
        tipValue: contextTipValue,
        template,
        updateSelectedTipOption,
        updateOrderTotal,
        updateTipValue,
    } = useContext(AppContext);
    const [prevTabPosition, setPrevTabPosition] = useState(2);
    const [tipTouched, setTipTouched] = useState<boolean>(false);
    const [tempTip, setTempTip] = useState<string>(() => {
        const customTipAmount = orderTotals.raw_values.tip.other.tip_amount;
        return customTipAmount ? (customTipAmount / 100).toString() : '';
    });
    const [tipUpdated, setTipUpdated] = useState<boolean>(() => {
        if (
            typeof selectedTipOption === 'number' &&
            typeof contextTipValue === 'number' &&
            selectedTipOption === 3
        ) {
            return true;
        } else {
            return false;
        }
    });

    const segment = useSegment();
    const otherTipRef = useRef<InputRef>(null);
    const showTip: boolean = template?.checkout_page?.show_tip_ui === false ? false : true;
    const tabOptions = ['10%', '15%', '20%', 'Custom'];
    const tabOptionDescriptions = [
        { amount: orderTotals.tip.ten_percent.tip_amount, description: `${tabOptions[0]} Tip` },
        { amount: orderTotals.tip.fifteen_percent.tip_amount, description: `${tabOptions[1]} Tip` },
        { amount: orderTotals.tip.twenty_percent.tip_amount, description: `${tabOptions[2]} Tip` },
        { amount: null, description: tabOptions[3] },
    ];

    const saveTipValue = useCallback(
        (newValue: number): void => {
            log.debug('%c TIP VALUE ', 'background-color: red', newValue);
            newValue = Math.round(newValue) || 0;
            updateTipValue(newValue);
        },
        [updateTipValue],
    );

    useEffect(() => {
        if (!showTip && !tempTip) {
            setOtherTipValue(0);
            saveTipValue(0);
            setTabPosition(3);
            setTipUpdated(true);
        }
    }, [showTip, saveTipValue, setTabPosition, setOtherTipValue, tempTip]);

    useEffect(() => {
        try {
            const tipPercentage = tipPercentages[tabPosition];
            if (tabPosition !== 3) {
                setTipUpdated(false);
                const tip = orderTotals.tip[tipPercentage].tip_amount || '$0';
                saveTipValue(parseFloat(tip.substring(1)) * 100);
            }
            updateSelectedTipOption(tabPosition);
            updateOrderTotal(orderTotals.raw_values.tip[tipPercentage].order_total);
        } catch (err) {
            log.error('Error setting orderTotals on selection', err);
        }
    }, [
        tabPosition,
        orderTotals,
        saveTipValue,
        updateSelectedTipOption,
        updateOrderTotal,
        tempTip,
    ]);

    const handleUpdateTip = (tempTip: string): void => {
        const value = parseFloat(tempTip);
        setFetchDiscounts(true);
        setOtherTipValue(value);
        saveTipValue(value * 100);
        setTipTouched(true);
        setTipUpdated(true);
    };

    const handleOnChange = (key: string) => {
        // Other tab position
        if (tabPosition === 3) {
            setPrevTabPosition(tabPosition);
            const tipPercentage = tipPercentages[tabPosition];
            const tip = orderTotals.tip[tipPercentage].tip_amount;
            saveTipValue(parseFloat(tip.substring(1)) * 100);
        } else {
            saveTipValue(parseFloat(tempTip) * 100);
        }
        if (key === '3') {
            otherTipRef?.current?.input?.focus();
            if (tabPosition === 3) {
                // allows the tip box to be reopened if clicked "other" twice.
                setTipUpdated(false);
            }
        }
        setTabPosition(parseInt(key, 10));
        segment.tipOptionSelected(tabOptions[parseInt(key, 10)]);
    };

    const decimalFormattedValue = (value: string): string => {
        const split = value.split('.');
        const dollars = split[0] + (value.includes('.') ? '.' : '');
        const cents = split?.[1]?.slice(0, 2) || '';
        return `${dollars}${cents}`;
    };

    const onChangeCustomTip = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.startsWith('.')) {
            setTempTip('');
        } else {
            const scrubbedValue = e.target.value.replace(/[$&+,:;=?@#|'<>-^*()%!/_-{}""]/g, '');
            const noDuplicateDots = scrubbedValue.replace(/(\.[^.]*)\./g, '$1');

            setTempTip(decimalFormattedValue(noDuplicateDots));
        }
    };

    const CartTabs = () => (
        <div className="cart-tabs">
            <p>100% of your tip goes to your driver.</p>
            <Tabs
                onChange={key => handleOnChange(key)}
                type="card"
                activeKey={tabPosition.toString()}
            >
                {tabOptionDescriptions.map((tab, index) => (
                    <TabPane
                        tab={
                            <Space className="pb-5" align="center" direction="vertical" size={0}>
                                {tab.amount ? (
                                    <>
                                        <b>{tab.amount}</b>
                                        <div>{tab.description}</div>
                                    </>
                                ) : (
                                    <b>{tab.description}</b>
                                )}
                            </Space>
                        }
                        key={index}
                    ></TabPane>
                ))}
            </Tabs>
        </div>
    );

    const OtherTipInput = () => (
        <Row className="my-10">
            <Col span={24}>
                <Input
                    prefix={<DollarOutlined />}
                    value={tempTip}
                    size="large"
                    onChange={e => onChangeCustomTip(e)}
                    type={getCurrentPage() === 'Checkout' ? 'text' : 'hidden'}
                    data-testid="checkout-order-summary-tip-value"
                    ref={otherTipRef}
                />
            </Col>
        </Row>
    );

    const ActionButtonGroup = () => (
        <Row gutter={10}>
            <Col span={12}>
                <Button
                    type="default"
                    size="large"
                    onClick={() => {
                        if (!tipTouched) {
                            setTabPosition(prevTabPosition);
                        }
                        setTipUpdated(true);
                    }}
                    data-testid="checkout-order-summary-tip-cancel"
                >
                    Cancel
                </Button>
            </Col>
            <Col span={12}>
                <TemplateButton
                    type="primary"
                    disabled={tempTip === ''}
                    onClick={() => handleUpdateTip(tempTip)}
                    size="large"
                    data-testid="checkout-order-summary-tip-submit"
                >
                    Update tip
                </TemplateButton>
            </Col>
        </Row>
    );

    return (
        <>
            <div className="checkout-subtitle">Driver tip</div>
            <div className="checkout-tip-box innerbox">
                <CartTabs />
                <div
                    className="checkout-order-summary-tip"
                    style={
                        tabPosition !== 3 || tipUpdated ? { display: 'none' } : { height: '90px' }
                    }
                >
                    {OtherTipInput()}
                    <ActionButtonGroup />
                </div>
            </div>
        </>
    );
};

interface IDriverTipSelctorProps {
    orderTotals: any;
    tabPosition: number;
    setTabPosition: (tabPosition: number) => void;
    setOtherTipValue: (tipValue: number) => void;
    setFetchDiscounts: (value: boolean) => void;
}
