import { RightOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC, useContext } from 'react';
import { AppContext } from '../../../contexts/app-context';
import { useFutureOrder } from '../../../hooks/useFutureOrder';
import { FutureOrderPopup } from '../../ui-components/future-order-components/future-order-popup';
import '../../../modules/ui-components/future-order-components/future-order-popup.scss';
import { ChangeAddressPopover } from '../change-address-popover';
import { MobileHeaderContentLamvEnabled } from './mobile-header-content-lamv-enabled';
import styles from './mobile-header-content.module.scss';

type MobileHeaderProps = {
    isAccountDropdownOpen?: boolean | undefined;
};

export const MobileHeaderContent: FC<MobileHeaderProps> = ({ isAccountDropdownOpen }) => {
    const ldFlags = useFlags();
    const { Text } = Typography;
    const { orderAddress } = useContext(AppContext);
    const {
        deliveryTime,
        setDeliveryTime,
        isFutureOrderPopoverVisible,
        toggleFutureOrderPopover,
        supportScheduleOrder,
    } = useFutureOrder();

    const PopoverAddressText = (
        <Text
            className={styles['header-action-item']}
            aria-label="Open input field to enter your delivery address"
            strong
        >
            <span className="no-wrap mr-10">
                {orderAddress?.structured_formatting?.main_text ?? `Your Address`}
            </span>
        </Text>
    );

    return ldFlags.enableLamv ? (
        <MobileHeaderContentLamvEnabled isAccountDropdownOpen={isAccountDropdownOpen} />
    ) : (
        <section>
            <Row className={styles['mobile-header-wrapper']} align="middle">
                <Col span={22}>
                    {'Deliver '}
                    <wbr></wbr>
                    {orderAddress ? (
                        <Text
                            strong
                            onClick={() => {
                                if (supportScheduleOrder) {
                                    toggleFutureOrderPopover({ action: 'Edit' });
                                }
                            }}
                            className={`no-wrap header-action-item ${
                                supportScheduleOrder ? styles['future-order-avail'] : ''
                            }`}
                        >
                            {deliveryTime}&nbsp;&nbsp;
                        </Text>
                    ) : (
                        <Text strong>{`ASAP `}</Text>
                    )}
                    <wbr></wbr>
                    {'to '}
                    <ChangeAddressPopover children={PopoverAddressText} placement="bottomRight" />
                </Col>
                {supportScheduleOrder && (
                    <Col
                        span={1}
                        offset={1}
                        onClick={() => toggleFutureOrderPopover({ action: 'Edit' })}
                        style={{ cursor: 'pointer' }}
                    >
                        <RightOutlined type="primary" />
                    </Col>
                )}
            </Row>
            {isFutureOrderPopoverVisible && (
                <FutureOrderPopup
                    onCancel={toggleFutureOrderPopover}
                    setDeliveryTime={setDeliveryTime}
                />
            )}
        </section>
    );
};
