import { useAuth0 } from '@auth0/auth0-react';
import { Form, FormInstance, Input, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { Auth } from '../../../../services/Auth';
import { UserProfile } from '../../../../services/UserProfile';
import { IUserProfile } from '../../../contexts/app-context';
import { TemplateButton } from '../../ui-components/template-button';
import { useMobileScreen } from '../../utils/general';
import { digitOnlyPhoneNumberFormat, formatPhoneNumber } from '../../utils/phone';
import useSegment from '../../utils/segment';
import { ExpandableSection, ExpandableSectionProps } from './expandable-section';

type UserInfoSectionProps = {
    userProfile: IUserProfile;
    updateUserProfileContext: (userProfile: IUserProfile) => void;
};

const fieldNameToLabelMap = {
    name: 'Name',
    phone_number: 'Phone Number',
};
export const UserInfoSection: React.FC<UserInfoSectionProps> = ({
    userProfile,
    updateUserProfileContext,
}) => {
    const [profileForm]: [FormInstance<Partial<IUserProfile>>] = Form.useForm();
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [stepOpenState, setStepOpenState] = useState({
        nameField: false,
        phoneField: false,
    });
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [userProfileOptimisticCopy, setUserProfileOptimisticCopy] = useState(userProfile);
    const { getAccessTokenSilently } = useAuth0();
    const isMobile = useMobileScreen();
    type FieldName = keyof typeof stepOpenState;
    const userName =
        userProfileOptimisticCopy?.name && !userProfileOptimisticCopy?.name.includes('@')
            ? userProfileOptimisticCopy?.name
            : '';
    const isUserSSOLogin = userProfile.user_id.split('|')[0] !== 'auth0';
    const segment = useSegment();
    const submitUserProfileChanges = async (modifiedUserProfileProps: Partial<IUserProfile>) => {
        modifiedUserProfileProps.phone_number = digitOnlyPhoneNumberFormat(
            modifiedUserProfileProps?.phone_number,
        );
        const fieldLabel =
            fieldNameToLabelMap[
                Object.keys(modifiedUserProfileProps)[0] as 'name' | 'phone_number'
            ];

        try {
            const optimisticUser = {
                ...userProfileOptimisticCopy,
                ...modifiedUserProfileProps,
            } as IUserProfile;
            await setUserProfileOptimisticCopy(optimisticUser);
            setStepOpenState({
                nameField: false,
                phoneField: false,
            });
            const token = await getAccessTokenSilently();
            const updatedUserProfile = await UserProfile.updateUserProfile(
                token,
                modifiedUserProfileProps,
            );
            await setUserProfileOptimisticCopy(updatedUserProfile);
            await updateUserProfileContext(updatedUserProfile);
            segment.profileInfoIdentify(updatedUserProfile.name, updatedUserProfile.phone_number);
            notification.success({
                message: `${fieldLabel} saved`,
                placement: 'bottomRight',
            });
        } catch (err) {
            setUserProfileOptimisticCopy(userProfile);
            notification.error({
                message: `Failed to save ${fieldLabel}`,
                placement: 'bottomRight',
            });
        }
    };

    const toggleExpandedContent = (fieldName: 'nameField' | 'phoneField', value: boolean) => {
        if (value) {
            enum FieldAction {
                'nameField' = 'Edit Name',
                'phoneField' = 'Edit Phone',
            }
            segment.editPersonalInfoClicked(FieldAction[fieldName]);
        }
        const newStepOpenState = { ...stepOpenState };
        Object.keys(stepOpenState).forEach(key => {
            if (key === fieldName) {
                newStepOpenState[key as FieldName] = value;
                profileForm.resetFields();
            } else {
                newStepOpenState[key as FieldName] = false;
            }
        });

        setStepOpenState(newStepOpenState);
    };

    const generateActionElement = (fieldName: FieldName) => {
        return stepOpenState[fieldName] ? (
            <p
                onClick={() => toggleExpandedContent(fieldName, false)}
                style={{ color: '#096DD9', cursor: 'pointer' }}
            >
                Cancel
            </p>
        ) : (
            <p
                onClick={() => toggleExpandedContent(fieldName, true)}
                style={{ color: '#096DD9', cursor: 'pointer' }}
            >
                Edit
            </p>
        );
    };

    const getExpanedContentActions = (
        fieldName: FieldName,
    ): {
        label: string;
        onClick?: () => void;
        disabled?: boolean;
        style?: React.CSSProperties;
        htmlType?: 'button' | 'submit';
    }[] =>
        isMobile
            ? [
                  {
                      label: 'Cancel',
                      style: { background: '#fff', color: 'black', border: '1px solid #D9D9D9' },
                      onClick: () => toggleExpandedContent(fieldName, false),
                  },
                  {
                      label: 'Save',
                      htmlType: 'submit',
                      disabled: isSaveDisabled,
                  },
              ]
            : [
                  {
                      label: 'Save',
                      htmlType: 'submit',
                      disabled: isSaveDisabled,
                  },
              ];

    interface SectionContent {
        sectionContent?: JSX.Element;
    }
    const handlePhoneChange = () => {
        const phoneValue = profileForm.getFieldValue('phone_number');
        const formattedValue = formatPhoneNumber(phoneValue);
        profileForm.setFieldsValue({ phone_number: formattedValue });
        profileForm.validateFields(['phone_number']);
    };

    const sectionsTemplate: (ExpandableSectionProps & SectionContent)[] = [
        {
            renderHeaderTitle: () => <h3>Name</h3>,
            description: userName,
            isOpen: stepOpenState.nameField,
            renderHeaderActionElement: () => generateActionElement('nameField'),
            handleMobileBackButton: () => toggleExpandedContent('nameField', false),
            expandedContentActions: getExpanedContentActions('nameField'),
            isShowingFullScreenMobile: true,
            mobileFullScreenHeader: 'Change Name',
            sectionContent: (
                <>
                    {isMobile ? <h3>Name</h3> : null}
                    <Form.Item
                        name="name"
                        initialValue={userName}
                        rules={[
                            { required: true, message: 'This field is required' },
                            {
                                type: 'string',
                                max: 50,
                                message: 'Name must be less than 50 characters',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Full Name"
                            style={!isMobile ? { width: '50%', maxWidth: 350, minWidth: 300 } : {}}
                        />
                    </Form.Item>
                </>
            ),
        },
        {
            renderHeaderTitle: () => <h3>Email</h3>,
            description: userProfileOptimisticCopy?.email ?? '',
            sectionContent: (
                <>
                    {isMobile ? <h3>Email Address</h3> : null}
                    <Input placeholder="Email" />
                </>
            ),
        },
        {
            renderHeaderTitle: () => <h3>Phone Number</h3>,
            description: formatPhoneNumber(userProfileOptimisticCopy?.phone_number),
            isOpen: stepOpenState.phoneField,
            renderHeaderActionElement: () => generateActionElement('phoneField'),
            handleMobileBackButton: () => toggleExpandedContent('phoneField', false),
            expandedContentActions: getExpanedContentActions('phoneField'),
            isShowingFullScreenMobile: true,
            mobileFullScreenHeader: 'Change Phone Number',
            sectionContent: (
                <>
                    {isMobile ? <h3>Phone Number</h3> : null}
                    <Form.Item
                        name="phone_number"
                        initialValue={formatPhoneNumber(userProfileOptimisticCopy?.phone_number)}
                        rules={[
                            { required: true, message: 'This field is required' },
                            {
                                pattern: new RegExp(/\([0-9]{3}\)\s[0-9]{3}\s[0-9]{4}/g),
                                message: 'Enter a valid phone number',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Phone Number"
                            maxLength={14}
                            style={!isMobile ? { width: '50%', maxWidth: 350, minWidth: 300 } : {}}
                            onChange={handlePhoneChange}
                        />
                    </Form.Item>
                </>
            ),
        },
        {
            renderHeaderTitle: () => (!isUserSSOLogin ? <h3>Password</h3> : <></>),
            renderHeaderActionElement: () =>
                !isUserSSOLogin ? (
                    <p
                        onClick={() => {
                            Auth.changePassword(userProfile?.email).then(() =>
                                setShowResetPasswordModal(true),
                            );
                            segment.editPersonalInfoClicked('Change Password');
                        }}
                        style={{ color: '#096DD9', cursor: 'pointer' }}
                    >
                        Change
                    </p>
                ) : (
                    <></>
                ),
        },
    ];

    const renderExpandableSections = () => {
        return sectionsTemplate.map((section, sIndex) => {
            const { sectionContent, ...sectionProps } = section;
            return (
                <ExpandableSection {...sectionProps} key={sIndex}>
                    {' '}
                    {sectionContent}{' '}
                </ExpandableSection>
            );
        });
    };

    const handleActionDisable = () => {
        const shouldDisable = !!profileForm.getFieldsError().filter(({ errors }) => errors.length)
            .length;

        setIsSaveDisabled(shouldDisable);
    };

    return (
        <>
            <article>
                <h2>Personal Information</h2>
                <Form
                    form={profileForm}
                    onFinish={submitUserProfileChanges}
                    onFieldsChange={handleActionDisable}
                >
                    {renderExpandableSections()}
                </Form>
            </article>
            <Modal
                visible={showResetPasswordModal}
                footer={[
                    <TemplateButton onClick={() => setShowResetPasswordModal(false)}>
                        Ok
                    </TemplateButton>,
                ]}
                closable={false}
            >
                <h2>We sent you a password reset link</h2>
                <p>Check {userProfile?.email ?? ''} for a link to change your password.</p>
            </Modal>
        </>
    );
    //rerun cypress
};
