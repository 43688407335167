import { Button, Col, Input, Row } from 'antd';
import log from 'loglevel';
import React, { FC, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AppContext } from '../../contexts/app-context';
import { TemplateButton } from '../ui-components/template-button';
import { useMobileScreen } from '../utils/general';
import useSegment from '../utils/segment';
import { CheckoutForms } from './index';

export const AddressForm: FC<AddressFormProps> = ({
    expand,
    setExpand,
    onClickContinue,
    onCancelEdit,
    onCancel,
    onModalOpen,
}) => {
    const segment = useSegment();
    const isMobile = useMobileScreen();
    const { isDinnerbell, orderAddress, orderAddressApt, updateOrderAddressApt } =
        useContext(AppContext);

    const defaultValues = {
        orderAddressApt,
    };
    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues,
        mode: 'all',
        reValidateMode: 'onBlur',
    });
    const watchOrderAddressApt = watch('orderAddressApt');

    const onClearAddress = () => {
        reset({
            orderAddressApt: '',
        });
    };

    const onCancelSaveAddressUpdate = () => {
        onClearAddress();
        onCancel();
    };

    const onSubmitAptNumberUpdate = (data: any) => {
        log.debug('%c ADDRESS SUBMIT DATA ', 'background-color: green', data);
        updateOrderAddressApt(data.orderAddressApt);
        onClickContinue();
        setExpand(false);
        onCancelEdit(CheckoutForms.DeliveryAddress);
        segment.updateCTAClicked('Update address');
        segment.addressUpdateIdentify(data.orderAddressApt, orderAddress);
    };

    const CancelButton = () => (
        <Button
            type="default"
            className={isMobile ? '' : 'mb-10 mr-10'}
            size="large"
            block={isMobile}
            onClick={onCancelSaveAddressUpdate}
        >
            Cancel
        </Button>
    );

    const UpdateButton = () => (
        <TemplateButton
            type="primary"
            htmlType="submit"
            data-testid="delivery-address-submit-btn"
            size="large"
            block={isMobile}
            disabled={orderAddressApt === watchOrderAddressApt}
        >
            Update
        </TemplateButton>
    );

    const ActionButtons = () => (
        <Row className="action-row mt-10">
            {isMobile ? (
                <>
                    <Col span={12} className="pr-5">
                        <CancelButton />
                    </Col>
                    <Col span={12} className="pl-5">
                        <UpdateButton />
                    </Col>
                </>
            ) : (
                <Col span={24}>
                    <CancelButton />
                    <UpdateButton />
                </Col>
            )}
        </Row>
    );

    const AddressInputField = () => (
        <Col xs={24} md={16}>
            <>
                <label htmlFor="orderAddress" className="sr-only">
                    Checkout Order Address
                </label>
                <Input
                    className="mb-10"
                    placeholder={orderAddress?.formatted_address}
                    disabled
                    size="large"
                />
            </>
        </Col>
    );

    const ChangeAddressLink = () => (
        <a
            className="ant-btn-link no-bg mb-10 pl-5"
            onClick={() => {
                onModalOpen();
            }}
        >
            Change address
        </a>
    );

    return (
        <form
            className={`${expand ? '' : 'ant-collapse-content-hidden'} ${
                isDinnerbell ? 'isDB' : ''
            }`}
            onSubmit={handleSubmit(onSubmitAptNumberUpdate)}
        >
            <Row gutter={10}>
                <AddressInputField />
                <Col xs={24} md={8}>
                    <Controller
                        name="orderAddressApt"
                        control={control}
                        render={({ field }) => (
                            <>
                                <label htmlFor="orderAddressApt" className="sr-only">
                                    Order Address Apartment
                                </label>
                                <Input
                                    {...field}
                                    className="mb-10"
                                    id="delivery-address-apt-input"
                                    placeholder="Apt, Unit or Suite"
                                    autoComplete="off"
                                    size="large"
                                />
                            </>
                        )}
                    />
                </Col>
                <ChangeAddressLink />
            </Row>
            <ActionButtons />
        </form>
    );
};

interface AddressFormProps {
    expand: boolean;
    setExpand: (k: boolean) => void;
    onClickContinue: () => void;
    onCancelEdit: (key: CheckoutForms) => void;
    onCancel: () => void;
    onModalOpen: () => void;
}
