import { CloseOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { BottomDrawerPopup } from '../ui-components/bottom-drawer-popup';
import { TemplateButton } from '../ui-components/template-button';
import {
    generateAuth0RedirectOptions,
    updateLocalStorage,
    useMobileScreen,
} from '../utils/general';
import useSegment from '../utils/segment';
import { AddressFormSteps, OrderAddressForm } from './index';

const linkActions = {
    seeTheMenu: 'See the menu link',
    login: 'Sign in to your account',
};
const styleDrawer = {
    height: '100vh',
    maxHeight: 'unset',
    borderTopLeftRadius: 'unset',
    borderTopRightRadius: 'unset',
};

export const AddressEntryModal = ({
    isModalVisible,
    formTitle,
    onCloseCallback,
    handleSubmit,
}: {
    handleSubmit: () => void;
    onCloseCallback: () => void;
    isModalVisible: boolean;
    formTitle: string;
}) => {
    const isMobile = useMobileScreen();
    const segment = useSegment();
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const { showAddressEntryPopover, userProfile, updateShowAddressEntryPopover, template } =
        useContext(AppContext);
    const { restaurantInfoTempQuery, updateRestaurantInfoTempQuery } = useContext(ConceptContext);
    const cdpLocation = useContext(CdpLocationContext);
    const [currentStep, setCurrentStep] = useState<AddressFormSteps>(AddressFormSteps.AddressEntry);

    const isCheckoutPage = cdpLocation === 'Checkout';
    const ldFlags = useFlags();

    const submitCallback = (formValues?: any) => {
        log.debug('%c form values: ', 'background-color: green', formValues);
        handleSubmit();
        onCloseCallback();
    };
    const onCancelModal = (e?: any) => {
        e?.onCancelForm?.();
        onCloseCallback();
        const via = 'Address Entry Modal';
        const clickAction = e?.reason ? e.reason : 'Close';
        if (showAddressEntryPopover) {
            updateShowAddressEntryPopover(false);
            const isClickOutside =
                (e?.target as HTMLElement)?.classList?.[0] === 'ant-modal-wrap' ||
                (e?.target as HTMLElement)?.classList?.[0] === 'ant-drawer-mask';
            const action =
                e?.type === 'keydown' ? 'Escape' : isClickOutside ? 'Click Outside' : 'X';
            return e?.linkAction === linkActions.login
                ? segment.accountAuthCTAClicked(false, via)
                : e?.linkAction
                ? segment.seeTheMenuCTAClicked(via)
                : segment.cancelAddressEntryLinkClicked(
                      action,
                      restaurantInfoTempQuery,
                      clickAction,
                  );
        }
        segment.cancelAddressEntryLinkClicked(cdpLocation, restaurantInfoTempQuery, clickAction);
        setCurrentStep(AddressFormSteps.AddressEntry);
        return updateRestaurantInfoTempQuery(null);
    };

    const handleLoginClick = () => {
        const auth0Options = generateAuth0RedirectOptions(template);
        updateLocalStorage('signupTouchpoint', 'Address Entry Modal');
        onCancelModal({ linkAction: linkActions.login });
        segment.cancelAddressEntryLinkClicked(linkActions.login, restaurantInfoTempQuery, 'Close');
        loginWithRedirect(auth0Options);
    };

    const isGuestUser = () => {
        const isLoggedIn = isAuthenticated && !isLoading;
        return !userProfile?.service_info?.length || !isLoggedIn;
    };

    return isModalVisible ? (
        <div className="address-form-popover">
            <BottomDrawerPopup
                visible={isModalVisible}
                onClose={onCancelModal}
                closable={true}
                width={400}
                styleDrawer={isCheckoutPage ? styleDrawer : {}}
            >
                <div className="mt-40" style={{ display: 'flex', flexDirection: 'column' }}>
                    {isMobile && (
                        <CloseOutlined
                            className="close-icon"
                            style={{
                                position: 'absolute',
                                left: !isCheckoutPage ? '12px' : 'unset',
                                right: isCheckoutPage ? '12px' : 'unset',
                                top: '15px',
                                fontSize: '24px',
                                color: 'rgba(0, 0, 0, 0.45)',
                            }}
                            onClick={e => onCancelModal(e)}
                        />
                    )}
                    <OrderAddressForm
                        currentFormStep={currentStep}
                        setCurrentFormStep={setCurrentStep}
                        formTitle={formTitle}
                        onSubmit={submitCallback}
                        onCloseModal={onCloseCallback}
                        handleMenuItemMatch={handleSubmit}
                        formControls={(onCancel, formState, forceDisable) => (
                            <section
                                className="controlled-action-buttons"
                                style={{ width: '100vw' }}
                            >
                                <Button
                                    size="large"
                                    block
                                    onClick={() =>
                                        onCancelModal({ onCancelForm: onCancel, reason: 'Cancel' })
                                    }
                                >
                                    Cancel
                                </Button>
                                <TemplateButton
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    id="button"
                                    block
                                    disabled={!formState?.isValid || forceDisable}
                                >
                                    Save
                                </TemplateButton>
                            </section>
                        )}
                    />
                    {showAddressEntryPopover &&
                        isGuestUser() &&
                        (ldFlags.enableAccounts ? (
                            <span className="address-form-links">
                                <a
                                    className="see-menu-link"
                                    onClick={() =>
                                        onCancelModal({ linkAction: linkActions.seeTheMenu })
                                    }
                                >
                                    See the menu
                                </a>
                                <span className="px-10">or</span>
                                <a className="see-menu-link" onClick={handleLoginClick}>
                                    Sign in to your account
                                </a>
                            </span>
                        ) : (
                            <a
                                className="see-menu-link"
                                style={{ marginTop: '10px' }}
                                onClick={() =>
                                    onCancelModal({ linkAction: linkActions.seeTheMenu })
                                }
                            >
                                See the menu
                            </a>
                        ))}
                </div>
            </BottomDrawerPopup>
        </div>
    ) : (
        <></>
    );
};
