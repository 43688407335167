import { CloseOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Radio, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import ForkKnifePlaceholder from '../../../assets/img/fork-knife-placeholder.svg';
import { AppContext } from '../../contexts/app-context';
import { ConceptContext } from '../../contexts/concept-context';
import { TemplateButton } from '../ui-components/template-button';
import {
    formatMoneyInteger,
    hasUnavailableModGroup,
    resizedImagePath,
    sanitizeObject,
    useMobileScreen,
} from '../utils/general';
import useSegment from '../utils/segment';
import {
    MenuItemInterface,
    MenuModifierGroupInterface,
    ModMetadataInterface,
    ModifierGroupsItemInterface,
    QuantityRuleInterface,
    findEntity,
    matchNestedRule,
} from './model';

const { TextArea } = Input;

export const ItemDetailPopup = ({
    show,
    item,
    basePrice,
    idHierarchy,
    isEdit,
    addCart,
    close,
    cartIndex,
    itemDestination,
    openAddressModal,
}: {
    show: boolean;
    item: MenuItemInterface;
    basePrice: number;
    idHierarchy: string[];
    isEdit?: boolean;
    itemDestination: string;
    openAddressModal?: () => void;
    close: () => void;
    addCart: (
        newItemModifier_groups: ModifierGroupsItemInterface[],
        basePrice: number,
        itemBasePrice: number,
        quantity: number,
        selectedItems: Record<string, unknown>,
        isEdit: boolean,
        itemIndex: number,
        specialInstructions: string,
    ) => void;
    cartIndex?: number;
}) => {
    const [quantity, setQuantity] = useState<number>(1);
    const [itemPrice, setItemPrice] = useState<number>(basePrice);
    const [selectedMods, setSelectedMods] = useState<ModMetadataInterface[]>();
    const [formValues, setFormValues] = useState<Record<string, string>>();
    const [form] = Form.useForm();
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [specialInstructions, setSpecialInstructions] = useState<string>('');
    const isMobile = useMobileScreen();
    const segment = useSegment();

    const {
        conceptDetails: { menuInfo, restaurantInfo },
    } = useContext(ConceptContext);
    const { cartItems, template } = useContext(AppContext);

    useEffect(() => {
        setFormValues({});
        // you need the check for show = true because when you close the modal it is still set to
        // isEdit but sets show to false which re-runs the useEffect. This will then set the
        // the quantity back to what the cart edit item was.
        if (isEdit && show && cartIndex != null && cartIndex >= 0) {
            const currentCartItem = cartItems[cartIndex]; //cartItems.find((citem) => citem.item_id === item.id);
            setQuantity(currentCartItem!.quantity);
            setItemPrice(currentCartItem!.item_price);
            form.setFieldsValue(currentCartItem?.selected_items || {});
            setSpecialInstructions(currentCartItem.special_instructions || '');
            setSelectedMods(() => {
                return currentCartItem.modifier_groups.map(modGroup => {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const { modifier_group_id, hierarchy, items } = modGroup;
                    const ids = items.map(({ id }) => id);
                    return {
                        modId: modifier_group_id,
                        idHierarchy: hierarchy,
                        items: ids,
                    };
                });
            });
        } else {
            // if it's not in the edit open state, then just reset everything back to default values so it doesn't
            // show again when you are adding a new item to the cart.
            setQuantity(1);
            setItemPrice(basePrice);
            setSelectedMods([]);
            form.resetFields();
            setSpecialInstructions('');
        }
    }, [show, isEdit, cartIndex, cartItems, basePrice, form]);

    useEffect(() => setItemPrice(basePrice), [basePrice]);

    useEffect(() => {
        if (show) {
            validateForm();
        }
    });

    useEffect(() => {
        if (show && !restaurantInfo) {
            segment.messageShownRoadblock('Menu Item Roadblock');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, restaurantInfo]);

    const convertToModifierGroups = (values: any) => {
        const newModifierGroups: ModifierGroupsItemInterface[] = [];
        const idHierarchyArray: string[] = Object.keys(values).sort((a: string, b: string) => {
            return a.length - b.length;
        });
        idHierarchyArray.forEach(key => {
            const modifierItemsList: MenuItemInterface[] = [];
            if (!values[key]) {
                return;
            }
            if (typeof values[key] === 'string') {
                const entity = findEntity(menuInfo, 'item', values[key]);
                modifierItemsList.push(entity);
            } else {
                values[key]?.map((id: string) => {
                    modifierItemsList.push(findEntity(menuInfo, 'item', id));
                });
            }
            const hierarchy = key.split(',');
            const modGroupId = hierarchy[hierarchy.length - 1];
            const modGroup = findEntity(menuInfo, 'modifier_group', modGroupId);
            const item = {
                name: modGroup.title,
                modifier_group_id: modGroupId,
                items: modifierItemsList,
                hierarchy,
            };
            newModifierGroups.push(item);
        });
        return newModifierGroups;
    };

    const addCartAndClose = async () => {
        const addedItems = sanitizeObject(formValues!);
        await form.validateFields();
        const modifiers = convertToModifierGroups(addedItems);
        addCart(
            modifiers,
            itemPrice,
            basePrice,
            quantity,
            formValues!,
            isEdit ?? false,
            cartIndex ?? -1,
            specialInstructions,
        );
        setItemPrice(basePrice);
        segment.productAdded(
            idHierarchy,
            item,
            quantity,
            itemPrice,
            modifiers,
            specialInstructions.trim(),
            itemDestination,
        );

        if (isEdit) {
            segment.updateItemCTAClicked();
        } else {
            segment.addtoCartCTAClicked();
        }
        close();
    };

    function findMod(mods: ModMetadataInterface[] | undefined, targetMod: ModMetadataInterface) {
        return mods?.find(mod => {
            return (
                mod.modId === targetMod.modId &&
                mod.idHierarchy?.every((itemId, index) => itemId === targetMod.idHierarchy?.[index])
            );
        });
    }

    const validateForm = () => {
        form.validateFields()
            .then(() => {
                setFormIsValid(true);
            })
            .catch(() => {
                setFormIsValid(false);
            });
    };

    const onChangeModifierGroups = (
        values: Record<string, string>,
        allValues: Record<string, any>,
    ) => {
        const key = Object.keys(values)[0];
        const value = values[key];
        const ids = key.split(',');

        const valueItems = typeof value === 'string' ? [value] : value;

        setSelectedMods(prevMods => {
            const currentMod = findMod(prevMods, { modId: ids.slice(-1)[0], idHierarchy: ids });
            if (!currentMod) {
                return [
                    ...(selectedMods || []),
                    {
                        modId: ids.slice(-1)[0],
                        idHierarchy: ids,
                        items: valueItems,
                    },
                ];
            } else {
                currentMod.items = valueItems;
                return [...prevMods!];
            }
        });

        let updatedPrice: number = matchNestedRule(item.price_rules, ids)?.price || 0;
        const addedItems = sanitizeObject(allValues);
        Object.keys(addedItems).forEach(key => {
            const value = addedItems[key];
            const modIds = key.split(',');

            if (typeof value === 'string') {
                const selectedItem = findEntity(menuInfo, 'item', value);
                updatedPrice +=
                    matchNestedRule(selectedItem.price_rules, [...modIds, value])?.price || 0;
            } else {
                value?.forEach((id: string) => {
                    const selectedItem = findEntity(menuInfo, 'item', id);
                    updatedPrice +=
                        matchNestedRule(selectedItem.price_rules, [...modIds, id])?.price || 0;
                });
            }
        });

        setItemPrice(updatedPrice);
        setFormValues(allValues);
    };

    const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        close();
        segment.productDismissed(
            findEntity(menuInfo, 'section', idHierarchy[1]),
            item,
            quantity,
            itemPrice,
            e?.type === 'keydown'
                ? 'Escape'
                : (e.target as HTMLElement).classList[0] === 'ant-modal-wrap'
                ? 'Click Outside'
                : 'X',
        );
    };

    const getCheckboxTag = (
        item: MenuModifierGroupInterface,
        minAllowed: number,
        maxAllowed: number,
    ) => {
        if (!minAllowed) {
            if (!maxAllowed || maxAllowed >= item.item_ids?.length) {
                return null;
            }

            return <Tag color="geekblue">Choose up to {maxAllowed}</Tag>;
        } else {
            if (minAllowed === maxAllowed) {
                return <Tag color="red"> Must choose {maxAllowed} </Tag>;
            }

            if (maxAllowed) {
                return (
                    <Tag color="geekblue">
                        Choose {minAllowed} to {maxAllowed}
                    </Tag>
                );
            }

            return <Tag color="red">Choose at least {minAllowed}</Tag>;
        }
    };

    const getCheckboxDisabledStatus = (
        selectedItems: string[],
        id: string,
        minAllowed: number,
        maxAllowed: number,
    ) => {
        if (!minAllowed && !maxAllowed) {
            return false;
        }

        if (!selectedItems?.length) {
            return false;
        }

        if (!selectedItems.includes(id)) {
            return selectedItems.length >= maxAllowed;
        } else {
            return false; // already selected item should never be disabled
        }
    };

    interface ModGroupItemPair {
        modGroupid: string;
        item: MenuItemInterface;
    }

    const renderModifierGroupItem = (modId: string, modIdHierarchy: string[]) => {
        const modItem = findEntity(menuInfo, 'modifier_group', modId);
        const quantityRule: QuantityRuleInterface | null = matchNestedRule(
            modItem.quantity_rules,
            modIdHierarchy,
        );
        const maxAllowed = quantityRule?.max_allowed || 0;
        const minAllowed = quantityRule?.min_allowed || 0;
        const isRequired = minAllowed === 1 && maxAllowed === 1;
        const isRadio = isRequired;
        const nestedItems = modItem.item_ids
            ?.map((id: string) => {
                return findEntity(menuInfo, 'item', id);
            })
            .filter(item => {
                // Check availability_rules
                const availabilityRule = matchNestedRule(item.availability_rules, modIdHierarchy);
                return availabilityRule?.is_available !== false;
            });
        const fieldName = modIdHierarchy.join(',');
        const modMetadata = findMod(selectedMods, { modId, idHierarchy: modIdHierarchy });

        const modifierQueue: ModGroupItemPair[] = [];

        if (hasUnavailableModGroup([modItem.id], menuInfo, true)) {
            return null;
        }

        return (
            <div
                key={modItem.id}
                data-testid="mod-item"
                className={restaurantInfo ? '' : 'disabled'}
            >
                {modItem.title && (
                    <Col span={24}>
                        <div className="detail-list-header">
                            <span style={{ fontSize: '1.25em' }}>{modItem.title}</span>
                            {isRequired ? (
                                <Tag className="required" color="red">
                                    Required
                                </Tag>
                            ) : (
                                getCheckboxTag(modItem, minAllowed, maxAllowed)
                            )}
                        </div>
                    </Col>
                )}
                <Col span={24}>
                    {isRadio ? (
                        <Form.Item
                            rules={[
                                {
                                    required: isRequired,
                                    message: '',
                                    // message: 'Please select one!'
                                },
                            ]}
                            name={fieldName}
                        >
                            <Radio.Group>
                                {nestedItems.map(item => {
                                    const priceRule = matchNestedRule(item.price_rules, [
                                        ...modIdHierarchy,
                                        item.id,
                                    ]);
                                    if (modMetadata?.items?.includes(item.id)) {
                                        item.modifier_group_ids?.forEach((modGroupid: string) => {
                                            modifierQueue.push({ modGroupid, item });
                                        });
                                    }
                                    return (
                                        <div key={item.id}>
                                            <div className="item-list">
                                                <Radio
                                                    value={item.id}
                                                    disabled={
                                                        restaurantInfo ? item.is_paused : true
                                                    }
                                                    style={
                                                        restaurantInfo ? {} : { color: '#7D7D7D' }
                                                    }
                                                >
                                                    <span className="item-list-name">
                                                        {item.title}
                                                    </span>
                                                </Radio>
                                                {!!priceRule?.price && (
                                                    <span
                                                        style={{
                                                            lineHeight: '1.5715',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        +{formatMoneyInteger(priceRule.price)}
                                                    </span>
                                                )}
                                            </div>
                                            <Divider className="my-15" />
                                        </div>
                                    );
                                })}
                            </Radio.Group>
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name={fieldName}
                            rules={
                                minAllowed > 0
                                    ? [
                                          {
                                              required: true,
                                              message: '',
                                              // message: getErrorMsg(
                                              //     modMetadata?.items || [],
                                              //     minAllowed,
                                              //     maxAllowed,
                                              // ),
                                          },
                                      ]
                                    : undefined // eslint-disable-line no-undefined
                            }
                        >
                            <Checkbox.Group>
                                {nestedItems.map(item => {
                                    const priceRule = matchNestedRule(item.price_rules, [
                                        ...modIdHierarchy,
                                        item.id,
                                    ]);
                                    if (modMetadata?.items?.includes(item.id)) {
                                        item.modifier_group_ids?.forEach((modGroupid: string) => {
                                            modifierQueue.push({ modGroupid, item });
                                        });
                                    }
                                    return (
                                        <div key={item.id}>
                                            <div className="item-list">
                                                <Checkbox
                                                    value={item.id}
                                                    disabled={
                                                        restaurantInfo
                                                            ? getCheckboxDisabledStatus(
                                                                  modMetadata?.items || [],
                                                                  item.id,
                                                                  minAllowed,
                                                                  maxAllowed,
                                                              ) || item.is_paused
                                                            : true
                                                    }
                                                >
                                                    <span className="item-list-name">
                                                        {item.title}
                                                    </span>
                                                </Checkbox>
                                                {!!priceRule?.price && (
                                                    <span>
                                                        +{formatMoneyInteger(priceRule.price)}
                                                    </span>
                                                )}
                                            </div>
                                            <Divider className="my-15" />
                                        </div>
                                    );
                                })}
                            </Checkbox.Group>
                        </Form.Item>
                    )}
                </Col>
                {modifierQueue.length > 0 &&
                    modifierQueue.map((modGroupItemPair: ModGroupItemPair) =>
                        renderModifierGroupItem(modGroupItemPair.modGroupid, [
                            ...modIdHierarchy,
                            modGroupItemPair.item.id,
                            modGroupItemPair.modGroupid,
                        ]),
                    )}
            </div>
        );
    };

    const Footer = () => {
        return !restaurantInfo ? (
            <TemplateButton
                type="primary"
                onClick={openAddressModal}
                size="large"
                block={true}
                data-testid="availability-btn"
            >
                Check availability
            </TemplateButton>
        ) : (
            <div className="footer-container">
                <MinusCircleOutlined
                    aria-label={`decrement ${item.title} quantity`}
                    onClick={() => {
                        setQuantity(quantity - 1 > 0 ? quantity - 1 : 1);
                        if (quantity > 1) {
                            segment.productQuantityDecreased(
                                findEntity(menuInfo, 'section', idHierarchy[1]),
                                item,
                                quantity,
                                quantity - 1,
                                basePrice,
                                itemPrice,
                            );
                        }
                    }}
                    disabled={quantity <= 1}
                    className={`item-quantity-plus-minus-button ${quantity <= 1 ? 'disabled' : ''}`}
                />
                <span className="mx-5 item-quantity-count">{quantity}</span>
                <PlusCircleOutlined
                    aria-label={`increment ${item.title} quantity`}
                    onClick={() => {
                        setQuantity(quantity + 1);
                        segment.productQuantityIncreased(
                            findEntity(menuInfo, 'section', idHierarchy[1]),
                            item,
                            quantity,
                            quantity + 1,
                            basePrice,
                            itemPrice,
                        );
                    }}
                    className="item-quantity-plus-minus-button"
                />
                <TemplateButton
                    type="primary"
                    onClick={addCartAndClose}
                    size="large"
                    block={true}
                    disabled={!formIsValid}
                    data-testid="add-to-order"
                >
                    {isEdit ? (
                        'Update'
                    ) : (
                        <>
                            Add to order
                            <div>{formatMoneyInteger(itemPrice * quantity)}</div>
                        </>
                    )}
                </TemplateButton>
            </div>
        );
    };

    function renderItemDetail() {
        const itemDetailProps = {
            item,
            isMobile,
            renderModifierGroupItem,
            idHierarchy,
            restaurantInfo,
            specialInstructions,
            setSpecialInstructions,
            itemPrice,
            Footer,
            template,
        };

        return (
            <>
                <Form form={form} onValuesChange={onChangeModifierGroups}>
                    <ItemDetail {...itemDetailProps} />
                </Form>
                <Button
                    className="close-icon"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={handleClose}
                />
            </>
        );
    }

    return (
        <Modal
            visible={show}
            onCancel={handleClose}
            closable={false}
            centered
            destroyOnClose
            className="popup-container"
            bodyStyle={{
                padding: '30px',
                maxHeight: '70vh',
            }}
            footer={<Footer />}
        >
            {renderItemDetail()}
        </Modal>
    );
};

const ItemDetail = ({
    item,
    renderModifierGroupItem,
    idHierarchy,
    restaurantInfo,
    specialInstructions,
    setSpecialInstructions,
    itemPrice,
    isMobile,
    template,
}: any) => {
    const [imageMissing, setImageMissing] = useState(!item.images[0]?.url);

    const handleMissingImage = () => {
        setImageMissing(true);
    };

    return (
        <div className="item-detail-content">
            <div className="item-text-container">
                <h3>
                    <b className="item-title">{item.title}</b>
                </h3>
                <p>{item.description}</p>
                {restaurantInfo ? (
                    <p>{formatMoneyInteger(itemPrice)}</p>
                ) : (
                    template?.menu_page?.show_parent_pricing && (
                        <p>{formatMoneyInteger(itemPrice)}</p>
                    )
                )}
            </div>

            <div className="scrollable-content" style={isMobile ? {} : { height: '48vh' }}>
                {imageMissing ? (
                    <img
                        className="missing-image-container item-image"
                        src={ForkKnifePlaceholder}
                        style={{
                            background: '#F0F0F0',
                            width: '100%',
                            paddingBottom: '33px',
                        }}
                    />
                ) : (
                    <div
                        className="item-image"
                        style={{
                            backgroundImage: `url(${resizedImagePath(item.images[0]?.url, 600)})`,
                        }}
                        onError={handleMissingImage}
                    ></div>
                )}

                <>
                    {item.modifier_group_ids.map((id: string) =>
                        renderModifierGroupItem(id, [...idHierarchy, id]),
                    )}
                    {restaurantInfo?.delivery?.allow_customer_instructions && (
                        <div>
                            <div className="detail-list-header">
                                <span>Special Instructions</span>
                            </div>
                            <TextArea
                                showCount
                                maxLength={500}
                                value={specialInstructions}
                                onChange={({ target: { value } }) => setSpecialInstructions(value)}
                            />
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};
