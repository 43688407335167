// Trivial wrapper around the global _LTracker.
// Keep typescript happy: import this file and use the imported logger
// instead of resolving the global _LTracker.

declare global {
    interface Window {
        _LTracker: {
            push: (data: any) => void;
            pushAjaxError: (error: any, message?: string, extraParams?: any) => void;
        };
    }
}

let logger = {
    push(o: any) {
        const m = `Loggly says:\n\n'${JSON.stringify(o)}`;
        console.log(m); // eslint-disable-line no-console
    },
    pushAjaxError,
};

function pushAjaxError(error: any, message?: string, extraParams?: any) {
    logger.push({
        errorMessage: message || 'Ajax Request Failed Error',
        errorBody: error,
        errorResponse: error?.response,
        failedEndpoint: error?.response?.url,
        ...(extraParams ?? {}),
    });
}

export function initLogging() {
    if (window.location.hostname !== 'localhost') {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cloudfront.loggly.com/js/loggly.tracker-latest.min.js';
        script.onload = () => {
            // Replace default console logger with fully initialized loggly
            logger = window._LTracker;
            logger.pushAjaxError = pushAjaxError;

            logger.push({
                logglyKey: '35dfa5c6-8dc4-4a0e-a5e3-28c9a51dbd96',
                sendConsoleErrors: true,
                tag: 'dtc-front-end',
            });

            // Add specific JS crash error handler that will log more specific info than just stacktraces
            window.onerror = (message, source, line, col, error) => {
                try {
                    logger.push({
                        message,
                        source,
                        line,
                        col,
                        errorMessage: error?.message ?? 'No error message',
                        stack: error?.stack ?? 'No error stack',
                        loggerMessage: 'dtc-base-error-handler',
                        location: window.location.href,
                        userAgent: window.navigator.userAgent,
                    });
                } catch (e) {
                    const m = `Error:\n\n'${JSON.stringify(e)}`;
                    console.log(m); // eslint-disable-line no-console
                }
            };
        };
        document.getElementsByTagName('head')[0].appendChild(script);
    }
}

export default logger;
