import { Tag, Typography } from 'antd';
import React, { FC } from 'react';
import {
    convertTimeStringToRestOffset,
    formatAddressDescription,
    formatMoneyInteger,
    parsePaymentInfoData,
} from '../../utils/general';
import { OStatusTagProps } from './models';
import { progressColorMapping } from './status-indicator-badge';

const { Text } = Typography;

export const OrderStatusTag: FC<OStatusTagProps> = ({
    orderStatus,
    estimatedDelivery,
    deliveryAddress,
    paymentMethodInfo,
    refundDetails,
    futureOrderDeliveryWindow,
    restaurantTimezoneOffset,
}) => {
    const [deliveryStatus, paymentStatus] = orderStatus;
    const isCompleteOrder = deliveryStatus === 'Complete' && !paymentStatus;

    return (
        !isCompleteOrder && (
            <Tag
                className="order-drawer-tag"
                data-testid="order-tag"
                color={progressColorMapping[paymentStatus ? paymentStatus : deliveryStatus]}
            >
                {refundDetails ? (
                    <>
                        <Text strong>
                            {'Amount refunded: '}
                            <span>{formatMoneyInteger(refundDetails.amount)}</span>
                        </Text>
                        <Text strong>
                            {'Issued to: '}
                            <span>{parsePaymentInfoData(paymentMethodInfo)}</span>
                        </Text>
                        <Text strong>
                            {'Date issued: '}
                            <span>
                                {convertTimeStringToRestOffset(
                                    refundDetails.issueDate,
                                    restaurantTimezoneOffset,
                                ).format('L')}
                            </span>
                        </Text>
                    </>
                ) : futureOrderDeliveryWindow() != null ? (
                    <>
                        <Text strong>
                            {'Scheduled delivery: '}
                            <span>{futureOrderDeliveryWindow()}</span>
                        </Text>
                        <Text strong>
                            Delivery address:{' '}
                            <span>{formatAddressDescription(deliveryAddress)}</span>
                        </Text>
                    </>
                ) : deliveryStatus !== 'Complete' && !paymentStatus ? (
                    <>
                        <Text strong>
                            {'Estimated delivery: '}
                            <span>
                                {convertTimeStringToRestOffset(
                                    estimatedDelivery,
                                    restaurantTimezoneOffset,
                                ).format('LT')}
                            </span>
                        </Text>
                        <Text strong>
                            Delivery address:{' '}
                            <span>{formatAddressDescription(deliveryAddress)}</span>
                        </Text>
                    </>
                ) : (
                    <></>
                )}
            </Tag>
        )
    );
};
