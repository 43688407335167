import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import React from 'react';

export const FeatureToggle = ({
    handleToggleChange,
    label,
    flag,
    value,
}: {
    handleToggleChange: (flag: string) => SwitchChangeEventHandler;
    label: string;
    flag: string;
    value: boolean;
}) => (
    <div>
        <label>{`${label}: `}</label>
        <Switch onChange={handleToggleChange(flag)} checked={value} />
    </div>
);
