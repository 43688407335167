import moment from 'moment';
import { _ } from '../utils/general';

// Typescript safe property lookup. menuManger[entityType] => getProperty(menuManager, entityType)
export function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
    return obj[key];
}

export type EntityTypes = 'menu' | 'section' | 'item' | 'modifier_group';

export function isEntityType(type: string): type is EntityTypes {
    return type === 'menu' || type === 'section' || type === 'item' || type === 'modifier_group';
}

export function isEntity(entity: any): entity is MenuEntity {
    return (
        typeof entity?.id === 'string' &&
        entity?.hasOwnProperty('entity_type') &&
        isEntityType(entity.entity_type)
    );
}

export function isMenuEntityType(entity: MenuEntity): entity is MenuInterface {
    return entity?.entity_type === 'menu';
}

export function isSectionEntityType(entity: MenuEntity): entity is MenuSectionInterface {
    return entity?.entity_type === 'section';
}

export function isItemEntityType(entity: MenuEntity): entity is MenuItemInterface {
    return entity?.entity_type === 'item';
}

export function isModifierGroupEntityType(
    entity: MenuEntity,
): entity is MenuModifierGroupInterface {
    return entity?.entity_type === 'modifier_group';
}

export function isMenuPayload(payload: any): payload is MenuManagerInterface {
    return (
        Array.isArray(payload?.menus) &&
        Array.isArray(payload?.sections) &&
        Array.isArray(payload?.items) &&
        Array.isArray(payload?.modifier_groups)
    );
}

export type DaysOfWeek =
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday';

export const weekdays: DaysOfWeek[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

export interface Voucher {
    code: string;
    discount: number;
    percent_off: number;
    error: string;
}

interface PercentTip<Type> {
    order_total: Type;
    tip_amount: Type;
}

export interface Tip<Type> {
    ten_percent: PercentTip<Type>;
    fifteen_percent: PercentTip<Type>;
    twenty_percent: PercentTip<Type>;
    other: PercentTip<Type>;
    no_tip_order_total: Type;
}

export interface Time {
    hr: number;
    min: number;
}

export interface Block {
    startTime: Time;
    endTime: Time;
}

export interface FutureOrder {
    delivery_buffer: number;
    delivery_window_duration: number;
    delivery_blackout_duration: number;
    delivery_limit: number;
}

export function isFutureOrderType(futureOrder: any): futureOrder is FutureOrder {
    return (
        futureOrder?.delivery_buffer &&
        futureOrder?.delivery_window_duration &&
        futureOrder?.delivery_blackout_duration &&
        futureOrder?.delivery_limit
    );
}

export interface DeliverableDate {
    date: moment.Moment;
    dayOfWeek: DayOfWeek;
    dayOfMonth: string;
    disabled: boolean;
}

type DayOfWeek = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

export function isDayOfWeek(day: string): day is DayOfWeek {
    const isValidDay = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].includes(day);
    if (!isValidDay) {
        throw new Error(`Invalid day of week: ${day}`);
    }
    return isValidDay;
}

export interface WeekWindows {
    sunday?: Block[] | null;
    monday?: Block[] | null;
    tuesday?: Block[] | null;
    wednesday?: Block[] | null;
    thursday?: Block[] | null;
    friday?: Block[] | null;
    saturday?: Block[] | null;
    today?: Block[] | null;
}

export interface DayPartInterface {
    id?: string;
    days_of_week: DaysOfWeek[];
    start_time?: string;
    end_time?: string;
    activate_at?: string;
    deactivate_at?: string;
}

export interface CalorieInfoInterface {
    lower_amount?: number;
    upper_amount?: number;
    amount_type?: 'fixed' | 'range';
}

export interface NutritionInfoInterface {
    calories: CalorieInfoInterface;
}

export interface MenuContextInterface {
    menu_id?: string;
    item_id?: string;
    modifier_group_id?: string;
}

export interface MenuRuleBaseInterface {
    contexts: MenuContextInterface[];
    price: number;
}

export interface PriceRuleInterface extends MenuRuleBaseInterface {
    price: number;
}

export interface AvailabilityRuleInterface extends MenuRuleBaseInterface {
    is_available: boolean;
}

export interface QuantityRuleInterface extends MenuRuleBaseInterface {
    min_allowed: number;
    max_allowed: number;
}

export type MenuRuleTypes = PriceRuleInterface | AvailabilityRuleInterface | QuantityRuleInterface;

export interface ImageInterface {
    destination: string;
    url: string;
}

export interface IOption {
    label: string;
    value: string;
}

export interface RadioGroupProps {
    title: string;
    options: IOption[];
    initialValue: string;
}

export interface POSInterface {
    id?: string;
    parent_id?: string;
    title?: string;
    description?: string;
    price?: number;
    is_menu_item?: boolean;
    is_combo_item?: boolean;
    image_urls?: any[];
}

interface EntityBaseInterface {
    id: string;
    entity_type: EntityTypes;
    title: string;
    last_modified: string;
    pos?: POSInterface;
}

export interface MenuInterface extends EntityBaseInterface {
    entity_type: 'menu';
    subtitle: string;
    publish_to_oos?: boolean;
    is_default?: boolean;
    destinations: string[];
    day_parts: DayPartInterface[];
    section_ids: string[];
}

export interface MenuSectionInterface extends EntityBaseInterface {
    entity_type: 'section';
    subtitle: string;
    item_ids: string[];
}

export interface CustomerInfoInterface {
    name: string;
    email: string;
    phonenumber: string;
}

export interface DeliveryPreferenceInterface {
    preference: string | undefined;
    text: string | undefined;
}

export interface MenuItemTaxInterface {
    rate: number;
    precision: number;
}

export interface WLPPMenuItemInterface extends MenuItemInterface {
    name?: string;
}

export interface MenuItemInterface extends EntityBaseInterface {
    entity_type: 'item';
    description: string;
    images: ImageInterface[];
    price_rules: PriceRuleInterface[];
    availability_rules: AvailabilityRuleInterface[];
    publish_to_oos?: boolean;
    is_paused?: boolean;
    pause_until?: string;
    modifier_group_ids: string[];
    nutritional_info?: NutritionInfoInterface;
    default_item_ids?: string[];
    is_alcohol?: boolean;
    tax: MenuItemTaxInterface;
}

export interface MenuModifierGroupInterface extends EntityBaseInterface {
    entity_type: 'modifier_group';
    type: 'standard' | 'size';
    quantity_rules: QuantityRuleInterface[];
    item_ids: string[];
    display_type?: 'expanded' | 'collapsed';
}

export interface MenuManagerInterface {
    app_id: string | null;
    source?: 'manual' | 'pos';
    tax: MenuItemTaxInterface;
    created_at?: string;
    menuset_last_updated?: string;
    menus: MenuInterface[];
    sections: MenuSectionInterface[];
    items: MenuItemInterface[];
    modifier_groups: MenuModifierGroupInterface[];
}

export interface ModifierGroupsItemInterface {
    modifier_group_id: string;
    name: string;
    items: MenuItemInterface[];
    hierarchy: string[];
}

export interface CartItemInterface extends LineItemInterface {
    item_price: number;
    images: ImageInterface[];
    description?: string;
    base_price?: number;
    section_name?: string;
    tax_rate: number;
    idHierarchy: string[];
    selected_items: Record<string, unknown>;
}

export interface ModMetadataInterface {
    modId: string;
    idHierarchy: string[];
    items?: string[];
}

export interface LineItemInterface {
    image_url?: string;
    item_id: string;
    menu_id: string;
    modifier_groups: ModifierGroupsItemInterface[];
    name: string;
    price: number;
    quantity: number;
    section_id: string;
    special_instructions?: string;
}

export type MenuEntity =
    | MenuInterface
    | MenuSectionInterface
    | MenuModifierGroupInterface
    | MenuItemInterface;

export type EntityListTypes = keyof Pick<
    MenuManagerInterface,
    'menus' | 'sections' | 'items' | 'modifier_groups'
>;
export type EntityAPITypes = 'menus' | 'sections' | 'items' | 'modifier-groups';

export interface OSFormProps {
    onCartItemEdit?: (
        section: MenuSectionInterface,
        item: MenuItemInterface,
        idHierarchyArray: string[],
        price: number,
        via: string,
        cartIndex: number,
        isEdit: boolean,
    ) => void;
    hideListItems?: boolean;
    tabPosition: number;
    orderTotals: OrderTotals;
}

export interface CSummaryProps {
    onCartItemEdit: (
        section: MenuSectionInterface,
        item: MenuItemInterface,
        idHierarchyArray: string[],
        price: number,
        via: string,
        cartIndex: number,
        isEdit: boolean,
    ) => void;
    cartVisible?: boolean;
    closeDrawer: (e: any) => void;
    addCartItem: (
        newItemModifier_groups: ModifierGroupsItemInterface[],
        basePrice: number,
        itemBasePrice: number,
        quantity: number,
        selectedItems: Record<string, unknown>,
        isEdit: boolean,
        itemIndex: number,
        specialInstructions: string,
        recommendedItemDetails?: { itemIdHierarchy: string[]; item: MenuItemInterface },
    ) => void;
}

export interface OrderTotals {
    promo_name: string;
    promo_discount: number;
    subtotal: string;
    taxes: string;
    discount: number;
    vouchers: Voucher[];
    tip: Tip<string>;
    raw_values: {
        subtotal: number;
        taxes: number;
        tip: Tip<number>;
    };
}

export interface OrderTotalPayload {
    promo_id?: string;
    promo_name: string;
    promo_banner?: string;
    vouchers: Voucher[];
    promo_discount: number;
    discount: number;
    subtotal: string;
    taxes: string;
    tip: Tip<string>;
    raw_values: {
        subtotal: number;
        taxes: number;
        tip: Tip<number>;
    };
}

export const orderTotalDefaultProps: OrderTotals = {
    promo_name: '',
    promo_discount: 0,
    subtotal: '',
    taxes: '',
    discount: 0,
    vouchers: [{ code: '', discount: 0, percent_off: 0, error: '' }],
    tip: {
        ten_percent: { order_total: '', tip_amount: '' },
        fifteen_percent: { order_total: '', tip_amount: '' },
        twenty_percent: { order_total: '', tip_amount: '' },
        other: { order_total: '', tip_amount: '' },
        no_tip_order_total: '',
    },
    raw_values: {
        subtotal: 0,
        taxes: 0,
        tip: {
            ten_percent: { order_total: 0, tip_amount: 0 },
            fifteen_percent: { order_total: 0, tip_amount: 0 },
            twenty_percent: { order_total: 0, tip_amount: 0 },
            other: { order_total: 0, tip_amount: 0 },
            no_tip_order_total: 0,
        },
    },
};

// Convert basic entity type to its payload list key equivalent
export function entityTypeToEntityListName(entityType: EntityTypes): EntityListTypes {
    const data: { [key: string]: EntityListTypes } = {
        menu: 'menus',
        section: 'sections',
        item: 'items',
        modifier_group: 'modifier_groups',
    };
    return data[entityType];
}

// Convert basic entity type to its menu manager backend URL equivalent
export function entityTypeToEntityAPIName(entityType: EntityTypes): EntityAPITypes {
    const data: { [key: string]: EntityAPITypes } = {
        menu: 'menus',
        section: 'sections',
        item: 'items',
        modifier_group: 'modifier-groups',
    };
    return data[entityType];
}

export function getChildIdList(parent: MenuEntity) {
    if (parent.entity_type === 'menu') {
        return parent.section_ids;
    } else if (parent.entity_type === 'item') {
        return parent.modifier_group_ids;
    } else if (parent.entity_type === 'section' || parent.entity_type === 'modifier_group') {
        return parent.item_ids;
    }
    return [];
}

export function findChildren(menuPayload: MenuManagerInterface, entity: MenuEntity): MenuEntity[] {
    if ('entity_type' in entity) {
        if (entity.entity_type === 'menu') {
            return entity.section_ids.map(id => {
                return findEntity(menuPayload, 'section', id);
            });
        } else if (entity.entity_type === 'section' || entity.entity_type === 'modifier_group') {
            return entity.item_ids.map(id => {
                return findEntity(menuPayload, 'item', id);
            });
        } else if (entity.entity_type === 'item') {
            return entity.modifier_group_ids.map(id => {
                return findEntity(menuPayload, 'modifier_group', id);
            });
        }
    }
    return [];
}

export function findEntity(
    menuPayload: MenuManagerInterface,
    type: 'menu',
    id?: string,
): MenuInterface;
export function findEntity(
    menuPayload: MenuManagerInterface,
    type: 'section',
    id?: string,
): MenuSectionInterface;
export function findEntity(
    menuPayload: MenuManagerInterface,
    type: 'item',
    id?: string,
): MenuItemInterface;
export function findEntity(
    menuPayload: MenuManagerInterface,
    type: 'modifier_group',
    id?: string,
): MenuModifierGroupInterface;
export function findEntity(
    menuPayload: MenuManagerInterface,
    type: EntityTypes,
    id?: string,
): MenuEntity;
export function findEntity(
    menuPayload: MenuManagerInterface,
    type: EntityTypes,
    id?: string,
): MenuEntity {
    if (id) {
        const entityLookup = entityTypeToEntityListName(type);
        const list = getProperty(menuPayload, entityLookup) as MenuEntity[];
        const res = list?.find((el: MenuEntity) => el.id === id);
        if (res) {
            return res;
        }
    }
    throw new Error(`entity_not_found: ${id}`);
}

function getIdFromContext(context: MenuContextInterface) {
    if (context.menu_id) {
        return context.menu_id;
    } else if (context.item_id) {
        return context.item_id;
    } else if (context.modifier_group_id) {
        return context.modifier_group_id;
    }
    return null;
}

export function getDefaultRule<T extends MenuRuleBaseInterface>(rules: T[] = []): T {
    return rules.filter(rule => {
        return _.isEmpty(rule.contexts);
    })[0];
}

// Given an array of rules and an array of ids representing the 'current' entity hierarchy,
// return the rule that most accurately matches the hierarchy.
// Return null if no matching rules are found.
// 'idList' is something like ['foo_item_id', 'bar_mod_group_id', 'baz_item_id'], and is usually
// built up as you traverse the entity hierarchy from some root down to a specific target
export function matchNestedRule<T extends MenuRuleBaseInterface>(
    rules: T[] = [],
    idList: string[] = [],
): T | null {
    if (!Array.isArray(rules) || rules.length < 1) {
        return null;
    }

    // If there's only one rule with no context, use it, we're done (common case: only one default rule)
    if (rules.length === 1 && _.isEmpty(rules[0]?.contexts)) {
        return rules[0];
    }

    if (_.isEmpty(idList)) {
        // If we don't have an id hierarchy to match contexts against, can only return the default rule
        return getDefaultRule(rules);
    }

    // Filter out any rules that contain contexts that aren't in the specified context list
    const matchingRules = rules.filter(rule => {
        if (_.isEmpty(rule.contexts)) {
            return true; // Rule with no context is the default rule, which always matches
        }
        return rule.contexts.every(subRule => {
            const id = getIdFromContext(subRule);
            if (id) {
                return idList.includes(id);
            }
            return false;
        });
    });

    if (matchingRules.length === 1) {
        // If we've matched exactly one rule, use it, we're done
        return matchingRules[0];
    } else if (matchingRules.length === 0) {
        // Optimization: if we've filtered out all possible rules, there's no match and we're done, return early
        // This happens often with 'is_available' rules, which usually don't include a default
        return null;
    }

    // From the remaining rules, filter out all rules that do not include the top level hierarchy id
    let newMatchingRules = matchingRules.filter(rule => {
        if (_.isEmpty(rule.contexts)) {
            // This is the default rule, but since we have more than one matching rule,
            // the default will *not* be used, so just get rid of it
            return false;
        }
        return rule.contexts.some(subRule => {
            return getIdFromContext(subRule) === idList[0];
        });
    });

    if (newMatchingRules.length === 1) {
        // If we've matched exactly one rule, use it, we're done
        return newMatchingRules[0];
    } else if (newMatchingRules.length === 0) {
        // If no rules matched the top level hierarchy, reset rule list to original matched rules...
        newMatchingRules = matchingRules;
    }

    // Then remove any contexts in each rule that refer to the top level hierarchy id...
    newMatchingRules = _.cloneJSON(newMatchingRules);
    newMatchingRules.forEach(rule => {
        if (!_.isEmpty(rule.contexts)) {
            rule.contexts = rule.contexts.filter(subRule => {
                return getIdFromContext(subRule) !== idList[0];
            });
        }
    });

    // Then recurse on the next hierarchy level
    return matchNestedRule(newMatchingRules, idList.slice(1));
}

export const tipPercentages = [
    'ten_percent',
    'fifteen_percent',
    'twenty_percent',
    'other',
] as const;
