import React from 'react';

function TrashCircled({
    background = '#F0F0F0',
    iconColor = 'black',
    onClick,
    className = '',
}: {
    background?: string;
    iconColor?: string;
    onClick: () => void;
    className?: string;
}) {
    return (
        <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            className={className}
        >
            <g clip-path="url(#clip0_16875_169698)">
                <rect x="0.5" y="0.910156" width="28" height="28" rx="14" fill={background} />
                <path
                    d="M12.3394 9.11041L12.1642 9.4584H9.82767C9.39687 9.4584 9.04883 9.80638 9.04883 10.2371C9.04883 10.6678 9.39687 11.0158 9.82767 11.0158H19.1738C19.6046 11.0158 19.9526 10.6678 19.9526 10.2371C19.9526 9.80638 19.6046 9.4584 19.1738 9.4584H16.8373L16.662 9.11041C16.5306 8.84516 16.2604 8.67969 15.9659 8.67969H13.0355C12.741 8.67969 12.4709 8.84516 12.3394 9.11041ZM19.1738 11.7945H9.82767L10.3437 20.044C10.3826 20.6597 10.8937 21.1391 11.5095 21.1391H17.492C18.1078 21.1391 18.6189 20.6597 18.6578 20.044L19.1738 11.7945Z"
                    fill={iconColor}
                />
            </g>
            <defs>
                <clipPath id="clip0_16875_169698">
                    <rect x="0.5" y="0.910156" width="28" height="28" rx="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default TrashCircled;
