import { Drawer, Modal } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { TemplateButton } from '../ui-components/template-button';
import { useMobileScreen, withAppRoot } from '../utils/general';
import useSegment from '../utils/segment';
import { SideNavContent } from './side-nav-content';

export const SideNav = ({
    isOpen,
    toggleSideNav,
}: {
    isOpen: boolean;
    toggleSideNav: (arg1: boolean) => void;
}) => {
    const { showSessionExpiredModal } = useContext(AppContext);
    const { clearAddressAndCartSession } = useContext(ConceptContext);
    const segment = useSegment();
    const isMobile = useMobileScreen();

    return (
        <>
            <CdpLocationContext.Provider value="Hamburger Nav">
                <Drawer
                    placement="right"
                    width={isMobile ? 300 : 440}
                    closable={true}
                    visible={isOpen}
                    className="sideNavOuter"
                    onClose={() => {
                        toggleSideNav(false);
                        segment.closeScreenLinkClicked('Hamburger Nav');
                    }}
                >
                    <SideNavContent closeFlyOutDrawer={() => toggleSideNav(false)} />
                </Drawer>
            </CdpLocationContext.Provider>
            <Modal
                visible={showSessionExpiredModal}
                closable={false}
                centered
                className="popup-container"
                footer={
                    <TemplateButton
                        key="link"
                        href={withAppRoot('address')}
                        type="primary"
                        size="large"
                        onClick={() => {
                            segment.sessionExpiredLinkClicked();
                            clearAddressAndCartSession();
                        }}
                    >
                        Continue
                    </TemplateButton>
                }
            >
                Your session has expired and your cart has been cleared.
                <br />
                Please refresh the page or click to continue.
            </Modal>
        </>
    );
};
