import { RadioChangeEvent } from 'antd';
import moment, { Moment } from 'moment-timezone';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from '../../../contexts/app-context';
import { CdpLocationContext } from '../../../contexts/cdp-location-context';
import { ConceptContext } from '../../../contexts/concept-context';
import {
    Block,
    DaysOfWeek,
    DeliverableDate,
    MenuManagerInterface,
    Time,
    WeekWindows,
    isDayOfWeek,
    isFutureOrderType,
} from '../../menu/model';
import { LocationMatchResponse, OrderEvent, OrderTimingType } from '../../restaurant/model';
import {
    convertTimeStrToObj,
    formatHours,
    generateFutureDeliveryTimeWindows,
    getEstimatedDeliveryWindow,
    getHolidayHourWindows,
    getPauseAdjustedWindows,
    useMobileScreen,
} from '../../utils/general';
import useSegment from '../../utils/segment';

export const dayConverter = {
    Sun: 'sunday',
    Mon: 'monday',
    Tue: 'tuesday',
    Wed: 'wednesday',
    Thu: 'thursday',
    Fri: 'friday',
    Sat: 'saturday',
} as const;

export const orderTypes: { [k: string]: OrderTimingType } = {
    asap: 'ASAP',
    scheduled: 'Future',
};

interface DeliveryWindowOptions {
    label: string;
    value: string;
}

export const useFutureOrderPopup = ({
    onCancel,
    setDeliveryTime,
    isPopupMode,
}: {
    onCancel: (event: OrderEvent) => void;
    setDeliveryTime?: (input: string) => void;
    isPopupMode?: boolean;
}) => {
    const { template, updateFutureOrderDate, futureOrderDate } = useContext(AppContext);
    const {
        conceptDetails: { menuInfo, restaurantInfo },
    } = useContext(ConceptContext);
    const cdpLocation = useContext(CdpLocationContext);

    const [isScheduledOrderOnly, setIsScheduledOrderOnly] = useState(false);
    const [selectedDateIndex, setSelectedDateIndex] = useState(0);
    const [windows, setWindows] = useState<WeekWindows | null>(null);
    const [deliverableDates, setDeliverableDates] = useState<DeliverableDate[]>(() => {
        if (template?.future_order?.supported_order_type.scheduled) {
            return futureOrderUtils.generateDeliverableDates(
                true,
                menuInfo,
                restaurantInfo,
                restaurantInfo?.delivery?.reopen_at,
                template?.future_order?.delivery_limit,
            );
        }
        return futureOrderUtils.generateDeliverableDates(
            false,
            menuInfo,
            restaurantInfo,
            restaurantInfo?.delivery?.reopen_at,
        );
    });
    const [selectedWindow, setSelectedWindow] = useState<Block | null>(null);
    const [disabledUpdateButton, setDisabledUpdateButton] = useState(true);
    const [radioVal, setRadioVal] = useState(() => {
        if (futureOrderDate) {
            return 'scheduled';
        }
        return 'asap';
    });
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const isMobile = useMobileScreen();
    const segment = useSegment();
    const pausedUntil = restaurantInfo?.delivery?.paused_until;

    useEffect(() => {
        // handle react-select click outside
        const handleClickOutside = (event: Event) => {
            const header = document.querySelector('.future-order-avail');

            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target as Element) &&
                !(event.target as Element).id.includes('react-select') &&
                !header?.contains(event.target as Element)
            ) {
                onCancel({ action: 'Cancel', orderType: orderTypes[radioVal] });
            }
        };
        if (wrapperRef && isPopupMode && !isMobile) {
            document.addEventListener('click', event => handleClickOutside(event));
        }

        return () => document.removeEventListener('click', event => handleClickOutside(event));
    });

    useEffect(() => {
        // handle type of order (asap or scheduled)
        if (!template?.future_order?.supported_order_type.asap) {
            setIsScheduledOrderOnly(true);
        }
    }, [template]);

    const reCreateIndexAndDatesFromContextData = useCallback(() => {
        const reCreatedDeliverableDates = futureOrderUtils.generateDeliverableDates(
            true,
            menuInfo,
            restaurantInfo,
            restaurantInfo?.delivery?.reopen_at,
            template?.future_order?.delivery_limit,
        );
        const reCreatedDateIndex = reCreatedDeliverableDates.findIndex(dateObj => {
            return moment(dateObj.date.date()).isSame(futureOrderDate?.date.date());
        });
        setSelectedWindow(futureOrderDate?.time || null);
        setDeliverableDates(reCreatedDeliverableDates);
        setSelectedDateIndex(reCreatedDateIndex);
        return reCreatedDateIndex;
    }, [menuInfo, restaurantInfo, template, futureOrderDate]);

    useEffect(() => {
        // generate date windows and time windows when first loaded
        const futureOrder = template?.future_order;
        if (
            menuInfo &&
            template &&
            restaurantInfo?.delivery?.timezone &&
            isFutureOrderType(futureOrder)
        ) {
            const deliveryWindows = generateFutureDeliveryTimeWindows(
                menuInfo?.menus[0].day_parts,
                futureOrder,
                new Date(),
                restaurantInfo.delivery.timezone,
            );

            setWindows(deliveryWindows);
        }
        if (futureOrderDate) {
            reCreateIndexAndDatesFromContextData();
        }
    }, [futureOrderDate, menuInfo, reCreateIndexAndDatesFromContextData, restaurantInfo, template]);

    useEffect(() => {
        // reset scheduled values when user switch to asap
        if (radioVal === 'asap') {
            setSelectedDateIndex(0);
            setSelectedWindow(null);
        }
    }, [radioVal]);

    const onDateSelect = (dateInx: number) => {
        setSelectedDateIndex(dateInx);
        setRadioVal('scheduled');
        setSelectedWindow(null);
        setDisabledUpdateButton(false);
        segment.fulfillmentDateOptionSelected(dateInx.toString(), cdpLocation);
    };

    function checkIfRestaurantClosedOnHoliday(date: string): boolean {
        if (!restaurantInfo?.delivery?.schedule?.holiday_hours) {
            return false;
        }
        const holidayHours = restaurantInfo.delivery.schedule.holiday_hours.find(x => {
            return x.date === date;
        });

        if (!holidayHours) {
            return false;
        }
        if (holidayHours.end_time === '00:00' && holidayHours.start_time === '00:00') {
            return true;
        }

        const startTime = convertTimeStrToObj(holidayHours.start_time);
        const endTime = convertTimeStrToObj(holidayHours.end_time);

        const startTimeInMinute = startTime.hr * 60 + startTime.min;
        const endTimeInMinute = endTime.hr * 60 + endTime.min;
        return startTimeInMinute - endTimeInMinute === 1;
    }

    const renderEstimatedDeliveryWindow = useCallback((): string => {
        const estimate = getEstimatedDeliveryWindow(restaurantInfo);
        return estimate ? `ASAP (${estimate.from}-${estimate.to} min)` : 'ASAP';
    }, [restaurantInfo]);

    const selectedDeliveryDate = deliverableDates[selectedDateIndex];

    const beforePausedUntilDay = moment(selectedDeliveryDate.date).isBefore(pausedUntil, 'day');

    let pauseAdjustedWindows = getPauseAdjustedWindows(
        pausedUntil,
        selectedDeliveryDate,
        windows,
        dayConverter[selectedDeliveryDate.dayOfWeek],
        template,
        menuInfo,
        restaurantInfo,
    );
    const holidayDayHourWindows = getHolidayHourWindows(
        selectedDeliveryDate,
        selectedDateIndex,
        restaurantInfo?.delivery?.paused_until,
        restaurantInfo,
        template,
    );
    pauseAdjustedWindows = holidayDayHourWindows ? holidayDayHourWindows : pauseAdjustedWindows;

    const noDeliveryWindowsOnSelectedDate =
        deliverableDates[selectedDateIndex].disabled ||
        beforePausedUntilDay ||
        pauseAdjustedWindows?.length === 0;

    const createDeliveryWindows = useCallback((): DeliveryWindowOptions[] => {
        let currentDay: DaysOfWeek | 'today' = 'today';
        const selectedDeliveryDay = deliverableDates[selectedDateIndex].dayOfWeek;
        if (selectedDateIndex !== 0) {
            currentDay = dayConverter[selectedDeliveryDay];
        }

        let windowOptions;
        const pauseAdjustedWindows = getPauseAdjustedWindows(
            pausedUntil,
            deliverableDates[selectedDateIndex],
            windows,
            dayConverter[selectedDeliveryDay],
            template,
            menuInfo,
            restaurantInfo,
        );

        const holidayWindows = getHolidayHourWindows(
            deliverableDates[selectedDateIndex],
            selectedDateIndex,
            pausedUntil,
            restaurantInfo,
            template,
        );

        if (pauseAdjustedWindows) {
            windowOptions = pauseAdjustedWindows?.map(window => {
                const windowStr = futureOrderUtils.windowToString(window);
                return {
                    value: JSON.stringify(window),
                    label: windowStr,
                };
            });
        } else if (holidayWindows) {
            windowOptions = holidayWindows.map(window => {
                const windowStr = futureOrderUtils.windowToString(window);
                return {
                    value: JSON.stringify(window),
                    label: windowStr,
                };
            });
        } else {
            windowOptions = windows
                ? windows[currentDay]?.map(window => {
                      const windowStr = futureOrderUtils.windowToString(window);
                      return {
                          value: JSON.stringify(window),
                          label: windowStr,
                      };
                  })
                : [];
        }

        return [{ value: '', label: 'Select a time window' }, ...(windowOptions || [])];
    }, [
        windows,
        pausedUntil,
        deliverableDates,
        selectedDateIndex,
        template,
        menuInfo,
        restaurantInfo,
    ]);

    const asapOrLaterOptions = useMemo(
        () => [
            {
                label: renderEstimatedDeliveryWindow(),
                value: 'asap',
                disabled:
                    restaurantInfo?.delivery?.reopen_at != null ||
                    !template?.future_order?.supported_order_type.asap,
            },
            {
                label: 'Later',
                value: 'scheduled',
                disabled:
                    !template?.future_order?.supported_order_type.scheduled ||
                    noDeliveryWindowsOnSelectedDate ||
                    createDeliveryWindows()?.length === 0,
            },
        ],

        [
            template,
            restaurantInfo,
            noDeliveryWindowsOnSelectedDate,
            createDeliveryWindows,
            renderEstimatedDeliveryWindow,
        ],
    );

    const selectClosestDate = () => {
        const closestDate = deliverableDates.findIndex((date, dateIndex) => {
            if (dateIndex === 0) {
                return windows?.today?.length;
            } else {
                return !date.disabled;
            }
        });
        setSelectedDateIndex(closestDate);
    };

    const onRadioChange = (e: RadioChangeEvent) => {
        setDisabledUpdateButton(false);
        const radioNewValue = e.target.value;
        if (radioNewValue === 'scheduled' && radioVal === 'asap') {
            selectClosestDate();
        }
        setRadioVal(radioNewValue);
        segment.orderTimingOptionSelected(orderTypes[radioNewValue], cdpLocation);
    };

    const saveSelectedTimeWindow = (timeWindowJSONString: string) => {
        if (!timeWindowJSONString.length) {
            setSelectedWindow(null);
            return;
        }
        setDisabledUpdateButton(false);
        const { startTime, endTime }: Block = JSON.parse(timeWindowJSONString);

        setSelectedWindow({
            startTime,
            endTime,
        });
    };

    const onUpdatePopup = () => {
        localStorage.setItem('orderTiming', JSON.stringify(orderTypes[radioVal]));
        if (radioVal === 'asap' || !selectedWindow) {
            updateFutureOrderDate(null);
            if (setDeliveryTime) {
                setDeliveryTime('ASAP');
            }
            onCancel({ action: 'Cancel', orderType: orderTypes[radioVal] });
            return;
        }
        const { startTime, endTime, date } = futureOrderUtils.setupDeliveryDateWithDeliveryTime(
            deliverableDates[selectedDateIndex].date,
            selectedWindow,
        );
        updateFutureOrderDate({ date, time: { startTime, endTime } });
        if (setDeliveryTime) {
            setDeliveryTime(
                `${date.format('ddd MM/DD')}, ${futureOrderUtils.getTimeString(
                    date,
                    startTime,
                )} - ${futureOrderUtils.getTimeString(date, endTime)}`,
            );
        }
        onCancel({ action: 'Update', orderType: orderTypes[radioVal] });
    };

    return {
        deliverableDates,
        pausedUntil,
        windows,
        dayConverter,
        isPopupMode,
        isMobile,
        wrapperRef,
        isScheduledOrderOnly,
        radioVal,
        asapOrLaterOptions,
        selectedWindow,
        disabledUpdateButton,
        selectedDateIndex,
        pauseAdjustedWindows,
        checkIfRestaurantClosedOnHoliday,
        onDateSelect,
        onRadioChange,
        saveSelectedTimeWindow,
        createDeliveryWindows,
        onUpdatePopup,
        onCancel,
    };
};

export const futureOrderUtils = {
    windowToString: ({ startTime, endTime }: Block): string => {
        let amOrPm = startTime.hr <= 24 && startTime.hr >= 12 ? 'pm' : 'am';
        const startStr = formatHours(startTime.hr, startTime.min) + amOrPm;

        amOrPm = endTime.hr <= 24 && endTime.hr >= 12 ? 'pm' : 'am';
        const endStr = formatHours(endTime.hr, endTime.min) + amOrPm;
        return `${startStr} - ${endStr}`;
    },

    getTimeString: (date: moment.Moment, { hr, min }: Time) => {
        const today = date.clone().startOf('day').add(hr, 'hours').add(min, 'minutes');
        return today.format('h:mm A');
    },

    getUtcDateTimeString: (date: moment.Moment, { hr, min }: Time) => {
        return date.clone().startOf('day').add(hr, 'hours').add(min, 'minutes').utc().format();
    },

    /**
     * generate a list of delivery windows for a {@link deliveryTimeLimit} in the future
     * @param isDeliverable if the dates are for show or interactive delivery actions
     * @param menuInfo
     * @param restaurantInfo
     * @param deliverableDatesLimit the limit of delivery dates to generate (in minutes)
     * @returns an array of {@link DeliverableDate}
     */
    generateDeliverableDates: (
        isDeliverable: boolean,
        menuInfo: null | MenuManagerInterface,
        restaurantInfo: null | LocationMatchResponse,
        reopenAtTimeString: string | undefined | null,
        deliveryTimeLimit?: undefined | number,
    ): DeliverableDate[] => {
        const openDays = menuInfo?.menus[0].day_parts.reduce((acc, curr, currInx) => {
            if (currInx === 0) {
                return [...curr.days_of_week];
            }
            curr.days_of_week.forEach(weekDay => {
                if (!acc.includes(weekDay)) {
                    acc.push(weekDay);
                }
            });
            return acc;
        }, [] as string[]);

        function isLocationClosed(dateStringObject: Moment): boolean {
            if (reopenAtTimeString === 'never') {
                return true;
            }
            return reopenAtTimeString
                ? dateStringObject.isBefore(reopenAtTimeString, 'day')
                : false;
        }

        function createDayObject(_: unknown, index: number): DeliverableDate {
            const date = moment().add(index, 'days');
            date.tz(restaurantInfo?.delivery?.timezone || '');
            const datePrefix = date.format('llll').split(', ')[0]; // example of llll format "Mon, May 16, 2020"
            const dayOfWeek = isDayOfWeek(datePrefix) ? datePrefix : 'Mon';
            const dayOfMonth = date.format('llll').split(', ')[1].split(' ')[1];
            const disabled =
                !openDays?.includes(date.format('dddd').toLowerCase()) || isLocationClosed(date);
            return { date, dayOfWeek, dayOfMonth, disabled };
        }

        if (!isDeliverable || !deliveryTimeLimit) {
            return Array.from({ length: 5 }).map(createDayObject);
        }

        const deliverableDatesLimit = Math.floor(deliveryTimeLimit / 1440);
        return Array.from({ length: deliverableDatesLimit }).map(createDayObject);
    },
    setupDeliveryDateWithDeliveryTime: (
        deliveryDate: moment.Moment,
        deliveryTime: Block,
    ): { date: moment.Moment; startTime: Time; endTime: Time } => {
        const { startTime, endTime } = deliveryTime;

        deliveryDate.set('hour', startTime.hr);
        deliveryDate.set('minute', startTime.min);
        deliveryDate.set('second', 0);

        return { date: deliveryDate, startTime, endTime };
    },
};
