import { Col, Row } from 'antd';
import React from 'react';
import { MenuItemInterface } from '../../menu/model';
import { ImageWithFallback } from '../../ui-components/image-with-fallback';
import PlusCircled from '../../ui-components/plus-circled/PlusCircled';
import { formatMoneyInteger } from '../../utils/general';
import './card.scss';

export const Card = ({
    itemPrice,
    menuItem,
    handleItemAdd,
}: {
    itemPrice: number;
    menuItem: MenuItemInterface;
    handleItemAdd: () => void;
}) => {
    return (
        <Row className="card-body" align="middle" style={{ borderRadius: 20 }}>
            <Col span={24} style={{ position: 'relative' }}>
                <ImageWithFallback
                    className="card-image"
                    src={menuItem.images[0]?.url}
                    resize={200}
                />
                <PlusCircled
                    onClick={handleItemAdd}
                    background="white"
                    signColor="black"
                    className="plus-sign"
                />
            </Col>
            <Col span={20} className="card-title">
                {menuItem.title}
            </Col>
            <Col span={24} className="secondary-text">
                {formatMoneyInteger(itemPrice)}
            </Col>
        </Row>
    );
};
