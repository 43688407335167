import { LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Col, Divider, Row, Space, Spin, Typography } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Redirect, useHistory } from 'react-router';
import { OrderList } from '../../../../services/OrderHistory';
import { PageNotFound } from '../../menu/page-not-found';
import { useMobileScreen } from '../../utils/general';
import useSegment from '../../utils/segment';
import { OrderHistoryInterface } from './models';
import { NoOrderHistory } from './no-order-history';
import { OrderListDetails } from './order-list-details';
import './index.scss';

const { Title } = Typography;

export const OrderHistory: FC = () => {
    const isMobile = useMobileScreen();
    const segment = useSegment();
    const ldFlags = useFlags();

    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
    const { location }: any = useHistory();

    const userOrderHistoryInfo = useQuery(
        ['userOrderHistoryListInfo', isAuthenticated, location?.state?.orderId],
        async () => {
            const token = await getAccessTokenSilently();
            const response = await OrderList.getUserOrderList(token);
            log.debug('%c FETCH ORDER HISTORY LIST ', 'background-color: red', response);
            return response.data;
        },
        {
            onError: error => {
                const errorMessage = 'An error occured while fetching order history';
                segment.errorShown('There has been an issue loading this page');
                log.error(`${errorMessage}: ${error}`);
            },
            refetchInterval: 10000,
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
        },
    );

    const filterListByDescPlacedOrderDate = (orderList: OrderHistoryInterface[]) => {
        return orderList.sort((a, b) =>
            a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0,
        );
    };

    if (!(ldFlags.enableAccounts || (isAuthenticated && isLoading))) {
        return <Redirect to="/address" />;
    }

    return (
        <>
            {userOrderHistoryInfo.isSuccess && (
                <Col className="order-history-wrapper bg-white" span={24}>
                    <Space size={0} className="order-tab-container width-100" direction="vertical">
                        <Title level={isMobile ? 3 : 2}>Order history</Title>
                        {isMobile && <Divider className="mb-20 mt-0" />}

                        {userOrderHistoryInfo?.data.length > 0 ? (
                            filterListByDescPlacedOrderDate(userOrderHistoryInfo.data).map(
                                (order: any) => (
                                    <Row key={order.order_id}>
                                        <OrderListDetails order={order} />
                                    </Row>
                                ),
                            )
                        ) : (
                            <NoOrderHistory />
                        )}
                    </Space>
                </Col>
            )}
            {userOrderHistoryInfo.isLoading && (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
            )}
            {userOrderHistoryInfo.isError && <PageNotFound />}
        </>
    );
};
