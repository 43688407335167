import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import { UserProfile } from '../../../services/UserProfile';
import { IUserProfile, UserProfileAddress } from '../../contexts/app-context';
import { formatAddressDescription } from './general';

export const placesToAddress = (place: any, apt: string = '', useShortName: boolean = false) => {
    const address = {
        address1: '',
        address2: '',
        city: '',
        region: '',
        postalcode: '',
        country: '',
    };
    for (const addrComp of place.address_components) {
        const isStreetNum = addrComp.types.includes('street_number');
        const isRoute = addrComp.types.includes('route');
        const value = addrComp.long_name;

        if (isStreetNum || isRoute) {
            if (address.address1) {
                let address1: string = address.address1 + '';
                if (isStreetNum) {
                    address1 = value + ' ' + address1;
                } else {
                    address1 += ' ' + value;
                }
                address.address1 = address1;
            } else {
                address.address1 = value;
            }
        } else if (addrComp.types.includes('locality')) {
            address.city = value;
        } else if (addrComp.types.includes('sublocality') && !address.city) {
            address.city = value;
        } else if (addrComp.types.includes('administrative_area_level_1')) {
            address.region = useShortName ? addrComp.short_name : value; // orders payload requires less than 8 characters. expects ISO 3166-2 format
        } else if (addrComp.types.includes('postal_code')) {
            address.postalcode = value;
        } else if (addrComp.types.includes('country')) {
            address.country = addrComp.short_name;
        }
    }
    address.address2 = apt;

    return address;
};

const duplicateAddressIndex = (
    address: UserProfileAddress,
    userProfileAddresses: UserProfileAddress[],
) => {
    const addressIndex = userProfileAddresses.findIndex(item => {
        return item.address1 === address.address1 && item.postalcode === address.postalcode;
    });

    return addressIndex;
};

export const updateAddress = async (
    addressPayload: { service_info: UserProfileAddress[] },
    getAccessTokenSilently: any,
    updateUserProfile: (input: IUserProfile) => void,
) => {
    const token = await getAccessTokenSilently();
    UserProfile.updateUserProfile(token, addressPayload)
        .then((data: IUserProfile) => {
            updateUserProfile(data);
        })
        .catch(err => log.error('Error saving address', err));
};

export const saveAddressToUserProfile = (
    orderAddressApt: string,
    placeDetailsQuery: any,
    currentAddressList: UserProfileAddress[],
    getAccessTokenSilently: any,
    updateUserProfile: (input: IUserProfile) => void,
    deliveryPreference?: any,
) => {
    const defaultValues = {
        preference: 'Meet the driver at the door',
        text: '',
    };
    const newAddress = placesToAddress(placeDetailsQuery, orderAddressApt);
    const newDeliveryPreferences = {
        delivery_preference: deliveryPreference?.preference || defaultValues.preference,
        special_instructions: deliveryPreference?.text || defaultValues.text,
    };
    const newUserProfileAddress: UserProfileAddress = {
        ...newAddress,
        id: uuidv4(),
        ...newDeliveryPreferences,
        default_address: currentAddressList.length < 1 ? true : false,
    };
    const addressIndex = duplicateAddressIndex(newUserProfileAddress, currentAddressList);

    let updatedAddressList;

    if (addressIndex < 0) {
        updatedAddressList = [...currentAddressList, newUserProfileAddress];
    } else {
        const updatedCurrentAddress = {
            ...currentAddressList[addressIndex],
            ...newDeliveryPreferences,
        };
        currentAddressList[addressIndex] = updatedCurrentAddress;
        updatedAddressList = [...currentAddressList];
    }
    updateAddress(
        {
            service_info: updatedAddressList,
        },
        getAccessTokenSilently,
        updateUserProfile,
    );
};

export const getConceptMatchedAddressIdx = (
    userProfile: IUserProfile | null,
    orderAddress: any,
    useDefaultAddress?: boolean | null,
) => {
    const addressList = userProfile?.service_info;
    if (!addressList || !orderAddress || !userProfile) {
        return -1;
    }
    const { service_info_default_address: defaultAddress = null } = orderAddress;
    const parsedOrderAddress = placesToAddress(orderAddress);
    const matchedAddressIndex = addressList.findIndex(address => {
        return (
            // Check for address match either from default address or from entered address
            (address.address1 === parsedOrderAddress.address1 &&
                address.postalcode === parsedOrderAddress.postalcode) ||
            (useDefaultAddress && defaultAddress === formatAddressDescription(address, true))
        );
    });
    return matchedAddressIndex;
};
