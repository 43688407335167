import { AppBrand } from './types';

const hotboxBrand: AppBrand = {
    app: {
        appId: 'io.nextbite.hotbox',
        appName: 'Hotbox by Wiz Khalifa',
        appHost: 'hotboxbywizkhalifa.com',
    },
    styles: {
        'border-radius-base': '2px',
        'body-background': '#fff',
        'padding-md': '16px',
    },
};

export default hotboxBrand;
