import { Button } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../contexts/app-context';
import { ImageWithFallback } from '../../ui-components/image-with-fallback';
import useSegment from '../../utils/segment';

interface SignUpWelcomeModalProps {
    handleClose?: (e?: any) => void;
}

export const SignUpWelcomeModal: React.FC<SignUpWelcomeModalProps> = ({ handleClose }) => {
    const segment = useSegment();
    const { template } = useContext(AppContext);
    return (
        <div className="sign-up-welcome-modal">
            <div className="modal-content">
                <div>
                    {template?.address_entry_page?.hero_image && (
                        <ImageWithFallback
                            width="100%"
                            src={template.address_entry_page.hero_image}
                        />
                    )}
                </div>
                <div className="modal-text">
                    <h2>Welcome to your account!</h2>
                    You can now save your information, manage your addresses, and access your order
                    history.
                </div>
            </div>
            <div className="account-profile-button">
                <Button
                    key="go-to-account-profile"
                    size="large"
                    onClick={handleClose}
                    style={{ width: '100%' }}
                >
                    <Link
                        to="/account/profile"
                        onClick={() => {
                            segment.welcomeModalCTAClicked(true);
                        }}
                        style={{
                            fontSize: '16px',
                            textAlign: 'center',
                            lineHeight: '22px',
                        }}
                    >
                        Go to my account
                    </Link>
                </Button>
            </div>
        </div>
    );
};
