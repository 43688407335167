import React from 'react';
import ReactMarkdown from 'react-markdown';
import { isVideo as checkIfVideo, resizedImagePath } from '../utils/general';

export const FeatureBlock = ({
    title,
    text,
    mediaSrc,
    big = false,
    bigNotHero = false,
    isMobile = false,
    altTag,
}: {
    title?: string;
    text?: string;
    mediaSrc?: string;
    big?: boolean;
    bigNotHero?: boolean;
    isMobile?: boolean;
    altTag?: string;
}) => {
    const isVideo = checkIfVideo(mediaSrc);

    const ImageBlockLeftTextRight = () => {
        const isVideo = checkIfVideo(mediaSrc);
        return (
            <div className="feature-child-box">
                {mediaSrc && (
                    <div className="feature-image">
                        {isVideo ? (
                            <video
                                title={altTag}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '20px 0 0 20px',
                                }}
                                controls
                                autoPlay
                                muted
                                loop
                            >
                                <source src={mediaSrc} />
                            </video>
                        ) : (
                            <img
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '20px 0 0 20px',
                                }}
                                src={resizedImagePath(mediaSrc, 600)}
                                alt={altTag}
                            />
                        )}
                    </div>
                )}
                {(title || text) && (
                    <div className={`feature-child-text-box ${big && 'big-text-box'}`}>
                        {title && (
                            <h1
                                className={big ? 'feature-big-title' : 'feature-text-title'}
                                style={bigNotHero ? { color: '#000000' } : {}}
                            >
                                <ReactMarkdown>{title}</ReactMarkdown>
                            </h1>
                        )}
                        {text && (
                            <p className={big ? 'feature-big-text' : 'feature-text'}>
                                <ReactMarkdown>{text}</ReactMarkdown>
                            </p>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return isMobile ? (
        <div className="feature-child-box-mobile" style={{ flexDirection: 'column' }}>
            {mediaSrc && (
                <div className="feature-image-mobile">
                    {isVideo ? (
                        <video
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '20px 20px 0 0',
                            }}
                            controls
                            autoPlay
                            muted
                            loop
                        >
                            <source src={mediaSrc} />
                        </video>
                    ) : (
                        <img
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '20px 20px 0 0',
                            }}
                            src={resizedImagePath(mediaSrc, 600)}
                        />
                    )}
                </div>
            )}
            {(title || text) && (
                <div className="feature-child-text-box-mobile">
                    {title && (
                        <h1
                            className={
                                big
                                    ? 'feature-text-title-mobile big-title'
                                    : 'feature-text-title-mobile'
                            }
                        >
                            <ReactMarkdown>{title}</ReactMarkdown>
                        </h1>
                    )}
                    {text && (
                        <p className={'feature-text-mobile'}>
                            <ReactMarkdown>{text}</ReactMarkdown>
                        </p>
                    )}
                </div>
            )}
        </div>
    ) : bigNotHero ? (
        <ImageBlockLeftTextRight />
    ) : (
        <div className="feature-child-box">
            {(title || text) && (
                <div className={`feature-child-text-box ${big && 'big-text-box'}`}>
                    {title && (
                        <h1
                            className={big ? 'feature-big-title' : 'feature-text-title'}
                            style={bigNotHero ? { color: '#000000' } : {}}
                        >
                            <ReactMarkdown>{title}</ReactMarkdown>
                        </h1>
                    )}
                    {text && (
                        <p className={big ? 'feature-big-text' : 'feature-text'}>
                            <ReactMarkdown>{text}</ReactMarkdown>
                        </p>
                    )}
                </div>
            )}
            {mediaSrc && (
                <div className="feature-image">
                    {isVideo ? (
                        <video
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '0 20px 20px 0',
                            }}
                            controls
                            autoPlay
                            muted
                            loop
                        >
                            <source src={mediaSrc} />
                        </video>
                    ) : (
                        <img
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '0 20px 20px 0',
                            }}
                            src={resizedImagePath(mediaSrc, 600)}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
