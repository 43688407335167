import { ShoppingCartOutlined } from '@ant-design/icons';
import { useStripe } from '@stripe/react-stripe-js';
import { Button, Checkbox, Divider, Drawer, Form, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import ApplePayIcon from '../../../assets/img/apple_pay.svg';
import EmptyCartPlate from '../../../assets/img/empty-cart-plate.png';
import GooglePayIcon from '../../../assets/img/light_gpay.svg';
import { Orders } from '../../../services/Orders';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { getCurrentPage } from '../../router';
import { PaymentMethod } from '../checkout';
import { CSummaryProps, orderTotalDefaultProps } from '../menu/model';
import { ItemList } from '../order-summary/itemlist';
import { TipDetails } from '../order-summary/tipdetails';
import { Recommendations } from '../recommendations';
import { TemplateButton } from '../ui-components/template-button';
import { getPaymentResult, useMobileScreen } from '../utils/general';
import { getOrderTotalPayload } from '../utils/order-totals';
import useSegment from '../utils/segment';
import './index.scss';

export const CartDrawer: FC<CSummaryProps> = ({
    onCartItemEdit,
    cartVisible,
    closeDrawer,
    addCartItem,
}) => {
    const {
        cartItems,
        addUtensils,
        isDinnerbell,
        updateCartItems,
        updateAddUtensils,
        updateStateId,
        setPaymentMethod,
        orderAddress,
        customerInfo,
        template,
        tipValue,
        updatePromoId,
        orderTotalIsFetching,
        updateOrderTotalIsFetching,
    } = useContext(AppContext);
    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);
    const cdpLocation = useContext(CdpLocationContext);
    const [orderTotals, setOrderTotals] = useState(orderTotalDefaultProps);

    const [form] = Form.useForm();
    const history = useHistory();
    const segment = useSegment();
    const isMobile = useMobileScreen();
    const stripe = useStripe();
    const formActionRef = useRef<any>();

    const paymentResult = useQuery(['paymentResultCheck', !!stripe], () =>
        getPaymentResult(stripe),
    );

    const fetchSubtotalDetails = useCallback(async () => {
        const otherTipValue = tipValue ? tipValue / 100 : 0;
        const vouchers: string[] = [];
        const payload = getOrderTotalPayload(
            orderAddress,
            restaurantInfo,
            cartItems,
            otherTipValue,
            customerInfo,
            vouchers,
            template,
            'Cart',
        );
        updateOrderTotalIsFetching(true);
        const { data: subtotals } = await Orders.getTotals(payload);
        if (subtotals) {
            setOrderTotals(subtotals);
        }
        if (subtotals?.promo_id) {
            updatePromoId(subtotals.promo_id);
        }
        updateOrderTotalIsFetching(false);
    }, [
        orderAddress,
        restaurantInfo,
        cartItems,
        customerInfo,
        template,
        updateOrderTotalIsFetching,
        updatePromoId,
        tipValue,
    ]);

    useEffect(() => {
        if (cartItems.length > 0 && getCurrentPage() !== 'Checkout') {
            fetchSubtotalDetails();
        }
    }, [cartItems, fetchSubtotalDetails, restaurantInfo, cdpLocation]);

    useEffect(() => {
        const localStorageCartItems = JSON.parse(localStorage.getItem('cartItems') ?? '[]');
        if (cartItems.length < 1 && localStorageCartItems && localStorageCartItems.length > 0) {
            const expiryStr = localStorage.getItem('expiry');
            const time = new Date();
            if (expiryStr && time.getTime() < JSON.parse(expiryStr)) {
                updateCartItems(JSON.parse(localStorageCartItems));
            }
        }
    }, [cartItems, updateCartItems]);

    useEffect(() => {
        const addUtensilsStr = localStorage.getItem('addUtensils');
        if (!addUtensils && addUtensilsStr) {
            updateAddUtensils(JSON.parse(addUtensilsStr));
        }
        form.setFieldsValue({
            checkoututensils: addUtensils,
        });
    }, [addUtensils, form, updateAddUtensils]);

    const subtotal = cartItems.reduce((s, a) => s + a.price, 0);

    const onFinish = (paymentMethod: PaymentMethod) => () => {
        const checkoutId = Math.round(new Date().getTime() / 1000).toString();
        setPaymentMethod(paymentMethod);
        updateStateId('checkoutId', checkoutId);
        trackCheckout(paymentMethod, segment);
        history.push({ pathname: '/checkout', state: orderTotals });
    };

    const onChangeUtensils = (event: CheckboxChangeEvent) =>
        updateAddUtensils((event.target as HTMLInputElement).checked);

    const ableCheckout = () => {
        const minTotal = restaurantInfo?.delivery?.logistics_configs[0]?.min_total;
        const maxTotal = restaurantInfo?.delivery?.logistics_configs[0]?.max_total;
        if (cartItems.length === 0 || orderTotalIsFetching) {
            return false;
        }
        return (minTotal ? subtotal >= minTotal : true) && (maxTotal ? subtotal <= maxTotal : true);
    };

    return (
        <CdpLocationContext.Provider value="Cart">
            <Drawer
                placement="right"
                className="cart-flyout"
                width={isMobile ? '' : 368}
                closable={true}
                onClose={closeDrawer}
                visible={cartVisible}
                footer={
                    <Footer
                        ableCheckout={ableCheckout}
                        formActionRef={formActionRef}
                        onFinish={onFinish}
                        paymentResult={paymentResult}
                        orderTotals={orderTotals}
                    />
                }
            >
                <Row className="cart-wrapper">
                    <Form
                        form={form}
                        name="checkout-order-summary"
                        className={`checkout-order-summary width-100 ${isDinnerbell && 'mt-20'}`}
                        onFinish={onFinish}
                    >
                        {cartItems.length < 1 ? (
                            <Form.Item
                                className="checkout-order-empty"
                                style={{ marginTop: `${window.innerHeight / 6}px` }}
                            >
                                {isDinnerbell ? (
                                    <img
                                        src={EmptyCartPlate}
                                        style={{ maxWidth: '96px', margin: '2em' }}
                                        alt="Empty Cart Icon"
                                    />
                                ) : (
                                    <ShoppingCartOutlined />
                                )}
                                <p>You haven't added any items</p>
                                <p>to your order yet.</p>
                            </Form.Item>
                        ) : (
                            <section className="main-content">
                                <div className="cart-header-title">My Order</div>
                                <ItemList
                                    pageType="cart"
                                    cartItems={cartItems}
                                    onCartItemEdit={onCartItemEdit}
                                    closeDrawer={closeDrawer}
                                />
                                <>
                                    <Divider className="cart-divider-filled pt-20" />
                                    <Form.Item
                                        name="checkoututensils"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <Checkbox onChange={onChangeUtensils}>
                                            Add utensils to my order
                                        </Checkbox>
                                    </Form.Item>
                                    <Divider className="cart-divider-filled" />
                                </>
                                <Form.Item name="checkoutRecommendations" noStyle>
                                    <Recommendations
                                        onCartItemEdit={onCartItemEdit}
                                        closeDrawer={closeDrawer}
                                        addCartItem={addCartItem}
                                        cartVisible={cartVisible}
                                    />
                                </Form.Item>
                                <TipDetails
                                    cartItems={cartItems}
                                    initialLoading={false}
                                    orderTotals={orderTotals}
                                />
                            </section>
                        )}
                    </Form>
                </Row>
            </Drawer>
        </CdpLocationContext.Provider>
    );
};

const Footer = ({
    formActionRef,
    onFinish,
    ableCheckout,
    paymentResult,
    orderTotals,
}: {
    formActionRef: any;
    onFinish: any;
    ableCheckout: any;
    paymentResult: any;
    orderTotals: any;
}) => {
    const ldFlags = useFlags();

    const digitalWalletButtonStyle = {
        opacity: `${ableCheckout() ? '100%' : '40%'}`,
        filter: `${ableCheckout() ? 'none' : 'grayscale(100%)'}`,
        width: `50px`,
    };

    return (
        <footer ref={formActionRef} className="form-action-container">
            <Form.Item className="checkout-order-summary-btn mb-10">
                <TemplateButton
                    type="primary"
                    data-testid="checkout-order-summary-btn"
                    onClick={onFinish(PaymentMethod.CreditCard)}
                    disabled={!ableCheckout()}
                >
                    <div className="checkout-button-text">
                        <div>Go To Checkout</div>
                        <div>{orderTotals.tip.no_tip_order_total}</div>
                    </div>
                </TemplateButton>
            </Form.Item>

            {paymentResult?.data?.canMakePayment?.applePay && ldFlags.showDigitalWallet && (
                <Form.Item className="checkout-order-summary-btn mb-10">
                    <Button
                        type="default"
                        data-testid="checkout-order-apple-pay-btn"
                        onClick={onFinish(PaymentMethod.ApplePay)}
                        disabled={!ableCheckout()}
                    >
                        <img
                            src={ApplePayIcon}
                            alt="Apple Pay Icon"
                            style={digitalWalletButtonStyle}
                        />
                    </Button>
                </Form.Item>
            )}

            {paymentResult?.data?.canMakePayment?.googlePay && ldFlags.showDigitalWallet && (
                <Form.Item className="checkout-order-summary-btn mb-10">
                    <Button
                        type="default"
                        data-testid="checkout-order-google-pay-btn"
                        onClick={onFinish(PaymentMethod.GooglePay)}
                        disabled={!ableCheckout()}
                    >
                        <img
                            src={GooglePayIcon}
                            alt="Google Pay Icon"
                            style={digitalWalletButtonStyle}
                        />
                    </Button>
                </Form.Item>
            )}
        </footer>
    );
};

function trackCheckout(paymentMethod: PaymentMethod, segment: any) {
    const ctaNameMap = {
        [PaymentMethod.ApplePay]: 'Apple Pay',
        [PaymentMethod.GooglePay]: 'Google Pay',
        [PaymentMethod.CreditCard]: 'Credit Card',
    };

    const ctaActionMap = {
        [PaymentMethod.ApplePay]: 'Checkout with Apple Pay',
        [PaymentMethod.GooglePay]: 'Checkout with Google Pay',
        [PaymentMethod.CreditCard]: 'Checkout with Credit Card',
    };

    if (paymentMethod === PaymentMethod.CreditCard) {
        segment.checkoutCTAClicked();
    } else {
        const cta = { name: ctaNameMap[paymentMethod], action: ctaActionMap[paymentMethod] };
        segment.digitalWalletCheckoutCTAClicked(cta);
    }

    segment.checkoutStarted();
}
