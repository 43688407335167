import { AxiosRequestConfig } from 'axios';
import { getPlatform } from '../../ts/modules/utils/general';
import { get, post } from './config';

/**
 * @description Concepts service for finding nearby concepts, get Menu Info, get Parent Menu.
 */

export const Concepts = {
    getNearby: (query: any, config?: AxiosRequestConfig | undefined) => {
        let url = `/concepts/nearby/?platform=${getPlatform()}`;
        if (query?.user_email) {
            // Only pass in user email param at all if it exists
            url += `&user_email=${query?.user_email}`;
        }
        return post(url, query, config);
    },
    getMenuSet: ({ appId }: { appId: string }) => {
        return get(`/concepts/menu-set/${appId}`);
    },
    getParentMenuSet: ({ brandId }: { brandId: string }) => {
        return get(`/concepts/parent-menu-set/${brandId}`);
    },
    getChildMenuSet: ({ locationShortId }: { locationShortId: string }) => {
        return get(`/concepts/child-menu-set/${locationShortId}`);
    },
};
