import { AppBrand } from './types';

const wingDynastyBrand: AppBrand = {
    app: {
        appId: 'io.nextbite.wingdynasty',
        appName: 'The Wing Dynasty',
        appHost: 'eatthewingdynasty.com',
    },
    styles: {
        'border-radius-base': '2px',
        'body-background': '#fff',
        'padding-md': '16px',
    },
};

export default wingDynastyBrand;
