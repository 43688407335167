import { LoadingOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import React, { useContext } from 'react';
import { ConceptContext } from '../../../contexts/concept-context';
import { useMobileScreen } from '../../utils/general';
import { PageNotFound } from '../page-not-found';
import { ConceptMenu } from './concept-menu';

export const ConceptMenuContainer = ({
    headerHeight,
    handleItemDetailPopUp,
}: {
    headerHeight: number;
    handleItemDetailPopUp: any;
}) => {
    const {
        conceptDetails: { menuInfo },
        isConceptFetching,
        isErrorFetching,
    } = useContext(ConceptContext);
    const isMobile = useMobileScreen();

    return menuInfo?.app_id ? (
        <ConceptMenu headerHeight={headerHeight} handleItemDetailPopUp={handleItemDetailPopUp} />
    ) : isConceptFetching ? (
        <MenuLoadingSkeleton isMobile={isMobile} />
    ) : isErrorFetching ? (
        <PageNotFound />
    ) : (
        <MenuLoadingSkeleton isMobile={isMobile} />
    );
};

export const MenuLoadingSkeleton = ({ isMobile }: { isMobile: boolean }) =>
    isMobile ? (
        <div
            style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <LoadingOutlined style={{ fontSize: '4em' }} />
        </div>
    ) : (
        <>
            <div>
                <div style={{ padding: '20px' }}>
                    <Skeleton active paragraph={{ rows: 8, width: '100%' }} />
                </div>
                <div style={{ margin: 'auto', width: '60%', marginBottom: '50px' }}>
                    <Skeleton active paragraph={{ rows: 1, width: '100%' }} title={false} />
                </div>
                <div style={{ margin: 'auto', width: '60%', marginBottom: '30px' }}>
                    <Skeleton active paragraph={{ rows: 1, width: '300px' }} title={false} />
                </div>
                <div
                    style={{
                        margin: 'auto',
                        width: '60%',
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        columnGap: '20px',
                        rowGap: '20px',
                    }}
                >
                    <Skeleton
                        active
                        paragraph={{ rows: 4, width: '100%' }}
                        title={false}
                        avatar={{ size: 100, shape: 'square' }}
                    />
                    <Skeleton
                        active
                        paragraph={{ rows: 4, width: '100%' }}
                        title={false}
                        avatar={{ size: 100, shape: 'square' }}
                    />
                    <Skeleton
                        active
                        paragraph={{ rows: 4, width: '100%' }}
                        title={false}
                        avatar={{ size: 100, shape: 'square' }}
                    />
                    <Skeleton
                        active
                        paragraph={{ rows: 4, width: '100%' }}
                        title={false}
                        avatar={{ size: 100, shape: 'square' }}
                    />
                    <Skeleton
                        active
                        paragraph={{ rows: 4, width: '100%' }}
                        title={false}
                        avatar={{ size: 100, shape: 'square' }}
                    />
                </div>
            </div>
        </>
    );
