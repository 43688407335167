import { Skeleton } from 'antd';
import React, { ImgHTMLAttributes, useEffect, useState } from 'react';
import { resizedImagePath } from '../utils/general';

interface ImageWithFallbackProps extends ImgHTMLAttributes<HTMLImageElement> {
    fallbackSources?: string[] | null;
    resize?: number;
}

export const ImageWithFallback = (props: ImageWithFallbackProps) => {
    const { fallbackSources, resize, ...imgProps } = props;
    const [sources, setSources] = useState<string[]>([]);
    const [fbIndex, setFbIndex] = useState<number>(() => {
        return sources.findIndex((imgSrc: string | undefined) => imgSrc && imgSrc.length > 0);
    });

    useEffect(() => {
        if (imgProps?.src) {
            setSources(
                resize ? [resizedImagePath(imgProps?.src, resize), imgProps.src] : [imgProps.src],
            );
        } else if (fallbackSources) {
            setSources(fallbackSources);
        } else {
            setSources([]);
        }
    }, [imgProps?.src, fallbackSources, resize]);

    if (!sources) {
        return null;
    }

    const imageErrorHandler = () => {
        setFbIndex(prevInd => {
            const newIndex = prevInd + 1;
            const img = new Image();
            img.src = sources[newIndex] || '';
            img.onerror = function () {
                if (newIndex < sources.length) {
                    imageErrorHandler();
                }
            };
            return newIndex;
        });
    };

    return fbIndex >= sources.length ? (
        <div
            {...imgProps}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                background: '#ece8e8',
            }}
        >
            <Skeleton.Image />
        </div>
    ) : (
        <img
            {...imgProps}
            src={sources[fbIndex] || ''}
            onError={props.onError ? props.onError : imageErrorHandler}
        />
    );
};
