import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Collapse, Drawer } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { AddressEntryModal } from '../order-address-form/address-entry-modal';
import { formatStreetAddress, useMobileScreen } from '../utils/general';
import useSegment from '../utils/segment';
import { AddressForm } from './address-form';
import { CheckoutForms } from './index';

const { Panel } = Collapse;

export const DeliveryAddressFormLamvEnabled: FC<DAFormProps> = ({
    isOpen,
    editButtonDisabled,
    onEdit,
    onCancelEdit,
    onClickContinue,
}) => {
    const [expand, setExpand] = useState(isOpen);
    const [isAddressModalVisible, setAddressModalVisible] = useState(false);

    const segment = useSegment();
    const isMobile = useMobileScreen();
    const cdpLocation = useContext(CdpLocationContext);

    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);
    const { isDinnerbell, orderAddress, orderAddressApt } = useContext(AppContext);

    const iconColor = isDinnerbell ? '#f1c143' : '#bbb';
    const iconStyles = {
        fontSize: '20px',
        color: iconColor,
    };

    useEffect(() => {
        setExpand(isOpen);
    }, [isOpen]);

    if (restaurantInfo == null || restaurantInfo.delivery == null) {
        return null;
    }

    const onCancel = () => {
        setExpand(false);
        segment.checkoutCancelLinkClicked('Edit Address');
        onCancelEdit(CheckoutForms.DeliveryAddress);
    };

    const onCancelAddressModal = () => {
        setExpand(true);
        setAddressModalVisible(false);
    };

    const handleSubmitAddressForm = () => {
        setAddressModalVisible(false);
        setExpand(false);
        onClickContinue();
        onCancelEdit(CheckoutForms.DeliveryAddress);
    };

    const handleAddressModalOpen = () => {
        setExpand(false);
        setAddressModalVisible(true);
        segment.changeAddressLinkClicked(cdpLocation);
    };

    const toggleExpand = () => {
        if (expand) {
            segment.checkoutCancelLinkClicked('Edit Address');
            onCancelEdit(CheckoutForms.DeliveryAddress);
        } else {
            segment.checkoutStepViewed('Address');
            segment.checkoutEditAddressLinkClicked();
            onEdit(CheckoutForms.DeliveryAddress);
        }
        setExpand(!expand);
    };

    const EditButton = () => (
        <Button
            type="link"
            onClick={toggleExpand}
            data-testid="delivery-address-edit-btn"
            className={`checkout-edit-link ${editButtonDisabled ? 'disabled-button' : ''}`}
            disabled={editButtonDisabled}
        >
            Edit
        </Button>
    );

    const PanelHeaderContent = (
        <div className="checkout-ci-title" onClick={toggleExpand}>
            <HomeOutlined style={iconStyles} />
            <div>
                <span className="checkout-subtext">
                    {expand && !isMobile ? (
                        'Delivery Address'
                    ) : (
                        <>
                            {formatStreetAddress(
                                orderAddress?.structured_formatting,
                                orderAddressApt,
                            )}
                            <br />
                            {orderAddress?.structured_formatting?.secondary_text}
                        </>
                    )}
                </span>
            </div>
        </div>
    );

    return (
        <div className="checkout-delivery-address-wrapper">
            <Collapse
                bordered={false}
                expandIconPosition={'right'}
                expandIcon={EditButton}
                activeKey={expand ? '1' : '0'}
            >
                <Panel className="checkout-form-panel" header={PanelHeaderContent} key="1">
                    {isMobile ? (
                        <Drawer
                            className="checkout-edit-drawer"
                            closable={true}
                            closeIcon={<ArrowLeftOutlined />}
                            onClose={onCancel}
                            placement="right"
                            title="Delivery Address"
                            width="100%"
                            visible={expand}
                        >
                            <AddressForm
                                onClickContinue={onClickContinue}
                                expand={expand}
                                setExpand={setExpand}
                                onCancelEdit={onCancelEdit}
                                onCancel={onCancel}
                                onModalOpen={handleAddressModalOpen}
                            />
                        </Drawer>
                    ) : (
                        <AddressForm
                            onClickContinue={onClickContinue}
                            expand={expand}
                            setExpand={setExpand}
                            onCancelEdit={onCancelEdit}
                            onCancel={onCancel}
                            onModalOpen={handleAddressModalOpen}
                        />
                    )}
                </Panel>
            </Collapse>
            <AddressEntryModal
                isModalVisible={isAddressModalVisible}
                formTitle="Enter your delivery address to check availability"
                onCloseCallback={onCancelAddressModal}
                handleSubmit={handleSubmitAddressForm}
            />
        </div>
    );
};

interface DAFormProps {
    isOpen: boolean;
    onClickContinue: () => void;
    onEdit: (key: CheckoutForms) => void;
    onCancelEdit: (key: CheckoutForms) => void;
    editButtonDisabled: boolean;
}
