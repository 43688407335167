import { CheckCircleFilled, CloseCircleFilled, EnvironmentOutlined } from '@ant-design/icons';
import { AutoComplete, Form, Input } from 'antd';
import log from 'loglevel';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { AppContext } from '../../../contexts/app-context';
import { UsePlaceToNearbyMenuDetails } from '../../../hooks/usePlaceToNearbyMenuDetails';
import './index.scss';

const vIconState = {
    HIDE: 'HIDE',
    VALID: 'VALID',
    INVALID: 'INVALID',
};

const validationIcons = {
    [vIconState.HIDE]: null,
    [vIconState.VALID]: <CheckCircleFilled style={{ color: '#52C41A' }} />,
    [vIconState.INVALID]: <CloseCircleFilled style={{ color: '#F5222D' }} />,
};

const ClearActionWrap = ({
    children,
    onClear,
    showClear,
    autoCompleteWrapperRef,
    onMouseEnter,
    onMouseLeave,
}: {
    children: React.ReactNode;
    onClear: () => void;
    showClear: boolean;
    autoCompleteWrapperRef: React.RefObject<HTMLInputElement>;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}) => {
    return (
        <div
            className="clear-auto-complete-wrapper"
            ref={autoCompleteWrapperRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
            {showClear && (
                <CloseCircleFilled
                    className="clear-icon"
                    style={{ color: 'gray' }}
                    onClick={onClear}
                />
            )}
        </div>
    );
};

export const PlacesAutocomplete = ({
    id,
    onChange,
    onFocus,
    onBlur,
    onClear,
    value: inputValue,
    placeholder = '',
    error,
    helperText,
    dataTestId,
    isAddressValid,
    isAddressFetching,
    onInputBlur,
    fetchDetails = true,
}: {
    id: string;
    fetchDetails?: boolean;
    onChange: (value?: any) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onClear?: () => void | undefined;
    value: any;
    placeholder?: string;
    error: boolean;
    helperText: string | undefined;
    dataTestId?: string | undefined;
    isAddressValid?: boolean | null;
    isAddressFetching?: boolean;
    onInputBlur?: (e: any) => void;
}) => {
    const {
        value,
        suggestions: { data },
        setValue: setPlacesAutocompleteValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        debounce: 300,
    });
    const [showClearIcon, setShowClearIcon] = useState(false);
    const { isDinnerbell } = useContext(AppContext);
    const isMountedRef = useRef<boolean>(false);
    const autoCompleteWrapperRef = useRef<any>(null);

    const [showAddressValidationIcon, setShowAddressValidationIcon] = useState(false);

    const { fetchConceptDetails, clearValues } = UsePlaceToNearbyMenuDetails();

    useEffect(() => {
        if (
            (!showAddressValidationIcon && isAddressFetching) ||
            typeof isAddressFetching !== 'boolean'
        ) {
            if (!!inputValue?.description?.length && typeof isAddressValid === 'boolean') {
                setShowAddressValidationIcon(true);
            }
        }
    }, [isAddressFetching, inputValue, isAddressValid, showAddressValidationIcon]);

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (
                autoCompleteWrapperRef.current &&
                !autoCompleteWrapperRef.current.contains(event.target as Element)
            ) {
                setShowClearIcon(false);
            }
        };
        if (autoCompleteWrapperRef) {
            document.addEventListener('click', event => handleClickOutside(event));
        }

        return () => document.removeEventListener('click', event => handleClickOutside(event));
    }, [autoCompleteWrapperRef, setShowClearIcon]);

    const onSearch = (searchText: string) => {
        log.debug('%c DO SEARCH ', 'background-color: green', searchText);
        setPlacesAutocompleteValue(searchText, true);
        if (!searchText.length) {
            onClear?.();
            clearValues();
            setShowAddressValidationIcon(false);
        }
    };

    const onSelect = async (selectedDataString: string) => {
        isMountedRef.current = true;
        if (document?.activeElement instanceof HTMLElement) {
            // This forces any onscreen mobile keyboard to go away, like for real
            document.activeElement.blur();
        }
        const selectedData = JSON.parse(selectedDataString);
        const { description } = selectedData;
        setPlacesAutocompleteValue(description, false);
        setShowClearIcon(false);
        onChange(selectedData);
        if (fetchDetails) {
            const addressEntered = true;
            const conceptDetails = await fetchConceptDetails(
                selectedData?.place_id,
                isMountedRef.current,
                addressEntered,
            );
            const tempData = { ...selectedData, conceptDetails };
            onChange(tempData);
        }
        clearSuggestions();
        isMountedRef.current = false;
    };
    const options = data
        .filter(suggestion => !!suggestion.terms[0].value.match(/\d*/)[0])
        .map(suggestion => {
            const {
                structured_formatting: { main_text: mainText, secondary_text: secondaryText },
            } = suggestion;
            return {
                label: (
                    <div
                        style={{
                            lineHeight: '3em',
                            borderBottom: '#d9d9d9 solid 1px',
                            paddingLeft: 12,
                            paddingRight: 12,
                        }}
                    >
                        <strong>{mainText}</strong> <small>{secondaryText}</small>
                    </div>
                ),
                value: JSON.stringify(suggestion),
            };
        });

    useEffect(() => {
        setPlacesAutocompleteValue(inputValue?.description ? inputValue?.description : '', false);
    }, [inputValue, setPlacesAutocompleteValue]);

    const validationIcon = useMemo(() => {
        if (isAddressValid) {
            return validationIcons[vIconState.VALID];
        } else {
            return validationIcons[vIconState.INVALID];
        }
    }, [isAddressValid]);

    const isFinishedFetching =
        showAddressValidationIcon && !isAddressFetching && typeof isAddressFetching === 'boolean';

    return (
        <>
            <Form.Item
                help={helperText || null}
                validateStatus={
                    error || (showAddressValidationIcon && !isAddressValid && isFinishedFetching)
                        ? 'error'
                        : 'success'
                }
                style={{ marginBottom: 0 }}
            >
                <ClearActionWrap
                    onClear={() => {
                        onClear?.();
                        clearValues();
                        onChange(null);
                        setShowAddressValidationIcon(false);
                    }}
                    autoCompleteWrapperRef={autoCompleteWrapperRef}
                    showClear={
                        !isAddressFetching &&
                        typeof inputValue?.description === 'string' &&
                        inputValue?.description.length > 0 &&
                        showClearIcon
                    }
                    onMouseEnter={() => setShowClearIcon(true)}
                    onMouseLeave={() => setShowClearIcon(false)}
                >
                    <AutoComplete
                        dropdownClassName="category-search-dropdown"
                        id={id}
                        options={options}
                        style={{ width: '100%' }}
                        onSelect={onSelect}
                        onSearch={onSearch}
                        value={value}
                        onBlur={onBlur}
                        onFocus={onFocus}
                    >
                        <Input
                            placeholder={placeholder}
                            data-testid={dataTestId}
                            size="large"
                            onFocus={() => {
                                setShowClearIcon(true);
                            }}
                            onPressEnter={() => {
                                if (options[0]) {
                                    onSelect(options[0].value);
                                }
                            }}
                            prefix={
                                !isDinnerbell ? (
                                    <EnvironmentOutlined style={{ fontSize: '16px' }} />
                                ) : (
                                    ''
                                )
                            }
                            suffix={isFinishedFetching ? validationIcon : null}
                            onBlur={onInputBlur}
                        />
                    </AutoComplete>
                </ClearActionWrap>
            </Form.Item>
        </>
    );
};
