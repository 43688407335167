import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { PaymentRequestTokenEvent, TokenResult } from '@stripe/stripe-js';
import { Alert, Col, Collapse, Input, Row, Tooltip } from 'antd';
import { AxiosResponse } from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../contexts/app-context';
import { ConceptContext } from '../../contexts/concept-context';
import { CartItemInterface, OrderTotals } from '../menu/model';
import { VoucherInputBox } from '../order-summary/voucher-input-box';
import { LocationMatchResponse } from '../restaurant/model';
import { futureOrderUtils } from '../ui-components/future-order-components/useFutureOrderPopup';
import { placesToAddress } from '../utils/address';
import {
    buildModGroupPayloadFromItem,
    getAnonymousId,
    getLatLngTimeZone,
    getPlatform,
    getTimeZoneOffset,
    getUTMQueries,
    isTestAccount,
    useMobileScreen,
} from '../utils/general';
import logger from '../utils/logger';
import request from '../utils/request';
import useSegment from '../utils/segment';
import { PaymentMethodForm } from './payment-method-form';
import { ErrorBanner, PaymentMethod } from './index';
import './index.scss';

const isStaging = process.env.BUILD_ENV === 'staging';

// We absolutely do not want to submit more than one order at a time.
// This `ORDER_PLACED` global state is the safest most bulletproof way to guarantee this.
// `ORDER_PLACED` should not be used for any React state calculations. Use it ONLY to prevent submitting multiple orders.
// Use `handlingOrder` to deal with React state / UI calculations.
let ORDER_PLACED = false;
export function overrideGlobalOrderPlaced(newValue: boolean) {
    ORDER_PLACED = newValue;
}

export interface TestToken {
    token: 'test';
}

export const StripeIntegrationForm: FC<SIFormProps> = ({
    isOpen,
    onClickContinue,
    paymentError,
    setPaymentError,
    setTopErrorBanner,
    setLoadingStep,
    paymentMethod,
    orderTotals,
    setOrderTotals,
    otherTipValue,
    setFetchDiscounts,
    fetchDiscounts,
    paymentResult,
    placeOrderbtnRef,
    setPaymentMethod,
    setPlaceOrderButtonEnabled,
    hasFutureOrderPassedTimeErr,
}) => {
    const [expand, setExpand] = useState<boolean>(isOpen);
    const [zipcode, setZipCode] = useState<string>('');
    const [readyCardNumber, setReadyCardNumber] = useState<boolean>(false);
    const [readyExpiry, setReadyExpiry] = useState<boolean>(false);
    const [readyCVC, setReadyCVC] = useState<boolean>(false);
    const { user } = useAuth0();
    const ldFlags = useFlags();

    const setGenericError = useCallback(
        (arg0: boolean) =>
            arg0
                ? setTopErrorBanner({
                      type: 'error',
                      message: "Sorry, we're unable to process your order at this time.",
                  })
                : setTopErrorBanner(null),
        [setTopErrorBanner],
    );

    const {
        orderAddress,
        cartItems,
        customerInfo,
        deliveryPreference,
        orderAddressApt,
        template,
        stateIds,
        tipValue,
        addUtensils,
        futureOrderDate,
        promoId,
        vouchers,
        updateStateIds,
        updateHandlingOrder,
        handlingOrder,
        updateOrderPayload,
    } = useContext(AppContext);
    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const history = useHistory();
    const kitchen = restaurantInfo?.delivery;
    const segment = useSegment();
    const isMobile = useMobileScreen();
    const { getUtcDateTimeString } = futureOrderUtils;

    useEffect(() => {
        if (template) {
            if (isOpen) {
                segment.checkoutStepViewed('Payment');
            }
            setExpand(isOpen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, template]);

    useEffect(() => {
        const enableOrderButton: boolean =
            expand &&
            readyCardNumber &&
            readyExpiry &&
            readyCVC &&
            zipcode.length === 5 &&
            !hasFutureOrderPassedTimeErr &&
            !handlingOrder;

        setPlaceOrderButtonEnabled(enableOrderButton);
    }, [
        expand,
        readyCardNumber,
        readyExpiry,
        readyCVC,
        zipcode,
        hasFutureOrderPassedTimeErr,
        handlingOrder,
        setPlaceOrderButtonEnabled,
    ]);

    const placeOrder = useCallback(
        (orderPayload: any): Promise<AxiosResponse> => {
            console.log('Starting place order'); // eslint-disable-line no-console
            const user_id: string | undefined = user?.sub;

            return request
                .post('/place-order/', orderPayload, {
                    params: {
                        location_id: kitchen?.id,
                        app_id: kitchen?.app_id,
                        platform: getPlatform(),
                        user_id,
                    },
                })
                .then(res => {
                    log.debug('%c UPDATE STATE ID ', 'background-color: green', res.data);
                    updateStateIds({ ...stateIds, orderId: res.data.order_id });
                    return res;
                })
                .catch(err => {
                    log.debug('%c FAIL: Place Orders failed, err: ', 'background-color: red', err);
                    setGenericError(true);
                    if (err.response) {
                        segment.errorShown(
                            `Generic Error, HTTP Status Code: ${err.response.status}`,
                        );
                    } else {
                        segment.errorShown(`Generic Error, HTTP Status Code: ${err.message}`);
                    }
                    updateHandlingOrder(false);
                    overrideGlobalOrderPlaced(false);
                    elements?.getElement(CardNumberElement)?.focus();
                    logger.pushAjaxError(err, 'Failed to place order');
                    return err;
                });
        },
        [
            elements,
            kitchen,
            stateIds,
            updateStateIds,
            segment,
            setGenericError,
            updateHandlingOrder,
            user,
        ],
    );

    const getInfo = useCallback(() => {
        const placedTime = new Date();
        const placedDatetime = placedTime.toISOString();
        const prepTime = kitchen?.prep_time || 0;
        let prepareTime = new Date(placedTime.getTime() + prepTime * 60000);
        let isFutureOrder = false;

        let scheduledOrderStartTime = null;
        let scheduledOrderEndTime = null;

        if (futureOrderDate) {
            scheduledOrderStartTime = getUtcDateTimeString(
                futureOrderDate.date,
                futureOrderDate.time.startTime,
            );
            scheduledOrderEndTime = getUtcDateTimeString(
                futureOrderDate.date,
                futureOrderDate.time.endTime,
            );
            const momentObj = futureOrderDate.date;
            prepareTime = momentObj.toDate();
            isFutureOrder = true;
        }

        // order payload needs a string under 8 characters expects ISO 3166-2 format
        const parsedAddress = placesToAddress(orderAddress, orderAddressApt.trim(), true);

        const userInfo = {
            name: customerInfo?.name,
            address_line1: parsedAddress.address1,
            address_line2: parsedAddress.address2,
            address_city: parsedAddress.city,
            address_state: parsedAddress.region,
            address_zip: parsedAddress.postalcode,
            address_country: parsedAddress.country,
        };
        return {
            scheduledOrderStartTime,
            scheduledOrderEndTime,
            placedDatetime,
            prepareTime,
            isFutureOrder,
            parsedAddress,
            userInfo,
        };
    }, [
        futureOrderDate,
        kitchen,
        orderAddress,
        orderAddressApt,
        customerInfo,
        getUtcDateTimeString,
    ]);

    const tryOrder = useCallback(
        async (
            placedDatetime: string,
            prepareTime: Date,
            isFutureOrder: boolean,
            parsedAddress: {
                country: string;
                address2: string;
                city: string;
                address1: string;
                postalcode: string;
                region: string;
            },
            scheduledOrderStartTime: string | null,
            scheduledOrderEndTime: string | null,
            stripeToken: PaymentRequestTokenEvent | TokenResult | TestToken,
        ) => {
            const orderPayload = await getOrderPayload(
                {
                    scheduledOrderStartTime,
                    scheduledOrderEndTime,
                    placedDatetime,
                    prepareTime,
                    isFutureOrder,
                    deliveryPreference,
                    parsedAddress,
                    addUtensils,
                    orderAddress,
                    tipValue,
                    cartItems,
                },
                customerInfo,
                stripeToken,
                kitchen,
                template,
                restaurantInfo,
                stateIds,
                promoId,
                vouchers,
            );

            log.debug('ORDER PAYLOAD', orderPayload);

            updateOrderPayload(orderPayload);

            const res = await placeOrder(orderPayload); // FINALLY, the entire reason for this site's existence

            log.debug('%c Done Placing Orders ', 'background-color: green', res); // eslint-disable-line no-console

            if (res?.status === 200) {
                segment.paymentCheckoutStepCompleted(stripeToken);
                onClickContinue();

                setLoadingStep(1);

                log.debug('%c Order Placed ', 'background-color: red', res?.data);
                // pollForStatusUpdates(res?.data.order_id);
            }
        },
        [
            onClickContinue,
            setLoadingStep,
            segment,
            deliveryPreference,
            cartItems,
            customerInfo,
            kitchen,
            template,
            restaurantInfo,
            stateIds,
            promoId,
            vouchers,
            addUtensils,
            placeOrder,
            tipValue,
            orderAddress,
            updateOrderPayload,
        ],
    );

    useEffect(() => {
        log.debug(
            '%c Payment Token useEffect',
            'background-color: blue',
            paymentResult?.data?.paymentRequest,
        );
        paymentResult?.data?.paymentRequest?.on?.(
            'token',
            async (tokenResult: PaymentRequestTokenEvent) => {
                if (ORDER_PLACED) {
                    return;
                }
                overrideGlobalOrderPlaced(true);
                log.debug('%c TOKEN RESULT', 'background-color: blue', tokenResult);
                segment.placeOrderWithWallet();
                const {
                    placedDatetime,
                    prepareTime,
                    isFutureOrder,
                    parsedAddress,
                    scheduledOrderStartTime,
                    scheduledOrderEndTime,
                } = getInfo();
                await tryOrder(
                    placedDatetime,
                    prepareTime,
                    isFutureOrder,
                    parsedAddress,
                    scheduledOrderStartTime,
                    scheduledOrderEndTime,
                    tokenResult,
                );
                tokenResult.complete('success');
            },
        );
    }, [paymentResult, getInfo, segment, tryOrder]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (handlingOrder) {
            return;
        }

        segment.placeOrderCTAClicked();

        log.debug('Orders submitted');
        updateHandlingOrder(true);
        setPaymentError('');
        setGenericError(false);

        if (validZipCode(zipcode)) {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                console.log(`FAIL: Missing either stripe: ${stripe} or elements: ${elements}`); // eslint-disable-line no-console
                return;
            }

            const cardElement = elements.getElement(CardNumberElement);

            if (cardElement) {
                const {
                    scheduledOrderStartTime,
                    scheduledOrderEndTime,
                    placedDatetime,
                    prepareTime,
                    isFutureOrder,
                    parsedAddress,
                    userInfo,
                } = getInfo();

                const tokenResult = await stripe.createToken(cardElement, userInfo);

                if (tokenResult?.token) {
                    await tryOrder(
                        placedDatetime,
                        prepareTime,
                        isFutureOrder,
                        parsedAddress,
                        scheduledOrderStartTime,
                        scheduledOrderEndTime,
                        tokenResult,
                    );
                } else if (tokenResult?.error) {
                    // In staging, if user used a test card on a test location, allow it and set payment token to special 'test' flag
                    if (
                        isStaging &&
                        isTestAccount(kitchen?.app_id || '') &&
                        tokenResult.error?.decline_code === 'live_mode_test_card'
                    ) {
                        const testToken: TestToken = { token: 'test' };
                        await tryOrder(
                            placedDatetime,
                            prepareTime,
                            isFutureOrder,
                            parsedAddress,
                            scheduledOrderStartTime,
                            scheduledOrderEndTime,
                            testToken,
                        );
                    } else if (
                        isStaging &&
                        tokenResult.error?.decline_code === 'live_mode_test_card'
                    ) {
                        const orderPayload = await getOrderPayload(
                            {
                                scheduledOrderStartTime,
                                scheduledOrderEndTime,
                                placedDatetime,
                                prepareTime,
                                isFutureOrder,
                                deliveryPreference,
                                parsedAddress,
                                addUtensils,
                                orderAddress,
                                tipValue,
                                cartItems,
                            },
                            customerInfo,
                            tokenResult,
                            kitchen,
                            template,
                            restaurantInfo,
                            stateIds,
                            promoId,
                            vouchers,
                        );
                        log.debug('Staging skipped Place Order');
                        log.debug('ORDER PAYLOAD', orderPayload);
                        history.push('/order-confirmation');
                    } else {
                        updateHandlingOrder(false);
                        setPaymentError(tokenResult.error.message || '');
                        segment.errorShown(tokenResult.error.message || '');
                    }
                }
            } else {
                updateHandlingOrder(false);
                setPaymentError('Your card information is incomplete.');
                segment.errorShown('FAIL: do not have card elements');
            }
        } else {
            console.log('FAIL: do not have a zipcode'); // eslint-disable-line no-console
            updateHandlingOrder(false);
            setPaymentError('Your card zip code is incomplete.');
            segment.errorShown('FAIL: do not have a zipcode');
        }
    };

    const onChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
        const zipCode = e.currentTarget.value;
        const regex = new RegExp(/^[0-9]*$/, 'g');
        if ((regex.test(zipCode) && zipCode.length <= 5) || zipCode.length === 0) {
            setZipCode(zipCode);
        }
    };

    const PanelHeaderContent = (
        <PaymentMethodForm paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
    );

    if (restaurantInfo == null || restaurantInfo.delivery == null) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Collapse bordered={false} activeKey={expand ? '4' : '0'} className="checkout-stripe">
                <Collapse.Panel header={PanelHeaderContent} key="4" showArrow={false}>
                    {paymentError ? (
                        <Alert
                            className="payment-error-alert"
                            message={paymentError}
                            type="error"
                            icon={<ExclamationCircleOutlined />}
                            showIcon
                        />
                    ) : null}
                    <Row>
                        <Col className="card-number" span={24}>
                            <label className={!isMobile ? 'mt-10' : ''}>
                                Card number
                                <CardNumberElement
                                    options={{ placeholder: '', ...options }}
                                    onChange={event => setReadyCardNumber(event.complete)}
                                />
                            </label>
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col flex="1 1 170px">
                            <label>
                                Expiration date
                                <CardExpiryElement
                                    options={options}
                                    onChange={event => setReadyExpiry(event.complete)}
                                />
                            </label>
                        </Col>
                        <Col flex="1 1 170px">
                            <label>
                                {'CVV '}
                                <Tooltip
                                    placement="right"
                                    title="Your CVV is the 3-digit number on the back of your card."
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip>
                                <CardCvcElement
                                    options={{ placeholder: '', ...options }}
                                    onChange={event => setReadyCVC(event.complete)}
                                />
                            </label>
                        </Col>
                        <Col flex="1 1 170px">
                            <label>
                                Zip code
                                <Input
                                    maxLength={5}
                                    onChange={onChangeInput}
                                    style={{
                                        paddingTop: 20,
                                        fontSize: 16,
                                        color: '#424770',
                                    }}
                                    className="payment-zipcode mb-20"
                                    value={zipcode}
                                    type="text"
                                    pattern="\d*"
                                    inputMode="numeric"
                                />
                            </label>
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>
            {ldFlags.enableVouchers && (
                <Row>
                    <VoucherInputBox
                        orderTotals={orderTotals}
                        setOrderTotals={setOrderTotals}
                        otherTipValue={otherTipValue}
                        fetchDiscounts={fetchDiscounts}
                        setFetchDiscounts={setFetchDiscounts}
                    />
                </Row>
            )}
            <button ref={placeOrderbtnRef} onClick={handleSubmit} style={{ display: 'none' }} />
        </form>
    );
};

function validZipCode(zipcode: string) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode);
}

interface SIFormProps {
    orderTotals: OrderTotals;
    isOpen: boolean;
    onClickContinue: () => void;
    paymentError: string;
    setPaymentError: (arg0: string) => void;
    setTopErrorBanner: (arg0: ErrorBanner | null) => void;
    setLoadingStep: (arg0: number) => void;
    paymentMethod: PaymentMethod;
    hasFutureOrderPassedTimeErr?: boolean;
    otherTipValue: number;
    fetchDiscounts: boolean;
    paymentResult: any;
    placeOrderbtnRef: any;
    setPaymentMethod: Dispatch<SetStateAction<PaymentMethod>>;
    setOrderTotals: (value: OrderTotals) => void;
    setFetchDiscounts: (arg0: boolean) => void;
    setPlaceOrderButtonEnabled: (arg0: boolean) => void;
}

const useOptions = () => {
    const fontSize = '16px';
    return useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    letterSpacing: '0.035em',
                    fontFamily: 'inherit',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        }),
        [fontSize],
    );
};

async function getOrderPayload(
    orderInfo: any,
    customerInfo: any,
    stripeToken: PaymentRequestTokenEvent | TokenResult | TestToken,
    kitchen: any,
    template: any,
    restaurantInfo: LocationMatchResponse | null,
    stateIds: any,
    promoId: string,
    vouchers: string[],
) {
    const {
        scheduledOrderStartTime,
        scheduledOrderEndTime,
        placedDatetime,
        prepareTime,
        isFutureOrder,
        deliveryPreference,
        parsedAddress,
        addUtensils,
        orderAddress,
        tipValue,
        cartItems,
    } = orderInfo;

    const deliveryInstructions = [
        deliveryPreference?.preference ?? '',
        deliveryPreference?.text ?? '',
    ]
        .filter(el => el)
        .join(' | ');

    const { location } = orderAddress?.geometry ?? {};

    const subtotal = cartItems.reduce((s: any, a: { price: any }) => s + a.price, 0);
    const taxes = Math.round(
        cartItems.reduce(
            (s: any, a: { price: number; quantity: number; tax_rate: number }) =>
                a.price * a.quantity * a.tax_rate,
            0,
        ),
    );

    log.debug('%c CONTEXT CUSTOMER INFO: ', 'background-color: green', customerInfo?.name.trim());
    log.debug('%c stripe token: ', 'background-color: green', stripeToken);
    log.debug(
        '%c stripe Token payer name: ',
        'background-color: green',
        'payerName' in stripeToken && stripeToken?.payerName,
    );

    return {
        app_id: kitchen.app_id,
        location_id: kitchen.id,
        brand_id: template?.metadata?.brand?.id,
        checkout_id: stateIds.checkoutId,
        cart_id: stateIds.cartId,
        session_id: stateIds.sessionId,
        anonymous_id: getAnonymousId(),
        order_type: 'new_order',
        placed_datetime: placedDatetime,
        prepare_by_datetime: prepareTime.toISOString(),
        scheduled_order_start_time: scheduledOrderStartTime,
        scheduled_order_end_time: scheduledOrderEndTime,
        is_future_order: isFutureOrder,
        special_instructions: addUtensils ? 'Include napkins and utensils' : '',
        service_info: {
            method: 'delivery',
            special_instructions: deliveryInstructions.trim(),
            delivery_info: orderAddress.place_id,
        },
        customer_info: {
            name:
                ('payerName' in stripeToken && stripeToken?.payerName) || customerInfo?.name.trim(),
            email:
                ('payerEmail' in stripeToken && stripeToken?.payerEmail) ||
                customerInfo?.email.trim(),
            address1: parsedAddress.address1,
            address2: parsedAddress.address2,
            city: parsedAddress.city,
            region: parsedAddress.region,
            postalcode: parsedAddress.postalcode,
            country: parsedAddress.country,
            phone_number:
                ('payerPhone' in stripeToken && stripeToken?.payerPhone) ||
                customerInfo?.phonenumber.trim(),
        },
        subtotal_info: {
            items_subtotal: subtotal,
            discounts: [],
            fees: [],
            taxes: [
                {
                    charge_id: uuidv4(),
                    description: 'tax',
                    amount: taxes,
                },
            ],
            surcharges: [
                {
                    charge_id: uuidv4(),
                    description: 'tip',
                    amount: tipValue,
                },
            ],
            total: subtotal + taxes + (tipValue ?? 0),
        },
        payment_info: {
            method: 'charge',
            data: btoa(JSON.stringify(stripeToken.token)),
        },
        contents: {
            line_items: cartItems.map((item: CartItemInterface) => {
                return {
                    item_id: item.item_id,
                    name: item.name,
                    price: item.item_price,
                    special_instructions: item.special_instructions?.trim(),
                    quantity: item.quantity,
                    menu_id: item.menu_id,
                    section_id: item.section_id,
                    modifier_groups: buildModGroupPayloadFromItem(item.modifier_groups),
                };
            }),
        },
        timezone_offsets: {
            customer_browser: new Date().getTimezoneOffset() * -1,
            customer_address: await getLatLngTimeZone(location?.lat, location?.lng),
            restaurant_address: getTimeZoneOffset(kitchen.timezone),
        },
        promotion_id: promoId,
        vouchers,
        platform: getPlatform(),
        utm_payload: getUTMQueries(),
    };
}
