import { CalendarOutlined, ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Radio, Row, Typography } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { OrderEvent } from '../../restaurant/model';
import { DtcSelect } from '../dtc-select';
import { TemplateButton } from '../template-button';
import { orderTypes, useFutureOrderPopup } from './useFutureOrderPopup';
import './future-order-popup.scss';

export const FutureOrderPopup = (props: {
    onCancel: (event: OrderEvent) => void;
    setDeliveryTime?: (input: string) => void;
    isPopupMode?: boolean;
    inCart?: boolean;
    useDropdownForTimeBox?: boolean;
}) => {
    const { Title } = Typography;
    const {
        deliverableDates,
        pausedUntil,
        windows,
        isMobile,
        wrapperRef,
        isScheduledOrderOnly,
        radioVal,
        asapOrLaterOptions,
        selectedWindow,
        disabledUpdateButton,
        selectedDateIndex,
        pauseAdjustedWindows,
        checkIfRestaurantClosedOnHoliday,
        onDateSelect,
        onRadioChange,
        saveSelectedTimeWindow,
        createDeliveryWindows,
        onUpdatePopup,
    } = useFutureOrderPopup(props);

    const { onCancel, isPopupMode, inCart } = props;

    const CancelButton = () => (
        <Button
            type="default"
            size="large"
            block
            onClick={() => onCancel({ action: 'Cancel', orderType: orderTypes[radioVal] })}
        >
            Cancel
        </Button>
    );

    const UpdateButton = () => (
        <TemplateButton
            size="large"
            block
            type="primary"
            disabled={(radioVal === 'scheduled' && !selectedWindow) || disabledUpdateButton}
            onClick={onUpdatePopup}
        >
            Update
        </TemplateButton>
    );

    const renderDateDropDown = () => {
        type dropdownOption = { value: number; label: string };
        const options = deliverableDates.reduce((acc: dropdownOption[], deliveryDate, dateInx) => {
            const beforePausedUntilDay = moment(deliveryDate.date).isBefore(pausedUntil, 'day');
            const noWindowsDueToPause = pauseAdjustedWindows?.length === 0;
            const noWindowsToday =
                (dateInx === 0 &&
                    (deliveryDate.disabled ||
                        !windows?.today?.length ||
                        beforePausedUntilDay ||
                        noWindowsDueToPause)) ||
                checkIfRestaurantClosedOnHoliday(deliveryDate.date.format('YYYY-MM-DD'));

            if (radioVal === 'asap' && noWindowsToday) {
                //for ASAP, if there are no delivery windows today, add a placeholder "Today" selection anyways
                return [
                    ...acc,
                    {
                        value: -1,
                        label: 'Today, ' + moment().format('MMMM D'),
                    },
                ];
            } else if (beforePausedUntilDay || noWindowsDueToPause) {
                return acc;
            } else if (noWindowsToday) {
                // don't add a "Today" selection'
                return acc;
            } else if (!deliveryDate.disabled) {
                return [
                    ...acc,
                    {
                        value: dateInx,
                        label:
                            dateInx === 0
                                ? 'Today, ' + deliveryDate?.date.format('MMMM D')
                                : deliveryDate?.date.format('dddd, MMMM D'),
                    },
                ];
            }
            return acc;
        }, []);

        const selectedOption = options.find(option => option.value === selectedDateIndex) || {
            value: '',
        };

        return (
            <div className="time-window-options">
                <DtcSelect
                    onChange={({ value }) => {
                        if (typeof value === 'number') {
                            onDateSelect(value);
                        }
                    }}
                    options={options}
                    icon={CalendarOutlined}
                    value={radioVal === 'asap' ? options[0] : selectedOption}
                ></DtcSelect>
            </div>
        );
    };

    return (
        <div
            className={`${isPopupMode ? 'future-popup-wrapper' : 'static-content'} ${
                inCart ? 'in-cart' : ''
            }`}
            ref={wrapperRef}
        >
            {isMobile && (
                <CloseOutlined
                    className="close-icon"
                    onClick={() => onCancel({ action: 'Cancel', orderType: orderTypes[radioVal] })}
                />
            )}
            <section className="timebox-carousel">
                <Title level={3} className="condensed-txt">
                    {isPopupMode ? 'Delivery Date' : ''}
                </Title>
                <div className="time-select-container">{renderDateDropDown()}</div>
            </section>
            <Col className="timeslot-selection">
                <Title level={3} className="condensed-txt">
                    Delivery {!isPopupMode ? 'time' : 'Time'}
                </Title>
                <Row>
                    {!isScheduledOrderOnly && (
                        <>
                            <Radio.Group
                                className="delivery-options"
                                options={asapOrLaterOptions}
                                size="large"
                                onChange={onRadioChange}
                                value={radioVal}
                                optionType="button"
                            />
                        </>
                    )}
                    {radioVal === 'scheduled' && Boolean(createDeliveryWindows()?.length) && (
                        <Col
                            span={24}
                            className={`time-window-options ${
                                !isScheduledOrderOnly ? 'mt-20' : ''
                            }`}
                        >
                            <DtcSelect
                                onChange={({ value }) => {
                                    if (typeof value === 'string') {
                                        saveSelectedTimeWindow(value);
                                    }
                                }}
                                icon={ClockCircleOutlined}
                                value={
                                    selectedWindow
                                        ? createDeliveryWindows()?.find(
                                              window =>
                                                  window.value ===
                                                  JSON.stringify({
                                                      startTime: selectedWindow?.startTime,
                                                      endTime: selectedWindow?.endTime,
                                                  }),
                                          )
                                        : { value: '', label: 'Select a time window' }
                                }
                                placeholder="Select a delivery window"
                                options={createDeliveryWindows() || []}
                            />
                        </Col>
                    )}
                </Row>
            </Col>
            <footer className="controlled-action-container">
                <Divider className="divider" />
                <Row wrap={false} className="controlled-buttons action-row">
                    <CancelButton />
                    <UpdateButton />
                </Row>
            </footer>
        </div>
    );
};

FutureOrderPopup.defaultProps = {
    isPopupMode: true,
    useDropdownForTimeBox: false,
    setDeliveryTime: () => null,
    inCart: false,
};
