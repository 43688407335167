import { EnvironmentFilled } from '@ant-design/icons';
import GoogleMapReact from 'google-map-react';
import React from 'react';

export default function GoogleMap(props: GoogleMapReact.Coords) {
    const defaultProps = {
        center: {
            lat: props.lat || 34,
            lng: props.lng || -118,
        },
        zoom: 11,
    };

    return (
        // Important! Always set the container height explicitly
        <div style={{ height: '120px', width: '100%', overflow: 'hidden', borderRadius: '12px' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: '' }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                <EnvironmentFilled style={{ fontSize: '25px', color: '#FF0000' }} />
            </GoogleMapReact>
        </div>
    );
}
