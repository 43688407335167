import { FacebookOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import { TemplateButton } from '../ui-components/template-button';
import { pausedLongerThanExpectedMessage } from '../utils/error-messages';
import { getLocationReopenDayTime, useMobileScreen } from '../utils/general';
import useSegment from '../utils/segment';

export const LocationClosed: FC<LocationClosedProps> = ({ restaurantInfo }) => {
    const { template } = useContext(AppContext);
    const [emailSent, setEmailSent] = useState(false);
    const isMobile = useMobileScreen();
    const segment = useSegment();
    const reopenAtDateTime = restaurantInfo?.delivery?.reopen_at;
    const locationInfo = getLocationReopenDayTime(reopenAtDateTime);

    if (restaurantInfo == null || restaurantInfo.delivery == null || template == null) {
        return null;
    }

    const shareEmail = (values: any) => {
        segment.submitEmailCTA(false);
        segment.emailCapturedAddressEntry(values.email, true);
        segment.IdentifyEmail(values.email);
        setEmailSent(true);
    };

    const { instagram, facebook, twitter } = template.general?.social_media || {};

    return (
        <div className="unavailable-delivery mt-10" data-testid="unavailable-delivery">
            {!emailSent ? (
                <>
                    {
                        <h3 className="location-error">
                            {locationInfo?.pausedLongerThanAWeek ? (
                                { pausedLongerThanExpectedMessage }
                            ) : (
                                <>
                                    Delivery is currently unavailable.{' '}
                                    {locationInfo && (
                                        <>
                                            We open {locationInfo.reopenDay} at{' '}
                                            {locationInfo.reopenHour}.{' '}
                                        </>
                                    )}
                                </>
                            )}
                            Share your email address and we'll let you know when you can order.
                        </h3>
                    }
                    <Form className="width-100" name="address_submit" onFinish={shareEmail}>
                        <Form.Item
                            style={{ marginTop: '20px' }}
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input placeholder="Enter your email address" required size="large" />
                            <h5 className="mt-5">
                                By sharing my email address, I agree to receive marketing
                                communications from {template.metadata.brand.name}.
                            </h5>
                        </Form.Item>
                        <Form.Item className="address-form-button-wrap">
                            <TemplateButton
                                type="primary"
                                htmlType="submit"
                                block={isMobile}
                                size="large"
                                className={isMobile ? 'mt-20' : ''}
                            >
                                Let me know
                            </TemplateButton>
                        </Form.Item>
                    </Form>
                </>
            ) : (
                <>
                    <h3>
                        Thanks! We’ll reach out the next time we’re open.
                        {(instagram || facebook || twitter) && (
                            <span> In the meantime, see what we’re up to on social.</span>
                        )}
                    </h3>

                    <div className="social-icons mt-30 blue-color">
                        {instagram && (
                            <Button
                                className="mb-10"
                                type="primary"
                                ghost
                                block
                                icon={<InstagramOutlined />}
                                href={instagram}
                                target="_blank"
                                onClick={() =>
                                    segment.linkClicked('www.instagram.com', 'Instagram')
                                }
                            >
                                Instagram
                            </Button>
                        )}
                        {facebook && (
                            <Button
                                className="mb-10"
                                type="primary"
                                ghost
                                block
                                icon={<FacebookOutlined />}
                                href={facebook}
                                target="_blank"
                                onClick={() => segment.linkClicked('www.facebook.com', 'Facebook')}
                            >
                                Facebook
                            </Button>
                        )}
                        {twitter && (
                            <Button
                                className="mb-10"
                                type="primary"
                                ghost
                                block
                                icon={<TwitterOutlined />}
                                href={twitter}
                                target="_blank"
                                onClick={() => segment.linkClicked('www.twitter.com', 'Twitter')}
                            >
                                Twitter
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

interface LocationClosedProps {
    restaurantInfo: any;
}
