import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { withAppRoot } from '../utils/general';

interface IPageNotFoundProps {
    errorMessage?: string;
    supportingMessage?: string;
    redirectText?: string;
}

export const PageNotFound: FC<IPageNotFoundProps> = ({
    errorMessage = 'There has been an issue loading this page.',
    supportingMessage = 'Please try again later.',
    redirectText,
}) => {
    return (
        <Row align="middle" className="error-container">
            <Col span={24}>
                <Row justify="center">
                    <img
                        style={{ height: '65px' }}
                        src={withAppRoot('src/assets/img/error-no-page-found.svg')}
                    />
                </Row>
                <Row justify="center" className="error-message mt-30">
                    <h2 data-test-id="server-error">
                        <span>{errorMessage}</span>
                    </h2>
                </Row>
                <Row justify="center" className="error-apology">
                    <span>
                        {supportingMessage}
                        &nbsp;<a href={withAppRoot('')}>{redirectText}</a>
                    </span>
                </Row>
            </Col>
        </Row>
    );
};
