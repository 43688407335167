import { ArrowLeftOutlined, MenuOutlined, ShoppingFilled, UserOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Badge } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { DesktopHeaderContent } from '../header-content/desktop/desktop-header-content';
import { Login } from '../sidenav/Login/login';
import { ImageWithFallback } from '../ui-components/image-with-fallback';
import { useMobileScreen, useResizeObserver } from '../utils/general';
import useSegment from '../utils/segment';
import { HeaderProps } from './header';
import { HeaderPopupModal } from './header-popup-modal';

export type templateNames =
    | 'default'
    | 'parentMenuPage'
    | 'childMenuPage'
    | 'checkOutPage'
    | 'mobileCheckOutPage'
    | 'noAction'
    | 'accountPage';

type componentNames =
    | 'cartIcon'
    | 'hamburgerIcon'
    | 'logo'
    | 'backButton'
    | 'title'
    | 'deliveryContent'
    | 'accountIcon';

type headerTemplatesType = {
    [key in templateNames]: {
        className: string;
        componentsListInOrder: componentNames[];
    };
};

type availableHeaderComponentsType = {
    [key in componentNames]: (...args: any[]) => JSX.Element | null;
};

const pagesHavemobileDeliveryContent: templateNames[] = ['childMenuPage'];

const headerTemplates: headerTemplatesType = {
    'default': {
        className: 'address-page-header-template',
        componentsListInOrder: ['hamburgerIcon', 'logo'],
    },
    parentMenuPage: {
        className: 'parent-menu-page-header-template',
        componentsListInOrder: ['hamburgerIcon', 'logo', 'deliveryContent'],
    },
    childMenuPage: {
        className: 'child-menu-page-header-template',
        componentsListInOrder: ['logo', 'deliveryContent', 'cartIcon', 'hamburgerIcon'],
    },
    checkOutPage: {
        className: 'checkout-page-header-template',
        componentsListInOrder: ['backButton', 'logo'],
    },
    mobileCheckOutPage: {
        className: 'checkout-page-header-template',
        componentsListInOrder: ['backButton', 'logo'],
    },
    noAction: {
        className: 'no-action-header-template',
        componentsListInOrder: ['logo'],
    },
    accountPage: {
        className: 'child-menu-page-header-template',
        componentsListInOrder: ['hamburgerIcon', 'logo', 'accountIcon', 'cartIcon'],
    },
};

const Skimmer = ({ top }: { top: number }) => (
    <div
        style={{
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            backgroundColor: 'rgba(0,0,0,0.3)',
            top,
        }}
    ></div>
);

export const useHeader = (props: Partial<HeaderProps>) => {
    const { logoLink, title, backButton, getHeaderHeight } = props;
    const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);
    const [isSideNavOpen, setIsSideNavOpen] = useState(false);
    const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
    const cdpLocation = useContext(CdpLocationContext);
    const segment = useSegment();
    const { cartItems, template } = useContext(AppContext);
    const { observe, height } = useResizeObserver();
    const history = useHistory();
    const ldFlags = useFlags();
    const { isAuthenticated } = useAuth0();
    const isMobile = useMobileScreen();

    const primaryColor = template?.general?.primary_color;

    useLayoutEffect(() => {
        if (getHeaderHeight) {
            getHeaderHeight(height);
        }
    }, [height, getHeaderHeight]);

    const toggleCartDrawer = () => {
        setIsCartDrawerOpen(prevState => !prevState);
        segment.cartClicked();
        segment.cartViewed(cdpLocation);
    };

    const closeCartDrawer = (e: any) => {
        setIsCartDrawerOpen(false);
        segment.cartClosed(
            e?.reason ||
                (e?.type === 'keydown'
                    ? 'Escape'
                    : (e.target as HTMLElement).classList[0] === 'ant-drawer-mask'
                    ? 'Click Outside'
                    : 'X'),
        );
    };

    const handleHamburgerClick = () => {
        setIsSideNavOpen(true);
        segment.hamburgerMenuLinkClicked();
    };

    const handleAccountClick = () => {
        segment.accountWidgetCTAClicked();
        if (isAuthenticated) {
            return history.push('/account/profile');
        }
        return setIsAccountDropdownOpen(prevState => !prevState);
    };

    const availableHeaderComponents: availableHeaderComponentsType = {
        cartIcon: key => {
            return (
                <span onClick={toggleCartDrawer} className="cart-icon cursor-pointer" key={key}>
                    <Badge count={cartItems.reduce((s, a) => s + a.quantity, 0)}>
                        <ShoppingFilled
                            style={{ fontSize: 30, color: primaryColor }}
                            data-testid="cart-icon"
                        />
                    </Badge>
                </span>
            );
        },
        hamburgerIcon: key => {
            return (
                <a key={key} className="menu-icon hamburger-icon" onClick={handleHamburgerClick}>
                    <MenuOutlined
                        style={{ fontSize: 30, color: primaryColor }}
                        data-testid="menu-icon"
                    />
                </a>
            );
        },
        backButton: key => {
            return (
                <div key={key} onClick={backButton?.handleBack} className="back-btn">
                    <ArrowLeftOutlined style={{ fontSize: 32 }} />
                    <span className="back-btn-text">{backButton?.text}</span>
                </div>
            );
        },
        logo: key => {
            return (
                <div key={key} className="header-logo">
                    <ImageWithFallback
                        alt="brand-logo"
                        className={
                            template?.general?.logo.website_logo?.includes('.svg')
                                ? 'svg-logo'
                                : 'png-logo'
                        }
                        src={template?.general?.logo.website_logo}
                        onClick={
                            // eslint-disable-next-line no-undefined
                            logoLink ? () => history.push(logoLink) : undefined
                        }
                        style={logoLink ? { cursor: 'pointer' } : {}}
                    />
                </div>
            );
        },
        title: key => {
            return (
                <h2 key={key} className="title" style={{ marginBottom: '0' }}>
                    {title}
                </h2>
            );
        },
        deliveryContent: key => {
            return <DesktopHeaderContent headerHeight={height} key={key} />;
        },
        accountIcon: key => {
            return ldFlags.enableAccounts ? (
                <React.Fragment key={key}>
                    <div className="account-icon cursor-pointer" onClick={handleAccountClick}>
                        <UserOutlined style={{ fontSize: 30 }} />
                    </div>

                    {isAccountDropdownOpen && <Skimmer top={height} />}
                    {isAccountDropdownOpen && (
                        <HeaderPopupModal
                            style={{ top: height, right: 80 }}
                            onClose={() => setIsAccountDropdownOpen(false)}
                        >
                            <Login
                                showInColumn={isMobile ? false : true}
                                signupTouchPoint="Menu Account Widget"
                            />
                        </HeaderPopupModal>
                    )}
                </React.Fragment>
            ) : null;
        },
    };

    const getHeaderComponents = (templateName: templateNames) => {
        const template = headerTemplates[templateName];
        // TODO: Remove this check when remove feature flag and use template.className instead
        const headerClassName =
            !ldFlags.enableAccounts && templateName === 'childMenuPage'
                ? 'old-menu-header'
                : template.className;

        if (template) {
            return {
                headerClassName,
                componentsListInOrder: template.componentsListInOrder.map((componentName, cIndex) =>
                    availableHeaderComponents[componentName](cIndex),
                ),
                showMobileDeliveryContent: pagesHavemobileDeliveryContent.includes(templateName),
            };
        }
        throw new Error(`Template ${templateName} not found in useHeader.tsx line: 177`);
    };

    return {
        toggleCartDrawer,
        closeCartDrawer,
        handleHamburgerClick,
        isCartDrawerOpen,
        isSideNavOpen,
        setIsSideNavOpen,
        height,
        observe,
        getHeaderComponents,
        isAccountDropdownOpen,
    };
};
