import { Button, Col, Form, Input, Row } from 'antd';
import React, { FC, useContext } from 'react';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { AppContext } from '../../contexts/app-context';
import { TemplateButton } from '../ui-components/template-button';
import { useMobileScreen } from '../utils/general';
import { formatPhoneNumber } from '../utils/phone';

interface ContactFormProps {
    contactFormAlternateView: boolean;
    contactFormRef: any;
    onFinish: (values: any) => void;
    onCancel: () => void;
    form: any;
}

export const ContactForm: FC<ContactFormProps> = ({
    form,
    onCancel,
    onFinish,
    contactFormRef,
    contactFormAlternateView,
}) => {
    const { customerInfo } = useContext(AppContext);
    const isMobile = useMobileScreen();

    // limit phone number to max 10 digits and remove all nonnumeric values
    const handlePhoneChange = () => {
        const phoneValue = form.getFieldValue('ci_phonenumber');
        const formattedValue = formatPhoneNumber(phoneValue);
        form.setFieldsValue({ ci_phonenumber: formattedValue });
        form.validateFields(['ci_phonenumber']);
    };

    const validateName = (rules: any, value: string) => {
        if (value) {
            if (value.replace(/[0-9\s!@#$%^&*()\-_=+,<.>/?;:'"[{\]}\\|]/gi, '').length > 2) {
                return Promise.resolve();
            }
            return Promise.reject('Enter a valid name');
        }
        return Promise.reject('This field is required');
    };

    const validatePhone = (rules: any, value: string) => {
        if (value) {
            if (isMobilePhone(value, 'en-US')) {
                return Promise.resolve();
            }
            return Promise.reject('Enter a valid phone number');
        }
        return Promise.reject('This field is required');
    };

    const isFormErrors = () => {
        const hasErrors = form.getFieldsError().some((field: any) => field.errors.length > 0);
        const hasEmptyFields = Object.values(form.getFieldsValue(true)).some(el => el === '');
        return hasErrors || hasEmptyFields;
    };

    const ActionButtonGroup = () => (
        <Col span={24} className="customer-info-action-buttons px-5">
            <Form.Item shouldUpdate>
                {() => (
                    <div className="action-row">
                        <Button type="default" onClick={onCancel} size="large" className={`mr-10`}>
                            Cancel
                        </Button>
                        <TemplateButton
                            type="primary"
                            htmlType="submit"
                            data-testid="contact-information-submit-btn"
                            size="large"
                            disabled={isFormErrors()}
                        >
                            Update
                        </TemplateButton>
                        <button
                            onClick={onFinish}
                            style={{ display: 'none' }}
                            disabled={isFormErrors()}
                        />
                    </div>
                )}
            </Form.Item>
        </Col>
    );

    return (
        <Form
            className="checkout-contact-form"
            name="checkout_contact_information"
            form={form}
            ref={contactFormRef}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
        >
            <Row
                className="contact-form-wrapper"
                gutter={12}
                style={{ paddingTop: `${!contactFormAlternateView && isMobile ? '' : '10px'}` }}
            >
                <Col xs={24} md={24} lg={14} xl={16}>
                    <Form.Item
                        label="Full name"
                        name="ci_name"
                        rules={[
                            {
                                validator: validateName,
                            },
                        ]}
                        initialValue={customerInfo?.name}
                        hasFeedback
                    >
                        <Input size="large" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={10} xl={8}>
                    <Form.Item
                        label="Phone number"
                        name="ci_phonenumber"
                        rules={[
                            {
                                validator: validatePhone,
                            },
                        ]}
                        initialValue={formatPhoneNumber(customerInfo?.phonenumber)}
                        validateTrigger={['onChange', 'onBlur']}
                        hasFeedback
                    >
                        <Input
                            max={9999999999}
                            maxLength={14}
                            size="large"
                            onChange={handlePhoneChange}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                    <Form.Item
                        label="Email address"
                        name="ci_email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Enter a valid email address',
                            },
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                        initialValue={customerInfo?.email}
                        hasFeedback
                    >
                        <Input type="email" size="large" />
                    </Form.Item>
                </Col>
            </Row>
            {!contactFormAlternateView && <ActionButtonGroup />}
        </Form>
    );
};
