import { Col, Space, Typography } from 'antd';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { TemplateButton } from '../../ui-components/template-button';
import { useMobileScreen } from '../../utils/general';
import useSegment from '../../utils/segment';

const { Title } = Typography;

export const NoOrderHistory: FC = () => {
    const history = useHistory();
    const segment = useSegment();

    const handleStartOrderClick = () => {
        history.push('/');
        segment.startAnOrderCTAClicked();
    };

    return (
        <Col className="order-tab-content" data-testid="no-orders-screen" span={24}>
            <Space size={12} align="center" direction="vertical">
                <Title level={useMobileScreen() ? 4 : 3}>You don't have any past orders</Title>
                <TemplateButton
                    onClick={handleStartOrderClick}
                    data-testid="start-an-order-btn"
                    size="large"
                >
                    Start an order
                </TemplateButton>
            </Space>
        </Col>
    );
};
