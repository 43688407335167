import bigmelt from './bigmelt';
import craveburger from './craveburger';
import dinnerbell from './dinnerbell';
import grilledcheesesociety from './grilledcheesesociety';
import hotbox from './hotbox';
import missmazys from './missmazys';
import monstermac from './monstermac';
import outlawburger from './outlawburger';
import tacos from './tacos';
import { AppBrand, AppBrandList } from './types';
import wildwings from './wildwings';
import wingdynasty from './wingdynasty';

const { APP_BRAND = 'dinnerbell' } = process.env;

const allBrands: AppBrandList = {
    bigmelt,
    craveburger,
    dinnerbell,
    grilledcheesesociety,
    hotbox,
    missmazys,
    monstermac,
    outlawburger,
    tacos,
    wildwings,
    wingdynasty,
};

let currentBrand: AppBrand = tacos;

if (allBrands[APP_BRAND]) {
    currentBrand = allBrands[APP_BRAND];
}

export const APP_SLUG = APP_BRAND;

export default currentBrand;
