import React from 'react';

function MinusPlusCircled({
    background = '#F0F0F0',
    signColor = 'black',
    onClick,
    className = '',
    sign,
}: {
    background?: string;
    signColor?: string;
    onClick: () => void;
    className?: string;
    sign: 'minus' | 'plus';
}) {
    return (
        <>
            {sign === 'minus' && (
                <svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                    onClick={onClick}
                >
                    <rect x="0.5" y="0.910156" width="28" height="28" rx="14" fill={background} />
                    <rect
                        x="20.125"
                        y="13.5039"
                        width="2.8125"
                        height="11.25"
                        rx="1.40625"
                        transform="rotate(90 20.125 13.5039)"
                        fill={signColor}
                    />
                </svg>
            )}
            {sign === 'plus' && (
                <svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    onClick={onClick}
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect x="0.5" y="0.910156" width="28" height="28" rx="14" fill={background} />
                    <rect
                        x="13.0938"
                        y="9.28516"
                        width="2.8125"
                        height="11.25"
                        rx="1.40625"
                        fill={signColor}
                    />
                    <rect
                        x="20.125"
                        y="13.5039"
                        width="2.8125"
                        height="11.25"
                        rx="1.40625"
                        transform="rotate(90 20.125 13.5039)"
                        fill={signColor}
                    />
                </svg>
            )}
        </>
    );
}

export default MinusPlusCircled;
