import { PaymentRequestTokenEvent, TokenResult } from '@stripe/stripe-js';
import md5 from 'md5';
import moment from 'moment-timezone';
import { useContext } from 'react';
import {
    AppContext,
    BrandTemplate,
    IUserProfile,
    StateIdsProps,
    UserProfileAddress,
} from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { getCurrentPage } from '../../router';
import { IUnavailableLocation } from '../address/no-concept-matched-popover';
import { TestToken } from '../checkout/stripe-integration-form';
import {
    CartItemInterface,
    MenuItemInterface,
    MenuManagerInterface,
    MenuSectionInterface,
    ModifierGroupsItemInterface,
    findEntity,
    matchNestedRule,
} from '../menu/model';
import { Location, LocationMatchResponse, OrderTimingType } from '../restaurant/model';
import { placesToAddress } from './address';
import { calculateItemTaxes } from './cart';
import {
    getCartTotals,
    getPlatform,
    getTimeStamp,
    getUTMQueries,
    getUTMQueriesString,
} from './general';

const gaEventameMap: any = {
    'Product Added': 'add_to_cart',
    'Checkout Started': 'begin_checkout',
    'Order Completed Client Side': 'purchase',
    'Product Removed': 'remove_from_cart',
    'Cart Viewed': 'view_cart',
    'Product Viewed': 'view_item',
};

const gaPropMap: any = {
    product_id: 'item_id',
    name: 'item_name',
    menu_section_name: 'item_category',
};

function convertGAProps(payload: any) {
    const res: any = {};
    for (let [key, value] of Object.entries(payload)) {
        if (key in gaPropMap) {
            key = gaPropMap[key];
        }
        if (value && typeof value === 'object') {
            value = convertGAProps(value);
        }
        res[key] = value;
    }
    return res;
}

const platform = getPlatform();

function formatCurrency(cents: number): number {
    return parseFloat((Math.round(cents || 0) / 100).toFixed(2));
}

function sumCartItems(cartItems: CartItemInterface[]): number {
    return cartItems.reduce((memo, cartItem) => {
        return memo + cartItem.quantity;
    }, 0);
}

function getFromLocalStorage(itemName: string) {
    const item = localStorage.getItem(itemName);
    return item ? JSON.parse(item) : null;
}

function trackWrapper(event: string, payload: any) {
    setTimeout(() => {
        const stateIds = getFromLocalStorage('stateIds');

        if (typeof window.gtag === 'function' && event in gaEventameMap) {
            try {
                const gaEvent = gaEventameMap[event];
                const gaPayload = convertGAProps(payload);
                window.gtag('event', gaEvent, gaPayload);
            } catch (err) {
                // Intentionally blank; ignore GA errors
            }
        }

        window.analytics.track(event, payload, {
            integrations: {
                Amplitude: {
                    session_id: stateIds?.sessionId,
                },
            },
        });
    }, 0);
}

function identifyWrapper(userEmail: string | undefined, a: any) {
    setTimeout(() => {
        const stateIds = getFromLocalStorage('stateIds');
        const integrations = {
            integrations: {
                Amplitude: {
                    session_id: stateIds?.sessionId,
                },
            },
        };
        const formatIdentiferString = (email: string) => email.toLowerCase().trim();
        const params = userEmail
            ? [formatIdentiferString(userEmail), a, integrations]
            : [a, integrations];

        window.analytics.identify(...params);
    }, 0);
}

function addUserTypeToTraits(userProfile: IUserProfile | null, traits: any) {
    const userType = {
        user_type: userProfile?.user_type,
    };
    return userProfile?.user_id ? { ...traits, ...userType } : traits;
}

function distance(lat1: number, lon1: number, lat2: number, lon2: number, unit: string) {
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    } else {
        const radlat1 = (Math.PI * lat1) / 180;
        const radlat2 = (Math.PI * lat2) / 180;
        const theta = lon1 - lon2;
        const radtheta = (Math.PI * theta) / 180;
        let dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === 'K') {
            dist = dist * 1.609344;
        }
        if (unit === 'N') {
            dist = dist * 0.8684;
        }
        return dist;
    }
}

// address segments
export function pageViewed(
    template: BrandTemplate | null,
    restaurantInfo: LocationMatchResponse | null,
) {
    const currentPage = getCurrentPage() || 'No Page Found';
    const delivery = restaurantInfo?.delivery;
    setTimeout(() => {
        // example: https://www.huevoritobreakfast.com/orders/menu?utm_source=lob&utm_medium=postcard&utm_campaign=20230113_Trigger%20|%20Compression%201x-2x&utm_content=qrcode
        const stateIds = getFromLocalStorage('stateIds');
        const UTMstring = getUTMQueriesString();
        const location = window.location.href;
        const search = '?' + UTMstring;
        const url = location + search;
        const UTMparams = getUTMQueries();
        const existingparams: any = {};
        for (const [key, value] of Object.entries(UTMparams)) {
            if (value != null) {
                existingparams[key] = value;
            }
        }
        const payload = {
            brand_id: template?.metadata.brand.id,
            brand_name: template?.metadata.brand.name,
            app_id: delivery?.app_id,
            location_id: delivery?.id,
            concept_name: delivery?.name,
            name: currentPage,
            platform,
            url,
            search,
        };

        const integration = {
            integrations: {
                Amplitude: {
                    session_id: stateIds?.sessionId,
                },
            },
            context: {
                campaign: existingparams,
            },
        };

        window.analytics.page(currentPage, payload, integration);
    }, 0);
}

export function addressEntered(template: BrandTemplate | null, orderAddress: any, via: string) {
    const addressParts = placesToAddress(orderAddress);
    trackWrapper('Address Entered', {
        address: {
            street: addressParts.address1,
            street2: addressParts.address2,
            city: addressParts.city,
            state: addressParts.region,
            postalCode: addressParts.postalcode,
        },
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        platform,
        postalCode: addressParts.postalcode,
        state: addressParts.region,
        via: via || 'Address Entry',
    });
}

export function IdentifyAddress(
    template: BrandTemplate | null,
    userProfile: IUserProfile | null,
    orderAddress: any,
    orderAddressApt: string,
) {
    const addressParts = placesToAddress(orderAddress, orderAddressApt);
    const identifierProperties = {
        address: {
            street: addressParts.address1,
            street2: addressParts.address2,
            city: addressParts.city,
            state: addressParts.region,
            postalCode: addressParts.postalcode,
        },
        platform,
        postalCode: addressParts.postalcode,
        state: addressParts.region,
        brands_viewed: [template?.metadata.brand.name],
    };
    const traits = addUserTypeToTraits(userProfile, identifierProperties);
    identifyWrapper(userProfile?.email, traits);
}

export function IdentifyEmail(
    template: BrandTemplate | null,
    userProfile: IUserProfile | null,
    email: string,
) {
    const identifierProperties = {
        email,
        brands_viewed: [template?.metadata.brand.name],
    };
    const traits = addUserTypeToTraits(userProfile, identifierProperties);
    identifyWrapper(userProfile?.email || email, traits);
}

export function IdentifySignUp(userProfile: IUserProfile) {
    const u = userProfile;
    identifyWrapper(u.email, {
        createdAt: u.date_created,
        email: u.email,
        last_login: u.last_login_date,
        linked_socials: u.linked_socials,
        user_type: u.user_type,
    });
}

export function IdentifyLogIn(userProfile: IUserProfile) {
    const u = userProfile;
    identifyWrapper(u.email, {
        last_login: u.last_login_date,
        linked_socials: u.linked_socials,
        secondary_email: null,
        user_type: u.user_type,
    });
}

export function startOrderCTA(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        cta_name: 'Start Order',
        cta_destination: 'Menu',
        serviceable: true,
        via: 'Address Entry Page',
    });
}

export function startAnOrderCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        cta_action: 'Open Menu',
        cta_destination: 'Menu',
        cta_name: 'Start an Order',
        location_id: delivery?.id,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        serviceable: true,
        via: 'Order History',
    });
}

export function seeTheMenuCTAClicked(
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    trackWrapper('CTA Clicked', {
        app_id: null,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: null,
        cta_action: 'See the menu',
        cta_destination: 'Menu',
        cta_name: 'See the menu',
        location_id: null,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        via,
    });
}

export function trackMyOrderCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        cta_action: 'Open Logistics Page',
        cta_destination: 'Doordash',
        cta_name: 'Track My Order',
        location_id: delivery?.id || null,
        platform,
        via: 'Order History',
    });
}

export function submitEmailCTA(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    serviceable: boolean,
    via?: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cta_name: 'Submit Email',
        cta_destination: 'Email Submission Confirmation',
        platform,
        serviceable,
        via: via || 'Address Entry Page',
    });
}

export function conceptSet(
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    restaurantInfo: LocationMatchResponse | null,
    orderAddress: any,
    via?: string,
) {
    const addressParts = placesToAddress(orderAddress);
    const delivery = restaurantInfo?.delivery;
    const quotedDelivery = getTimeStamp(restaurantInfo?.delivery?.quoted_delivery);
    const quotedPickup = getTimeStamp(restaurantInfo?.delivery?.quoted_pickup);
    let deliveryMinutes = 0;
    if (quotedDelivery && quotedPickup) {
        deliveryMinutes = (quotedDelivery - quotedPickup) / 1000 / 60;
    }

    trackWrapper('Concept Set', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        brand_enterprise: delivery?.brand?.brand_enterprise?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        prep_time: delivery?.prep_time,
        concept_address: {
            street: delivery?.address?.address1,
            street2: delivery?.address?.address2 || '',
            city: delivery?.address?.city,
            state: delivery?.address?.state,
            postalCode: delivery?.address?.zipcode,
        },
        concept_state: delivery?.address?.state,
        concept_postalCode: delivery?.address?.zipcode,
        user_state: addressParts.region,
        user_postalCode: addressParts.postalcode,
        concept_automatic_order_confirmation:
            restaurantInfo?.delivery?.provider_configs?.[0]?.automatic_confirmation || null,
        concept_distance: parseFloat(
            distance(
                Number(orderAddress.geometry.location.lat()),
                Number(orderAddress.geometry.location.lng()),
                Number(delivery?.location_lat),
                Number(delivery?.location_lon),
                'N',
            ).toFixed(2),
        ),
        concept_order_min: formatCurrency(delivery?.logistics_configs[0]?.min_total || 0),
        concept_order_max: formatCurrency(delivery?.logistics_configs[0]?.max_total || 0),
        concept_service_methods: delivery?.service_methods,
        estimated_delivery_length: deliveryMinutes,
        delivery_radius: delivery?.logistics_configs[0]?.radius,
        via: via || 'Address Entry Page',
    });
}

export function emailCapturedAddressEntry(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    email: string,
    serviceable: boolean,
    via?: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Email Captured', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        email,
        serviceable,
        via: via || 'Address Entry Page',
    });
}

export function emailCapturedCheckout(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    email: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Email Captured', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        email,
        via: 'Checkout',
    });
}

export function linkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    via: string,
    url: string,
    destination: string,
    linkCategory: string = 'Social',
    serviceable: boolean | null = null,
    linkAction: string = 'Open new window',
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        link_category: linkCategory,
        link_url: url,
        link_destination: destination,
        link_action: linkAction,
        via,
        page: currentPage,
        platform,
        serviceable,
    });
}

export function closeDetailsExpandedView(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    action?: string,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id || null,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'Hide Order History Details',
        link_category: action || 'Close',
        link_destination: null,
        location_id: delivery?.id || null,
        platform,
        via: currentPage,
    });
}

export function showOrderDetailsCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id || null,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name || null,
        cta_action: 'Show Order History Details',
        cta_destination: 'Order History Expanded Page',
        cta_name: 'Show Details',
        location_id: delivery?.id || null,
        platform,
        via: currentPage,
    });
}

export function defaultAddressUnserviceableClicked(
    template: BrandTemplate | null,
    restaurantInfo: IUnavailableLocation | null,
    action: string,
) {
    trackWrapper('CTA Clicked', {
        app_id: restaurantInfo?.appId || null,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: restaurantInfo?.name || null,
        cta_action: getCurrentPage(),
        cta_destination: getCurrentPage(),
        cta_name: action,
        location_id: restaurantInfo?.locationId || null,
        via: 'Address Unserviceable Modal',
    });
}

export function closeAddressUnserviceableModal(
    template: BrandTemplate | null,
    restaurantInfo: IUnavailableLocation | null,
    via: string,
) {
    trackWrapper('Link Clicked', {
        app_id: restaurantInfo?.appId || null,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: restaurantInfo?.name || null,
        link_action: 'Close Address Unserviceable Modal',
        link_category: 'Close',
        link_destination: 'Menu',
        location_id: restaurantInfo?.locationId || null,
        via,
    });
}

export function messageShownRoadblock(template: BrandTemplate | null, via: string) {
    trackWrapper('Message Shown', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        message_category: 'Roadblock',
        message_name: 'Address Required',
        via,
    });
}

// menu segments

export function menuShown(
    restaurantInfo: LocationMatchResponse | null,
    menuInfo: MenuManagerInterface | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    deliveryEstimate: { from: number; to: number } | null,
    menuType?: string,
) {
    const delivery = restaurantInfo?.delivery;
    let numMenuItems = 0;
    if (menuInfo?.app_id) {
        for (const sectionId of menuInfo.menus[0].section_ids) {
            const section = findEntity(menuInfo, 'section', sectionId);
            for (const itemId of section.item_ids) {
                const item = findEntity(menuInfo, 'item', itemId);
                if (!item.is_paused) {
                    numMenuItems += 1;
                }
            }
        }
    }

    trackWrapper('Menu Shown', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id || null,
        location_id: delivery?.id || null,
        concept_name: delivery?.name || null,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        delivery_estimate_max: deliveryEstimate?.to || null,
        delivery_estimate_min: deliveryEstimate?.from || null,
        num_menu_sections: menuInfo?.sections.length,
        num_menu_items: numMenuItems,
        menu_type: menuType || 'Child',
    });
}

export function cartClicked(
    restaurantInfo: LocationMatchResponse | null,
    cartItems: CartItemInterface[],
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Cart Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        num_cart_items: sumCartItems(cartItems),
    });
}

export function menuSectionClicked(
    restaurantInfo: LocationMatchResponse | null,
    menuInfo: MenuManagerInterface | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    selectedSection: MenuSectionInterface,
    menuSectionPosition: number,
    numVisibleSections: number,
    menuType?: string,
) {
    let numItems = null;
    if (menuInfo) {
        numItems = selectedSection.item_ids.reduce((sum, itemId) => {
            const item = findEntity(menuInfo, 'item', itemId);
            return sum + (item.is_paused ? 0 : 1);
        }, 0);
    }
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Menu Section Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id || null,
        location_id: delivery?.id || null,
        concept_name: delivery?.name || null,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        menu_section_id: selectedSection.id,
        menu_section_name: selectedSection.title,
        menu_type: menuType || 'Child',
        num_menu_sections: numVisibleSections,
        menu_section_position: menuSectionPosition + 1,
        num_items_in_section: numItems,
    });
}

export function productClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    selectedSection: MenuSectionInterface,
    selectedItem: MenuItemInterface,
    price: number | null,
    menuType?: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Product Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id || null,
        location_id: delivery?.id || null,
        concept_name: delivery?.name || null,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        product_id: selectedItem.title,
        internal_product_id: selectedItem.id,
        sku: selectedItem.id,
        name: selectedItem.title,
        description: selectedItem.description,
        menu_section_id: selectedSection.id,
        menu_section_name: selectedSection.title,
        menu_type: menuType || 'Child',
        num_items_in_section: selectedSection.item_ids.length,
        position: Number(selectedSection.item_ids.indexOf(selectedItem.id)) + 1,
        base_price: formatCurrency(selectedItem.price_rules[0]?.price),
        price: price ? formatCurrency(price) : price,
        image_url: selectedItem.images[0]?.url,
    });
}

// Need to add when update item.
export function productViewed(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    selectedSection: MenuSectionInterface,
    selectedItem: MenuItemInterface,
    via: string,
    price: number | null,
    menuType?: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Product Viewed', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id || null,
        location_id: delivery?.id || null,
        concept_name: delivery?.name || null,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        product_id: selectedItem.title,
        internal_product_id: selectedItem.id,
        sku: selectedItem.id,
        name: selectedItem.title,
        description: selectedItem.description,
        menu_section_id: selectedSection.id,
        menu_section_name: selectedSection.title,
        menu_type: menuType || 'Child',
        num_items_in_section: selectedSection.item_ids.length,
        position: Number(selectedSection.item_ids.indexOf(selectedItem.id)) + 1,
        num_modifier_groups: selectedItem.modifier_group_ids.length,
        base_price: formatCurrency(selectedItem.price_rules[0]?.price),
        price: price ? formatCurrency(price) : price,
        image_url: selectedItem.images[0]?.url,
        via,
    });
}

export function productDismissed(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    selectedSection: MenuSectionInterface,
    selectedItem: MenuItemInterface,
    quantity: number,
    price: number | null,
    via: string,
    menuType?: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Product Dismissed', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        product_id: selectedItem.title,
        internal_product_id: selectedItem.id,
        sku: selectedItem.id,
        name: selectedItem.title,
        description: selectedItem.description,
        menu_section_id: selectedSection.id,
        menu_section_name: selectedSection.title,
        menu_type: menuType || 'Child',
        num_items_in_section: selectedSection.item_ids.length,
        position: Number(selectedSection.item_ids.indexOf(selectedItem.id)) + 1,
        num_modifier_groups: selectedItem.modifier_group_ids.length,
        base_price: formatCurrency(selectedItem.price_rules[0]?.price),
        price: price ? formatCurrency(price) : price,
        quantity,
        value: price ? formatCurrency(quantity * price) : price,
        image_url: selectedItem?.images[0]?.url,
        via,
    });
}

export function productQuantityIncreased(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
    selectedSection: MenuSectionInterface,
    selectedItem: MenuItemInterface,
    quantityPrevious: number,
    quantity: number,
    basePrice: number,
    itemPrice: number,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Product Quantity Increased', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        product_id: selectedItem.title,
        internal_product_id: selectedItem.id,
        sku: selectedItem.id,
        name: selectedItem.title,
        description: selectedItem.description,
        menu_section_id: selectedSection.id,
        menu_section_name: selectedSection.title,
        num_items_in_section: selectedSection.item_ids.length,
        position: Number(selectedSection.item_ids.indexOf(selectedItem.id)) + 1,
        num_modifier_groups: selectedItem.modifier_group_ids.length,
        base_price: formatCurrency(basePrice),
        price: formatCurrency(itemPrice),
        value: formatCurrency(quantity * itemPrice),
        quantity_previous: quantityPrevious,
        quantity,
        image_url: selectedItem?.images[0]?.url,
        via,
    });
}

export function productQuantityDecreased(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
    selectedSection: MenuSectionInterface,
    selectedItem: MenuItemInterface,
    quantityPrevious: number,
    quantity: number,
    basePrice: number,
    itemPrice: number,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Product Quantity Decreased', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        product_id: selectedItem.title,
        internal_product_id: selectedItem.id,
        sku: selectedItem.id,
        name: selectedItem.title,
        description: selectedItem.description,
        menu_section_id: selectedSection.id,
        menu_section_name: selectedSection.title,
        num_items_in_section: selectedSection.item_ids.length,
        position: Number(selectedSection.item_ids.indexOf(selectedItem.id)) + 1,
        num_modifier_groups: selectedItem.modifier_group_ids.length,
        base_price: formatCurrency(basePrice),
        price: formatCurrency(itemPrice),
        value: formatCurrency(quantity * itemPrice),
        quantity_previous: quantityPrevious,
        quantity,
        image_url: selectedItem?.images[0]?.url,
        via,
    });
}

export function cartItemQuantityChanged(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    menuInfo: MenuManagerInterface,
    cartItem: CartItemInterface,
    amount: number,
    via: string,
) {
    /* eslint-disable @typescript-eslint/naming-convention */
    const { base_price, idHierarchy, item_price, quantity } = cartItem;
    const newQuantity = quantity + amount;
    let segmentFunction: (...args: any[]) => void = () => null;

    if (amount > 0) {
        segmentFunction = productQuantityIncreased;
    } else if (amount < 0) {
        segmentFunction = productQuantityDecreased;
    }

    segmentFunction(
        restaurantInfo,
        template,
        orderTiming,
        via,
        findEntity(menuInfo, 'section', idHierarchy[1]),
        findEntity(menuInfo, 'item', idHierarchy[2]),
        quantity,
        newQuantity,
        base_price as number,
        item_price,
    );
    /* eslint-enable @typescript-eslint/naming-convention */
}

interface ModMetadata {
    modifier_group_id: string;
    modifier_group_name: string;
    modifier_id: string;
    modifier_name: string;
    modifier_price: number;
}

function flattenMods(
    modifiers: ModifierGroupsItemInterface[],
    idHierarchy: string[],
): ModMetadata[] {
    const res = [];
    for (const mod of modifiers) {
        for (const item of mod.items) {
            const localHierarchy = [...idHierarchy, mod.modifier_group_id, item.id];
            const priceRule = matchNestedRule(item.price_rules, localHierarchy);
            const price = priceRule ? formatCurrency(priceRule.price) : 0;

            res.push({
                modifier_group_id: mod.modifier_group_id,
                modifier_group_name: mod.name,
                modifier_id: item.id,
                modifier_name: item.title,
                modifier_price: price,
            });
        }
    }
    return res;
}

export function productAdded(
    restaurantInfo: LocationMatchResponse | null,
    menuInfo: MenuManagerInterface | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    idHierarchy: string[],
    selectedItem: MenuItemInterface,
    quantity: number,
    itemPrice: number,
    modifiers: ModifierGroupsItemInterface[],
    specialInstructions: string,
    via: string,
) {
    const selectedSection = menuInfo && findEntity(menuInfo, 'section', idHierarchy[1]);
    const delivery = restaurantInfo?.delivery;
    const modifierData = flattenMods(modifiers, idHierarchy);
    const stateIds = getFromLocalStorage('stateIds');

    trackWrapper('Product Added', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        cart_id: stateIds?.cartId,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        product_id: selectedItem.title,
        internal_product_id: selectedItem.id,
        sku: selectedItem.id,
        name: selectedItem.title,
        description: selectedItem.description,
        menu_section_id: selectedSection?.id,
        menu_section_name: selectedSection?.title,
        base_price: formatCurrency(selectedItem.price_rules[0]?.price),
        price: formatCurrency(itemPrice),
        value: formatCurrency(quantity * itemPrice),
        quantity,
        item_instructions: specialInstructions,
        num_modifier_groups: selectedItem.modifier_group_ids.length,
        modifiers: modifierData,
        image_url: selectedItem.images[0]?.url,
        via,
    });
}

export function addtoCartCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        cta_name: 'Add to Cart',
        cta_action: 'Add item to cart',
    });
}

export function updateItemCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        cta_name: 'Update Item',
        cta_action: 'Update cart item',
    });
}

function cartEventPayload(
    restaurantInfo: LocationMatchResponse | null,
    cartItems: CartItemInterface[],
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    const productsData = cartItems?.map((item: CartItemInterface) => {
        const hierarchy = [item.menu_id, item.section_id, item.item_id];
        const mods = flattenMods(item.modifier_groups, hierarchy);
        return {
            base_price: formatCurrency(item.base_price || 0),
            brand_id: template?.metadata.brand.id,
            brand_name: template?.metadata.brand.name,
            description: item.description,
            image_url: item?.images[0]?.url,
            item_instructions: item.special_instructions,
            menu_section_id: item.section_id,
            menu_section_name: item.section_name,
            modifier_ids: mods.map(el => el.modifier_id),
            modifier_names: mods.map(el => el.modifier_name),
            name: item.name,
            price: formatCurrency(item.item_price || 0),
            product_id: item.name,
            internal_product_id: item.item_id,
            quantity: item.quantity,
            sku: item.item_id,
            value: (item.item_price / 100) * item.quantity,
            platform,
        };
    });
    const numProducts = sumCartItems(cartItems);
    const menuInfo = getFromLocalStorage('menuInfo');
    const tax = calculateItemTaxes(menuInfo, cartItems);
    const subtotal = cartItems.reduce((s, a) => s + a.price, 0);
    const serviceFee = template?.general?.service_fee || 0;
    const deliveryFee = restaurantInfo?.delivery?.logistics_configs?.[0]?.delivery_fee || 0;
    const total = subtotal + serviceFee + deliveryFee + tax;
    return {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        cart_id: stateIds.cartId,
        concept_name: delivery?.name,
        delivery_fee: formatCurrency(deliveryFee),
        location_id: delivery?.id,
        num_products: numProducts,
        order_timing: orderTiming,
        platform,
        products: productsData,
        service_fee: formatCurrency(serviceFee),
        subtotal: formatCurrency(subtotal),
        tax: formatCurrency(tax),
        total: formatCurrency(total),
        via,
    };
}

export function cartViewed(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
    cartItems: CartItemInterface[],
    via: string,
) {
    const orderTiming = getFromLocalStorage('orderTiming');
    trackWrapper(
        'Cart Viewed',
        cartEventPayload(restaurantInfo, cartItems, template, stateIds, orderTiming, via),
    );
}

export function cartClosed(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
    cartItems: CartItemInterface[],
    via: string,
) {
    const orderTiming = getFromLocalStorage('orderTiming');
    trackWrapper(
        'Cart Closed',
        cartEventPayload(restaurantInfo, cartItems, template, stateIds, orderTiming, via),
    );
}

export function editAddressLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        link_category: 'Edit',
        link_action: 'Edit address',
        link_destination: 'Inline',
        via,
    });
}

export function editOrderTimingLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        concept_name: delivery?.name,
        location_id: delivery?.id,
        link_action: 'Edit order timing',
        link_category: 'Edit',
        link_destination: 'Edit order timing',
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        via,
    });
}

export function cancelOrderTimingLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        concept_name: delivery?.name,
        location_id: delivery?.id,
        link_action: 'Cancel order timing update',
        link_category: 'Cancel',
        link_destination: 'Self',
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        via,
    });
}

export function cancelLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        link_category: 'Cancel',
        link_action: 'Cancel update',
        link_destination: 'Inline',
        via,
    });
}

export function editItemLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        link_category: 'Edit',
        link_action: 'Edit Item',
        link_destination: 'Menu Item Modal',
        via,
    });
}

export function changeAddressLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        link_category: 'Edit',
        link_action: 'Change address',
        link_destination: 'Address Entry Page',
        via,
    });
}

export function hamburgerMenuLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    page: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'View Hamburger Nav Menu',
        link_category: 'Nav',
        link_destination: 'Hamburger Nav Menu',
        location_id: delivery?.id,
        platform,
        page,
    });
}

export function sharePageLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'Open Share Page',
        link_category: 'Hamburger Nav Menu',
        link_destination: 'Share',
        link_url: null,
        location_id: delivery?.id,
        platform,
        via: 'Hamburger Nav',
        page: currentPage,
    });
}

export function menuPageLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'View Menu',
        link_category: 'Hamburger Nav Menu',
        link_destination: 'Menu',
        link_url: null,
        location_id: delivery?.id,
        platform,
        via: 'Hamburger Nav',
        page: currentPage,
    });
}

export function aboutPageLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'Open About Page',
        link_category: 'Hamburger Nav Menu',
        link_destination: 'About',
        link_url: null,
        location_id: delivery?.id,
        platform,
        via: 'Hamburger Nav',
        page: currentPage,
    });
}

export function supportPageLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'Open Support Page',
        link_category: 'Hamburger Nav Menu',
        link_destination: 'Support',
        link_url: null,
        location_id: delivery?.id,
        platform,
        via: 'Hamburger Nav',
        page: currentPage,
    });
}

export function callSupportPageLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'Call Support',
        link_category: 'Hamburger Nav Menu',
        link_destination: 'Phone',
        location_id: delivery?.id,
        platform,
        via: 'Hamburger Nav',
        page: currentPage,
    });
}

export function textSupportPageLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'Text Support',
        link_category: 'Hamburger Nav Menu',
        link_destination: 'SMS',
        location_id: delivery?.id,
        platform,
        via: 'Hamburger Nav',
        page: currentPage,
    });
}

export function emailSupportPageLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'Email Support',
        link_category: 'Hamburger Nav Menu',
        link_destination: 'Email',
        location_id: delivery?.id,
        platform,
        via: 'Hamburger Nav',
        page: currentPage,
    });
}

export function faqSupportPageLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'Open FAQ',
        link_category: 'Hamburger Nav Menu',
        link_destination: 'FAQ',
        location_id: delivery?.id,
        platform,
        via: 'Hamburger Nav',
        page: currentPage,
    });
}

export function legalPageLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'View Legal Links',
        link_category: 'Hamburger Nav Menu',
        link_destination: 'Expand Section',
        location_id: delivery?.id,
        platform,
        via: 'Hamburger Nav',
        page: currentPage,
    });
}

export function termsAndConditionsLegalLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    url: string,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'View Terms and Conditions',
        link_category: 'Hamburger Nav Menu',
        link_destination: 'Terms and Conditions',
        link_url: url,
        location_id: delivery?.id,
        platform,
        via: 'Hamburger Nav',
        page: currentPage,
    });
}

export function privacyPolicyLegalLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    url: string,
) {
    const currentPage = getCurrentPage();
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'View Privacy Policy',
        link_category: 'Hamburger Nav Menu',
        link_destination: 'Privacy Policy',
        link_url: url,
        location_id: delivery?.id,
        platform,
        via: 'Hamburger Nav',
        page: currentPage,
    });
}

export function expandAndHideOrderSummary(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    action: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: `${action} Order Summary`,
        link_category: 'Order Summary',
        link_destination: null,
        link_url: null,
        location_id: delivery?.id,
        platform,
        via: 'Checkout',
    });
}

export function closeScreenLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'Close Screen',
        link_category: 'Close',
        link_destination: null,
        link_url: null,
        location_id: delivery?.id,
        platform,
        via,
    });
}

export function skipOnboardingLinkClicked(template: BrandTemplate | null) {
    trackWrapper('Link Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        link_action: 'Skip Flow',
        link_category: 'Skip',
        link_destination: 'Next',
        link_url: null,
        platform,
        via: 'Onboarding Flow',
    });
}

export function productRemoved(
    menuInfo: MenuManagerInterface | null,
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
    lineItem: CartItemInterface,
) {
    const orderTiming = getFromLocalStorage('orderTiming');
    const section = menuInfo && findEntity(menuInfo, 'section', lineItem.section_id);
    const item = menuInfo && findEntity(menuInfo, 'item', lineItem.item_id);
    const delivery = restaurantInfo?.delivery;
    const modifiers = lineItem.modifier_groups;
    const idHierarchy = [lineItem.menu_id, lineItem.section_id, lineItem.item_id];
    const modifiersData = flattenMods(modifiers, idHierarchy);

    trackWrapper('Product Removed', {
        app_id: delivery?.app_id,
        base_price: item ? formatCurrency(item.price_rules[0]?.price) : null,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        cart_id: stateIds.cartId,
        concept_name: delivery?.name,
        description: item?.description,
        item_instructions: lineItem.special_instructions,
        image_url: item?.images[0]?.url,
        location_id: delivery?.id,
        menu_section_id: section?.id,
        menu_section_name: section?.title,
        modifiers: modifiersData,
        name: item?.title,
        num_modifier_groups: item?.modifier_group_ids.length,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        price: formatCurrency(lineItem.item_price),
        product_id: item?.title,
        internal_product_id: item?.id,
        quantity: lineItem.quantity,
        sku: item?.id,
        value: formatCurrency(lineItem.price),
    });
}

export function paymentMethodOptionSelected(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    optionValue: string,
    via: string,
) {
    const paymentMethodMap: PaymentMethodMap = {
        cash: 'Cash',
        credit_card: 'Credit Card',
        paypal: 'PayPal',
        apple_pay: 'Apple Pay',
        google_pay: 'Google Pay',
        other: 'Other',
    };
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Option Selected', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        location_id: delivery?.id,
        option_category: 'Payment Method',
        option_required: true,
        option_type: 'Single Select',
        option_value: paymentMethodMap[optionValue.toLowerCase()],
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        via,
    });

    interface PaymentMethodMap {
        [key: string]: string | undefined;
    }
}

export function utensilsOptionSelected(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    optionValue: string,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Option Selected', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        option_required: false,
        option_category: 'Utensils',
        option_type: 'Single Select',
        option_value: optionValue,
        via,
    });
}

export function orderTimingOptionSelected(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Option Selected', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        location_id: delivery?.id,
        option_category: 'Order Timing',
        option_required: true,
        option_type: 'Single Select',
        option_value: orderTiming,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        via,
    });
}

export function fulfillmentDateOptionSelected(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    numberOfDays: string,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Option Selected', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        location_id: delivery?.id,
        option_category: 'Fulfillment Date',
        option_required: true,
        order_timing: orderTiming,
        option_type: 'Single Select',
        option_value: numberOfDays,
        order_type: 'Delivery',
        platform,
        via,
    });
}

export function digitalWalletCheckoutCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    cta: { name?: any; action?: any },
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        cta_name: cta.name,
        cta_action: cta.action,
        cta_destination: 'Checkout',
        via: 'Cart',
    });
}

export function checkoutCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        cta_name: 'Checkout',
        cta_action: 'Proceed to checkout',
        cta_destination: 'Checkout',
    });
}

export function updatedCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        cta_name: 'Checkout',
        cta_action: 'Proceed to checkout',
        cta_destination: 'Checkout',
        via,
    });
}

export function checkoutStarted(
    restaurantInfo: LocationMatchResponse | null,
    cartItems: CartItemInterface[],
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
) {
    const via = 'Cart';
    const orderTiming = getFromLocalStorage('orderTiming');
    trackWrapper('Checkout Started', {
        checkout_id: stateIds.checkoutId,
        affiliation: 'Ordermark DTC',
        ...cartEventPayload(restaurantInfo, cartItems, template, stateIds, orderTiming, via),
    });
}

export function cartIdentify(
    userProfile: IUserProfile | null,
    orderAddressApt: string,
    orderAddress: any,
) {
    const addressParts = placesToAddress(orderAddress);
    const identifierProperties = {
        address: {
            street: addressParts.address1,
            street2: orderAddressApt,
            city: addressParts.city,
            state: addressParts.region,
            postalCode: addressParts.postalcode,
        },
    };
    const traits = addUserTypeToTraits(userProfile, identifierProperties);
    identifyWrapper(userProfile?.email, traits);
}

export function checkoutStepViewed(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
    orderTiming: OrderTimingType | null,
    stepName: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Checkout Step Viewed', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        cart_id: stateIds.cartId,
        checkout_id: stateIds.checkoutId,
        concept_name: delivery?.name,
        location_id: delivery?.id,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        step_name: stepName,
    });
}

export function checkoutEditAddressLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        link_category: 'Edit',
        link_action: 'Edit address',
        link_destination: 'Edit Address',
        via: 'Checkout',
    });
}

export function checkoutEditDeliveryPreferenceLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        link_category: 'Edit',
        link_action: 'Edit delivery preferences',
        link_destination: 'Edit Delivery Preferences',
        via: 'Checkout',
    });
}

export function checkoutEditContactInfoLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        link_category: 'Edit',
        link_action: 'Edit contact info',
        link_destination: 'Edit Contact Info',
        via: 'Checkout',
    });
}

export function checkoutCancelLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        link_category: 'Cancel',
        link_action: 'Cancel update',
        link_destination: 'Checkout',
        via,
    });
}

export function continueToPaymentCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        cta_name: 'Continue to Payment',
        cta_action: 'Continue to payment step',
    });
}

export function placeOrderCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        cta_name: 'Place Order',
        cta_destination: 'Order Confirmation Page',
        cta_action: 'Submit order and pay',
    });
}

export function placeOrderWithWallet(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        cta_name: 'Place Order',
        cta_destination: 'Order Confirmation Page',
        cta_action: 'Submit order and pay',
        via: 'Payment Sheet',
    });
}

export function payWithDigitalWalletCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    cta: any,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        cta_action: 'Show Payment Sheet',
        cta_destination: cta.destination,
        cta_name: cta.name,
        location_id: delivery?.id,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        via: 'Checkout',
    });
}

export function updateCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
    action: string,
    destination: string = 'Child Menu',
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        cta_action: action,
        cta_destination: destination,
        cta_name: 'Update',
        location_id: delivery?.id,
        order_timing: orderTiming,
        platform,
        via,
    });
}

export function updateOrderTimingCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        cta_action: 'Update Order Timing',
        cta_name: 'Update',
        location_id: delivery?.id,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        via,
    });
}

export function saveAddressCTAClicked(
    template: BrandTemplate | null,
    restaurantInfo: LocationMatchResponse | null,
    via: string,
) {
    const delivery = restaurantInfo?.delivery;
    const ctaDestination = via === 'Menu Nav' ? 'Child Menu' : 'Child Menu Item';
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        cta_destination: ctaDestination,
        cta_name: 'Save Address',
        location_id: delivery?.id,
        serviceable: true,
        platform,
        via,
    });
}

export function getStartedCTAClicked(template: BrandTemplate | null) {
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        cta_destination: 'Menu',
        cta_name: 'Get Started',
        via: 'Onboarding Flow',
        platform,
    });
}

export function shareOrderCTAClicked(
    template: BrandTemplate | null,
    restaurantInfo: LocationMatchResponse | null,
    ctaDestination: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        cta_destination: ctaDestination,
        cta_name: 'Share',
        location_id: delivery?.id,
        platform,
    });
}

export function contactInfoCheckoutStepCompleted(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
    orderTiming: OrderTimingType | null,
    name: string,
    email: string,
    phone: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Checkout Step Completed', {
        checkout_id: stateIds.checkoutId,
        cart_id: stateIds.cartId,
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        step_name: 'Contact Info',
        name,
        email,
        phone,
    });
}

export function paymentCheckoutStepCompleted(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
    orderTiming: OrderTimingType | null,
    stripeToken: PaymentRequestTokenEvent | TokenResult | TestToken,
) {
    const delivery = restaurantInfo?.delivery;
    const paymentMethod = getPaymentMethod(stripeToken);

    trackWrapper('Checkout Step Completed', {
        checkout_id: stateIds.checkoutId,
        cart_id: stateIds.cartId,
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        step_name: 'Payment',
        payment_method: paymentMethod,
    });

    function getPaymentMethod(stripeToken: PaymentRequestTokenEvent | TokenResult | TestToken) {
        interface WalletNameMap {
            [key: string]: string | undefined;
        }

        const walletNameMap: WalletNameMap = {
            applePay: 'Apple Pay',
            googlePay: 'Google Pay',
        };
        if ((stripeToken as PaymentRequestTokenEvent).walletName) {
            return walletNameMap[(stripeToken as PaymentRequestTokenEvent).walletName];
        }
        return 'CreditCard';
    }
}

export function contactInfoIdentify(
    userProfile: IUserProfile | null,
    name: string,
    email: string,
    phone: string,
) {
    const identifierProperties = {
        name,
        email,
        phone,
    };
    const traits = addUserTypeToTraits(userProfile, identifierProperties);
    identifyWrapper(userProfile?.email || email, traits);
}

export function addressUpdateIdentify(
    userProfile: IUserProfile | null,
    orderAddressApt: string,
    orderAddress: any,
) {
    const addressParts = placesToAddress(orderAddress);
    const identifierProperties = {
        address: {
            street: addressParts.address1,
            street2: orderAddressApt,
            city: addressParts.city,
            state: addressParts.region,
            postalCode: addressParts.postalcode,
        },
    };
    const traits = addUserTypeToTraits(userProfile, identifierProperties);
    identifyWrapper(userProfile?.email, traits);
}

export function tipOptionSelected(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    optionValue: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Option Selected', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_timing: orderTiming,
        order_type: 'Delivery',
        platform,
        option_required: true,
        option_category: 'Tip',
        option_type: 'Single Select',
        option_value: optionValue,
        via: 'Checkout',
    });
}

export function deliveryPreferencesOptionSelected(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    optionValue: string,
    restaurantInfoQuery?: LocationMatchResponse | null,
    via?: string,
) {
    const delivery = restaurantInfoQuery ? restaurantInfoQuery?.delivery : restaurantInfo?.delivery;
    trackWrapper('Option Selected', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        location_id: delivery?.id,
        order_type: 'Delivery',
        platform,
        option_category: 'Delivery Preferences',
        option_required: true,
        option_type: 'Single Select',
        option_value: optionValue,
        via: via || getCurrentPage(),
    });
}

export function phoneLinkClicked(template: BrandTemplate | null, via: string) {
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        link_category: 'Footer',
        link_destination: 'Phone',
        link_action: 'Call Support',
        platform,
        via,
    });
}

export function fAQLinkClicked(
    template: BrandTemplate | null,
    url: string | undefined,
    via: string,
) {
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        link_category: 'Footer',
        link_destination: 'FAQ',
        link_url: url,
        link_action: 'View FAQ',
        platform,
        via,
    });
}

export function termsAndConditionsLinkClicked(
    template: BrandTemplate | null,
    url: string | undefined,
    via: string,
) {
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        link_category: 'Footer',
        link_destination: 'Terms and Conditions',
        link_url: url,
        link_action: 'View Terms and Conditions',
        platform,
        via,
    });
}

export function privacyPolicyLinkClicked(
    template: BrandTemplate | null,
    url: string | undefined,
    via: string,
) {
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        link_category: 'Footer',
        link_destination: 'Privacy Policy',
        link_url: url,
        link_action: 'View Privacy Policy',
        platform,
        via,
    });
}

export function cancelAddressEntryLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    via: string,
    restaurantInfoQuery: LocationMatchResponse | null,
    action: 'Cancel' | 'Close',
) {
    const delivery = restaurantInfoQuery?.delivery;
    const linkDestination = restaurantInfo ? 'Child Menu' : 'Parent Menu';

    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id || null,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name || null,
        link_action: `${action} Address Modal`,
        link_category: action,
        link_destination: linkDestination,
        location_id: delivery?.id || null,
        order_type: 'Delivery',
        platform,
        via,
    });
}

export function couponEntered(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
    code: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Coupon Entered', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cart_id: stateIds.cartId,
        checkout_id: stateIds.checkoutId,
        coupon_id: code,
        coupon_name: code,
        platform,
    });
}

export function couponApplied(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
    code: string,
    discount: number,
    discountType: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Coupon Applied', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cart_id: stateIds.cartId,
        checkout_id: stateIds.checkoutId,
        coupon_id: code,
        coupon_name: code,
        discount: formatCurrency(discount),
        discount_type: discountType,
        platform,
    });
}

export function couponDenied(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
    code: string,
    discount: number,
    reason: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Coupon Denied', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cart_id: stateIds.cartId,
        checkout_id: stateIds.checkoutId,
        coupon_id: code,
        coupon_name: code,
        discount: formatCurrency(discount),
        discount_type: null,
        reason,
        platform,
    });
}

export function couponRemoved(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    stateIds: StateIdsProps,
    code: string,
    discount: number,
    discountType: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Coupon Removed', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cart_id: stateIds.cartId,
        checkout_id: stateIds.checkoutId,
        coupon_id: code,
        coupon_name: code,
        discount: formatCurrency(discount),
        discount_type: discountType,
        order_type: 'Delivery',
        platform,
    });
}

export function promotionViewed(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    name: string,
    creative: string,
    promotionId: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Promotion Viewed', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        name,
        creative,
        promotion_id: promotionId,
        platform,
    });
}

export function promotionClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    email: string,
    position: string,
    creative: string,
    name: string,
    promotionId: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Promotion Clicked', {
        app_id: delivery?.app_id,
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        creative,
        email,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        name,
        position,
        promotion_id: promotionId,
        platform,
        url_brand_name: template?.metadata?.brand?.name?.toLowerCase().replace(/ /g, '-'),
    });
}

export function appOpened(template: BrandTemplate | null) {
    const utcDate = moment.utc().format('DD-MM-YYYY'); //eg. 04-03-2022
    const utcTime = moment.utc().format('HH:mm'); // eg. 20:03

    trackWrapper('App Opened', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        date: utcDate,
        time: utcTime,
        platform,
    });
}

export function flowViewed(
    template: BrandTemplate | null,
    stepViewed: number,
    totalFlowSteps: number,
) {
    trackWrapper('Flow Viewed', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        flow_name: 'Onboarding Flow',
        step_viewed: stepViewed,
        total_steps: totalFlowSteps,
        platform,
    });
}

export function errorShown(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    orderTiming: OrderTimingType | null,
    err: string,
) {
    try {
        const currentPage = getCurrentPage();
        const delivery = restaurantInfo?.delivery;
        trackWrapper('Error Shown', {
            brand_name: template?.metadata.brand.name || null,
            brand_id: template?.metadata.brand.id || null,
            app_id: delivery?.app_id || null,
            location_id: delivery?.id || null,
            concept_name: delivery?.name || null,
            order_timing: orderTiming,
            order_type: 'Delivery',
            platform,
            page: currentPage,
            error_message: err,
        });
    } catch (error) {
        console.error(error); // eslint-disable-line no-console
    }
}

export function accountLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    via: string,
    destination: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        link_category: 'Accounts Nav',
        link_destination: destination,
        via,
    });
}

export function editPersonalInfoClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    action: 'Edit Name' | 'Edit Phone' | 'Change Password',
) {
    const destinationDict = {
        'Edit Name': 'Name Editor',
        'Edit Phone': 'Phone Editor',
        'Change Password': 'Password Reset Flow',
    };
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Edit Clicked Account Profile', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        link_category: 'edit',
        link_destination: destinationDict[action],
        link_action: action,
        via: 'Profile Page',
    });
}

export function profileInfoIdentify(
    userProfile: IUserProfile | null,
    name: string | undefined,
    phone: string | undefined,
) {
    const identifierPackage = name ? { name } : { phone };
    const traits = addUserTypeToTraits(userProfile, identifierPackage);
    identifyWrapper(userProfile?.email, traits);
}

export function editProfileAddressClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    action: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Edit Clicked Account Profile', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        link_category: 'Edit',
        link_destination: action,
        link_action: action,
        via: 'Saved Addresses Page',
    });
}

export function addProfileAddressCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cta_name: 'Add an Address',
        cta_action: 'Add Address',
        cta_destination: 'Saved Addresses Page',
        via: 'Saved Addresses Page',
    });
}

export function saveProfileAddressCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cta_name: 'Save',
        cta_action: 'Save Address',
        via: 'Saved Addresses Page',
    });
}

export function deleteProfileAddressCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cta_name: 'Delete Address',
        cta_action: 'Delete Address',
        via: 'Saved Addresses Page',
    });
}

export function addressDeliveryPreferenceSelected(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    value: string,
) {
    const deliveryPrefencesMap: DeliveryPrefencesMap = {
        'Meet the driver at the door': 'Handoff',
        'Contactless delivery (leave food at door)': 'Contactless',
    };
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Option Selected', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_type: 'Delivery',
        platform,
        option_required: true,
        option_category: 'Delivery Preferences',
        option_type: 'Single Select',
        option_value: deliveryPrefencesMap[value],
        via: 'Saved Addresses Page',
    });

    interface DeliveryPrefencesMap {
        [key: string]: string | undefined;
    }
}

export function defaultAddressSelected(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    checked?: boolean,
) {
    const optionValue = checked ? 'Set Default' : 'Unset Default';
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Option Selected', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_type: 'Delivery',
        platform,
        option_required: false,
        option_category: 'Default Address',
        option_type: 'Delivery',
        option_value: optionValue,
        via: 'Saved Addresses Page',
    });
}

export function savedAddressSelected(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    cdpLocation: string,
    selectedAddress: string | null | undefined,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Option Selected', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        location_id: delivery?.id,
        option_category: 'Saved Address',
        option_required: true,
        option_type: 'Single Select',
        option_value: selectedAddress,
        order_type: 'Delivery',
        via: cdpLocation,
    });
}

export function cancelProfileAddressClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    action: string,
) {
    const delivery = restaurantInfo?.delivery;

    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id || null,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name || null,
        link_action: action,
        link_category: 'Cancel',
        link_destination: 'Saved Addresses Page',
        location_id: delivery?.id || null,
        via: 'Saved Addresses Page',
    });
}

export function profileAddressEntered(
    template: BrandTemplate | null,
    userAddress: UserProfileAddress,
) {
    trackWrapper('Address Entered', {
        address: {
            street: userAddress.address1,
            street2: userAddress.address2,
            city: userAddress.city,
            state: userAddress.region,
            postalCode: userAddress.postalcode,
        },
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        postalCode: userAddress.postalcode,
        state: userAddress.region,
        via: 'Saved Address Page',
    });
}

export function identifyProfileAddress(
    userProfile: IUserProfile | null,
    userAddress: UserProfileAddress | undefined,
    totalAddresses: number,
) {
    const date = moment.utc().format('DD-MM-YYYY HH:mm');
    const identifierProperties = {
        address: {
            street: userAddress?.address1,
            street2: userAddress?.address2,
            city: userAddress?.city,
            state: userAddress?.region,
            postalCode: userAddress?.postalcode,
        },
        date_last_modified: date,
        postalCode: userAddress?.postalcode,
        saved_adddress_count: totalAddresses,
        state: userAddress?.region,
    };
    const traits = addUserTypeToTraits(userProfile, identifierProperties);
    identifyWrapper(userProfile?.email, traits);
}

export function accountWidgetCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cta_name: 'Account Widget',
        cta_action: 'Open Account Menu',
        cta_destination: 'Account Menu',
        via: 'Menu',
    });
}

export function accountAuthCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    cdpLocation: string,
    signUp: boolean,
    via?: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cta_name: signUp ? 'Sign Up' : 'Sign In',
        cta_action: signUp ? 'Sign Up' : 'Sign In',
        cta_destination: signUp ? 'Auth Page Sign Up' : 'Auth Page Sign In',
        via: via ? via : cdpLocation,
    });
}

export function welcomeModalCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    profile = false,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cta_name: profile ? 'Go to my account' : 'View Menu',
        cta_action: profile ? 'Profile Page' : 'Menu',
        cta_destination: profile ? 'Profile Page' : 'Menu',
        via: 'Welcome Modal',
    });
}

export function createAccountCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cta_name: 'Create an Account',
        cta_action: 'Sign Up',
        cta_destination: 'Auth Page Sign Up',
        via: 'Order Confirmation Page',
    });
}

export function signOutCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        cta_name: 'Sign Out',
        cta_action: 'Sign Out',
        via: 'Menu',
    });
}

export function savedAddressActionCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    cdpLoction: string,
) {
    const ctaAction = restaurantInfo
        ? 'Choose Saved Address'
        : 'Set Saved Address as Child Menu Address';
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        cta_action: ctaAction,
        cta_destination: 'Menu',
        cta_name: 'Save',
        location_id: delivery?.id,
        serviceable: true,
        via: cdpLoction,
    });
}

export function menuLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    action: string,
    via: string,
) {
    const menuMap: MenuLinkDestinationMap = {
        Profile: 'Profile Page',
        'Order History': 'Order History Page',
        'Saved Addresses': 'Saved Addresses Page',
    };
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        link_category: 'Hamburger Nav Menu',
        link_action: action,
        link_destination: menuMap[action],
        via,
    });

    interface MenuLinkDestinationMap {
        [key: string]: string | undefined;
    }
}

export function exitWelcomeModalClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        brand_name: template?.metadata.brand.name,
        brand_id: template?.metadata.brand.id,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        link_category: 'Close',
        link_action: 'Close Screen',
        link_destination: 'Menu',
        via: 'Welcome Modal',
    });
}

export function accountEmailCaptured(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    email: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Email Captured', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        location_id: delivery?.id,
        concept_name: delivery?.name,
        order_type: 'Delivery',
        email,
        via: 'Auth Page',
    });
}

export function sessionExpiredCaptured(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    cartItems: CartItemInterface[],
) {
    const delivery = restaurantInfo?.delivery;
    const cartTotals = getCartTotals(cartItems);
    trackWrapper('Session Expired Shown', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        cart_qty: cartTotals.quantity,
        cart_ttl: cartTotals.price,
        concept_name: delivery?.name,
        location_id: delivery?.id,
        order_type: 'Delivery',
        via: getCurrentPage(),
    });
}

export function sessionExpiredLinkClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    cartItems: CartItemInterface[],
) {
    const delivery = restaurantInfo?.delivery;
    const cartTotals = getCartTotals(cartItems);
    trackWrapper('Session Expired Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        cart_qty: cartTotals.quantity,
        cart_ttl: cartTotals.price,
        concept_name: delivery?.name,
        link_action: 'Clear Session Storage',
        link_category: 'Session Expired',
        link_destination: 'Address Entry',
        location_id: delivery?.id,
        via: getCurrentPage(),
    });
}

export function updateMyOrderClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        cta_action: 'Navigate to Menu',
        cta_destination: 'Menu',
        cta_name: 'Update my Order',
        location_id: delivery?.id,
        order_type: 'Delivery',
        via: 'Item 86 Modal',
    });
}
export function continueWithAvailableItemsClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        cta_action: 'Go to Checkout',
        cta_destination: 'Checkout',
        cta_name: 'Continue with Available Items',
        location_id: delivery?.id,
        order_type: 'Delivery',
        via: 'Item 86 Modal',
    });
}

export function close86ModalClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('Link Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        link_action: 'Close 86 Modal',
        link_destination: 'Checkout',
        link_category: 'Close',
        location_id: delivery?.id,
        order_type: 'Delivery',
        via: 'X',
    });
}

export function signUpEmailCTAClicked(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        app_id: delivery?.app_id,
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        concept_name: delivery?.name,
        cta_action: 'Sign Up for Welcome - Place First Order',
        cta_destination: 'Email',
        cta_name: 'Sign Up For Exclusive Offers',
        location_id: delivery?.id,
        order_type: 'Delivery',
        via: 'Welcome Promo Banner',
    });
}

export function genericCTA(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    action: string,
    destination: string,
    name: string,
    via?: string,
) {
    const delivery = restaurantInfo?.delivery;
    trackWrapper('CTA Clicked', {
        brand_id: template?.metadata.brand.id,
        brand_name: template?.metadata.brand.name,
        app_id: delivery?.app_id,
        concept_name: delivery?.name,
        location_id: delivery?.id,
        cta_action: action,
        cta_destination: destination,
        cta_name: name,
        order_type: 'Delivery',
        via: via || getCurrentPage(),
    });
}

function roundCents(v: number) {
    return (v / 100).toFixed(2);
}

function deliveryProvider(kitchen: Location): string | null {
    const provider = kitchen?.logistics_configs?.[0]?.logistics_provider;
    if (provider === 'doordash_drive') {
        return 'DDD';
    } else if (provider === 'restaurant_delivery') {
        return 'Self Delivery';
    }
    return null;
}

function userAlias(email: string): any {
    if (!email) {
        return null;
    }
    return {
        alias_name: md5(email.toLowerCase()),
        alias_label: 'MD5_HEX_EMAIL',
    };
}

const CONTACTLESS_TEXT = 'Contactless delivery (leave food at door)';
const HANDOFF_TEXT = 'Meet the driver at the door';

function deliveryType(orderPayload: any): string {
    const instructions = orderPayload?.service_info?.special_instructions ?? '';
    if (instructions.includes(CONTACTLESS_TEXT)) {
        return 'Contactless';
    } else if (instructions.includes(HANDOFF_TEXT)) {
        return 'Handoff';
    }
    return '';
}

function deliveryInstructions(orderPayload: any): string {
    // instructions might contain delivery type (handoff vs contactless) info; if so, strip it
    // eg: instructions = "Leave it at my door | Test special instructions here"
    const instructions = orderPayload?.service_info?.special_instructions ?? '';
    if (instructions.includes(CONTACTLESS_TEXT) || instructions.includes(HANDOFF_TEXT)) {
        if (instructions.includes('|')) {
            return instructions.split('|')[1].trim();
        }
        return '';
    }
    return instructions;
}

function totalUnitPrice(lineItem: any): number {
    let price = (lineItem.price || 0) / lineItem.quantity;
    for (const modifier of lineItem?.modifier_groups || []) {
        for (const modItem of modifier.items) {
            price += totalUnitPrice(modItem);
        }
    }
    return price;
}

function modifierIds(lineItem: any): string[] {
    let res: string[] = [];
    for (const modifier of lineItem?.modifier_groups || []) {
        for (const item of modifier.items) {
            res.push(item.item_id);
            res = res.concat(modifierIds(item));
        }
    }
    return res;
}

function modifierNames(lineItem: any): string[] {
    let res: string[] = [];
    for (const modifier of lineItem?.modifier_groups || []) {
        for (const item of modifier.items) {
            res.push(item.name);
            res = res.concat(modifierNames(item));
        }
    }
    return res;
}

function getProducts(order: any, menu: MenuManagerInterface, template: BrandTemplate): any[] {
    const brandId = template?.metadata?.brand?.id;
    const lineItems = order.contents.line_items;
    const res: any[] = [];

    for (const lineItem of lineItems) {
        const section = lineItem.section_id
            ? findEntity(menu, 'section', lineItem.section_id)
            : null;
        const item = findEntity(menu, 'item', lineItem.item_id);
        const basePrice = (lineItem.price || 0) / lineItem.quantity / 100;
        const price = totalUnitPrice(lineItem) / 100;
        res.push({
            base_price: basePrice,
            brand_name: template.metadata?.brand?.name,
            description: item.description,
            image_url: item.images.length > 0 ? item.images[0].url : '',
            internal_product_id: item.id,
            menu_section_id: section?.id ?? null,
            menu_section_name: section?.title ?? null,
            modifier_ids: modifierIds(lineItem),
            modifier_names: modifierNames(lineItem),
            name: item.title,
            price,
            product_id: item.title,
            quantity: lineItem.quantity,
            sku: item.id,
            special_instructions: lineItem.special_instructions,
            value: lineItem.quantity * price,
            brand_id: brandId,
        });
    }
    return res;
}

function findTax(orderPayload: any): number | null {
    if (orderPayload?.subtotal_info != null) {
        const surcharges = orderPayload?.subtotal_info?.surcharges || [];
        for (const charge of surcharges) {
            if (charge?.description?.toLowerCase() === 'tax') {
                return charge.amount;
            }
        }

        const taxes = orderPayload?.subtotal_info?.taxes || [];
        for (const tax of taxes) {
            if (tax?.description?.toLowerCase() === 'tax') {
                return tax.amount;
            }
        }
    }
    return null;
}

function findTip(orderPayload: any): number | null {
    if (orderPayload?.subtotal_info != null) {
        const surcharges = orderPayload?.subtotal_info?.surcharges || [];
        for (const charge of surcharges) {
            if (charge?.description?.toLowerCase() === 'tip') {
                return charge.amount;
            }
        }
    }
    return null;
}

function formatTime(time: Date): string {
    function pad(v: number): string {
        return v.toString().padStart(2, '0');
    }
    return `${pad(time.getHours())}:${pad(time.getMinutes())}`;
}

function printDateTime(time: Date | string | null): string | null {
    if (time instanceof Date) {
        return time.toISOString();
    }
    return time;
}

export function orderComplete(
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
    stateIds: StateIdsProps | null,
    userProfile: IUserProfile | null,
    menuInfo: MenuManagerInterface,
    orderPayload: any,
    orderAddress: any,
    orderAddressApt: string,
    paymentMethod: any,
    couponIds: string[],
) {
    const kitchen = restaurantInfo?.delivery;
    const totals = orderPayload.subtotal_info;
    if (!kitchen || !template || !totals) {
        return;
    }

    const parsedAddress = placesToAddress(orderAddress, orderAddressApt.trim(), true);

    const discount = totals.discounts.reduce((x: any, y: any) => x + y.amount, 0);

    const total = totals.total;
    const subtotal = totals.items_subtotal;
    const discountSubtotal = subtotal - discount;

    const tax = findTax(orderPayload);
    const tip = findTip(orderPayload);
    const tipPercent = ((tip || 0) / subtotal) * 100;

    const isFutureOrder = !!orderPayload?.is_future_order && !!orderPayload.prepare_by_datetime;
    const deliveryWindow = template?.future_order?.delivery_window_duration || null;
    let deliveryWindowStr: string | null = null;
    let deliveryWindowStart: Date | null = null;
    let deliveryWindowEnd: Date | null = null;

    if (isFutureOrder && deliveryWindow) {
        deliveryWindowStart = new Date(orderPayload.prepare_by_datetime);
        deliveryWindowStart.setMinutes(deliveryWindowStart.getMinutes() + 15);

        deliveryWindowEnd = new Date(deliveryWindowStart);
        deliveryWindowEnd.setMinutes(deliveryWindowEnd.getMinutes() + deliveryWindow);

        deliveryWindowStr = `${formatTime(deliveryWindowStart)}-${formatTime(deliveryWindowEnd)}`;
    }

    trackWrapper('Order Completed Client Side', {
        address: {
            street: parsedAddress.address1,
            street2: parsedAddress.address2,
            city: parsedAddress.city,
            state: parsedAddress.region,
            postalCode: parsedAddress.postalcode,
        },
        affiliation: 'Ordermark DTC',
        app_id: kitchen?.app_id,
        brand_id: template.metadata.brand.id,
        brand_name: template.metadata.brand.name,
        cart_id: stateIds?.cartId,
        checkout_id: stateIds?.checkoutId,
        concept_name: kitchen?.name,
        coupon: couponIds,
        currency: 'USD',
        delivery_fee: (kitchen?.logistics_configs?.[0]?.delivery_fee ?? 0) / 100,
        // delivery_id: _delivery_id(order_status),
        delivery_instructions: deliveryInstructions(orderPayload),
        delivery_type: deliveryType(orderPayload),
        discount: roundCents(discount),
        delivery_provider: deliveryProvider(kitchen),
        email: userProfile?.email,
        location_id: kitchen?.id,
        name: userProfile?.name,
        num_products: orderPayload?.contents?.line_items?.length,
        order_id: stateIds?.orderId,
        order_type: 'Delivery',
        payment_method: paymentMethod,
        phone: userProfile?.phone_number,
        products: getProducts(orderPayload, menuInfo, template),
        // quoted_delivery_time: _quoted_delivery_time(order_status),
        // quoted_pickup_time: _quoted_pickup_time(order_status),
        revenue: roundCents(subtotal),
        service_fee: (template?.general?.service_fee || 0) / 100,
        subtotal: discountSubtotal ? roundCents(discountSubtotal) : null,
        tax: tax ? roundCents(tax) : null,
        tip: tip ? roundCents(tip) : null,
        tip_percent: tipPercent,
        total: total ? roundCents(total) : null,
        delivery_window: deliveryWindowStr,
        delivery_window_end: printDateTime(deliveryWindowEnd),
        delivery_window_start: printDateTime(deliveryWindowStart),
        fulfillment_date: printDateTime(orderPayload.prepare_by_datetime?.split('T')?.[0]),
        is_future_order: orderPayload.is_future_order,
        order_date: printDateTime(orderPayload.placed_datetime),
        pickup_datetime: orderPayload.is_future_order ? orderPayload.prepare_by_datetime : null,
        user_alias: userAlias(userProfile?.email ?? ''),
        platform,
    });
}

export default function useSegment() {
    const cdpLocation = useContext(CdpLocationContext);
    const { template, cartItems, stateIds, orderTiming, userProfile } = useContext(AppContext);
    const {
        conceptDetails: { menuInfo, restaurantInfo },
    } = useContext(ConceptContext);

    return {
        pageViewed: pageViewed.bind(null, template, restaurantInfo),
        addressEntered: addressEntered.bind(null, template),
        IdentifyAddress: IdentifyAddress.bind(null, template, userProfile),
        IdentifyEmail: IdentifyEmail.bind(null, template, userProfile),
        startOrderCTA: startOrderCTA.bind(null, restaurantInfo, template, orderTiming),
        submitEmailCTA: submitEmailCTA.bind(null, restaurantInfo, template),
        conceptSet: conceptSet.bind(null, template, orderTiming),
        emailCapturedAddressEntry: emailCapturedAddressEntry.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        emailCapturedCheckout: emailCapturedCheckout.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        linkClicked: linkClicked.bind(null, restaurantInfo, template, cdpLocation),
        menuShown: menuShown.bind(null, restaurantInfo, menuInfo, template, orderTiming),
        cartClicked: cartClicked.bind(null, restaurantInfo, cartItems, template, orderTiming),
        menuSectionClicked: menuSectionClicked.bind(
            null,
            restaurantInfo,
            menuInfo,
            template,
            orderTiming,
        ),
        productClicked: productClicked.bind(null, restaurantInfo, template, orderTiming),
        productViewed: productViewed.bind(null, restaurantInfo, template, orderTiming),
        productDismissed: productDismissed.bind(null, restaurantInfo, template, orderTiming),
        productQuantityIncreased: productQuantityIncreased.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
            cdpLocation,
        ),
        productQuantityDecreased: productQuantityDecreased.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
            cdpLocation,
        ),
        cartItemQuantityChanged: cartItemQuantityChanged.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
            menuInfo,
        ),
        productAdded: productAdded.bind(null, restaurantInfo, menuInfo, template, orderTiming),
        addtoCartCTAClicked: addtoCartCTAClicked.bind(null, restaurantInfo, template, orderTiming),
        updateItemCTAClicked: updateItemCTAClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        cartViewed: cartViewed.bind(null, restaurantInfo, template, stateIds, cartItems),
        cartClosed: cartClosed.bind(null, restaurantInfo, template, stateIds, cartItems),
        editAddressLinkClicked: editAddressLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        cancelLinkClicked: cancelLinkClicked.bind(null, restaurantInfo, template, orderTiming),
        editItemLinkClicked: editItemLinkClicked.bind(null, restaurantInfo, template, orderTiming),
        changeAddressLinkClicked: changeAddressLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        productRemoved: productRemoved.bind(null, menuInfo, restaurantInfo, template, stateIds),
        paymentMethodOptionSelected: paymentMethodOptionSelected.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        utensilsOptionSelected: utensilsOptionSelected.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        checkoutCTAClicked: checkoutCTAClicked.bind(null, restaurantInfo, template, orderTiming),
        updatedCTAClicked: updatedCTAClicked.bind(null, restaurantInfo, template, orderTiming),
        checkoutStarted: checkoutStarted.bind(null, restaurantInfo, cartItems, template, stateIds),
        cartIdentify: cartIdentify.bind(null, userProfile),
        checkoutStepViewed: checkoutStepViewed.bind(
            null,
            restaurantInfo,
            template,
            stateIds,
            orderTiming,
        ),
        checkoutEditAddressLinkClicked: checkoutEditAddressLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        checkoutEditDeliveryPreferenceLinkClicked: checkoutEditDeliveryPreferenceLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        checkoutEditContactInfoLinkClicked: checkoutEditContactInfoLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        checkoutCancelLinkClicked: checkoutCancelLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        continueToPaymentCTAClicked: continueToPaymentCTAClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        placeOrderCTAClicked: placeOrderCTAClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        updateCTAClicked: updateCTAClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
            cdpLocation,
        ),
        contactInfoCheckoutStepCompleted: contactInfoCheckoutStepCompleted.bind(
            null,
            restaurantInfo,
            template,
            stateIds,
            orderTiming,
        ),
        paymentCheckoutStepCompleted: paymentCheckoutStepCompleted.bind(
            null,
            restaurantInfo,
            template,
            stateIds,
            orderTiming,
        ),
        contactInfoIdentify: contactInfoIdentify.bind(null, userProfile),
        addressUpdateIdentify: addressUpdateIdentify.bind(null, userProfile),
        tipOptionSelected: tipOptionSelected.bind(null, restaurantInfo, template, orderTiming),
        deliveryPreferencesOptionSelected: deliveryPreferencesOptionSelected.bind(
            null,
            restaurantInfo,
            template,
        ),
        phoneLinkClicked: phoneLinkClicked.bind(null, template),
        fAQLinkClicked: fAQLinkClicked.bind(null, template),
        termsAndConditionsLinkClicked: termsAndConditionsLinkClicked.bind(null, template),
        privacyPolicyLinkClicked: privacyPolicyLinkClicked.bind(null, template),
        payWithDigitalWalletCTAClicked: payWithDigitalWalletCTAClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        digitalWalletCheckoutCTAClicked: digitalWalletCheckoutCTAClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        couponEntered: couponEntered.bind(null, restaurantInfo, template, stateIds),
        couponApplied: couponApplied.bind(null, restaurantInfo, template, stateIds),
        couponDenied: couponDenied.bind(null, restaurantInfo, template, stateIds),
        couponRemoved: couponRemoved.bind(null, restaurantInfo, template, stateIds),
        promotionViewed: promotionViewed.bind(null, restaurantInfo, template),
        promotionClicked: promotionClicked.bind(null, restaurantInfo, template),
        errorShown: errorShown.bind(null, restaurantInfo, template, orderTiming),
        placeOrderWithWallet: placeOrderWithWallet.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        editOrderTimingLinkClicked: editOrderTimingLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        cancelOrderTimingLinkClicked: cancelOrderTimingLinkClicked.bind(
            null,
            restaurantInfo,
            template,
        ),
        updateOrderTimingCTAClicked: updateOrderTimingCTAClicked.bind(
            null,
            restaurantInfo,
            template,
        ),
        orderTimingOptionSelected: orderTimingOptionSelected.bind(null, restaurantInfo, template),
        fulfillmentDateOptionSelected: fulfillmentDateOptionSelected.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        saveAddressCTAClicked: saveAddressCTAClicked.bind(null, template),
        cancelAddressEntryLinkClicked: cancelAddressEntryLinkClicked.bind(
            null,
            restaurantInfo,
            template,
        ),
        hamburgerMenuLinkClicked: hamburgerMenuLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            cdpLocation,
        ),
        sharePageLinkClicked: sharePageLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            cdpLocation,
        ),
        menuPageLinkClicked: menuPageLinkClicked.bind(null, restaurantInfo, template, cdpLocation),
        aboutPageLinkClicked: aboutPageLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            cdpLocation,
        ),
        supportPageLinkClicked: supportPageLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            cdpLocation,
        ),
        callSupportPageLinkClicked: callSupportPageLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            cdpLocation,
        ),
        textSupportPageLinkClicked: textSupportPageLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            cdpLocation,
        ),
        emailSupportPageLinkClicked: emailSupportPageLinkClicked.bind(
            null,
            restaurantInfo,
            template,
        ),
        faqSupportPageLinkClicked: faqSupportPageLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            cdpLocation,
        ),
        legalPageLinkClicked: legalPageLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            cdpLocation,
        ),
        termsAndConditionsLegalLinkClicked: termsAndConditionsLegalLinkClicked.bind(
            null,
            restaurantInfo,
            template,
        ),
        privacyPolicyLegalLinkClicked: privacyPolicyLegalLinkClicked.bind(
            null,
            restaurantInfo,
            template,
        ),
        closeScreenLinkClicked: closeScreenLinkClicked.bind(null, restaurantInfo, template),
        skipOnboardingLinkClicked: skipOnboardingLinkClicked.bind(null, template),
        appOpened: appOpened.bind(null, template),
        flowViewed: flowViewed.bind(null, template),
        getStartedCTAClicked: getStartedCTAClicked.bind(null, template),
        shareOrderCTAClicked: shareOrderCTAClicked.bind(null, template, restaurantInfo),
        expandAndHideOrderSummary: expandAndHideOrderSummary.bind(null, restaurantInfo, template),
        closeDetailsExpandedView: closeDetailsExpandedView.bind(null, restaurantInfo, template),
        showOrderDetailsCTAClicked: showOrderDetailsCTAClicked.bind(null, restaurantInfo, template),
        startAnOrderCTAClicked: startAnOrderCTAClicked.bind(
            null,
            restaurantInfo,
            template,
            orderTiming,
        ),
        trackMyOrderCTAClicked: trackMyOrderCTAClicked.bind(null, restaurantInfo, template),
        defaultAddressUnserviceableClicked: defaultAddressUnserviceableClicked.bind(null, template),
        closeAddressUnserviceableModal: closeAddressUnserviceableModal.bind(null, template),
        messageShownRoadblock: messageShownRoadblock.bind(null, template),
        editPersonalInfoClicked: editPersonalInfoClicked.bind(null, restaurantInfo, template),
        accountLinkClicked: accountLinkClicked.bind(null, restaurantInfo, template),
        profileInfoIdentify: profileInfoIdentify.bind(null, userProfile),
        addProfileAddressCTAClicked: addProfileAddressCTAClicked.bind(
            null,
            restaurantInfo,
            template,
        ),
        editProfileAddressClicked: editProfileAddressClicked.bind(null, restaurantInfo, template),
        defaultAddressSelected: defaultAddressSelected.bind(null, restaurantInfo, template),
        addressDeliveryPreferenceSelected: addressDeliveryPreferenceSelected.bind(
            null,
            restaurantInfo,
            template,
        ),
        cancelProfileAddressClicked: cancelProfileAddressClicked.bind(
            null,
            restaurantInfo,
            template,
        ),
        deleteProfileAddressCTAClicked: deleteProfileAddressCTAClicked.bind(
            null,
            restaurantInfo,
            template,
        ),
        identifyProfileAddress: identifyProfileAddress.bind(null, userProfile),
        saveProfileAddressCTAClicked: saveProfileAddressCTAClicked.bind(
            null,
            restaurantInfo,
            template,
        ),
        profileAddressEntered: profileAddressEntered.bind(null, template),
        accountWidgetCTAClicked: accountWidgetCTAClicked.bind(null, restaurantInfo, template),
        accountAuthCTAClicked: accountAuthCTAClicked.bind(
            null,
            restaurantInfo,
            template,
            cdpLocation,
        ),
        createAccountCTAClicked: createAccountCTAClicked.bind(null, restaurantInfo, template),
        welcomeModalCTAClicked: welcomeModalCTAClicked.bind(null, restaurantInfo, template),
        signOutCTAClicked: signOutCTAClicked.bind(null, restaurantInfo, template),
        menuLinkClicked: menuLinkClicked.bind(null, restaurantInfo, template),
        exitWelcomeModalClicked: exitWelcomeModalClicked.bind(null, restaurantInfo, template),
        accountEmailCaptured: accountEmailCaptured.bind(null, restaurantInfo, template),
        IdentifySignUp: IdentifySignUp.bind(null),
        IdentifyLogIn: IdentifyLogIn.bind(null),
        seeTheMenuCTAClicked: seeTheMenuCTAClicked.bind(null, template, orderTiming),
        savedAddressActionCTAClicked: savedAddressActionCTAClicked.bind(
            null,
            restaurantInfo,
            template,
            cdpLocation,
        ),
        savedAddressSelected: savedAddressSelected.bind(
            null,
            restaurantInfo,
            template,
            cdpLocation,
        ),
        sessionExpiredCaptured: sessionExpiredCaptured.bind(
            null,
            restaurantInfo,
            template,
            cartItems,
        ),
        sessionExpiredLinkClicked: sessionExpiredLinkClicked.bind(
            null,
            restaurantInfo,
            template,
            cartItems,
        ),
        updateMyOrderClicked: updateMyOrderClicked.bind(null, restaurantInfo, template),
        continueWithAvailableItemsClicked: continueWithAvailableItemsClicked.bind(
            null,
            restaurantInfo,
            template,
        ),
        close86ModalClicked: close86ModalClicked.bind(null, restaurantInfo, template),
        signUpEmailCTAClicked: signUpEmailCTAClicked.bind(null, restaurantInfo, template),
        genericCTA: genericCTA.bind(null, restaurantInfo, template),
        orderComplete: orderComplete.bind(null, restaurantInfo, template, stateIds, userProfile),
    };
}
