import { RightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { Header } from '../layout/header';
import { useMobileScreen, withAppRoot } from '../utils/general';
import useSegment from '../utils/segment';
import './index.scss';

const AboutPageContent: FC = () => {
    const { template } = useContext(AppContext);
    const history = useHistory();
    const isMobile = useMobileScreen();
    const segment = useSegment();
    const [backgroundImage, setBackgroundImage] = useState<string>();
    const [headerTitle, setHeaderTitle] = useState<string>();
    const { copy, image, title } = template?.about_us_page || {};

    // Handle placeholder local images until assets uploaded to staging server
    const buildBackgroundImageUrl = useCallback(() => {
        if (image) {
            setBackgroundImage(image.startsWith('src/assets') ? withAppRoot(image) : image);
        }
    }, [image]);

    // eslint-disable-next-line no-undefined
    useEffect(() => setHeaderTitle(isMobile ? 'About' : undefined), [isMobile]);

    useEffect(() => {
        const pathname = location.pathname;
        if (pathname.includes('about')) {
            segment.pageViewed();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!copy) {
            history.push('/');
        }
        buildBackgroundImageUrl();
    }, [buildBackgroundImageUrl, copy, history]);

    const LinkBar = ({ text, url }: { text: string; url: string }) => {
        return (
            <a
                href={url}
                target="_blank"
                onClick={() => {
                    segment.linkClicked(url, 'Marketing Site', 'Navigation', null, `View ${text}`);
                }}
            >
                <div className="about-button-row link-bar">
                    <span>{text}</span>
                    <RightOutlined style={{ fontStyle: 'bold' }} />
                </div>
            </a>
        );
    };

    const infoUrl = location.origin + '/info/';

    return (
        <div>
            <div className="extend-to-bottom">
                {/* TODO: This Header needs the right template for its needs, it will be default for now */}
                <Header templateName="default" title={headerTitle} logoLink="/" />
                <Row className="about-page">
                    <Col span={24}>
                        {image && (
                            <div
                                className="about-image"
                                style={{ backgroundImage: `url(${backgroundImage})` }}
                            ></div>
                        )}
                        <div className="about-content">
                            <h2>{title}</h2>
                            <ReactMarkdown>{copy || ''}</ReactMarkdown>
                            <LinkBar
                                text="Privacy Policy"
                                url={
                                    template?.general?.legal?.privacy_policy_link ||
                                    infoUrl + 'privacy-policy'
                                }
                            />
                            <LinkBar
                                text="Terms & Conditions"
                                url={
                                    template?.general?.legal?.terms_conditions_link ||
                                    infoUrl + 'terms-and-conditions'
                                }
                            />
                            <LinkBar
                                text="FAQ"
                                url={template?.general?.support?.faq_link || infoUrl + 'faq'}
                            />
                            <Button
                                size="large"
                                className="about-button-row back-button"
                                style={{ backgroundColor: template?.general?.primary_color }}
                                onClick={() => {
                                    segment.genericCTA('Go Back to Menu', 'Menu', 'Back to Menu');
                                    history.push('/');
                                }}
                            >
                                Back To Menu
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export const AboutPage: FC = () => {
    return (
        <CdpLocationContext.Provider value="About">
            <AboutPageContent />
        </CdpLocationContext.Provider>
    );
};
