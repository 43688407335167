import { LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Input, Row, Space, Spin, Typography } from 'antd';
import log from 'loglevel';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { Controller, FieldError, useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { Header } from '../layout/header';
import { PlacesAutocomplete } from '../ui-components/PlacesAutocomplete';
import { ImageWithFallback } from '../ui-components/image-with-fallback';
import { TemplateButton } from '../ui-components/template-button';
import { saveAddressToUserProfile } from '../utils/address';
import { isOfType, useMobileScreen, useResizeObserver } from '../utils/general';
import useSegment from '../utils/segment';
import './index.scss';
import { LocationClosed } from './location-closed';
import { NoLocations } from './no-locations';

const { Title } = Typography;

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const AddressPageContent: FC = () => {
    const history = useHistory();
    const {
        orderAddress,
        orderAddressApt,
        updateOrderAddress,
        updateOrderAddressApt,
        updateCartItems,
        template,
        stateIds,
        updateStateIds,
        isDinnerbell,
        userProfile,
        updateUserProfile,
    } = useContext(AppContext);

    const segment = useSegment();
    const brandIdRef = useRef('');
    const isMobile = useMobileScreen();
    const cdpLocation = useContext(CdpLocationContext);
    const { observe, height: headerHeight } = useResizeObserver();
    const {
        updateConceptDetails,
        isErrorFetching,
        isConceptFetching,
        updateIsErrorFetching,
        updateRestaurantInfoTempQuery,
        restaurantInfoTempQuery,
    } = useContext(ConceptContext);

    const [formTouched, setFormTouched] = useState(false);
    const [prevPath, setPrevPath] = useState('');

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    useEffect(() => {
        const pathname = location.pathname;
        if (pathname.includes('address') && template && pathname !== prevPath) {
            segment.pageViewed();
            setPrevPath(pathname);
        }
    }, [template, prevPath, segment]);

    useEffect(() => {
        const pageTitle = document.querySelector('title');
        if (pageTitle && template?.metadata?.brand.name) {
            const brandName = template.metadata.brand.name;
            pageTitle.innerHTML = `Check if ${brandName} delivers to your address`;
        }
        if (template?.metadata.brand.id) {
            brandIdRef.current = template?.metadata.brand.id;
        }
    }, [template]);

    const addressPageScrollToTop = () => {
        if (isMobile) {
            // Wait until Safari animations has finished
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 200);
        }
    };

    const schema = yup
        .object({
            orderAddress: yup
                .mixed()
                .required('Please enter delivery address')
                .notOneOf([''], 'Please enter delivery address'),
        })
        .required();

    const defaultValues = {
        orderAddress,
        orderAddressApt,
    };

    type FormData = {
        orderAddress: any;
        orderAddressApt: string | undefined;
    };

    const { control, handleSubmit, formState, watch, reset, getValues } = useForm<FormData>({
        defaultValues,
        resolver: yupResolver(schema),
        mode: 'all',
        reValidateMode: 'onBlur',
    });

    const watchOrderAddress = watch('orderAddress');
    const conceptDetailsData = watchOrderAddress?.conceptDetails;
    const locationStatus = conceptDetailsData?.locationStatus;

    log.debug('%c watchOrderAddress ', 'background-color: green', watchOrderAddress);

    useEffect(() => {
        if (
            conceptDetailsData?.placeDetails &&
            getValues('orderAddress') &&
            restaurantInfoTempQuery?.delivery?.app_id !==
                conceptDetailsData?.restaurantInfo?.delivery?.app_id
        ) {
            updateRestaurantInfoTempQuery(conceptDetailsData?.restaurantInfo);
        }
    }, [
        conceptDetailsData,
        getValues,
        segment,
        cdpLocation,
        restaurantInfoTempQuery,
        updateRestaurantInfoTempQuery,
    ]);

    const onClearAddress = () => {
        updateIsErrorFetching(false);
        updateRestaurantInfoTempQuery(null);
        reset({
            orderAddress: null,
        });
    };

    const onSubmit = (data: any) => {
        log.debug('%c ADDRESS SUBMIT DATA ', 'background-color: green', data);

        // Just return here if we have no location or it is closed for any reason
        if (locationStatus?.noLocations || !locationStatus?.isOpen) {
            return;
        }
        const { placeDetails, restaurantInfo } = conceptDetailsData;
        const sessionId = stateIds.sessionId.length > 0 ? stateIds.sessionId : generateSessionId();

        if (placeDetails && !isErrorFetching && !isConceptFetching) {
            updateOrderAddress({
                ...placeDetails,
                ...data.orderAddress,
            });
            updateOrderAddressApt(data.orderAddressApt);

            if (isAuthenticated) {
                saveAddressToUserProfile(
                    data.orderAddressApt,
                    placeDetails,
                    userProfile?.service_info ?? [],
                    getAccessTokenSilently,
                    updateUserProfile,
                );
            }
            updateConceptDetails(conceptDetailsData);
            updateStateIds({ sessionId, orderId: '', cartId: '', checkoutId: '' });
            updateCartItems([]);
            segment.startOrderCTA();
            segment.conceptSet(restaurantInfo, placeDetails, cdpLocation);
            history.push('/');
        }
    };

    if (template == null) {
        return null;
    }

    return (
        <>
            <Helmet>
                <title>{`Check if ${
                    template?.metadata?.brand?.name ?? 'restaurant'
                } delivers to your address`}</title>
                <meta name="Address Page" content="Delivery address form" />
                <link
                    rel="icon"
                    type="image/png"
                    href={template?.general?.favicon || ''}
                    sizes="32x32"
                ></link>
            </Helmet>
            <div ref={observe}>
                <Header templateName="default" />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="address-page-form">
                <Row justify="center" className="address-entry">
                    {isMobile && !formTouched && (
                        <Col span={24}>
                            {template.address_entry_page?.hero_image && (
                                <ImageWithFallback
                                    width="100%"
                                    className="mobile-hero-img"
                                    src={template.address_entry_page.hero_image}
                                    resize={800}
                                />
                            )}
                        </Col>
                    )}
                    <Col xs={24} md={8} className="address-entry-input">
                        <Row justify="center" className="address-valid">
                            {(!isMobile || !formTouched) && template.address_entry_page?.hero_copy && (
                                <h1
                                    className={`main-heading ${
                                        isIOS ? 'mobile-ios-letter-fix' : ''
                                    } ${isDinnerbell ? 'isDB' : ''}`}
                                >
                                    <ReactMarkdown>
                                        {template.address_entry_page.hero_copy}
                                    </ReactMarkdown>
                                </h1>
                            )}
                            <div className="autocomplete-box-container">
                                <Space direction="vertical" className="width-100">
                                    <>
                                        <Title
                                            level={3}
                                            className="address-entry-title condensed-txt sr-only"
                                        >
                                            Address
                                        </Title>
                                        <Controller
                                            name="orderAddress"
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <>
                                                        <label
                                                            htmlFor="orderAddress"
                                                            className="sr-only"
                                                        >
                                                            Order Address
                                                        </label>
                                                        <PlacesAutocomplete
                                                            id="orderAddress"
                                                            placeholder="Enter your delivery address"
                                                            dataTestId="address-input"
                                                            onClear={onClearAddress}
                                                            onFocus={() => setFormTouched(true)}
                                                            error={false}
                                                            // isAddressValid={!!isLocationOpen}
                                                            // isAddressFetching={
                                                            //     restaurantInfoQuery.isFetching
                                                            // }
                                                            helperText={
                                                                isOfType<FieldError>(
                                                                    formState?.errors?.orderAddress,
                                                                    'message',
                                                                )
                                                                    ? formState?.errors
                                                                          ?.orderAddress?.message
                                                                    : ''
                                                            }
                                                            {...field}
                                                            onChange={e => {
                                                                field.onChange(e);
                                                                addressPageScrollToTop();
                                                            }}
                                                            onBlur={() => {
                                                                field.onBlur();
                                                                addressPageScrollToTop();
                                                            }}
                                                        />
                                                    </>
                                                );
                                            }}
                                        />
                                    </>
                                </Space>
                            </div>
                            {isErrorFetching && (
                                <Col span={24}>
                                    <Row className="nearby-concepts-failure">
                                        Oops! Looks like there was an error on our end. Please try
                                        again soon.
                                    </Row>
                                    <Row>
                                        <Button
                                            className="retry"
                                            type="link"
                                            onClick={onClearAddress}
                                        >
                                            Retry
                                        </Button>
                                    </Row>
                                </Col>
                            )}
                            {locationStatus?.isOpen &&
                                getValues('orderAddress') &&
                                !isConceptFetching && (
                                    <Col span={24}>
                                        <Controller
                                            name="orderAddressApt"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <label
                                                        htmlFor="orderAddressApt"
                                                        className="sr-only"
                                                    >
                                                        Order Address Apartment
                                                    </label>
                                                    <Input
                                                        id="orderAddressApt"
                                                        className="mt-10 mb-20"
                                                        placeholder="Apartment/unit number"
                                                        size="large"
                                                        {...field}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Col>
                                )}
                            <Spin
                                size="default"
                                tip="Looking for restaurants..."
                                indicator={loadingIcon}
                                spinning={isConceptFetching}
                                className="address-page-spinner"
                            />
                            {formState.isValid && (
                                <Col span={24}>
                                    {locationStatus?.isOpen && !isConceptFetching && (
                                        <>
                                            <div className={isMobile ? 'flex-column' : ''}>
                                                <div className="full-flex item-center">
                                                    {!isMobile && (
                                                        <TemplateButton
                                                            type="primary"
                                                            htmlType="submit"
                                                            id="btn-start-order"
                                                            data-testid="btn-start-order"
                                                            size="large"
                                                            disabled={!locationStatus?.isOpen}
                                                        >
                                                            Start your order
                                                        </TemplateButton>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {conceptDetailsData?.restaurantInfo &&
                                        !isConceptFetching &&
                                        !locationStatus?.isOpen && (
                                            <LocationClosed
                                                restaurantInfo={conceptDetailsData?.restaurantInfo}
                                            />
                                        )}
                                    {conceptDetailsData?.restaurantInfo &&
                                        locationStatus?.noLocations &&
                                        !isConceptFetching && <NoLocations />}
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col xs={0} md={16} className="address-entry-img">
                        {template.address_entry_page?.hero_image && (
                            <ImageWithFallback
                                width="100%"
                                src={template.address_entry_page.hero_image}
                                resize={1000}
                                style={{
                                    height: `calc(100vh - ${headerHeight}px)`,
                                }}
                            />
                        )}
                    </Col>
                    {locationStatus?.isOpen && isMobile && (
                        <div className={`flex-item-center ${formTouched ? 'order-start-box' : ''}`}>
                            <TemplateButton
                                type="primary"
                                htmlType="submit"
                                id="btn-start-order"
                                data-testid="btn-start-order"
                                block={isMobile}
                                size="large"
                                className="mobile-start-order-button"
                            >
                                Start your order
                            </TemplateButton>
                        </div>
                    )}
                </Row>
            </form>
        </>
    );
};

const generateSessionId = () => Math.round(new Date().getTime() / 1000).toString();

export const AddressPage: FC = () => {
    return (
        <CdpLocationContext.Provider value="Address Entry">
            <AddressPageContent />
        </CdpLocationContext.Provider>
    );
};
