import { Button } from 'antd';
import React, { useEffect, useMemo } from 'react';
import CloseIcon from '../../../assets/img/close.svg';
import { LocationDetailsInterface } from '../../contexts/concept-context';
import {
    CartItemInterface,
    MenuManagerInterface,
    MenuModifierGroupInterface,
    MenuSectionInterface,
    matchNestedRule,
} from '../menu/model';
import { ItemList } from '../order-summary/itemlist';
import useSegment from '../utils/segment';

function deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}

const removeModItems = (updatedCartItems: CartItemInterface[], unavailableItemIds: string[]) => {
    unavailableItemIds.forEach(id => {
        updatedCartItems.forEach(item => {
            item.modifier_groups.forEach(modifier => {
                const updatedModifierItems = modifier.items.filter(modItem => {
                    if (modItem.id === id) {
                        return false;
                    }
                    return true;
                });
                modifier.items = updatedModifierItems;
            });
        });
    });
};

const removeModGroupsWithNoModItems = (updatedCartItems: CartItemInterface[]) => {
    updatedCartItems.forEach(item => {
        const updatedModifierGroups = item.modifier_groups.filter(modifier => {
            if (modifier.items.length < 1) {
                return false;
            }
            return true;
        });
        item.modifier_groups = updatedModifierGroups;
    });
};

const removeItemsThatViolateQuantityRule = (
    updatedCartItems: CartItemInterface[],
    menuInfo: MenuManagerInterface,
) => {
    return updatedCartItems.filter(item => {
        for (const modifier of item.modifier_groups) {
            const modifierIdToBeChecked = modifier.modifier_group_id;

            const modGroup = menuInfo.modifier_groups.find(
                modifier => modifier.id === modifierIdToBeChecked,
            );
            if (modGroup) {
                const quantityRule = matchNestedRule(modGroup.quantity_rules, modifier.hierarchy);
                const minAllowed = quantityRule?.min_allowed || 0;
                if (modifier.items.length < minAllowed) {
                    return false;
                }
            }
        }

        return true;
    });
};

const recalculateItemPrices = (updatedCartItems: CartItemInterface[]) => {
    for (const item of updatedCartItems) {
        const basePrice = item.base_price || 0;
        let modItemsSum = 0;
        for (const modifier of item.modifier_groups) {
            for (const modItem of modifier.items) {
                const price = matchNestedRule(modItem.price_rules, modifier.hierarchy)?.price || 0;
                modItemsSum += price;
            }
        }

        const unitPrice = basePrice + modItemsSum;
        item.item_price = unitPrice;
        item.price = item.quantity * unitPrice;
    }
};

const updateModItemsInCart = (
    updatedCartItems: CartItemInterface[],
    menuInfo: MenuManagerInterface,
    unavailableItemIds: string[],
) => {
    removeModItems(updatedCartItems, unavailableItemIds);
    updatedCartItems = removeItemsThatViolateQuantityRule(updatedCartItems, menuInfo);
    removeModGroupsWithNoModItems(updatedCartItems);
    recalculateItemPrices(updatedCartItems);

    return updatedCartItems;
};

const remove86edItemsFromMenu = (
    unavailableItemIds: string[],
    conceptDetails: LocationDetailsInterface,
    updateConceptDetails: (input: LocationDetailsInterface) => void,
) => {
    const { menuInfo } = conceptDetails;
    const updatedItems = menuInfo.items.filter(item => !unavailableItemIds.includes(item.id));

    const updatedSections: MenuSectionInterface[] = deepCopy(menuInfo.sections);
    for (const section of updatedSections) {
        section.item_ids = section.item_ids.filter(id => !unavailableItemIds.includes(id));
    }

    const updatedModifierGroups: MenuModifierGroupInterface[] = deepCopy(menuInfo.modifier_groups);
    for (const modifier of updatedModifierGroups) {
        modifier.item_ids = modifier.item_ids.filter(id => !unavailableItemIds.includes(id));
    }

    const updatedMenuInfo = {
        ...menuInfo,
        items: updatedItems,
        sections: updatedSections,
        modifier_groups: updatedModifierGroups,
    };

    updateConceptDetails({ ...conceptDetails, menuInfo: updatedMenuInfo });
};

const isBelowMinSubtotal = (
    conceptDetails: LocationDetailsInterface,
    updatedCartItems: CartItemInterface[],
) => {
    const subtotal = updatedCartItems.reduce((acc, { price }) => price + acc, 0);

    const minSubtotal = conceptDetails.restaurantInfo?.delivery?.logistics_configs[0].min_total;
    if (minSubtotal) {
        return subtotal < minSubtotal;
    }
    return false;
};

interface ItemUnavailablePopupProps {
    cartItems: CartItemInterface[];
    updateCartItems: (input: CartItemInterface[]) => void;
    conceptDetails: LocationDetailsInterface;
    updateConceptDetails: (input: LocationDetailsInterface) => void;
    unavailableItemIds: string[];
    setPopup: React.Dispatch<React.SetStateAction<boolean>>;
    setUnavailableItemIds: React.Dispatch<React.SetStateAction<string[]>>;
    setFetchDiscounts: React.Dispatch<React.SetStateAction<boolean>>;
    history: any;
}

const ItemUnavailablePopup = React.memo(
    ({
        cartItems,
        updateCartItems,
        conceptDetails,
        updateConceptDetails,
        unavailableItemIds,
        setPopup,
        setUnavailableItemIds,
        setFetchDiscounts,
        history,
    }: ItemUnavailablePopupProps) => {
        const segment = useSegment();

        const updatedCartItems = useMemo(() => {
            let updatedCartItems: CartItemInterface[] = deepCopy(cartItems);

            updatedCartItems = updatedCartItems.filter(
                item => !unavailableItemIds.includes(item.item_id),
            );

            updatedCartItems = updateModItemsInCart(
                updatedCartItems,
                conceptDetails.menuInfo,
                unavailableItemIds,
            );
            return updatedCartItems;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [unavailableItemIds]);

        useEffect(() => {
            if (unavailableItemIds.length > 0) {
                remove86edItemsFromMenu(unavailableItemIds, conceptDetails, updateConceptDetails);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [unavailableItemIds]);

        if (unavailableItemIds.length === 0) {
            return null;
        }

        const closeModal = () => {
            setPopup(false);
            setUnavailableItemIds([]);
            segment.close86ModalClicked();
        };

        const props = {
            conceptDetails,
            updatedCartItems,
            updateCartItems,
            setFetchDiscounts,
            closeModal,
            history,
        };

        return (
            <div className="item-unavailable">
                <div className="list">
                    <img
                        className="close"
                        onClick={closeModal}
                        src={CloseIcon}
                        width="15px"
                        height="15px"
                    />
                    <div className="header">We'll have to make some changes to your order</div>
                    <div className="info">
                        Some items from this order are currently unavailable.
                    </div>
                    {
                        <>
                            <ItemList
                                pageType="item-unavailable-modal"
                                cartItems={cartItems}
                                unavailableItemIds={unavailableItemIds}
                            />
                        </>
                    }
                </div>
                <div className="btn-container">
                    <ModalButtons {...props} />
                </div>
            </div>
        );
    },
);

interface ModalButtonsProps {
    conceptDetails: LocationDetailsInterface;
    updatedCartItems: CartItemInterface[];
    updateCartItems: (input: CartItemInterface[]) => void;
    setFetchDiscounts: React.Dispatch<React.SetStateAction<boolean>>;
    closeModal: () => void;
    history: any;
}

const ModalButtons = ({
    conceptDetails,
    updatedCartItems,
    updateCartItems,
    setFetchDiscounts,
    closeModal,
    history,
}: ModalButtonsProps) => {
    const segment = useSegment();
    if (updatedCartItems.length > 0) {
        return (
            <>
                {!isBelowMinSubtotal(conceptDetails, updatedCartItems) && (
                    <Button
                        className="button"
                        onClick={() => {
                            setFetchDiscounts(true);
                            updateCartItems(updatedCartItems);
                            segment.continueWithAvailableItemsClicked();
                            closeModal();
                        }}
                        data-testid="continue-with-available-items"
                    >
                        Continue with available items
                    </Button>
                )}
                <Button
                    className="button"
                    onClick={() => {
                        updateCartItems(updatedCartItems);
                        segment.updateMyOrderClicked();
                        history.push('/');
                    }}
                    data-testid="update-my-order"
                >
                    Update my Order
                </Button>
            </>
        );
    } else {
        return (
            <Button
                className="button"
                onClick={() => {
                    setFetchDiscounts(true);
                    updateCartItems(updatedCartItems);
                    history.push('/');
                }}
                data-testid="start-new-order"
            >
                Start new order
            </Button>
        );
    }
};

export default ItemUnavailablePopup;
