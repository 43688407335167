import { EnvironmentOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import React, { FC, useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import { getCurrentPage } from '../../router';
import { BottomDrawerPopup } from '../ui-components/bottom-drawer-popup';
import { TemplateButton } from '../ui-components/template-button';
import { getLocationReopenDayTime, useMobileScreen } from '../utils/general';
import './index.scss';

const { Title } = Typography;

export const NoConceptMatchedPopover: FC<NoConceptMatchedProps> = ({
    closePopoverVisible,
    unavailableLocationInfo,
}) => {
    const { noConceptPopoverVisible, userProfile, useDefaultAddress } = useContext(AppContext);
    const defaultAddress = userProfile?.service_info?.filter(
        address => address.default_address === true,
    )[0];

    const isMobile = useMobileScreen();
    const isMenuPage = getCurrentPage() === 'Menu';
    const locationInfo = getLocationReopenDayTime(unavailableLocationInfo?.reopenAt);

    const LocationUnavailableMessage = () => {
        return (
            <Title style={{ textAlign: 'center', padding: '0 8px' }} level={4}>
                Sorry, we don’t deliver to {defaultAddress?.address1} at this time
            </Title>
        );
    };

    const LocationClosedMessage = () => {
        return (
            <div className="mb-10">
                <Title style={{ textAlign: 'center', padding: '0 8px' }} level={4}>
                    Sorry, delivery to {defaultAddress?.address1} is unavailable at this time
                </Title>
                {!locationInfo?.pausedLongerThanAWeek && (
                    <Row justify="center" style={{ fontSize: '16px' }}>
                        Delivery will be available {locationInfo?.reopenDay} at{' '}
                        {locationInfo?.reopenHour}
                    </Row>
                )}
            </div>
        );
    };

    const MenuButtonGroup = () => {
        return (
            <>
                <TemplateButton
                    size="large"
                    block
                    onClick={() => {
                        // TODO: Open OrderAddressForm from address-entry-modal
                        closePopoverVisible({ action: 'Choose a new address' });
                    }}
                >
                    Choose a new address
                </TemplateButton>
                <Button
                    data-testid="see-the-menu-btn"
                    size="large"
                    block
                    style={{ marginTop: '14px' }}
                    onClick={() => {
                        closePopoverVisible({ action: 'See the Menu' });
                    }}
                >
                    See the menu
                </Button>
            </>
        );
    };

    const PopoverContent = () => {
        return (
            <Col className={isMobile ? '' : 'mt-10 mb-10'} style={{ justifyContent: 'center' }}>
                <Space className="mb-10" size={14} direction="vertical" align="center">
                    <EnvironmentOutlined style={{ fontSize: 42, color: '#BFBFBF' }} />
                    {unavailableLocationInfo?.reopenAt &&
                    unavailableLocationInfo?.reopenAt !== 'never' ? (
                        <LocationClosedMessage />
                    ) : (
                        <LocationUnavailableMessage />
                    )}
                </Space>
                {isMenuPage ? (
                    <MenuButtonGroup />
                ) : (
                    <TemplateButton
                        data-testid="choose-address-btn"
                        size="large"
                        block
                        onClick={() => {
                            closePopoverVisible({ action: 'Choose a new address' });
                        }}
                    >
                        Choose a new address
                    </TemplateButton>
                )}
            </Col>
        );
    };

    return noConceptPopoverVisible && !useDefaultAddress ? (
        <BottomDrawerPopup
            children={PopoverContent()}
            visible={noConceptPopoverVisible}
            onClose={closePopoverVisible}
            width={400}
        />
    ) : (
        <></>
    );
};

interface NoConceptMatchedProps {
    closePopoverVisible: (e: any) => void;
    unavailableLocationInfo: IUnavailableLocation | null;
}

export interface IUnavailableLocation {
    appId: string | undefined;
    locationId: string | undefined;
    reopenAt: string | undefined;
    name: string | undefined;
}
