import { Col, Space, Typography } from 'antd';
import React, { FC } from 'react';
import { TemplateButton } from '../../ui-components/template-button';
import { useMobileScreen } from '../../utils/general';
import useSegment from '../../utils/segment';

type NoAddressProp = {
    addClick: () => void;
};

export const NoAddress: FC<NoAddressProp> = ({ addClick }) => {
    const { Title } = Typography;
    const segment = useSegment();

    const handleStartOrderClick = () => {
        segment.addProfileAddressCTAClicked();
        addClick();
    };

    return (
        <Col span={24} className="address-content" data-testid="no-address">
            <Space size={12} align="center" direction="vertical">
                <Title level={useMobileScreen() ? 4 : 3}>No saved addresses</Title>
                <TemplateButton onClick={handleStartOrderClick} size="large">
                    Add new address
                </TemplateButton>
            </Space>
        </Col>
    );
};
