import axios from 'axios';

/**
 * @description Auth service for interacting with user data
 */

export type userFullData = {
    identities: {
        provider: string;
        user_id: string;
    }[];
    user_id: string;
};

export const accessTokenParams = {
    audience: `https://${process.env.AUTH0_DOMAIN}/api/v2/`,
    client_id: process.env.AUTH0_CLIENT_ID,
    scope: 'openid email profile read:current_user read:users read:users_app_metadata read:user_idp_tokens update:current_user_identities',
};

export const Auth = {
    changePassword: (userEmail = '') => {
        return axios.request({
            url: `https://${process.env.AUTH0_DOMAIN}/dbconnections/change_password`,
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            data: {
                client_id: process.env.AUTH0_CLIENT_ID,
                email: userEmail,
                connection: 'Username-Password-Authentication',
            },
        });
    },
    fetchUserAccountsData: (token: string, userSub: string): Promise<userFullData> => {
        return axios
            .request({
                url: `https://${process.env.AUTH0_DOMAIN}/api/v2/users/${userSub}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            })
            .then(({ data }) => data);
    },
    linkAccount: (newUserIdObj: object, token: string, userSub: string) => {
        return axios.request({
            url: `https://${process.env.AUTH0_DOMAIN}/api/v2/users/${userSub}/identities`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: {
                link_with: newUserIdObj,
            },
        });
    },
    disconnectAccount: (userId: string, provider: string, token: string, userSub: string) => {
        return axios.request({
            url: `https://${process.env.AUTH0_DOMAIN}/api/v2/users/${userSub}/identities/${provider}/${userId}`,
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    },
};
