import deepEqual from 'deep-equal';
import log from 'loglevel';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../contexts/app-context';
import { CdpLocationContext } from '../contexts/cdp-location-context';
import { ConceptContext } from '../contexts/concept-context';
import {
    CartItemInterface,
    MenuItemInterface,
    MenuSectionInterface,
    ModifierGroupsItemInterface,
    findEntity,
} from '../modules/menu/model';
import { itemNameToUrlPath, itemUrlPathToID } from '../modules/utils/general';
import useSegment from '../modules/utils/segment';
import { getCurrentPage } from '../router';

export const useItemDetailPopup = () => {
    const [cartIndexItem, setCartIndexItem] = useState<number>(-1);
    const [idHierarchy, setIdHierarchy] = useState<string[]>([]);
    const [isEditItem, setIsEditItem] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<MenuItemInterface>();
    const [selectedItemPrice, setSelectedItemPrice] = useState<number>();
    const [showItemDetailPopup, setShowItemDetailPopup] = useState(false);
    const [viaItemDestination, setViaItemDestination] = useState('Menu Item');
    const [itemParam, setItemParam] = useState<string>('');

    const { cartItems, updateCartItems, updateStateId } = useContext(AppContext);
    const {
        conceptDetails: { menuInfo },
    } = useContext(ConceptContext);

    const cdpLocation = useContext(CdpLocationContext);
    const history = useHistory();
    const segment = useSegment();

    useEffect(() => {
        function checkDeepLink() {
            const isItemPath = window.location.pathname.includes('/item/');
            const isItemPathIndex = window.location.pathname.lastIndexOf('/');
            if (isItemPath && isItemPathIndex > 0) {
                const itemParamFormatted = itemUrlPathToID(
                    window.location.pathname.slice(isItemPathIndex + 1),
                    menuInfo,
                );
                setItemParam(itemParamFormatted);
            } else {
                setItemParam('');
            }
        }

        if (showItemDetailPopup && selectedItem && !itemParam) {
            let pathname = '';

            if (window.location.pathname !== '/') {
                pathname = window.location.pathname;
            }
            if (!pathname.endsWith('/')) {
                pathname += '/';
            }
            window.history.pushState(
                {},
                '',
                `${pathname}item/${itemNameToUrlPath(selectedItem.title)}`,
            );
        }

        checkDeepLink();

        return history.listen(() => {
            if (showItemDetailPopup && history.action === 'POP') {
                setShowItemDetailPopup(false);
            }
        });
    }, [showItemDetailPopup, history, selectedItem, itemParam, menuInfo]);

    const handleItemDetailPopUp =
        (cdpContext?: string, cartAction?: () => void) =>
        (
            section: MenuSectionInterface,
            item: MenuItemInterface,
            idHierarchyArray: string[],
            itemPrice: number,
            via: string,
            cartIndex?: number,
            isEdit?: boolean,
        ) => {
            log.debug('%c CDP LOCATION CONTEXT ', 'background-color: red', cdpLocation);
            if (isEdit) {
                setIsEditItem(true);
                setCartIndexItem(cartIndex ?? -1);
                if (cartAction) {
                    cartAction();
                }
            } else {
                setIsEditItem(false);
                setCartIndexItem(-1);
                segment.productClicked(section, item, itemPrice);
            }
            setSelectedItem(item);
            setIdHierarchy(idHierarchyArray);
            setSelectedItemPrice(itemPrice);
            setShowItemDetailPopup(true);
            setViaItemDestination(via);
            const viewedVia = itemParam
                ? 'Deep Link'
                : cdpContext ?? cdpLocation ?? getCurrentPage();
            segment.productViewed(section, item, viewedVia, itemPrice);
        };

    const addCartItem = (
        newItemModifierGroups: ModifierGroupsItemInterface[],
        itemPrice: number,
        itemBasePrice: number,
        quantity: number,
        selectedItems: Record<string, unknown>,
        isEdit: boolean,
        itemIndex: number,
        specialInstructions: string,
        recommendedItem?: { itemIdHierarchy: string[]; item: MenuItemInterface },
    ) => {
        let newLineItem: CartItemInterface;
        const selectedItemRef = recommendedItem ? recommendedItem.item : selectedItem;
        const idHierarchyRef = recommendedItem ? recommendedItem.itemIdHierarchy : idHierarchy;

        if (selectedItemRef) {
            const itemTax = selectedItemRef.tax?.rate == null ? menuInfo.tax : selectedItemRef.tax;
            const tax_rate = (itemTax?.rate || 0) / Math.pow(10, itemTax?.precision || 0) / 100;
            const totalPrice = itemPrice * quantity;
            newLineItem = {
                item_id: selectedItemRef.id,
                name: selectedItemRef.title,
                images: selectedItemRef.images,
                description: selectedItemRef.description,
                base_price: itemBasePrice,
                item_price: itemPrice,
                price: totalPrice,
                special_instructions: specialInstructions,
                quantity,
                menu_id: idHierarchyRef[0],
                section_id: idHierarchyRef[1],
                section_name: findEntity(menuInfo, 'section', idHierarchyRef[1]).title,
                modifier_groups: newItemModifierGroups,
                tax_rate,
                idHierarchy: idHierarchyRef,
                selected_items: selectedItems,
            };

            if (localStorage.getItem('expiry') != null) {
                const existedItemIndex = cartItems.findIndex(item => {
                    return (
                        item.item_id === newLineItem.item_id &&
                        deepEqual(item.modifier_groups, newLineItem.modifier_groups)
                    );
                });
                // if it's not edit then it should be a new item. don't care about what index it is.
                const idx = existedItemIndex < 0 ? itemIndex : existedItemIndex;
                if (idx < 0) {
                    const stateIdsStr = localStorage.getItem('stateIds');
                    if (stateIdsStr && !JSON.parse(stateIdsStr).cartId) {
                        log.debug('%c CART ID ', 'color: #00a651; font-weight: bold;', stateIdsStr);
                        const cartId = Math.round(new Date().getTime() / 1000).toString();
                        updateStateId('cartId', cartId);
                    }
                    updateCartItems(cartItems?.concat(newLineItem));
                } else {
                    if (deepEqual(cartItems[idx].idHierarchy, newLineItem.idHierarchy)) {
                        const itemEditing = cartItems[idx];
                        if (isEdit) {
                            itemEditing.quantity = newLineItem.quantity;
                        } else {
                            itemEditing.quantity += newLineItem.quantity;
                        }
                        // if you're editing a cart item, you will end up here. also need to update the modifier groups
                        if (newItemModifierGroups.length > 0) {
                            itemEditing.modifier_groups = newItemModifierGroups;
                            itemEditing.selected_items = selectedItems;
                        }
                        // price & special instructions in shopping cart need to be updated as well when editing the cart
                        itemEditing.price = itemPrice * itemEditing.quantity;
                        itemEditing.item_price = itemPrice;
                        itemEditing.special_instructions = specialInstructions;
                    } else {
                        cartItems.push(newLineItem);
                    }
                    updateCartItems([...cartItems]);
                }
            }
        }
    };

    const closePopup = () => {
        if (itemParam) {
            window.history.pushState({}, '', window.location.pathname.split('/item')[0]);
        } else {
            window.history.go(-1);
        }
        setShowItemDetailPopup(false);
    };

    return {
        cartIndexItem,
        idHierarchy,
        isEditItem,
        selectedItem,
        selectedItemPrice,
        showItemDetailPopup,
        addCartItem,
        closePopup,
        handleItemDetailPopUp,
        itemParam,
        viaItemDestination,
    };
};
