import { AppBrand } from './types';

const monsterMacBrand: AppBrand = {
    app: {
        appId: 'io.nextbite.monstermac',
        appName: 'Monster Mac',
        appHost: 'monstermac.com',
    },
    styles: {
        'border-radius-base': '2px',
        'body-background': '#fff',
        'padding-md': '16px',
    },
};

export default monsterMacBrand;
