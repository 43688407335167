const getPausedAndNoFOrderMessage = (
    reopenDay: string | null | undefined,
    time: string | null | undefined,
) => {
    return `This restaurant is currently closed, but will be accepting orders again on ${reopenDay} at ${time}. Please try again later.`;
};

const getExtendedPauseAndFOrderdMessage = (futureOrderDate: string) => {
    const formatTimeStringWithBetween = futureOrderDate.replace(/[,]/, ', between ');
    return `The restaurant is currently closed, but able to accept future orders. We've defaulted you to the soonest available delivery time: ${formatTimeStringWithBetween}. You can modify this at any time.`;
};

const restaurantClosedMessageShort = 'Sorry, this location is currently closed.';

const noLocationsMessage =
    "Sorry, we don't deliver to that address at this time. Sign up for our newsletter and be the first to know when we do!";

const noLocationsMessageShort = "Sorry, we don't deliver to that address at this time.";

const locationPermanentlyClosedMessage =
    "Sorry, this location is currently closed. Share your email address with us and we'll reach out when they are accepting orders again!";

const newConceptMatchMessage =
    'Saving this new address will remove everything from your cart and restart your order.';

const enterAddressMessage = 'Please enter delivery address';

const pausedLongerThanExpectedMessage =
    'This restaurant is currently closed, please try again later.';

export {
    getPausedAndNoFOrderMessage,
    getExtendedPauseAndFOrderdMessage,
    restaurantClosedMessageShort,
    noLocationsMessage,
    noLocationsMessageShort,
    locationPermanentlyClosedMessage,
    newConceptMatchMessage,
    enterAddressMessage,
    pausedLongerThanExpectedMessage,
};
