import { CopyrightOutlined, RightOutlined } from '@ant-design/icons';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../contexts/app-context';
import './index.scss';

export const Footer: FC = () => {
    const history = useHistory();
    const { template } = useContext(AppContext);
    const brandName = template?.metadata?.brand.name;

    return (
        <>
            <span
                style={{ color: template?.general?.secondary_color || 'white', fontSize: '14px' }}
            >
                {' '}
                <CopyrightOutlined /> 2023 {brandName}™{' '}
            </span>
            <a
                className="learn-more"
                onClick={() => {
                    if (window.location.href?.toLowerCase()?.includes('tender')) {
                        history.push('/about');
                    } else {
                        history.push(`${window.location.href}about`);
                    }
                }}
                title={
                    window.location.href?.toLowerCase()?.includes('tender')
                        ? '/about'
                        : `${window.location.href}about`
                }
            >
                Learn More <RightOutlined style={{ fontStyle: 'bold' }} />
            </a>
        </>
    );
};
