import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { useItemDetailPopup } from '../../hooks/useItemDetailPopup';
import { Header } from '../layout/header';
import { Menu } from '../menu';
import { ItemDetailPopup } from '../menu/item-detail-popup';
import {
    MenuItemInterface,
    MenuManagerInterface,
    MenuSectionInterface,
    matchNestedRule,
} from '../menu/model';
import { AddressEntryModal } from '../order-address-form/address-entry-modal';
import useSegment from '../utils/segment';

const MenuPageContent = () => {
    const [headerHeight, setHeaderHeight] = useState<number>(200);
    const [isAddressModalVisible, setAddressModalVisible] = useState<boolean>(false);
    const segment = useSegment();
    const ldFlags = useFlags();
    const {
        cartIndexItem,
        idHierarchy,
        isEditItem,
        selectedItem,
        selectedItemPrice,
        showItemDetailPopup,
        addCartItem,
        closePopup,
        handleItemDetailPopUp,
        viaItemDestination,
    } = useItemDetailPopup();
    const { conceptDetails, restaurantInfoTempQuery } = useContext(ConceptContext);
    const {
        userProfile,
        orderAddress,
        updateShowAddressEntryPopover,
        showAddressEntryPopover,
        template,
    } = useContext(AppContext);

    useEffect(() => {
        if (
            !conceptDetails?.restaurantInfo &&
            !orderAddress &&
            showAddressEntryPopover &&
            ldFlags.enableLamv &&
            !userProfile
        ) {
            setAddressModalVisible(true);
        }
    }, [
        setAddressModalVisible,
        conceptDetails,
        orderAddress,
        ldFlags,
        showAddressEntryPopover,
        userProfile,
    ]);

    useEffect(() => {
        segment.pageViewed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // set dynamic-app-height when ios addressbar is active for vh changes
    const dynamicAppHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--dynamic-app-height', `${window.innerHeight}rem`);
    };
    window.addEventListener('resize', dynamicAppHeight);
    dynamicAppHeight();

    const handleItemAvailabilityModals = () => {
        const hasItemParam = location.pathname.includes('item');

        if (hasItemParam) {
            const item = location.pathname.split('/item')[1];
            closePopup();
            setAddressModalVisible(true);
            window.history.pushState({}, '', '/item' + item);
        } else {
            closePopup();
            setAddressModalVisible(true);
        }
    };

    const handleAddressClose = () => {
        const hasItemParam = location.pathname.includes('item');
        const hasStoreParam = location.pathname.includes('store');

        updateShowAddressEntryPopover(false);
        setAddressModalVisible(false);
        if (hasItemParam && !hasStoreParam) {
            window.history.pushState({}, '', '');
        }
    };

    const updateItemPopupValues = (
        selectedItem: MenuItemInterface,
        section: MenuSectionInterface,
        childMenu: MenuManagerInterface,
    ) => {
        if (!selectedItem || !section || !childMenu) {
            return;
        }
        const itemIdHierarchy = [childMenu.menus[0].id, section.id, selectedItem.id];
        const itemPrice = matchNestedRule(selectedItem.price_rules, idHierarchy)?.price || 0;
        const via = 'Menu Item';
        handleItemDetailPopUp(via)(
            section,
            selectedItem,
            itemIdHierarchy,
            itemPrice,
            via,
            -1,
            false,
        );
    };

    const findMenuItemByName = (childMenu: MenuManagerInterface) => {
        const exactItemMatch = childMenu?.items?.find(item => item?.title === selectedItem?.title);
        if (exactItemMatch) {
            for (const section of childMenu.sections) {
                for (const id of section.item_ids) {
                    if (exactItemMatch.id === id) {
                        updateItemPopupValues(exactItemMatch, section, childMenu);
                        break;
                    }
                }
            }
        } else {
            closePopup();
        }
    };

    const matchAvailabilityItemInquiry = () => {
        const childMenu = restaurantInfoTempQuery?.menuInfo;
        log.debug('Address Concept matched Child Menu', childMenu);
        log.debug('Selected Item Popup', selectedItem);

        if (!childMenu || !selectedItem) {
            return;
        }
        findMenuItemByName(childMenu);
    };

    return (
        <>
            <Helmet>
                <title>
                    {template?.general?.meta_tags?.title || 'Ordermark | Online Ordering'}
                </title>
                <meta
                    name="description"
                    content={
                        template?.general?.meta_tags?.description ||
                        'Ordermark Online Food Ordering Service'
                    }
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={template?.general?.favicon || ''}
                    sizes="32x32"
                ></link>
            </Helmet>
            <Header
                templateName="childMenuPage"
                getHeaderHeight={setHeaderHeight}
                headerHeight={headerHeight}
            />
            <Menu headerHeight={headerHeight} handleItemDetailPopUp={handleItemDetailPopUp()} />
            <ItemDetailPopup
                itemDestination={viaItemDestination}
                show={showItemDetailPopup}
                item={selectedItem!}
                basePrice={selectedItemPrice!}
                idHierarchy={idHierarchy}
                isEdit={isEditItem}
                close={closePopup}
                addCart={addCartItem}
                cartIndex={cartIndexItem}
                openAddressModal={handleItemAvailabilityModals}
            />
            <CdpLocationContext.Provider value="LAMV Address Modal">
                <AddressEntryModal
                    isModalVisible={isAddressModalVisible}
                    onCloseCallback={handleAddressClose}
                    formTitle="Enter your delivery address to check availability"
                    handleSubmit={matchAvailabilityItemInquiry}
                />
            </CdpLocationContext.Provider>
        </>
    );
};

export const MenuPage = () => (
    <CdpLocationContext.Provider value="Menu">
        <MenuPageContent />
    </CdpLocationContext.Provider>
);
