import { AppBrand } from './types';

const missMazysBrand: AppBrand = {
    app: {
        appId: 'io.nextbite.missmazys',
        appName: "Miss Mazy's Amazin' Chicken",
        appHost: 'missmazys.com',
    },
    styles: {
        'border-radius-base': '2px',
        'body-background': '#fff',
        'padding-md': '16px',
    },
};

export default missMazysBrand;
