import React, { useEffect, useRef } from 'react';

type headerPopupProps = {
    onClose: () => void;
    style: React.CSSProperties;
};
export const HeaderPopupModal: React.FC<headerPopupProps> = ({ children, onClose, style }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    // clickoutside
    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            const header = document.querySelector('.future-order-avail');

            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target as Element) &&
                !header?.contains(event.target as Element)
            ) {
                onClose();
            }
        };
        if (wrapperRef) {
            setTimeout(() => {
                document.addEventListener('click', event => handleClickOutside(event));
            }, 300);
        }

        return () => document.removeEventListener('click', event => handleClickOutside(event));
    });

    return (
        <aside style={{ ...style }} className="header-popup-modal" ref={wrapperRef}>
            {children}
        </aside>
    );
};
