import log from 'loglevel';
import moment from 'moment-timezone';
import React, { FC, createContext, useCallback, useState } from 'react';
import brand from '../../../config/brand';
import { PaymentMethod } from '../modules/checkout';
import {
    CartItemInterface,
    CustomerInfoInterface,
    DeliveryPreferenceInterface,
} from '../modules/menu/model';
import { OrderTimingType } from '../modules/restaurant/model';
import {
    getDeliveryTimeMomentObject,
    getUnexpiredValueFromLocalStorage,
    getValueFromLocalStorage,
    updateLocalStorage,
} from '../modules/utils/general';

const { APP_BRAND } = process.env;

const getCurrentHost = (): string => {
    return APP_BRAND && APP_BRAND.trim() !== '' ? brand.app.appHost : window.location.hostname;
};

type setStateBasedOnPrevStateType = (a: string[]) => string[];

export type IAppContext = {
    orderAddress: any;
    orderAddressApt: string;
    cartItems: CartItemInterface[];
    customerInfo: CustomerInfoInterface | null;
    deliveryPreference: DeliveryPreferenceInterface | null;
    template: BrandTemplate | null;
    stateIds: StateIdsProps;
    tipValue: number | null;
    selectedTipOption: number | null;
    addUtensils: boolean | null;
    promoId: string;
    vouchers: string[];
    bannerShown: boolean;
    isDinnerbell: boolean;
    futureOrderDate: FutureOrderDate | null | undefined;
    discounts: number[];
    orderPlaced: boolean;
    userProfile: IUserProfile | null;
    isWelcomeModalOpen: boolean;
    updateWelcomeModalOpen: (isOpen: boolean) => void;
    updateBannerShown: (status: boolean) => void;
    updateStateId: (name: string, value: string) => void;
    updateOrderAddress: (input: any) => void;
    updateOrderAddressApt: (input: string | null) => void;
    updateCartItems: (input: CartItemInterface[]) => void;
    updateCustomerInfo: (input: CustomerInfoInterface | null) => void;
    updateDeliveryPreference: (input: DeliveryPreferenceInterface | null) => void;
    updateTemplate: (input: any) => void;
    updateStateIds: (input: StateIdsProps) => void;
    updateTipValue: (input: number) => void;
    updateAddUtensils: (input: boolean) => void;
    updateFutureOrderDate: (input: FutureOrderDate | null) => void;
    updatePromoId: (input: string) => void;
    updateVouchers: (input: string[] | setStateBasedOnPrevStateType) => void;
    updateDiscounts: (input: any) => void;
    updateOrderPlaced: (orderPlaced: boolean) => void;
    updateSelectedTipOption: (input: number) => void;
    paymentMethod: any;
    setPaymentMethod: any;
    updateIsDinnerbell: (input: boolean) => void;
    brandUrl: string;
    updateBrandUrl: (input: string) => void;
    updateOrderTiming: (input: OrderTimingType) => void;
    orderTiming: OrderTimingType | null;
    showSessionExpiredModal: boolean;
    updateShowSessionExpiredModal: (show: boolean) => void;
    orderTotal: number | null;
    updateOrderTotal: (input: number) => void;
    updateUserProfile: (input: IUserProfile | null) => void;
    updateUseDefaultAddress: (value: boolean | null) => void;
    useDefaultAddress: boolean | null;
    clearAddressAndCart: () => void;
    noConceptPopoverVisible: boolean;
    updateNoConceptPopoverVisible: (value: boolean) => void;
    showAddressEntryPopover: boolean;
    updateShowAddressEntryPopover: (value: boolean) => void;
    handlingOrder: boolean;
    updateHandlingOrder: (value: boolean) => void;
    orderTotalIsFetching: boolean;
    updateOrderTotalIsFetching: (value: boolean) => void;
    orderPayload: null;
    updateOrderPayload: (value: any) => void;
};

export interface UserProfileAddress {
    id?: string;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    region?: string | null;
    postalcode?: string | null;
    country?: string | null;
    phone_number?: string | null;
    default_address?: boolean;
    method?: string;
    special_instructions?: string;
    delivery_preference?: string;
}

export interface IUserProfile {
    email: string;
    user_id: string;
    name?: string | null;
    nickname?: string | null;
    phone_number?: string | null;
    service_info?: UserProfileAddress[] | null;
    user_type?: string | null;
    date_created?: string | null;
    last_login_date?: string | null;
    first_order_date?: string | null;
    last_order_date?: string | null;
    last_updated_date?: string | null;
    linked_socials?: string[] | null;
    brands?: string[] | null;
}

export interface StateIdsProps {
    orderId: string;
    sessionId: string;
    cartId: string;
    checkoutId: string;
}

export interface FutureOrderDate {
    date: moment.Moment;
    time: {
        startTime: { hr: number; min: number };
        endTime: { hr: number; min: number };
    };
}

export interface BrandTemplate {
    url: string;
    metadata: {
        brand: {
            name: string;
            id: string;
            version: string;
        };
    };
    future_order: {
        supported_order_type: {
            asap: boolean;
            scheduled?: boolean;
        };
        delivery_buffer?: number;
        delivery_window_duration?: number;
        delivery_blackout_duration?: number;
        delivery_limit?: number;
    };
    general: {
        hero_image: string;
        primary_color: string;
        secondary_color: string;
        custom_header_font: string;
        custom_body_font: string;
        favicon?: string;
        social_media: {
            facebook: string;
            twitter: string;
            instagram: string;
        };
        logo: {
            website_logo: string;
            email_logo: string;
        };
        legal: {
            terms_conditions_link: string;
            privacy_policy_link: string;
        };
        support: {
            customer_service_number: string;
            customer_service_sms: string;
            customer_service_email: string;
            faq_link: string;
        };
        service_fee?: number;
        meta_tags: {
            title: string;
            description: string;
        };
        disclaimers: {
            title: string;
            description: string;
        };
    };
    banners: {
        global: {
            banner_content: string;
            body_color: string;
            font_color: string;
            show_banner_pages: {
                address_entry_page?: boolean;
                menu_page?: boolean;
                order_confirmation_page?: boolean;
                checkout_page?: boolean;
            };
        };
        promo: {
            body_color: string;
            border_color: string;
            font_color: string;
        };
        email_capture: {
            header_text: string;
            body: string;
        };
    };
    about_us_page: {
        image: string;
        title: string;
        copy: string;
    };
    address_entry_page: {
        hero_image: string;
        hero_copy: string;
        address_match_image: string;
    };
    menu_page: {
        show_parent_pricing: boolean;
        hero_image: string;
        hero_content_block: {
            title: string;
            image_description: string;
            image: string;
            alt_tag: string;
        };
        left_image_content_block: {
            title: string;
            image_description: string;
            image: string;
            alt_tag: string;
        };
        right_image_content_block: {
            title: string;
            image_description: string;
            image: string;
            alt_tag: string;
        };
    };
    order_confirmation_page: {
        hero_image: string;
        title_copy: string;
    };
    checkout_page: {
        show_delivery_preference_ui: boolean;
        show_tip_ui: boolean;
        // TODO: Utilize this in stripe-integration-form.tsx
        payment_processing_steps: {
            1: {
                title: string;
                image: string;
            };
            2: {
                title: string;
                image: string;
            };
            3: {
                title: string;
                image: string;
            };
        };
    };
}

const defaultValue = {
    orderAddress: null,
    userProfile: null,
    useDefaultAddress: null,
    orderAddressApt: '',
    orderPlaced: false,
    cartItems: [],
    customerInfo: null,
    isWelcomeModalOpen: false,
    deliveryPreference: {
        preference: 'Meet the driver at the door',
        text: '',
    },
    template: null,
    stateIds: {
        orderId: '',
        sessionId: '',
        cartId: '',
        checkoutId: '',
    },
    orderTiming: null,
    tipValue: null,
    addUtensils: false,
    promoId: '',
    vouchers: [],
    bannerShown: false,
    futureOrderDate: null,
    showAddressEntryPopover: true,
    isDinnerbell: false,
    discounts: [],
    selectedTipOption: null,
    updateBannerShown: () => null,
    updateStateId: () => null,
    updateOrderAddress: () => null,
    updateOrderAddressApt: () => null,
    updateCartItems: () => null,
    updateCustomerInfo: () => null,
    updateDeliveryPreference: () => null,
    updateTemplate: () => null,
    updateStateIds: () => null,
    updateTipValue: () => null,
    updateAddUtensils: () => null,
    paymentMethod: null,
    setPaymentMethod: () => null,
    updatePromoId: () => null,
    updateVouchers: () => null,
    updateDiscounts: () => null,
    updateFutureOrderDate: () => null,
    updateIsDinnerbell: () => null,
    updateOrderPlaced: () => null,
    updateSelectedTipOption: () => null,
    brandUrl: getCurrentHost(),
    updateBrandUrl: () => {
        log.debug('updateBrandUrl default');
    },
    updateOrderTiming: () => null,
    showSessionExpiredModal: false,
    updateShowSessionExpiredModal: () => null,
    orderTotal: null,
    updateOrderTotal: () => null,
    updateUserProfile: () => null,
    updateUseDefaultAddress: () => null,
    updateWelcomeModalOpen: () => null,
    clearAddressAndCart: () => null,
    noConceptPopoverVisible: false,
    updateNoConceptPopoverVisible: () => null,
    updateShowAddressEntryPopover: () => null,
    updateHandlingOrder: () => null,
    handlingOrder: false,
    orderTotalIsFetching: false,
    updateOrderTotalIsFetching: () => null,
    orderPayload: null,
    updateOrderPayload: () => null,
};

export const AppContext = createContext<IAppContext>(defaultValue);

export const AppContextProvider: FC = (props: any) => {
    const [brandUrl, setBrandUrl] = useState<string>(
        () => getValueFromLocalStorage('brandUrl') ?? defaultValue.brandUrl,
    );
    const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState<boolean>(
        defaultValue.isWelcomeModalOpen,
    );
    const [orderTotalIsFetching, setOrderTotalIsFetching] = useState<boolean>(
        defaultValue.orderTotalIsFetching,
    );
    const [userProfile, setUserProfile] = useState<IUserProfile | null>(
        () => getValueFromLocalStorage('userProfile') ?? defaultValue.userProfile,
    );

    // Removed orderAddress from session expiry filter.
    // TODO: check the intended behavior with Tami/Remi
    const [orderAddress, setOrderAddress] = useState<any>(
        () => getUnexpiredValueFromLocalStorage('orderAddress') ?? defaultValue.orderAddress,
    );

    const [orderAddressApt, setOrderAddressApt] = useState<string>(
        () =>
            getUnexpiredValueFromLocalStorage('orderAddressApt')?.toString() ??
            defaultValue.orderAddressApt,
    );
    const [cartItems, setCartItems] = useState<CartItemInterface[]>(
        () => getUnexpiredValueFromLocalStorage('cartItems') ?? defaultValue.cartItems,
    );
    const [customerInfo, setCustomerInfo] = useState(
        () => getUnexpiredValueFromLocalStorage('customerInfo') ?? defaultValue.customerInfo,
    );
    const [deliveryPreference, setDeliveryPreference] = useState(
        () =>
            getUnexpiredValueFromLocalStorage('deliveryPreference') ??
            defaultValue.deliveryPreference,
    );
    const [template, setTemplate] = useState<BrandTemplate | null>(() => {
        const initialTemplate =
            getUnexpiredValueFromLocalStorage('template') ?? defaultValue.template;
        log.debug('%c INITIAL TEMPLATE', 'background-color: red', initialTemplate);
        return initialTemplate;
    });
    const [stateIds, setStateIds] = useState<StateIdsProps>(
        () => getUnexpiredValueFromLocalStorage('stateIds') ?? defaultValue.stateIds,
    );
    const [tipValue, setTipValue] = useState<number | null>(
        () => getUnexpiredValueFromLocalStorage('tipValue') ?? defaultValue.tipValue,
    );
    const [addUtensils, setAddUtensils] = useState<boolean | null>(() => {
        return getUnexpiredValueFromLocalStorage('addUtensils') ?? defaultValue.addUtensils;
    });
    const [handlingOrder, setHandlingOrder] = useState<boolean>(() => {
        return getUnexpiredValueFromLocalStorage('handlingOrder') ?? defaultValue.handlingOrder;
    });
    const [promoId, setPromoId] = useState<string>('');
    const [vouchers, setVouchers] = useState<string[]>([]);
    const [bannerShown, setBannerShown] = useState<boolean>(false);
    const [discounts, setDiscounts] = useState<number[]>([]);
    const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.CreditCard);
    const [isDinnerbell, setIsDinnerbell] = useState<boolean>(false);
    const [futureOrderDate, setFutureOrderDate] = useState<FutureOrderDate | null | undefined>(
        () => {
            const deliveryTimeObject =
                getUnexpiredValueFromLocalStorage('futureOrderDate') ||
                defaultValue.futureOrderDate;
            return getDeliveryTimeMomentObject(deliveryTimeObject);
        },
    );
    const [orderPlaced, setOrderPlaced] = useState<boolean>(
        () => getUnexpiredValueFromLocalStorage('orderPlaced') ?? defaultValue.orderPlaced,
    );
    const [selectedTipOption, setSelectedTipOption] = useState<number | null>(null);
    const [orderTiming, setOrderTiming] = useState<OrderTimingType | null>(
        () => getUnexpiredValueFromLocalStorage('orderTiming') ?? defaultValue.orderTiming,
    );
    const [showSessionExpiredModal, setShowSessionExpiredModal] = useState<boolean>(false);
    const [orderTotal, setOrderTotal] = useState<number | null>(null);
    const [useDefaultAddress, setUseDefaulAddress] = useState<boolean | null>(
        getUnexpiredValueFromLocalStorage('useDefaultAddress') ?? defaultValue.useDefaultAddress,
    );
    const [noConceptPopoverVisible, setNoConceptPopoverVisible] = useState<boolean>(
        getUnexpiredValueFromLocalStorage('useDefaultAddress') ?? defaultValue.useDefaultAddress,
    );
    const [showAddressEntryPopover, setShowAddressEntryPopover] = useState<boolean>(
        getUnexpiredValueFromLocalStorage('showAddressEntry') ??
            defaultValue.showAddressEntryPopover,
    );
    const [orderPayload, setOrderPayload] = useState(null);

    // These functions update localStorage when state in context change.
    const updateShowAddressEntryPopover = useCallback((value: boolean) => {
        setShowAddressEntryPopover(value);
        updateLocalStorage('showAddressEntry', value);
    }, []);

    const updateWelcomeModalOpen = useCallback((value: boolean) => {
        setIsWelcomeModalOpen(value);
    }, []);

    const updateSelectedTipOption = useCallback((value: number | null) => {
        setSelectedTipOption(value);
    }, []);

    const updateOrderTiming = useCallback((newOrderTiming: OrderTimingType) => {
        setOrderTiming(newOrderTiming);
        updateLocalStorage('orderTiming', newOrderTiming);
    }, []);

    const updateOrderPlaced = useCallback((confirmation: boolean) => {
        setOrderPlaced(confirmation);
        updateLocalStorage('orderPlaced', confirmation);
    }, []);

    const updateOrderAddress = useCallback((newOrderAddress: any) => {
        setOrderAddress(newOrderAddress);
        updateLocalStorage('orderAddress', newOrderAddress);
    }, []);

    const updateOrderAddressApt = useCallback((newOrderAddressApt: string | null) => {
        setOrderAddressApt(newOrderAddressApt ?? '');
        updateLocalStorage('orderAddressApt', newOrderAddressApt);
    }, []);

    const updateDeliveryPreference = useCallback(
        (newDeliveryPreference: DeliveryPreferenceInterface | null) => {
            log.debug(
                '%c updateDeliveryPreference ',
                'background-color: green',
                newDeliveryPreference,
            );
            setDeliveryPreference(newDeliveryPreference);
            updateLocalStorage('deliveryPreference', newDeliveryPreference);
        },
        [],
    );

    const updateTemplate = useCallback((newTemplate: BrandTemplate) => {
        setTemplate(newTemplate);
        updateLocalStorage('template', newTemplate);
    }, []);

    const updateCustomerInfo = useCallback((newCustomerInfo: CustomerInfoInterface | null) => {
        setCustomerInfo(newCustomerInfo);
        updateLocalStorage('customerInfo', newCustomerInfo);
    }, []);

    const updateCartItems = useCallback((newCartItems: CartItemInterface[]) => {
        setCartItems(newCartItems);
        updateLocalStorage('cartItems', newCartItems);
    }, []);

    const updateAddUtensils = useCallback((newAddUtensils: boolean) => {
        setAddUtensils(newAddUtensils);
        updateLocalStorage('addUtensils', newAddUtensils);
    }, []);

    const updateTipValue = useCallback((newTipValue: number | null) => {
        setTipValue(newTipValue);
        updateLocalStorage('tipValue', newTipValue);
    }, []);

    const updateStateId = useCallback((name: string, value: string) => {
        setStateIds(prev => {
            const newId = { ...prev, [name]: value };
            localStorage.setItem('stateIds', JSON.stringify(newId));
            return newId;
        });
    }, []);

    const updateBrandUrl = useCallback((newBrandUrl: string) => {
        log.debug('%c updateBrandUrl ', 'background-color: red', newBrandUrl);
        setBrandUrl(newBrandUrl);
        updateLocalStorage('brandUrl', newBrandUrl);
    }, []);

    const updateStateIds = useCallback((newStateIds: StateIdsProps) => {
        setStateIds(newStateIds);
        updateLocalStorage('stateIds', newStateIds);
    }, []);

    const updateFutureOrderDate = useCallback(
        (newFutureOrderDate: FutureOrderDate | null | undefined) => {
            setFutureOrderDate(newFutureOrderDate);
            updateLocalStorage('futureOrderDate', newFutureOrderDate);
        },
        [],
    );

    const updateUserProfile = useCallback((newUserProfile: IUserProfile | null) => {
        setUserProfile(newUserProfile);
        updateLocalStorage('userProfile', newUserProfile);
    }, []);

    const updateUseDefaultAddress = useCallback((value: boolean | null) => {
        updateLocalStorage('useDefaultAddress', value);
        setUseDefaulAddress(value);
    }, []);

    const updateHandlingOrder = useCallback((value: boolean) => {
        updateLocalStorage('handlingOrder', value);
        setHandlingOrder(value);
    }, []);

    const updateOrderTotalIsFetching = useCallback((value: boolean) => {
        setOrderTotalIsFetching(value);
    }, []);

    const clearAddressAndCart = useCallback(() => {
        updateOrderAddress(defaultValue.orderAddress);
        updateOrderAddressApt(defaultValue.orderAddressApt);
        updateTipValue(defaultValue.tipValue);
        updateAddUtensils(defaultValue.addUtensils);
        updateCartItems(defaultValue.cartItems);
        updateDeliveryPreference(defaultValue.deliveryPreference);
        updateFutureOrderDate(defaultValue.futureOrderDate);
        updateStateIds({ sessionId: '', orderId: '', cartId: '', checkoutId: '' });
        updateHandlingOrder(defaultValue.handlingOrder);
    }, [
        updateOrderAddress,
        updateOrderAddressApt,
        updateTipValue,
        updateAddUtensils,
        updateCartItems,
        updateDeliveryPreference,
        updateFutureOrderDate,
        updateStateIds,
        updateHandlingOrder,
    ]);

    const appContextValue = {
        selectedTipOption,
        orderAddress,
        orderAddressApt,
        cartItems,
        customerInfo,
        deliveryPreference,
        template,
        stateIds,
        tipValue,
        addUtensils,
        promoId,
        vouchers,
        bannerShown,
        futureOrderDate,
        isDinnerbell,
        discounts,
        orderPlaced,
        isWelcomeModalOpen,
        updateBannerShown: setBannerShown,
        updateOrderAddress,
        updateOrderAddressApt,
        updateCartItems,
        updateCustomerInfo,
        updateDeliveryPreference,
        updateTemplate,
        updateStateId,
        updateStateIds,
        updateTipValue,
        updateAddUtensils,
        updateSelectedTipOption,
        clearAddressAndCart,
        paymentMethod,
        setPaymentMethod,
        updateAllStateIds: setStateIds,
        updatePromoId: setPromoId,
        updateVouchers: setVouchers,
        updateDiscounts: setDiscounts,
        updateIsDinnerbell: setIsDinnerbell,
        updateFutureOrderDate,
        updateOrderPlaced,
        brandUrl,
        updateBrandUrl,
        orderTiming,
        updateOrderTiming,
        showSessionExpiredModal,
        updateShowSessionExpiredModal: setShowSessionExpiredModal,
        orderTotal,
        updateOrderTotal: setOrderTotal,
        userProfile,
        updateUserProfile,
        useDefaultAddress,
        updateUseDefaultAddress,
        updateWelcomeModalOpen,
        noConceptPopoverVisible,
        updateNoConceptPopoverVisible: setNoConceptPopoverVisible,
        updateShowAddressEntryPopover,
        showAddressEntryPopover,
        handlingOrder,
        updateHandlingOrder,
        orderTotalIsFetching,
        updateOrderTotalIsFetching,
        orderPayload,
        updateOrderPayload: setOrderPayload,
    };

    return <AppContext.Provider value={appContextValue}>{props.children}</AppContext.Provider>;
};
