import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Collapse, Drawer, Form } from 'antd';
import log from 'loglevel';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import { ConceptContext } from '../../contexts/concept-context';
import { hasContactInfoComplete, saveContactInfoToUserProfile } from '../utils/contact-info';
import { useMobileScreen } from '../utils/general';
import { digitOnlyPhoneNumberFormat, formatPhoneNumber } from '../utils/phone';
import useSegment from '../utils/segment';
import { ContactForm } from './contact-form';
import { CheckoutForms } from './index';

const { Panel } = Collapse;

interface CIFormProps {
    isOpen: boolean;
    editButtonDisabled: boolean;
    contactFormAlternateView: boolean;
    contactFormRef: any;
    onClickContinue: () => void;
    onEdit: (key: CheckoutForms) => void;
    onCancelEdit: (key: CheckoutForms) => void;
}

export const CustomerInformationForm: FC<CIFormProps> = ({
    editButtonDisabled,
    onClickContinue,
    onEdit,
    onCancelEdit,
    contactFormRef,
    contactFormAlternateView,
}) => {
    const {
        customerInfo,
        isDinnerbell,
        template,
        updateCustomerInfo,
        userProfile,
        updateUserProfile,
    } = useContext(AppContext);
    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);
    const [form] = Form.useForm();
    const isMobile = useMobileScreen();
    const segment = useSegment();
    const { getAccessTokenSilently } = useAuth0();
    const hasContactInfo = hasContactInfoComplete(customerInfo);

    const [buttonVis, setButtonVis] = useState(!!hasContactInfo);
    const [expand, setExpand] = useState(() => {
        if (!contactFormAlternateView) {
            onClickContinue();
            return false;
        }
        return true;
    });

    log.debug('%c CUSTOMER INFO ', 'background-color: green', customerInfo);

    useEffect(() => {
        if (!customerInfo) {
            form.resetFields();
        }
    }, [customerInfo, form]);

    if (restaurantInfo == null || restaurantInfo.delivery == null || template == null) {
        return null;
    }

    const onCancel = () => {
        setExpand(false);
        form.setFieldsValue({
            ci_name: customerInfo?.name,
            ci_email: customerInfo?.email,
            ci_phonenumber: formatPhoneNumber(customerInfo?.phonenumber),
        });
        onClickContinue();
        onCancelEdit(CheckoutForms.CustomerInformation);
    };

    const onFinish = (values: any) => {
        saveContactInfoToUserProfile(
            values,
            userProfile,
            getAccessTokenSilently,
            updateUserProfile,
            segment,
        );
        values.ci_name = values.ci_name.trim();
        values.ci_phonenumber = digitOnlyPhoneNumberFormat(values.ci_phonenumber);

        updateCustomerInfo({
            email: values.ci_email,
            name: values.ci_name,
            phonenumber: values.ci_phonenumber,
        });

        segment.emailCapturedCheckout(values.ci_email);
        segment.updateCTAClicked('Update contact info');
        segment.contactInfoIdentify(values.ci_name, values.ci_email, values.ci_phonenumber);
        segment.contactInfoCheckoutStepCompleted(
            values.ci_name,
            values.ci_email,
            values.ci_phonenumber,
        );
        setExpand(false);
        setButtonVis(true);
        onClickContinue();
    };

    const toggleExpand = (event: any) => {
        event.persist();
        if (buttonVis) {
            if (expand) {
                onCancel();
            } else {
                segment.checkoutStepViewed('Contact Info');
                segment.checkoutEditContactInfoLinkClicked();
                setExpand(true);
                onEdit(CheckoutForms.CustomerInformation);
            }
        }
    };

    const EditBtn = () => {
        return buttonVis && !contactFormAlternateView ? (
            <Button
                type="link"
                onClick={event => toggleExpand(event)}
                data-testid="contact-information-edit-btn"
                className={`checkout-edit-link ${editButtonDisabled ? 'disabled-button mt-5' : ''}`}
                disabled={editButtonDisabled}
            >
                Edit
            </Button>
        ) : (
            <></>
        );
    };

    const iconColor = isDinnerbell ? '#f1c143' : '#bbb';
    const iconStyles = {
        fontSize: '20px',
        color: iconColor,
    };

    return (
        <Collapse
            bordered={false}
            expandIconPosition={'right'}
            expandIcon={EditBtn}
            activeKey={expand ? '3' : '0'}
        >
            <Panel
                header={
                    <div className="checkout-ci-title" onClick={event => toggleExpand(event)}>
                        {hasContactInfo && !expand && <UserOutlined style={iconStyles} />}
                        <div>
                            <span
                                className={`checkout-subtext ${expand ? 'expanded-view' : ''} ${
                                    !buttonVis ? 'edit-btn-hidden' : ''
                                }`}
                            >
                                {!expand && (
                                    <span data-testid="customer-name">{customerInfo?.name}</span>
                                )}
                            </span>
                        </div>
                    </div>
                }
                key="3"
            >
                {contactFormAlternateView && (
                    <ContactForm
                        form={form}
                        onCancel={onCancel}
                        onFinish={onFinish}
                        contactFormAlternateView={contactFormAlternateView}
                        contactFormRef={contactFormRef}
                    />
                )}
                {!contactFormAlternateView &&
                    (isMobile ? (
                        <Drawer
                            className="checkout-edit-drawer"
                            closable={true}
                            closeIcon={<ArrowLeftOutlined />}
                            onClose={onCancel}
                            placement="right"
                            title="Contact Information"
                            width="100%"
                            visible={expand}
                        >
                            <ContactForm
                                form={form}
                                onCancel={onCancel}
                                onFinish={onFinish}
                                contactFormAlternateView={contactFormAlternateView}
                                contactFormRef={contactFormRef}
                            />
                        </Drawer>
                    ) : (
                        <ContactForm
                            form={form}
                            onCancel={onCancel}
                            onFinish={onFinish}
                            contactFormAlternateView={contactFormAlternateView}
                            contactFormRef={contactFormRef}
                        />
                    ))}
            </Panel>
        </Collapse>
    );
};
