import { QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Row, Space, Tooltip, Typography } from 'antd';
import React, { FC } from 'react';
import { formatMoneyInteger } from '../../utils/general';
import { CSummaryProps } from './models';

const { Text } = Typography;

export const CartSummary: FC<CSummaryProps> = ({ subtotalInfo, refundDetails }) => {
    return (
        <Space size={4} direction="vertical" className="order-summary-breakdown width-100">
            <Row justify="space-between">
                <Col>Subtotal:</Col>
                <Col>{formatMoneyInteger(subtotalInfo.items_subtotal)}</Col>
            </Row>

            <Row justify="space-between">
                <Text>
                    {'Delivery Fee  '}
                    <Tooltip
                        placement="right"
                        title="Delivery fee varies based on your location and other factors."
                    >
                        <QuestionCircleOutlined />
                    </Tooltip>
                    &nbsp;:
                </Text>
                <Text style={{ color: '#047857' }}>
                    {getSurchargeFee('delivery', subtotalInfo.surcharges)}
                </Text>
            </Row>
            <Row justify="space-between">
                <Text>
                    {'Service Fee '}
                    <Tooltip
                        placement="right"
                        title="Helps cover costs including: credit card fees, support, and much more."
                    >
                        <QuestionCircleOutlined />
                    </Tooltip>
                    :
                </Text>
                <Text style={{ color: '#047857' }}>
                    {getSurchargeFee('service_fee', subtotalInfo.surcharges)}
                </Text>
            </Row>
            <Row justify="space-between">
                <Text>
                    {'Driver Tip '}
                    <Tooltip placement="right" title="100% of the tip goes to your driver.">
                        <QuestionCircleOutlined />
                    </Tooltip>
                    :
                </Text>
                <Text>{getSurchargeFee('tip', subtotalInfo.surcharges)}</Text>
            </Row>
            <Row justify="space-between">
                <Text>Taxes:</Text>
                <Text>{getSurchargeFee('tax', subtotalInfo.taxes)}</Text>
            </Row>

            {subtotalInfo.discounts.length > 0 && (
                <Row justify="space-between">
                    <Col>Discounts:</Col>
                    <Text style={{ color: '#047857' }}>{`-${getSurchargeFee(
                        'discounts',
                        subtotalInfo.discounts,
                    )}`}</Text>
                </Row>
            )}
            <Row justify="space-between">
                <h2 className="mb-0">Total:</h2>
                <h2 className="mb-0">{formatMoneyInteger(subtotalInfo.total)}</h2>
            </Row>

            {refundDetails && (
                <Row justify="space-between">
                    <h2 className="mb-0">Updated total:</h2>
                    <h2 className="mb-0" style={{ color: '#047857' }}>
                        {formatMoneyInteger(subtotalInfo.total - refundDetails.amount)}
                    </h2>
                </Row>
            )}
        </Space>
    );
};

const getSurchargeFee = (feeType: string, chargeArray: any[]) => {
    if (feeType === 'discounts') {
        return formatMoneyInteger(chargeArray[0].amount);
    }
    if (chargeArray.length > 0) {
        const charge = chargeArray.find((item: any) => item.description === feeType);
        const feeAmount = charge?.amount || 0;
        return feeAmount > 0 || feeType === 'tax' ? formatMoneyInteger(feeAmount) : 'Free';
    } else {
        return 'Free';
    }
};
