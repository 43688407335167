import log from 'loglevel';
import React, { FC, createContext, useCallback, useContext, useState } from 'react';
import { getValueFromLocalStorage, updateLocalStorage } from '../../ts/modules/utils/general';
import { AppContext } from '../contexts/app-context';
import { MenuManagerInterface } from '../modules/menu/model';
import { LocationMatchResponse } from '../modules/restaurant/model';

export interface LocationDetailsInterface {
    menuInfo: MenuManagerInterface;
    placeDetails: any;
    restaurantInfo: LocationMatchResponse | null;
    locationStatus: LocationStatus | null;
}

export interface LocationStatus {
    isOpen: boolean | undefined;
    isNewConcept: boolean | undefined;
    noLocations: boolean | undefined;
}

interface ConceptInterface {
    isErrorFetching: boolean;
    isConceptFetching: boolean;
    conceptDetails: LocationDetailsInterface;
    updateConceptDetails: (input: LocationDetailsInterface) => void;
    updateIsConceptFetching: (input: boolean) => void;
    updateIsErrorFetching: (input: boolean) => void;
    restaurantInfoTempQuery: LocationConceptMatch | null;
    updateRestaurantInfoTempQuery: (input: LocationConceptMatch | null) => void;
    clearAddressAndCartSession: () => void;
}

interface LocationConceptMatch extends LocationMatchResponse {
    menuInfo?: MenuManagerInterface;
}

export const ConceptContextInitValue = {
    conceptDetails: {
        menuInfo: {
            app_id: null,
            tax: {
                rate: 0,
                precision: 0,
            },
            menus: [],
            sections: [],
            items: [],
            modifier_groups: [],
        },
        restaurantInfo: null,
        placeDetails: null,
        locationStatus: null,
    },
    isConceptFetching: false,
    isErrorFetching: false,
    restaurantInfoTempQuery: null,
    updateRestaurantInfoTempQuery: () => null,
    updateConceptDetails: () => null,
    updateIsConceptFetching: () => null,
    updateIsErrorFetching: () => null,
    clearAddressAndCartSession: () => null,
};

export const ConceptContext = createContext<ConceptInterface>(ConceptContextInitValue);

export const ConceptContextProvider: FC = ({ children }) => {
    const { clearAddressAndCart } = useContext(AppContext);
    const [conceptDetails, setConceptDetails] = useState<LocationDetailsInterface>(() => {
        return getValueFromLocalStorage('conceptDetails') || ConceptContextInitValue.conceptDetails;
    });
    const [restaurantInfoTempQuery, setRestaurantInfoTempQuery] =
        useState<LocationConceptMatch | null>(
            ConceptContextInitValue.conceptDetails.locationStatus,
        );
    const [isErrorFetching, setIsErrorFetching] = useState<boolean>(
        ConceptContextInitValue.isErrorFetching,
    );
    const [isConceptFetching, setIsConceptFetching] = useState<boolean>(
        ConceptContextInitValue.isConceptFetching,
    );

    const updateConceptDetails = useCallback(
        (newConceptDetails: LocationDetailsInterface) => {
            setConceptDetails(newConceptDetails);
            updateLocalStorage('conceptDetails', newConceptDetails);
        },
        [setConceptDetails],
    );

    const clearAddressAndCartSession = useCallback(() => {
        clearAddressAndCart();
        localStorage.removeItem('expiry');
        updateConceptDetails(ConceptContextInitValue.conceptDetails);
    }, [updateConceptDetails, clearAddressAndCart]);

    const ConceptContextValue = {
        conceptDetails,
        updateConceptDetails,
        isErrorFetching,
        isConceptFetching,
        restaurantInfoTempQuery,
        updateRestaurantInfoTempQuery: setRestaurantInfoTempQuery,
        updateIsErrorFetching: setIsErrorFetching,
        updateIsConceptFetching: setIsConceptFetching,
        clearAddressAndCartSession,
    };
    log.debug('%c FETCH CONCEPT CONTEXT VALUES', 'background-color: blue', ConceptContextValue);

    return (
        <ConceptContext.Provider value={ConceptContextValue}>{children}</ConceptContext.Provider>
    );
};
