import { CloseOutlined } from '@ant-design/icons';
import { Button, Row, Typography } from 'antd';
import classnames from 'classnames/bind';
import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/app-context';
import { CdpLocationContext } from '../../../contexts/cdp-location-context';
import { ConceptContext } from '../../../contexts/concept-context';
import { useFutureOrder } from '../../../hooks/useFutureOrder';
import { AddressFormSteps, OrderAddressForm } from '../../order-address-form';
import { BottomDrawerPopup } from '../../ui-components/bottom-drawer-popup';
import { FutureOrderPopup } from '../../ui-components/future-order-components/future-order-popup';
import '../../../modules/ui-components/future-order-components/future-order-popup.scss';
import { TemplateButton } from '../../ui-components/template-button';
import useSegment from '../../utils/segment';
import styles from './mobile-header-content.module.scss';

type MobileHeaderProps = {
    isAccountDropdownOpen?: boolean | undefined;
};

export const MobileHeaderContentLamvEnabled: FC<MobileHeaderProps> = ({
    isAccountDropdownOpen,
}) => {
    const { Text } = Typography;
    const segment = useSegment();
    const ldFlags = useFlags();
    const cx = classnames.bind(styles);

    const cdpLocation = useContext(CdpLocationContext);
    const { orderAddress, userProfile, template } = useContext(AppContext);
    const { updateRestaurantInfoTempQuery, restaurantInfoTempQuery } = useContext(ConceptContext);
    const {
        deliveryTime,
        setDeliveryTime,
        isFutureOrderPopoverVisible,
        toggleFutureOrderPopover,
        isAddressPopoverVisible,
        setIsAddressPopoverVisible,
    } = useFutureOrder();

    const primaryColor = template?.general?.primary_color;
    const secondaryColor = template?.general?.secondary_color;

    const [expand, setExpand] = useState<boolean>(false);
    const [allowFutureOrderEdit, setAllowFutureOrderEdit] = useState<boolean>(true);
    const [currentStep, setCurrentStep] = useState<AddressFormSteps>(() => {
        const userProfileAddressList = userProfile?.service_info || [];
        return userProfileAddressList.length > 0 && ldFlags.enableAccounts
            ? AddressFormSteps.SavedAddresses
            : AddressFormSteps.AddressEntry;
    });

    // const addressFormTitle =
    //     !allowFutureOrderEdit && !orderAddress
    //         ? 'Before you can schedule an order, we\'ll need to make sure that we can deliver to you. What is your delivery address?'
    //         : 'Enter your delivery address';

    const addressFormTitle = 'Enter your delivery address';

    const handleHeaderClick = () => {
        if (orderAddress) {
            setIsAddressPopoverVisible(prev => !prev);
        } else {
            setAllowFutureOrderEdit(false);
            setIsAddressPopoverVisible(prev => !prev);
            segment.messageShownRoadblock('Date Roadblock');
        }
    };

    const submitCallback = (formValues?: any) => {
        log.debug('%c header content form values: ', 'background-color: green', formValues);
        setIsAddressPopoverVisible(false);
    };

    const cancelCallback = (e?: any) => {
        const action = e?.reason ? e.reason : 'Close';
        log.debug('%c Cancel Callback ', 'background-color: green');
        e?.onCancelForm?.();
        setIsAddressPopoverVisible(false);
        segment.cancelAddressEntryLinkClicked(cdpLocation, restaurantInfoTempQuery, action);
        updateRestaurantInfoTempQuery(null);
    };

    useEffect(() => {
        if (isAccountDropdownOpen) {
            setExpand(false);
        }
    }, [isAccountDropdownOpen]);

    useEffect(() => {
        if (!isAddressPopoverVisible) {
            setAllowFutureOrderEdit(true);
        }
    }, [isAddressPopoverVisible, allowFutureOrderEdit]);

    useEffect(() => {
        const body = document.querySelector('body');
        if (body && expand) {
            body.classList.add('no-scroll');
            body.setAttribute('style', 'touch-action: none');
        } else if (body && !expand) {
            body.classList.remove('no-scroll');
            body.setAttribute('style', 'touch-action: unset');
        }
    }, [isAddressPopoverVisible, isFutureOrderPopoverVisible, expand]);

    return (
        <section style={{ alignSelf: 'center', width: '90%', margin: '20px' }}>
            <Row
                className={cx({ 'mobile-header-wrapper': true, 'popover-active': expand })}
                align="middle"
                style={{
                    cursor: 'pointer',
                    background: secondaryColor,
                    borderRadius: '10px',
                    justifyContent: 'center',
                }}
                onClick={handleHeaderClick}
            >
                {orderAddress ? (
                    <div style={{ fontSize: '12px' }}>
                        {'Deliver '}
                        <wbr></wbr>
                        <Text style={{ color: primaryColor }} strong>
                            {deliveryTime}&nbsp;&nbsp;
                        </Text>
                        <wbr></wbr>
                        {'to '}
                        <Text
                            style={{ color: primaryColor }}
                            aria-label="Open input field to enter your delivery address"
                            strong
                        >
                            {orderAddress?.structured_formatting?.main_text ?? `Your Address`}
                        </Text>
                    </div>
                ) : (
                    <Text style={{ color: primaryColor }} strong>{`Add Delivery Address`}</Text>
                )}
            </Row>
            {isAddressPopoverVisible && (
                <BottomDrawerPopup
                    visible={isAddressPopoverVisible}
                    onClose={() => cancelCallback()}
                    useModal={false}
                >
                    <div className={cx('bottom-sheet-container')}>
                        <CloseOutlined
                            className={cx('close-icon')}
                            onClick={() => cancelCallback()}
                        />
                        <OrderAddressForm
                            currentFormStep={currentStep}
                            setCurrentFormStep={setCurrentStep}
                            formTitle={addressFormTitle}
                            onSubmit={submitCallback}
                            onCloseModal={() => cancelCallback()}
                            formControls={(onCancel, formState, forceDisable) => (
                                <section className={cx('controlled-action-buttons')}>
                                    <Button
                                        size="large"
                                        block
                                        type="default"
                                        onClick={() =>
                                            cancelCallback({
                                                onCancelForm: onCancel,
                                                reason: 'Cancel',
                                            })
                                        }
                                    >
                                        Cancel
                                    </Button>
                                    <TemplateButton
                                        block
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        disabled={!formState?.isValid || forceDisable}
                                    >
                                        Save
                                    </TemplateButton>
                                </section>
                            )}
                        />
                    </div>
                </BottomDrawerPopup>
            )}
            {isFutureOrderPopoverVisible && (
                <FutureOrderPopup
                    onCancel={toggleFutureOrderPopover}
                    setDeliveryTime={setDeliveryTime}
                />
            )}
        </section>
    );
};
