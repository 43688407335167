import { CreditCardOutlined } from '@ant-design/icons';
import { useStripe } from '@stripe/react-stripe-js';
import { Form, Radio, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';
import ApplePayIcon from '../../../assets/img/apple_pay.svg';
import GooglePayIcon from '../../../assets/img/light_gpay.svg';
import { getPaymentResult } from '../utils/general';
import useSegment from '../utils/segment';
import { PaymentMethod } from './index';

export const PaymentMethodForm = ({
    paymentMethod,
    setPaymentMethod,
}: {
    paymentMethod: PaymentMethod;
    setPaymentMethod: Dispatch<SetStateAction<PaymentMethod>>;
}) => {
    const ldFlags = useFlags();
    const stripe = useStripe();
    const segment = useSegment();

    const paymentResult = useQuery(['paymentResultCheck', !!stripe], () =>
        getPaymentResult(stripe),
    );

    const isThereMultiplePaymentMethods =
        ldFlags.showDigitalWallet &&
        (paymentResult?.data?.canMakePayment?.applePay ||
            paymentResult?.data?.canMakePayment?.googlePay);

    const getBorderColor = (method: PaymentMethod, selectedMethod: PaymentMethod) => {
        const borderColor = method === selectedMethod ? '#000000' : '#d9d9d9';
        return { border: `1px solid ${borderColor}` };
    };

    log.debug('%c PAYMENT RESULT: ', 'background-color: green', paymentResult);

    return isThereMultiplePaymentMethods ? (
        <Form layout="vertical" className="payment-methods">
            <Form.Item name="requiredMarkValue" style={{ fontWeight: 600 }}>
                <Radio.Group
                    name="payment-type"
                    defaultValue={paymentMethod}
                    className={`payment-method-group`}
                    onChange={e => setPaymentMethod(e.target.value)}
                >
                    {paymentResult?.isLoading && (
                        <Radio className="section-block" value={0} disabled>
                            loading payment methods..
                        </Radio>
                    )}
                    {paymentResult?.data?.canMakePayment?.applePay &&
                        ldFlags.showDigitalWallet &&
                        !paymentResult?.isLoading && (
                            <Row
                                className="section-block"
                                style={getBorderColor(PaymentMethod.ApplePay, paymentMethod)}
                            >
                                <Radio
                                    value={PaymentMethod.ApplePay}
                                    onClick={() =>
                                        segment.paymentMethodOptionSelected(
                                            PaymentMethod.ApplePay,
                                            'Checkout',
                                        )
                                    }
                                >
                                    <img
                                        src={ApplePayIcon}
                                        alt="Apple Pay"
                                        style={{
                                            height: '100%',
                                            padding: '4px 8px 4px 0px',
                                            width: '48px',
                                        }}
                                    />
                                </Radio>
                            </Row>
                        )}
                    {paymentResult?.data?.canMakePayment?.googlePay &&
                        ldFlags.showDigitalWallet &&
                        !paymentResult?.isLoading && (
                            <Row
                                className="section-block"
                                style={getBorderColor(PaymentMethod.GooglePay, paymentMethod)}
                            >
                                <Radio
                                    value={PaymentMethod.GooglePay}
                                    onClick={() =>
                                        segment.paymentMethodOptionSelected(
                                            PaymentMethod.GooglePay,
                                            'Checkout',
                                        )
                                    }
                                >
                                    <img
                                        src={GooglePayIcon}
                                        alt="Google Pay"
                                        style={{
                                            height: '100%',
                                            padding: '4px 8px 4px 0px',
                                            width: '50px',
                                        }}
                                    />
                                </Radio>
                            </Row>
                        )}
                    {!paymentResult?.isLoading && (
                        <Row
                            className="section-block"
                            style={getBorderColor(PaymentMethod.CreditCard, paymentMethod)}
                        >
                            <Radio
                                value={PaymentMethod.CreditCard}
                                style={{ fontWeight: 'normal' }}
                                onClick={() =>
                                    segment.paymentMethodOptionSelected(
                                        PaymentMethod.CreditCard,
                                        'Checkout',
                                    )
                                }
                            >
                                <CreditCardOutlined />
                                {` Credit or debit card`}
                            </Radio>
                        </Row>
                    )}
                </Radio.Group>
            </Form.Item>
        </Form>
    ) : (
        <></>
    );
};
