import { CloseOutlined } from '@ant-design/icons';
import { Capacitor } from '@capacitor/core';
import { Button, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { useItemDetailPopup } from '../../hooks/useItemDetailPopup';
import { SignUpWelcomeModal } from '../account/welcome-modal';
import {
    IUnavailableLocation,
    NoConceptMatchedPopover,
} from '../address/no-concept-matched-popover';
import { CartDrawer } from '../cart';
import { GlobalBanner } from '../global-banner';
import { MobileHeaderContent } from '../header-content/mobile/mobile-header-content';
import { ItemDetailPopup } from '../menu/item-detail-popup';
import { OnboardingModal } from '../onboarding';
import { SideNav } from '../sidenav';
import { BottomDrawerPopup } from '../ui-components/bottom-drawer-popup';
import { useMobileScreen } from '../utils/general';
import useSegment from '../utils/segment';
import { templateNames, useHeader } from './useHeader';

import './index.scss';
import '../account/welcome-modal/index.scss';

export interface HeaderProps {
    title?: string;
    subtitle?: string;
    backButton?: { handleBack: () => void; text?: string };
    getHeaderHeight?: (value: number) => void;
    headerHeight?: number;
    logoLink?: string | null;
    templateName: templateNames;
}

export const Header = ({
    title,
    subtitle,
    backButton,
    headerHeight,
    getHeaderHeight,
    logoLink,
    templateName,
}: HeaderProps) => {
    const {
        isDinnerbell,
        template,
        isWelcomeModalOpen,
        updateWelcomeModalOpen,
        updateNoConceptPopoverVisible,
        noConceptPopoverVisible,
        orderAddress,
    } = useContext(AppContext);
    const { restaurantInfoTempQuery, updateRestaurantInfoTempQuery } = useContext(ConceptContext);
    const {
        isSideNavOpen,
        setIsSideNavOpen,
        closeCartDrawer,
        isCartDrawerOpen,
        getHeaderComponents,
        isAccountDropdownOpen,
    } = useHeader({ getHeaderHeight, title, subtitle, logoLink, backButton });
    const segment = useSegment();

    const { headerClassName, componentsListInOrder, showMobileDeliveryContent } =
        getHeaderComponents(templateName);

    const [unavailableLocationInfo, setUnavailableLocationInfo] =
        useState<IUnavailableLocation | null>(null);

    const handleClose = () => {
        updateWelcomeModalOpen(false);
    };

    const {
        cartIndexItem,
        idHierarchy,
        isEditItem,
        selectedItem,
        selectedItemPrice,
        showItemDetailPopup,
        addCartItem,
        closePopup,
        handleItemDetailPopUp,
        viaItemDestination,
    } = useItemDetailPopup();
    const isMobile = useMobileScreen();

    useEffect(() => {
        if (restaurantInfoTempQuery?.delivery) {
            const locationInfo = {
                appId: restaurantInfoTempQuery?.delivery?.app_id,
                locationId: restaurantInfoTempQuery?.delivery?.id,
                reopenAt: restaurantInfoTempQuery?.delivery?.reopen_at,
                name: restaurantInfoTempQuery?.delivery?.name,
            };
            setUnavailableLocationInfo(locationInfo);
        }
    }, [restaurantInfoTempQuery]);

    useEffect(() => {
        if (getHeaderHeight) {
            getHeaderHeight(210);
        }
    }, [getHeaderHeight]);

    const closePopoverVisible = (e: any) => {
        if (e.action) {
            segment.defaultAddressUnserviceableClicked(unavailableLocationInfo, e.action);
            document.getElementById('orderAddress')?.focus();
        } else {
            segment.closeAddressUnserviceableModal(
                unavailableLocationInfo,
                e?.action || (e?.type === 'keydown' ? 'Escape' : 'Click Outside'),
            );
        }
        updateNoConceptPopoverVisible(!noConceptPopoverVisible);
        updateRestaurantInfoTempQuery(null);
    };

    const CloseIcon = () => {
        return (
            <CloseOutlined
                style={{
                    fontSize: '24px',
                    marginTop: '12px',
                    marginLeft: '12px',
                    color: 'rgba(0, 0, 0, 0.45)',
                }}
            />
        );
    };

    if (!template) {
        return null;
    }

    return (
        <CdpLocationContext.Provider value="Menu Nav">
            {Capacitor.isNativePlatform() && isDinnerbell && <OnboardingModal />}
            <div
                className="white-background-block"
                style={{
                    height: headerHeight ? (isMobile ? headerHeight : headerHeight + 110) : 310,
                }}
            />
            <div
                className="fixed-header-container"
                style={
                    isMobile
                        ? { marginBottom: 100 }
                        : { marginBottom: headerHeight ? headerHeight : 200 }
                }
            >
                <div
                    className="white-background-container"
                    style={{
                        height: isMobile ? 50 : headerHeight,
                        maxWidth: isMobile ? '100%' : '1020px',
                        width: '100%',
                    }}
                >
                    <Row
                        style={{
                            width: '100%',
                            alignSelf: 'center',
                            zIndex: 3,
                            marginBottom: '30px',
                        }}
                    >
                        <div id="native-status-bar"></div>
                        <GlobalBanner getHeaderHeight={getHeaderHeight} />
                    </Row>
                    <Row
                        style={{
                            maxWidth: '1020px',
                            margin: isMobile ? '0 auto' : '',
                            alignSelf: 'center',
                            width: isMobile ? '90%' : '100%',
                            background: '#FAFAFA',
                            padding: '20px 40px',
                            borderRadius: '20px',
                            zIndex: 3,
                            top: '55px',
                            marginBottom: '30px',
                        }}
                    >
                        <div className={`globalHeader ${headerClassName}`}>
                            {componentsListInOrder}
                        </div>
                    </Row>
                </div>
            </div>
            {!isMobile && (
                <div
                    className="white-background-block"
                    style={{
                        height: 20,
                        minHeight: 30,
                        zIndex: 50,
                        marginTop: -25,
                        top: headerHeight,
                    }}
                />
            )}

            {isMobile && showMobileDeliveryContent ? (
                <div
                    style={{
                        margin: '15vh auto auto',
                        zIndex: '1',
                        marginTop: headerHeight
                            ? orderAddress
                                ? headerHeight - 120
                                : headerHeight - 90
                            : '',
                    }}
                >
                    <MobileHeaderContent isAccountDropdownOpen={isAccountDropdownOpen} />
                </div>
            ) : (
                <></>
            )}
            <SideNav isOpen={isSideNavOpen} toggleSideNav={setIsSideNavOpen} />

            <CartDrawer
                closeDrawer={closeCartDrawer}
                cartVisible={isCartDrawerOpen}
                onCartItemEdit={handleItemDetailPopUp('Cart')}
                addCartItem={addCartItem}
            />

            <ItemDetailPopup
                show={showItemDetailPopup}
                item={selectedItem!}
                basePrice={selectedItemPrice!}
                idHierarchy={idHierarchy}
                isEdit={isEditItem}
                close={closePopup}
                addCart={addCartItem}
                cartIndex={cartIndexItem}
                itemDestination={viaItemDestination}
            />
            {isWelcomeModalOpen && (
                <BottomDrawerPopup
                    visible={isWelcomeModalOpen}
                    onClose={() => {
                        handleClose();
                        segment.exitWelcomeModalClicked();
                    }}
                    closable={true}
                >
                    {isMobile && (
                        <Button
                            icon={<CloseIcon />}
                            onClick={handleClose}
                            type="text"
                            size="large"
                        />
                    )}
                    <SignUpWelcomeModal handleClose={handleClose} />
                </BottomDrawerPopup>
            )}
            <NoConceptMatchedPopover
                closePopoverVisible={(e: any) => closePopoverVisible(e)}
                unavailableLocationInfo={unavailableLocationInfo}
            />
        </CdpLocationContext.Provider>
    );
};
