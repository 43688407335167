import { CloseOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import log from 'loglevel';
import React, { FC } from 'react';
import { withAppRoot } from '../utils/general';
import { ImageWithFallback } from './image-with-fallback';

interface IErrorScreenProps {
    noDriver: boolean;
    genericError?: boolean;
    paymentCaptureFailed?: boolean;
    onCancel?: () => void;
}

export const ErrorScreen: FC<IErrorScreenProps> = ({
    onCancel,
    noDriver,
    genericError = false,
    paymentCaptureFailed = false,
}) => {
    if (!noDriver && !genericError && !paymentCaptureFailed) {
        return null;
    }
    const messageType = noDriver
        ? 'noDriverFound'
        : genericError
        ? 'genericError'
        : 'paymentCaptureFailed';
    const { imgPath, errorMessage, apology } = errorMessageDict[messageType];

    log.error('Error Found while submitting order:', errorMessage, apology);

    return (
        <Col className="oc-error-container" data-testid="error-screen-message" span={24}>
            {onCancel && (
                <CloseOutlined
                    className="close-icon"
                    data-testid="error-screen-close-icon"
                    onClick={onCancel}
                />
            )}
            <Row justify="center">
                <ImageWithFallback
                    className="oc-error-icon"
                    data-testid="icon"
                    src={withAppRoot(imgPath)}
                />
            </Row>
            <Row justify="center">
                <div className="err-msg">{errorMessage}</div>
            </Row>
            <Row justify="center">
                <div className="apology">{apology}</div>
            </Row>
        </Col>
    );
};

export const errorMessageDict = {
    noDriverFound: {
        imgPath: 'src/assets/img/error-delivery-creation.svg',
        errorMessage: 'Oh no!',
        apology:
            'There are no delivery drivers available at this time, we apologize for the inconvenience. You may see a pending charge on your account for this order, this will be refunded soon.',
    },
    genericError: {
        imgPath: 'src/assets/img/error-delivery-creation.svg',
        errorMessage: "Oh no! Unfortunately we're unable to process your order at this time.",
        apology:
            "We're so sorry for the inconvenience. Please come back sometime soon and try again.",
    },
    paymentCaptureFailed: {
        imgPath: 'src/assets/img/error-delivery-creation.svg',
        errorMessage: 'Oops. It looks like there was an issue with your payment.',
        apology:
            "If you'd like to place your order, please be sure to use a different payment method.",
    },
};
