import { Card, Col } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { useItemDetailPopup } from '../../hooks/useItemDetailPopup';
import { formatMoneyInteger, itemNameToUrlPath, resizedImagePath } from '../utils/general';
import { MenuItemInterface, MenuSectionInterface, findEntity, matchNestedRule } from './model';

export const ItemCard = ({
    section,
    item,
    idHierarchy,
    onSelect,
    showPrice = false,
}: {
    section: MenuSectionInterface;
    item: MenuItemInterface;
    idHierarchy: string[];
    showPrice?: boolean;
    onSelect: (
        section: MenuSectionInterface,
        item: MenuItemInterface,
        idHierarchy: string[],
        price: number,
        via: string,
        cartIndex: number,
        isEdit: boolean,
    ) => void;
}) => {
    const [price, setPrice] = useState<number>(0);
    const [isPriceVarying, setIsPriceVarying] = useState(false);
    const [usePlaceholder, setUsePlaceholder] = useState(!item.images[0]?.url);
    const {
        conceptDetails: { menuInfo },
    } = useContext(ConceptContext);
    const [firstRender, setFirstRender] = useState(true);

    const { itemParam } = useItemDetailPopup();

    const calculatePrice = useCallback(() => {
        return matchNestedRule(item.price_rules, idHierarchy)?.price || 0;
    }, [item, idHierarchy]);

    const handleMissingImage = () => {
        setUsePlaceholder(true);
    };

    useEffect(() => {
        if (itemParam === item.id) {
            onSelect(section, item, idHierarchy, price, 'Menu Item', -1, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemParam]);

    useEffect(() => {
        const itemPrice: number = calculatePrice();
        if (!itemPrice && item.modifier_group_ids?.length) {
            for (const modId of item.modifier_group_ids) {
                const modItem = findEntity(menuInfo!, 'modifier_group', modId);
                const nestedItems = modItem.item_ids
                    ?.map((id: string) => {
                        return findEntity(menuInfo!, 'item', id);
                    })
                    .filter(item => {
                        const availabilityRule = matchNestedRule(item.availability_rules, [
                            ...idHierarchy,
                            modId,
                            item.id,
                        ]);
                        return availabilityRule?.is_available !== false;
                    });

                const allNestedZero = nestedItems?.every(item => {
                    const priceRule = matchNestedRule(item.price_rules, [
                        ...idHierarchy,
                        modId,
                        item.id,
                    ]);
                    return !priceRule?.price;
                });

                if (!allNestedZero) {
                    setIsPriceVarying(true);
                    break;
                }
            }
        }
        setPrice(itemPrice);
        setFirstRender(false);
    }, [calculatePrice, idHierarchy, item.modifier_group_ids, menuInfo]);

    if (firstRender) {
        return null;
    }

    return (
        <CdpLocationContext.Provider value="Menu Item">
            <Col key={item.id} xs={24} md={8} className="card-wrapper">
                <Card
                    className="menu-card item-card"
                    bodyStyle={{ padding: 0, cursor: 'pointer' }}
                    onClick={() =>
                        onSelect(section, item, idHierarchy, price, 'Menu Item', -1, false)
                    }
                    data-testid={`item-card-${item.id}`}
                >
                    <div>
                        {usePlaceholder ? (
                            <div className="missing-image-container">
                                <svg
                                    width="50"
                                    height="69"
                                    viewBox="0 0 50 69"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M50.0006 16.6256C50.0006 8.74277 43.6126 0 37.7766 0C31.9406 0 25.4736 8.74277 25.4736 16.6256C25.4736 22.9319 28.9437 26.7299 33.8333 28.0915L33.2024 63.9225C33.2024 66.2157 35.174 68.0789 37.6977 68.0789C40.2214 68.0789 42.2719 66.2157 42.193 63.9225L41.5621 28.0915C46.5306 26.8016 50.0006 22.9319 50.0006 16.6256Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M17.5869 1.79139C16.4828 1.79139 15.6153 2.57967 15.6153 3.58294V18.4886C15.6153 18.9186 15.2209 19.2769 14.7477 19.2769H12.6184C12.1452 19.2769 11.7509 18.9186 11.7509 18.4886V3.51128C11.672 2.57967 10.8045 1.79139 9.77925 1.79139C8.67514 1.79139 7.80763 2.57967 7.80763 3.58294V18.4886C7.80763 18.9186 7.4133 19.2769 6.94011 19.2769H4.81076C4.33757 19.2769 3.94325 18.9186 3.94325 18.4886V3.51128C3.94325 2.50801 3.07573 1.71973 1.97162 1.71973C0.867514 1.71973 0 2.50801 0 3.51128V21.6418C0 24.7232 2.60254 27.2314 5.83601 27.5181L5.20509 63.8507C5.20509 66.1439 7.17671 68.0071 9.70039 68.0071C12.2241 68.0071 14.2746 66.1439 14.1957 63.8507L13.6436 27.5897C16.956 27.3031 19.4796 24.7232 19.4796 21.7134V3.51128C19.4796 2.57967 18.6121 1.79139 17.5869 1.79139Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        ) : (
                            <img
                                className="card-image"
                                src={resizedImagePath(item.images[0]?.url, 300)}
                                onError={handleMissingImage}
                                title={`${window.location.pathname}/item/${itemNameToUrlPath(
                                    item.title,
                                )}`}
                            />
                        )}
                        <div className="item-card-details">
                            <h3>{item.title}</h3>
                            <span
                                className="list-item-price"
                                style={showPrice ? {} : { display: 'none' }}
                            >
                                {isPriceVarying ? <i>Price Varies</i> : formatMoneyInteger(price)}
                            </span>
                        </div>
                    </div>
                </Card>
            </Col>
        </CdpLocationContext.Provider>
    );
};
