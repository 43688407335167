import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Radio, RadioChangeEvent, Typography } from 'antd';
import log from 'loglevel';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Controller, FieldValues, FormState } from 'react-hook-form';
import { useHistory } from 'react-router';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { AppContext, FutureOrderDate, UserProfileAddress } from '../../../contexts/app-context';
import { CdpLocationContext } from '../../../contexts/cdp-location-context';
import { ConceptContext, LocationDetailsInterface } from '../../../contexts/concept-context';
import { findClosestDate } from '../../../hooks/useFutureOrder';
import { UsePlaceToNearbyMenuDetails } from '../../../hooks/usePlaceToNearbyMenuDetails';
import { getConceptMatchedAddressIdx, placesToAddress } from '../../utils/address';
import { newConceptMatchMessage } from '../../utils/error-messages';
import { formatAddressDescription, getLocationReopenDayTime } from '../../utils/general';
import useSegment from '../../utils/segment';
import { PlacesAutocomplete } from '../PlacesAutocomplete';
import { TemplateButton } from '../template-button';
import './index.scss';

const { Title } = Typography;

export const SavedAddresses: FC<ISavedAddressesProps> = ({
    formState,
    control,
    locationResults,
    sortedAddressList,
    onClearAddress,
    onCancel,
    updateSortedAddressList,
    updateLocationResults,
    handleMenuItemMatch,
}) => {
    const {
        useDefaultAddress,
        userProfile,
        template,
        orderAddress,
        updateOrderAddress,
        updateDeliveryPreference,
        updateFutureOrderDate,
    } = useContext(AppContext);
    const {
        updateConceptDetails,
        clearAddressAndCartSession,
        isConceptFetching,
        updateRestaurantInfoTempQuery,
    } = useContext(ConceptContext);
    const {
        suggestions: { data: addressSuggestions, status: addressLookupStatus },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({ debounce: 200 });
    UsePlaceToNearbyMenuDetails();
    const { fetchConceptDetails, conceptDetailsData } = UsePlaceToNearbyMenuDetails();
    const cdpLocation = useContext(CdpLocationContext);

    const [prevPlaceId, setPrevPlaceId] = useState<string>('');
    const [selectedAddressIdx, setSelectedAddressIdx] = useState<number>(() => {
        return getConceptMatchedAddressIdx(userProfile, orderAddress, useDefaultAddress);
    });
    const [addressList, setAddressList] = React.useState<UserProfileAddress[]>(
        userProfile?.service_info || [],
    );
    const [primaryButtonText] = React.useState<string>(() =>
        cdpLocation === 'Checkout' ? 'Update' : 'Save',
    );

    const isMounted = useRef<boolean>(false);
    const segment = useSegment();
    const history = useHistory();

    const lookupAddressAndSetValue = useCallback(
        (address: UserProfileAddress) => {
            const addressDescription = formatAddressDescription(address, true);
            log.debug('Current address to lookup location status', addressDescription);
            setValue(addressDescription);
        },
        [setValue],
    );

    const getFutureOrderDateWindow = useCallback(() => {
        const tempFutureOrderDate = null;
        const dateResults = findClosestDate(
            template,
            conceptDetailsData?.menuInfo,
            conceptDetailsData?.restaurantInfo,
            updateFutureOrderDate,
            tempFutureOrderDate,
            false,
            conceptDetailsData?.restaurantInfo?.delivery?.reopen_at,
        );
        if (dateResults && typeof dateResults === 'object') {
            const { window, date } = dateResults;
            return { date, time: window };
        }
        return tempFutureOrderDate;
    }, [conceptDetailsData, template, updateFutureOrderDate]);

    const addAdditionalValidations = useCallback(
        (conceptDetails: any, index: number) => {
            const { restaurantInfo, locationStatus } = conceptDetails;
            const reopenTimeObject = getLocationReopenDayTime(restaurantInfo?.delivery?.reopen_at);
            const isPausedLongerThanAWeek = reopenTimeObject?.pausedLongerThanAWeek;
            const supportsScheduledOrders = template?.future_order?.supported_order_type?.scheduled;
            const isLocationClosed = locationStatus != null && !locationStatus?.isOpen;
            const locationFoundForAddress = restaurantInfo?.delivery;
            const temporarilyPaused =
                isLocationClosed && !isPausedLongerThanAWeek && !supportsScheduledOrders;
            const isDefaultAddress =
                userProfile?.service_info && userProfile?.service_info[index]?.default_address;

            if (locationFoundForAddress) {
                if (locationStatus?.isOpen) {
                    conceptDetails.message = locationStatus?.isOpen;
                } else if (
                    isPausedLongerThanAWeek ||
                    reopenTimeObject?.reopenDay == null ||
                    reopenTimeObject?.reopenHour == null
                ) {
                    conceptDetails.message = availabilityMessagesDict.extendedPause;
                } else if (temporarilyPaused) {
                    conceptDetails.message = `${availabilityMessagesDict.isClosed} ${reopenTimeObject?.reopenDay} at ${reopenTimeObject?.reopenHour}`;
                } else if (!isPausedLongerThanAWeek && supportsScheduledOrders) {
                    const futureOrderDateWindow = getFutureOrderDateWindow();
                    if (futureOrderDateWindow) {
                        conceptDetails.futureOrderDate = futureOrderDateWindow;
                        conceptDetails.message = availabilityMessagesDict.fOrderEnabledAndePaused;
                        conceptDetails.isDisabled = false;
                    } else {
                        conceptDetails.message = `${availabilityMessagesDict.isClosed} ${reopenTimeObject?.reopenDay} at ${reopenTimeObject?.reopenHour}`;
                    }
                }
            } else {
                conceptDetails.message = availabilityMessagesDict.noLocations;
            }
            if (!conceptDetails.hasOwnProperty('isDisabled')) {
                conceptDetails.isDisabled = locationStatus.isOpen ? false : true;
            }
            conceptDetails.isSelected = index === selectedAddressIdx;
            conceptDetails.isDefault = isDefaultAddress;
            conceptDetails.initialIndex = index;
            return conceptDetails;
        },
        [userProfile, selectedAddressIdx, template, getFutureOrderDateWindow],
    );

    const sortAddressesByAvailability = useCallback(() => {
        const tempList: any = [];
        locationResults.forEach((conceptDetails: any, index: number) => {
            const updatedConceptDetails = addAdditionalValidations(conceptDetails, index);
            tempList.push(updatedConceptDetails);
        });
        const sortedAddresses = tempList.sort((a: any, b: any) => {
            return (
                a.isDisabled - b.isDisabled ||
                b.isSelected - a.isSelected ||
                b.isDefault - a.isDefault ||
                b.locationStatus.isOpen - a.locationStatus.isOpen ||
                b.locationStatus.noLocations - a.locationStatus.noLocations
            );
        });
        updateSortedAddressList(sortedAddresses);
    }, [locationResults, addAdditionalValidations, updateSortedAddressList]);

    log.debug('%c Sorted address List ', 'background-color: green', sortedAddressList);
    log.debug('%c Places Details Location results ', 'background-color: green', locationResults);
    log.debug('%c User profile saved addresses', 'background-color: green', addressList);

    useEffect(() => {
        if (locationResults.length && selectedAddressIdx > -1 && !orderAddress) {
            const { restaurantInfo, menuInfo } = locationResults[selectedAddressIdx];
            updateRestaurantInfoTempQuery({ ...restaurantInfo, menuInfo });
        } else {
            updateRestaurantInfoTempQuery(null);
        }
    }, [
        locationResults,
        selectedAddressIdx,
        updateRestaurantInfoTempQuery,
        sortAddressesByAvailability,
        orderAddress,
    ]);

    useEffect(() => {
        const isAddressListSortedAndLoaded =
            locationResults.length > 0 && !addressList.length && !sortedAddressList.length;
        if (isAddressListSortedAndLoaded) {
            log.debug('Finished location lookups');
            sortAddressesByAvailability();
        }
    }, [locationResults, sortAddressesByAvailability, addressList, sortedAddressList]);

    useEffect(() => {
        // Set address value for usePlacesAutocomplete to generate suggestions
        if (addressList.length > 0 && !sortedAddressList.length) {
            const currentAddress = addressList?.[0];
            lookupAddressAndSetValue(currentAddress);
        }
    }, [addressList, lookupAddressAndSetValue, sortAddressesByAvailability, sortedAddressList]);

    useEffect(() => {
        // Fetch concept details for selected address
        const isSuggestionsFetched = addressLookupStatus === 'OK' && addressSuggestions?.length > 0;
        if (isSuggestionsFetched) {
            const placeId = addressSuggestions[0].place_id;
            if (placeId !== prevPlaceId) {
                isMounted.current = true;
                fetchConceptDetails(placeId, isMounted.current);
            }
        }
        return () => {
            isMounted.current = false;
        };
    }, [
        setAddressList,
        addressList,
        prevPlaceId,
        fetchConceptDetails,
        addressLookupStatus,
        addressSuggestions,
    ]);

    useEffect(() => {
        // Save location status to state to display in UI
        if (!addressList.length) {
            return;
        }
        const currentPlaceId = conceptDetailsData?.placeDetails?.place_id;
        const placesPartsWithConceptResults = {
            ...conceptDetailsData,
            addressParts: addressSuggestions[0],
        };
        if (conceptDetailsData?.locationStatus && currentPlaceId !== prevPlaceId) {
            setPrevPlaceId(currentPlaceId);
            updateLocationResults([...locationResults, placesPartsWithConceptResults]);
            setAddressList(addressList.slice(1));
            clearSuggestions();
        }
    }, [
        conceptDetailsData,
        updateLocationResults,
        prevPlaceId,
        locationResults,
        setAddressList,
        addressList,
        clearSuggestions,
        addressSuggestions,
    ]);

    const getCityStateAndZipcode = (placeDetails: UserProfileAddress) => {
        return `${placeDetails.city}, ${placeDetails.region} ${placeDetails.postalcode}`;
    };

    const getActiveAddress = (currentAddressIdx: number) => {
        if (currentAddressIdx > -1) {
            return currentAddressIdx;
        }
        const isNextAvailableAddressIdx = sortedAddressList.findIndex(
            (address: any) => address.isDisabled === false,
        );
        if (isNextAvailableAddressIdx > -1) {
            setSelectedAddressIdx(isNextAvailableAddressIdx);
        }
        return isNextAvailableAddressIdx;
    };

    const onChangeRadio = ({ target: { value } }: RadioChangeEvent) => {
        log.debug('Selected Address', userProfile?.service_info?.[value], value);
        setSelectedAddressIdx(value);
        segment.savedAddressSelected(userProfile?.service_info?.[value].address1);
    };

    const isButtonDisabled = () => {
        if (!sortedAddressList.length || selectedAddressIdx < 0) {
            return true;
        }
        const currRadioActiveAddressParts = locationResults[selectedAddressIdx].addressParts;
        const isOrderAddressSameAsSelection =
            orderAddress?.structured_formatting?.main_text ===
                currRadioActiveAddressParts.structured_formatting?.main_text &&
            orderAddress?.place_id === currRadioActiveAddressParts.place_id;
        return isOrderAddressSameAsSelection;
    };

    const saveAndUpdateContextValues = (
        selectedAddress: SelectedAddressParts,
        addressOptions: UserProfileAddress | undefined,
    ) => {
        const tempConceptDetails = {
            locationStatus: selectedAddress.locationStatus,
            placeDetails: selectedAddress.placeDetails,
            restaurantInfo: selectedAddress.restaurantInfo,
            menuInfo: selectedAddress.menuInfo,
        };
        if (selectedAddress?.locationStatus?.isNewConcept) {
            clearAddressAndCartSession();
            updateFutureOrderDate(null);
            history.push('/');
        }
        if (selectedAddress?.futureOrderDate) {
            updateFutureOrderDate(selectedAddress.futureOrderDate);
        }
        const place = { ...selectedAddress.placeDetails, ...selectedAddress.addressParts };
        updateConceptDetails(tempConceptDetails);
        updateOrderAddress(place);
        updateDeliveryPreference({
            preference: addressOptions?.delivery_preference || '',
            text: addressOptions?.special_instructions || '',
        });
    };

    const onSubmit = () => {
        const selectedAddress = sortedAddressList.filter(
            (address: any) => address.initialIndex === selectedAddressIdx,
        )[0];
        log.debug('Save address options to context', selectedAddress);
        const addressOptions = userProfile?.service_info?.[selectedAddress.initialIndex];
        saveAndUpdateContextValues(selectedAddress, addressOptions);
        handleMenuItemMatch?.();
        onCancel?.();
        segment.savedAddressActionCTAClicked();
    };

    const NewConceptMessage = ({ address }: { address: SelectedAddressParts }) => {
        return selectedAddressIdx === address.initialIndex &&
            address?.locationStatus?.isNewConcept ? (
            <Alert className="mt-10" message={newConceptMatchMessage} type="warning" showIcon />
        ) : (
            <></>
        );
    };

    const RadioOptions = () => {
        return sortedAddressList.map((address: any, index: number) => {
            const placeAddressParts = placesToAddress(address.placeDetails);
            return (
                <div className="action-radios" key={index}>
                    <Radio value={address.initialIndex} disabled={address.isDisabled}>
                        <div className="address-description">{placeAddressParts.address1}</div>
                        <div>{getCityStateAndZipcode(placeAddressParts)}</div>
                        <div className="address-availability">{address.message}</div>
                        <NewConceptMessage address={address} />
                    </Radio>
                    <Divider className="divider" />
                </div>
            );
        });
    };

    const AddressList = () => {
        return (
            <Radio.Group
                onChange={onChangeRadio}
                value={getActiveAddress(selectedAddressIdx)}
                className="address-list-radio-group"
            >
                <RadioOptions />
            </Radio.Group>
        );
    };

    const ButtonGroup = () => {
        return orderAddress ? (
            <section className="button-group">
                <Button className="mr-10" size="large" block type="default" onClick={onCancel}>
                    Cancel
                </Button>
                <TemplateButton
                    block
                    type="primary"
                    size="large"
                    onClick={onSubmit}
                    disabled={isButtonDisabled()}
                    data-testid="saved-addresses-btn"
                >
                    {primaryButtonText}
                </TemplateButton>
            </section>
        ) : (
            <section className="button-group">
                <TemplateButton
                    block
                    type="primary"
                    size="large"
                    onClick={onSubmit}
                    disabled={isButtonDisabled()}
                    data-testid="saved-addresses-btn"
                >
                    Continue
                </TemplateButton>
            </section>
        );
    };

    const AddressEntryInput = () => {
        return (
            <>
                <Title level={3} className="address-entry-title condensed-txt">
                    {orderAddress ? 'Delivery address' : 'Confirm your delivery address'}
                </Title>
                <Controller
                    name="orderAddress"
                    control={control}
                    render={({ field }) => {
                        return (
                            <>
                                <label htmlFor="orderAddress" className="sr-only">
                                    Order Address
                                </label>
                                <PlacesAutocomplete
                                    {...field}
                                    error={formState?.errors?.orderAddress && !isConceptFetching}
                                    helperText={formState?.errors?.orderAddress?.message}
                                    placeholder="Enter a new address"
                                    id="orderAddress"
                                    onClear={onClearAddress}
                                    isAddressFetching={isConceptFetching}
                                    value={''}
                                />
                            </>
                        );
                    }}
                />
                <Divider className="divider line-heavy" />
            </>
        );
    };

    return (
        <div className="saved-address-wrapper">
            <AddressEntryInput />
            {sortedAddressList.length > 0 ? (
                <>
                    <AddressList />
                    <ButtonGroup />
                </>
            ) : (
                <div className="spin-wrapper mt-0 mb-10">
                    <LoadingOutlined className="mb-10" style={{ fontSize: '2em' }} />
                    <div>Loading saved addresses</div>
                </div>
            )}
        </div>
    );
};

interface ISavedAddressesProps {
    control?: any;
    locationResults: any;
    sortedAddressList: any;
    formState?: FormState<FieldValues>;
    onCancel?: () => void;
    onClearAddress?: (() => void) | undefined;
    handleMenuItemMatch?: (() => void) | undefined;
    updateSortedAddressList: (sortedAddressList: any) => void;
    updateLocationResults: (sortedAddressList: any) => void;
}

interface SelectedAddressParts extends LocationDetailsInterface {
    addressParts: UserProfileAddress;
    isDefault: boolean;
    isSelectable: boolean;
    initialIndex: number;
    futureOrderDate?: FutureOrderDate;
}

const availabilityMessagesDict = {
    isClosed: 'Restaurant is currently closed, but will be accepting orders again on',
    noLocations: 'No available restaurants',
    extendedPause: 'Restaurant is currently closed, please try again later',
    fOrderEnabledAndePaused: 'Restaurant is currently closed, but is accepting future orders',
    isOpen: '',
};
