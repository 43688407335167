export function formatPhoneNumber(phoneValue: string | undefined | null): string {
    if (!phoneValue) {
        return '';
    }
    phoneValue = phoneValue.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneValue.length;

    if (phoneNumberLength < 4) {
        return phoneValue;
    } else if (phoneNumberLength < 7) {
        phoneValue = `(${phoneValue.slice(0, 3)}) ${phoneValue.slice(3)}`;
        return phoneValue;
    } else if (phoneNumberLength >= 11) {
        phoneValue = phoneValue[0] === '1' ? phoneValue.slice(1, 11) : phoneValue.slice(0, 9);
    }
    return prettifyPhoneNumber(phoneValue);
}

export function digitOnlyPhoneNumberFormat(val: string | undefined | null): string {
    if (!val) {
        return '';
    }
    return val.replace(/[^\d]/g, '');
}

function prettifyPhoneNumber(phoneValue: string): string {
    return `(${phoneValue.slice(0, 3)}) ${phoneValue.slice(3, 6)}-${phoneValue.slice(6, 10)}`;
}
