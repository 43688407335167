import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Tooltip, Typography } from 'antd';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import md5 from 'md5';
import React, { FC, useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { CartItemInterface, OrderTotals, tipPercentages } from '../menu/model';
import { formatMoneyInteger, useMobileScreen } from '../utils/general';
import './index.scss';

const { Text } = Typography;

interface TDProps {
    cartItems: CartItemInterface[];
    initialLoading: boolean | null;
    tabPosition?: number;
    orderTotals: OrderTotals;
}

export const TipDetails: FC<TDProps> = ({
    cartItems,
    initialLoading,
    orderTotals,
    tabPosition = 0,
}) => {
    const { orderAddress, template, promoId, vouchers, orderTotalIsFetching } =
        useContext(AppContext);
    const cdpLocation = useContext(CdpLocationContext);
    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);
    const showTip: boolean = template?.checkout_page?.show_tip_ui === false ? false : true;

    const ldFlags = useFlags();
    const ldClient = useLDClient();
    const isMobile = useMobileScreen();

    ldClient?.identify({
        key: md5(orderAddress.formatted_address),
        custom: {
            deliveryAddress: orderAddress.formatted_address,
            brandId: template?.metadata.brand.id ?? '',
        },
    });

    log.debug('%c LD FLAGS ', 'background-color: green', ldFlags);
    log.debug('%c ORDER TOTALS ', 'background-color: green', orderTotals);

    const subtotal = cartItems.reduce((s, a) => s + a.price, 0);
    const tipPercentage = tipPercentages[tabPosition];

    let orderTotalCost: string | number = orderTotals.tip[tipPercentage].order_total;

    if (cdpLocation === 'Cart') {
        orderTotalCost = orderTotals.tip.no_tip_order_total;
    }

    if (restaurantInfo?.delivery == null || !template) {
        return null;
    }

    return (
        <div style={{ position: 'relative' }}>
            {(orderTotalIsFetching || initialLoading) && (
                <div
                    className={`overlay ${isMobile && cdpLocation === 'Checkout' ? 'grey-bg' : ''}`}
                >
                    <LoadingOutlined style={{ fontSize: 50, height: 50 }} />
                </div>
            )}
            <div
                className="checkout-order-details"
                style={cdpLocation === 'Cart' ? { height: 'unset' } : {}}
            >
                {cdpLocation === 'Cart' &&
                    restaurantInfo?.delivery?.logistics_configs[0].max_total &&
                    subtotal > restaurantInfo?.delivery?.logistics_configs[0].max_total && (
                        <Form.Item className="checkout-order-price-warning">
                            <p>
                                This restaurant has a{' '}
                                {formatMoneyInteger(
                                    restaurantInfo?.delivery?.logistics_configs[0].max_total,
                                )}{' '}
                                order maximum. Please remove items from your order to proceed to
                                checkout.
                            </p>
                        </Form.Item>
                    )}
                {cdpLocation === 'Cart' &&
                    restaurantInfo?.delivery?.logistics_configs[0].min_total &&
                    subtotal < restaurantInfo?.delivery?.logistics_configs[0].min_total && (
                        <Form.Item className="checkout-order-price-warning">
                            <p>
                                This restaurant has a{' '}
                                {formatMoneyInteger(
                                    restaurantInfo?.delivery?.logistics_configs[0].min_total,
                                )}{' '}
                                order minimum. Add{' '}
                                {formatMoneyInteger(
                                    restaurantInfo?.delivery?.logistics_configs[0].min_total -
                                        subtotal,
                                )}{' '}
                                to your order to proceed to checkout.
                            </p>
                        </Form.Item>
                    )}
                <Row justify="space-between">
                    <Col>
                        <Text data-testid="cart-subtotal-price">Subtotal: </Text>
                    </Col>
                    <Col>
                        <Text>{orderTotals.subtotal}</Text>
                    </Col>
                </Row>
                {cdpLocation === 'Checkout' && (promoId || vouchers.length > 0) && (
                    <Row justify="space-between">
                        <Col>
                            <Text data-testid="cart-discount">Discounts: </Text>
                        </Col>
                        <Col>
                            <Text style={{ color: '#389E0D' }}>
                                -{formatMoneyInteger(orderTotals.discount)}
                            </Text>
                        </Col>
                    </Row>
                )}
                <Row justify="space-between">
                    <Col>
                        <Text>
                            Delivery Fee
                            <Tooltip
                                placement="right"
                                title="Delivery fee varies based on your location and other factors."
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                            :
                        </Text>
                    </Col>
                    <Col>
                        <Text>
                            {restaurantInfo?.delivery?.logistics_configs[0].delivery_fee ? (
                                formatMoneyInteger(
                                    restaurantInfo?.delivery?.logistics_configs[0].delivery_fee,
                                )
                            ) : (
                                <div className="free">Free</div>
                            )}
                        </Text>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col>
                        <Text>
                            Service Fee
                            <Tooltip
                                placement="right"
                                title="Helps cover costs including: credit card fees, support, and much more."
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                            :
                        </Text>
                    </Col>
                    <Col>
                        <Text>
                            {template?.general?.service_fee ? (
                                formatMoneyInteger(template?.general?.service_fee)
                            ) : (
                                <div className="free">Free</div>
                            )}
                        </Text>
                    </Col>
                </Row>
                {cdpLocation === 'Checkout' && showTip && (
                    <Row justify="space-between">
                        <Col>
                            <Text>
                                Driver Tip
                                <Tooltip
                                    placement="right"
                                    title="100% of the tip goes to your driver."
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip>
                                :
                            </Text>
                        </Col>
                        <Col>
                            <Text data-testid="checkout-driver-tip">
                                {(() => {
                                    const tipPercentage = tipPercentages[tabPosition];
                                    const tip = orderTotals.tip[tipPercentage].tip_amount;
                                    return tip;
                                })()}
                            </Text>
                        </Col>
                    </Row>
                )}
                <Row justify="space-between">
                    <Col>
                        <Text>{cdpLocation === 'Checkout' ? 'Taxes:' : 'Estimated Taxes:'} </Text>
                    </Col>
                    <Col>
                        <Text>{orderTotals.taxes}</Text>
                    </Col>
                </Row>
                <Row justify="space-between" className="checkout-order-totalprice">
                    <Col>
                        <Text>TOTAL:</Text>
                    </Col>
                    <Col>
                        <Text>{orderTotalCost}</Text>
                    </Col>
                </Row>
            </div>
            <Form.Item
                className="checkout-total-price"
                name="checkouttotalprice"
                hidden={true}
                initialValue={orderTotalCost}
            >
                <input />
            </Form.Item>
        </div>
    );
};
