import { ArrowLeftOutlined, CheckCircleFilled, HomeOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Drawer, Form, Input, Modal, Row } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import { ConceptContext } from '../../contexts/concept-context';
import { TemplateButton } from '../ui-components/template-button';
import { formatAddress, formatStreetAddress, useMobileScreen, withAppRoot } from '../utils/general';
import useSegment from '../utils/segment';
import { CheckoutForms } from './index';

const { Panel } = Collapse;

interface DAFormProps {
    isOpen: boolean;
    editButtonDisabled: boolean;
    onClickContinue: () => void;
    onEdit: (key: CheckoutForms) => void;
    onCancelEdit: (key: CheckoutForms) => void;
}

export const DeliveryAddressForm: FC<DAFormProps> = ({
    isOpen,
    editButtonDisabled,
    onClickContinue,
    onEdit,
    onCancelEdit,
}) => {
    const [form] = Form.useForm();
    const [expand, setExpand] = useState(isOpen);
    const [isShowModal, setShowModal] = useState(false);

    const {
        conceptDetails: { restaurantInfo },
        clearAddressAndCartSession,
    } = useContext(ConceptContext);
    const { isDinnerbell, orderAddress, orderAddressApt, updateOrderAddressApt } =
        useContext(AppContext);

    const segment = useSegment();
    const isMobile = useMobileScreen();

    useEffect(() => {
        setExpand(isOpen);
    }, [isOpen]);

    if (restaurantInfo == null || restaurantInfo.delivery == null) {
        return null;
    }

    const onCancel = () => {
        setExpand(false);
        segment.checkoutCancelLinkClicked('Edit Address');
        onCancelEdit(CheckoutForms.DeliveryAddress);
    };

    const onFinish = (values: any) => {
        const newApt = values.dd_apt.trim();
        updateOrderAddressApt(newApt);
        setExpand(false);
        onClickContinue();
        segment.updateCTAClicked('Update address');
        segment.addressUpdateIdentify(newApt, orderAddress);
    };

    const toggleExpand = (event: any) => {
        event.persist();
        if (expand) {
            segment.checkoutCancelLinkClicked('Edit Address');
            onCancelEdit(CheckoutForms.DeliveryAddress);
        } else {
            segment.checkoutStepViewed('Address');
            segment.checkoutEditAddressLinkClicked();
            onEdit(CheckoutForms.DeliveryAddress);
        }
        setExpand(!expand);
    };

    const EditButton = () => (
        <Button
            type="link"
            onClick={event => toggleExpand(event)}
            data-testid="delivery-address-edit-btn"
            className={`checkout-edit-link ${editButtonDisabled ? 'disabled-button' : ''}`}
            disabled={editButtonDisabled}
        >
            Edit
        </Button>
    );

    const CancelButton = () => (
        <Button
            type="default"
            className={isMobile ? '' : 'mt-10 mb-10 mr-10'}
            size="large"
            block={isMobile}
            onClick={onCancel}
        >
            Cancel
        </Button>
    );

    const UpdateButton = () => (
        <TemplateButton
            type="primary"
            htmlType="submit"
            data-testid="delivery-address-submit-btn"
            size="large"
            block={isMobile}
            onClick={() => {
                updateOrderAddressApt(form.getFieldValue('dd_apt'));
                onCancelEdit(CheckoutForms.DeliveryAddress);
            }}
        >
            Update
        </TemplateButton>
    );

    const showModal = () => {
        setShowModal(true);
    };

    const iconColor = isDinnerbell ? '#f1c143' : '#bbb';
    const iconStyles = {
        fontSize: '20px',
        color: iconColor,
    };

    const AddressForm = () => (
        <Form
            form={form}
            name="checkout_delivery_address"
            className={`${expand ? '' : 'ant-collapse-content-hidden'} ${
                isDinnerbell ? 'isDB' : ''
            }`}
            onFinish={onFinish}
        >
            <Row gutter={10}>
                <Col xs={24} md={16}>
                    <Form.Item
                        name="dd_address"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your address!',
                            },
                        ]}
                        initialValue={orderAddress?.formatted_address}
                    >
                        <Input
                            placeholder={formatAddress(
                                orderAddress?.description || '',
                                orderAddressApt,
                            )}
                            disabled
                            size="large"
                            style={{ marginBottom: '-10px' }}
                            suffix={<CheckCircleFilled style={{ color: '#52C41A' }} />}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item name="dd_apt" initialValue={orderAddressApt}>
                        <Input
                            placeholder="Apt, Suite, Unit"
                            data-testid="delivery-address-apt-input"
                            size="large"
                            style={{ marginBottom: '-10px' }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <p className="checkout-subtext">
                        Need to change your street address? Changing your address will remove
                        everything from your cart and restart your order.{' '}
                        <a className="ant-btn-link" onClick={showModal}>
                            Change address
                        </a>
                    </p>
                    <Modal
                        centered
                        visible={isShowModal}
                        onCancel={() => setShowModal(false)}
                        style={isMobile ? { width: '100%', top: 0, left: 0 } : {}}
                        className="clear-address-confirmation-popover"
                        footer={[
                            <TemplateButton
                                key="link"
                                href={withAppRoot('')}
                                type="primary"
                                size="large"
                                onClick={clearAddressAndCartSession}
                            >
                                Yes
                            </TemplateButton>,
                            <Button size="large" key="back" onClick={() => setShowModal(false)}>
                                No
                            </Button>,
                        ]}
                    >
                        <h2 className="mt-20">Are you sure you want to change your address?</h2>
                        <p>
                            This will restart your order and anything saved in your cart will be
                            lost.
                        </p>
                    </Modal>
                </Col>
            </Row>

            <Row className="action-row">
                {isMobile ? (
                    <>
                        <Col span={12} className="pr-5">
                            <CancelButton />
                        </Col>
                        <Col span={12} className="pl-5">
                            <UpdateButton />
                        </Col>
                    </>
                ) : (
                    <Col span={24}>
                        <CancelButton />
                        <UpdateButton />
                    </Col>
                )}
            </Row>
        </Form>
    );

    return (
        <Collapse
            bordered={false}
            expandIconPosition={'right'}
            expandIcon={EditButton}
            activeKey={expand ? '1' : '0'}
        >
            <Panel
                className="checkout-form-panel"
                header={
                    <div className="checkout-ci-title" onClick={event => toggleExpand(event)}>
                        <HomeOutlined style={iconStyles} />
                        <div>
                            <span className="checkout-subtext">
                                {expand && !isMobile ? (
                                    'Delivery Address'
                                ) : (
                                    <>
                                        {formatStreetAddress(
                                            orderAddress?.structured_formatting,
                                            orderAddressApt,
                                        )}
                                        <br />
                                        {orderAddress?.structured_formatting?.secondary_text}
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                }
                key="1"
            >
                {isMobile ? (
                    <Drawer
                        className="checkout-edit-drawer"
                        closable={true}
                        closeIcon={<ArrowLeftOutlined />}
                        onClose={onCancel}
                        placement="right"
                        title="Delivery Address"
                        width="100%"
                        visible={expand}
                    >
                        <AddressForm />
                    </Drawer>
                ) : (
                    <AddressForm />
                )}
            </Panel>
        </Collapse>
    );
};
