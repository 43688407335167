import { BuildOutlined, WarningTwoTone } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Popover } from 'antd';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import md5 from 'md5';
import React, { useContext } from 'react';
import { AppContext } from '../../../contexts/app-context';
import { BrandSelect } from '../brand-select';
import { FeatureToggle } from './FeatureToggle';
import './index.scss';
import { MockLdFlags } from './services/MockLdFlags';

export const DtcDevTools = ({ forceUpdate }: { forceUpdate: any }) => {
    // this isProd check could possibly be removed since there is a check at parent.
    // Leaving it in for now to be safe.
    const ldFlags = useFlags();
    const ldClient = useLDClient();
    const { logout, isAuthenticated, isLoading } = useAuth0();

    const logoutRoute = ldFlags.enableLamv ? '/orders' : '/orders/address';
    const logoutRedirectUri = `${window.location.origin}${logoutRoute}`;
    const isProd = process.env.BUILD_ENV === 'prod';
    const { orderAddress } = useContext(AppContext);

    const mswEnabled = JSON.parse(localStorage.getItem('mswEnabled') ?? 'false');

    const handleToggleChange = (flag: string) => (value: boolean) => {
        MockLdFlags.post({ [flag]: { value } });
        ldClient?.identify({
            key: md5(orderAddress?.formatted_address ?? 'no_address'),
        });
        forceUpdate();
    };

    const handleMswOptionChange = () => {
        localStorage.setItem('mswEnabled', (!mswEnabled).toString());
        window.location.reload();
    };

    const clearLocalStorageAndReload = () => {
        if (isAuthenticated && !isLoading) {
            logout({ returnTo: logoutRedirectUri });
        }

        setTimeout(() => {
            sessionStorage.clear();
            localStorage.clear();
            window.location.reload();
        }, 300);
    };

    const content = (
        <div className="devtools__options">
            <div>
                <div>
                    <h3>Brand: </h3>
                </div>
                <div>
                    <BrandSelect
                        clearAddress={(selectedUrl: any) => {
                            log.debug('%c brand selected ', 'background-color: red', selectedUrl);
                        }}
                    />
                </div>
            </div>
            <div>
                <div>
                    <h3>MSW Options: </h3>
                </div>
                <div>
                    <Button onClick={handleMswOptionChange}>{`${
                        mswEnabled ? 'Disable' : 'Enable'
                    } LD mocks`}</Button>
                </div>
            </div>

            {mswEnabled && (
                <div>
                    <div>
                        <h3>LD Feature Flags/Toggles: </h3>
                    </div>

                    {Object.keys(ldFlags).map((flag: string) => {
                        return (
                            <FeatureToggle
                                key={flag}
                                label={flag}
                                handleToggleChange={handleToggleChange}
                                flag={flag}
                                value={ldFlags[flag]}
                            />
                        );
                    })}
                </div>
            )}

            {/*<pre>{JSON.stringify(ldFlags, null, 2)}</pre>*/}

            <div>
                <div>
                    <Button danger onClick={clearLocalStorageAndReload}>
                        <WarningTwoTone twoToneColor="red" />
                        Clear storage and reload
                    </Button>
                </div>
            </div>
        </div>
    );
    return isProd ? (
        <></>
    ) : (
        <div className="devtools__container">
            <Popover
                overlayClassName="devtools__popover"
                placement="topLeft"
                title={<h4 className="devtools__title">Dev tools</h4>}
                content={content}
                trigger="click"
            >
                <BuildOutlined className="devtools__button" />
            </Popover>
        </div>
    );
};
