import { get } from './config';

/**
 * @description Brand Templates service for getting template
 */

export const TEMPLATE_VERSION = '3';

export const BrandTemplates = {
    get: ({ url }: { url: string }) => {
        return get(`/brand-template/`, { params: { url, template_version: TEMPLATE_VERSION } });
    },
};
