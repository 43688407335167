import { IUserProfile } from '../../ts/contexts/app-context';
import { get, patch, post } from './config';

/**
 * @description getting user metadata from DTC Backend
 */

export const UserProfile = {
    signUpNewUser: (
        token: string,
        app_id: string | null,
        location_id: string | null,
        signupTouchpoint: string | null,
    ): Promise<IUserProfile> => {
        return post('/accounts/sign-up/', null, {
            params: {
                app_id,
                location_id,
                signup_touchpoint: signupTouchpoint,
                accounts_url: `${window.location.host}/orders/account/profile`,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => response.data)
            .catch(err => {
                throw err;
            });
    },
    getUserProfileData: (token: string): Promise<IUserProfile> => {
        return get('/accounts/get-user-profile/', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => response.data)
            .catch(err => {
                throw err;
            });
    },
    updateUserProfile: (token: string, updatedUser: object) => {
        return patch('/accounts/update-user-profile/', updatedUser, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => response.data)
            .catch(err => {
                throw err;
            });
    },
};
