import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Affix, Col, Row, Spin, Tabs } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useContext, useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useHistory } from 'react-router';
import scrollToElement from 'scroll-to-element';
import { AppContext } from '../../../contexts/app-context';
import { ConceptContext } from '../../../contexts/concept-context';
import { overrideGlobalOrderPlaced } from '../../checkout/stripe-integration-form';
import { Footer } from '../../footer';
import { ImageWithFallback } from '../../ui-components/image-with-fallback';
import {
    getEstimatedDeliveryWindow,
    getPlatform,
    sanitizeMenuPausedModGroups,
    scrollCategorySubnav,
    useMobileScreen,
} from '../../utils/general';
import request from '../../utils/request';
import useSegment from '../../utils/segment';
import { EmailBlock } from '../email-block';
import { FeatureBlock } from '../feature-block';
import { ItemCard } from '../item-card';
import { MenuDisclaimer } from '../menu-disclaimer';
import { MobilesSliderBlock } from '../mobile-slider-block';
import { MenuItemInterface, MenuSectionInterface, findEntity } from '../model';
import '../index.scss';

const { TabPane } = Tabs;

export const ConceptMenu = ({
    handleItemDetailPopUp,
    headerHeight,
}: {
    headerHeight: number;
    handleItemDetailPopUp: any;
}) => {
    const [activeTab, setActiveTab] = useState<string>('');
    const [visibleTabs, setVisibleTabs] = useState<boolean[]>([]);
    const [banner, setBanner] = useState<string>('');
    const [subnavScroll, setSubnavScroll] = useState<number>(0);
    const [tabScrolling, setTabScrolling] = useState<boolean>(false);
    const [verticalScrollOffset, setVerticalScrollOffset] = useState<number>(-140);

    const {
        bannerShown,
        template,
        orderPlaced,
        updateOrderPlaced,
        updateCartItems,
        handlingOrder,
    } = useContext(AppContext);
    const {
        conceptDetails: { menuInfo, restaurantInfo },
    } = useContext(ConceptContext);

    const segment = useSegment();
    const isMobile = useMobileScreen();
    const ldFlags = useFlags();
    const history = useHistory();

    const bannerStyles = {
        backgroundColor: template?.banners?.promo?.body_color,
        borderColor: template?.banners?.promo?.border_color,
        color: template?.banners?.promo?.font_color,
    };
    const nativeStatusBar = document.querySelector('#native-status-bar');
    const brandPrimaryColor = template?.general?.primary_color;

    const updateScrollPosition = () => {
        const subnav = document.querySelector<HTMLDivElement>('.ant-tabs-nav-list');
        if (subnav) {
            const scrollMatch = subnav.style.transform.match(/(?:\()(-*\d*)/);
            if (scrollMatch) {
                setSubnavScroll(Math.abs(parseInt(scrollMatch[1], 10)));
            }
        }
    };

    useEffect(() => {
        if (handlingOrder) {
            history.push('/checkout');
        }
    }, [handlingOrder, history]);

    useEffect(() => {
        let offset = -140;
        if (nativeStatusBar) {
            offset -= nativeStatusBar.clientHeight;
        }
        if (bannerShown) {
            const globalBanner = document.querySelector<HTMLDivElement>('.banner-wrapper');
            if (globalBanner) {
                offset -= globalBanner.clientHeight;
            }
        }
        setVerticalScrollOffset(offset);
    }, [bannerShown, nativeStatusBar]);

    useEffect(() => {
        const config = {
            params: {
                brand_id: template?.metadata.brand.id,
                platform: getPlatform(),
            },
        };

        request.get('/discounts/', config).then(res => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { brand_name, banner_text, name, promotion_id, error } = res.data;
            if (!error) {
                if (!brand_name || template?.metadata.brand.name === brand_name) {
                    setBanner(banner_text);
                    segment.promotionViewed(name, banner_text, promotion_id);
                }
            }
        });
        if (orderPlaced) {
            updateOrderPlaced(false);
            overrideGlobalOrderPlaced(false);
            updateCartItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderPlaced]);

    // If a section has no items or only paused items, don't show it
    useEffect(() => {
        if (menuInfo && menuInfo.menus[0].section_ids.length > 0) {
            setActiveTab(menuInfo.menus[0].section_ids[0]);
            const initialVisible: boolean[] = menuInfo.menus[0].section_ids.map(() => false);
            initialVisible[0] = true;
            setVisibleTabs(initialVisible);
        }
    }, [menuInfo]);

    useEffect(() => {
        if (restaurantInfo && menuInfo?.app_id && template) {
            const estimate = getEstimatedDeliveryWindow(restaurantInfo);
            segment.menuShown(estimate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, restaurantInfo]);

    useEffect(() => {
        let scrollTimeout: any;

        if (tabScrolling) {
            updateScrollPosition();
        } else {
            scrollTimeout = setTimeout(() => updateScrollPosition(), 100);
        }

        return () => clearTimeout(scrollTimeout);
    }, [tabScrolling]);

    const handleTabScroll = () => {
        setTabScrolling(true);
        setTabScrolling(false);
    };

    const menu = sanitizeMenuPausedModGroups(menuInfo).menus[0];
    const sections = menu?.section_ids.map(sectionId => findEntity(menuInfo, 'section', sectionId));

    const handleTabClick = (key: string) => {
        const elem = document.getElementById(key);
        scrollToElement(elem!, {
            offset: verticalScrollOffset,
            duration: 500,
        });
        setActiveTab(key);
        updateScrollPosition();
        const selectedSection = findEntity(menuInfo, 'section', key);
        const index = sections.findIndex(section => section.id === selectedSection.id);
        segment.menuSectionClicked(selectedSection, index, sections.length);
    };
    const renderTabBar = (props: any, DefaultTabBar: any) => {
        return (
            <DefaultTabBar {...props} onTabClick={handleTabClick} onTabScroll={handleTabScroll} />
        );
    };

    const handleSubnavScroll = (disabled: boolean, scrollForward: boolean) => {
        if (!disabled) {
            const horizontalScroll = scrollCategorySubnav(scrollForward);
            setSubnavScroll(horizontalScroll);
        }
    };

    const changeView = (inView: any, entry: any) => {
        const splitId: string[] = entry.target.id.split('|');
        const sectionIds: string[] = sections.map(el => el.id);
        const index: number = sectionIds.findIndex(id => id === splitId[1]);
        visibleTabs[index] = inView;
        setVisibleTabs(visibleTabs);
        const firstVisible: number = visibleTabs.findIndex(val => val);
        // if nothing is visible then don't set the nav. This can happen on cases where you've scrolled
        // past the last section and no visible tab headers. or if a section has a large number of items
        // then potentially the next section isn't visible yet.
        if (firstVisible >= 0) {
            setActiveTab(sectionIds[firstVisible]);
        }
        setTimeout(() => updateScrollPosition(), 100);
    };
    if (menuInfo?.app_id == null || restaurantInfo == null) {
        return <Spin size="large" />;
    }

    let hideArrowsInSubNav = false;
    const subnavWrapper = document.querySelector('.ant-tabs-nav-wrap');
    const subnavList = document.querySelector('.ant-tabs-nav-list');
    let maxScroll = 0;
    if (subnavWrapper && subnavList) {
        hideArrowsInSubNav = subnavList.clientWidth <= subnavWrapper.clientWidth;
        maxScroll = subnavList.clientWidth - subnavWrapper.clientWidth;
    }
    const isLeftArrowDisabled = subnavScroll <= 0;
    const isRightArrowDisabled = subnavScroll >= maxScroll;

    const leftArrowStyle = isLeftArrowDisabled
        ? { color: '#BFBFBF', cursor: 'auto' }
        : { color: '#262626' };
    const rightArrowStyle = isRightArrowDisabled
        ? { color: '#BFBFBF', cursor: 'auto' }
        : { color: '#262626' };

    const ArrowNavigation = {
        left: (
            <ArrowLeftOutlined
                style={leftArrowStyle}
                onClick={() => handleSubnavScroll(isLeftArrowDisabled, false)}
            />
        ),
        right: (
            <ArrowRightOutlined
                style={rightArrowStyle}
                onClick={() => handleSubnavScroll(isRightArrowDisabled, true)}
            />
        ),
    };
    const isEmailContent =
        template?.banners?.email_capture?.header_text && template?.banners?.email_capture?.body;

    const isHeroContentBlock =
        template?.menu_page?.hero_content_block?.title ||
        template?.menu_page?.hero_content_block?.image ||
        template?.menu_page?.hero_content_block?.image_description;
    const useOldHeroImage = template?.menu_page?.hero_image && !isHeroContentBlock;
    const isLeftImageContentBlock =
        template?.menu_page?.left_image_content_block?.title ||
        template?.menu_page?.left_image_content_block?.image ||
        template?.menu_page?.left_image_content_block?.image_description;
    const isRightImageContentBlock =
        template?.menu_page?.right_image_content_block?.title ||
        template?.menu_page?.right_image_content_block?.image ||
        template?.menu_page?.right_image_content_block?.image_description;

    const areRightLeftBlocks = isRightImageContentBlock && isLeftImageContentBlock;

    return (
        <>
            <div className="viewport-minus-header-height">
                <Row className="menu-page">
                    {useOldHeroImage && (
                        <Col
                            span={24}
                            className="hero-image-box"
                            style={{ width: '100%', height: 300, marginTop: 20 }}
                        >
                            <div style={{ margin: 'auto', maxWidth: 1020 }}>
                                <ImageWithFallback
                                    style={{
                                        top: '-80%',
                                        maxWidth: 1020,
                                        margin: 'auto',
                                    }}
                                    src={template?.menu_page?.hero_image}
                                    resize={1000}
                                />
                            </div>
                        </Col>
                    )}
                    {(isHeroContentBlock ||
                        isLeftImageContentBlock ||
                        isRightImageContentBlock ||
                        isEmailContent) && (
                        <div className="feature-box-wrapper" style={isMobile ? { zIndex: 1 } : {}}>
                            {isEmailContent && (
                                <EmailBlock
                                    title={template?.banners.email_capture.header_text}
                                    bodyText={template?.banners.email_capture.body}
                                    isMobile={isMobile}
                                    privacyPolicyLink={template.general.legal.privacy_policy_link}
                                    termsConditionsLink={
                                        template.general.legal.terms_conditions_link
                                    }
                                />
                            )}
                            {(isHeroContentBlock ||
                                isLeftImageContentBlock ||
                                isRightImageContentBlock) && (
                                <>
                                    {isMobile ? (
                                        <MobilesSliderBlock template={template} />
                                    ) : (
                                        <>
                                            {isHeroContentBlock && (
                                                <div
                                                    className="feature-box"
                                                    style={{ maxHeight: '328px' }}
                                                >
                                                    <FeatureBlock
                                                        title={
                                                            template?.menu_page?.hero_content_block
                                                                ?.title
                                                        }
                                                        text={
                                                            template?.menu_page?.hero_content_block
                                                                ?.image_description
                                                        }
                                                        mediaSrc={
                                                            template?.menu_page?.hero_content_block
                                                                ?.image
                                                        }
                                                        big
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className="feature-box"
                                                style={
                                                    isMobile
                                                        ? { flexDirection: 'column', gap: '35px' }
                                                        : {}
                                                }
                                            >
                                                {isLeftImageContentBlock && (
                                                    <FeatureBlock
                                                        title={
                                                            template?.menu_page
                                                                ?.left_image_content_block?.title
                                                        }
                                                        text={
                                                            template?.menu_page
                                                                ?.left_image_content_block
                                                                ?.image_description
                                                        }
                                                        mediaSrc={
                                                            template?.menu_page
                                                                ?.left_image_content_block?.image
                                                        }
                                                        big={!areRightLeftBlocks}
                                                        bigNotHero={!areRightLeftBlocks}
                                                    />
                                                )}
                                                {isRightImageContentBlock && (
                                                    <FeatureBlock
                                                        title={
                                                            template?.menu_page
                                                                ?.right_image_content_block?.title
                                                        }
                                                        text={
                                                            template?.menu_page
                                                                ?.right_image_content_block
                                                                ?.image_description
                                                        }
                                                        mediaSrc={
                                                            template?.menu_page
                                                                ?.right_image_content_block?.image
                                                        }
                                                        big={!areRightLeftBlocks}
                                                        bigNotHero={!areRightLeftBlocks}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    <div className="tab-menu-container">
                        <div className="tab-container">
                            <Affix
                                offsetTop={isMobile ? headerHeight + 10 : headerHeight + 30}
                                className="width-100 affix-container"
                            >
                                <Row className="tabs-row">
                                    <Tabs
                                        type="card"
                                        activeKey={activeTab}
                                        renderTabBar={renderTabBar}
                                        className="menu-tabs width-100"
                                        tabBarExtraContent={
                                            hideArrowsInSubNav ? null : ArrowNavigation
                                        }
                                    >
                                        {sections.map((section: MenuSectionInterface) => {
                                            return <TabPane tab={section.title} key={section.id} />;
                                        })}
                                    </Tabs>
                                </Row>
                            </Affix>
                        </div>
                        <Row className="menu-container">
                            {banner && ldFlags.enableVouchers && (
                                <Row className="banner" style={bannerStyles}>
                                    {banner}
                                </Row>
                            )}
                            {sections.map((section: MenuSectionInterface) => (
                                <Row
                                    key={section.id}
                                    gutter={[24, 24]}
                                    id={section.id}
                                    style={{ width: '105%' }}
                                >
                                    <Col xs={24}>
                                        <InView key={`inview_${section.id}`} onChange={changeView}>
                                            {({ ref }) => {
                                                return (
                                                    <div ref={ref} id={`div|${section.id}`}>
                                                        <h2 className="section-heading">
                                                            {section.title}
                                                        </h2>
                                                    </div>
                                                );
                                            }}
                                        </InView>
                                    </Col>
                                    {section.item_ids
                                        .map((id: string) => {
                                            return findEntity(menuInfo, 'item', id);
                                        })
                                        .map(
                                            (item: MenuItemInterface) =>
                                                !item.is_paused && (
                                                    <ItemCard
                                                        section={section}
                                                        key={item.id}
                                                        item={item}
                                                        idHierarchy={[menu.id, section.id, item.id]}
                                                        onSelect={handleItemDetailPopUp}
                                                        showPrice={true}
                                                    />
                                                ),
                                        )}
                                </Row>
                            ))}
                            <MenuDisclaimer />
                            {!isMobile && <div className="footer-invisible-divider" />}
                            <Row
                                className={isMobile ? 'footer-section-mobile' : 'footer-section'}
                                style={{ background: brandPrimaryColor }}
                            >
                                <Footer />
                            </Row>
                        </Row>
                    </div>
                </Row>
            </div>
        </>
    );
};
