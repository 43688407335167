import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Typography } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useContext } from 'react';
import { AppContext } from '../../../contexts/app-context';
// import { AccountLinkingSection } from './account-linking-section';
import { useMobileScreen } from '../../utils/general';
import { UserInfoSection } from './user-info-section';
import './index.scss';

const { Title } = Typography;

export const ProfilePage = () => {
    const ldFlags = useFlags();
    const isMobile = useMobileScreen();
    const { userProfile, updateUserProfile } = useContext(AppContext);
    if (!ldFlags.enableAccounts) {
        return null;
    }

    const renderUserProfileSection = () => {
        if (!userProfile) {
            return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />;
        } else {
            return (
                <UserInfoSection
                    userProfile={userProfile}
                    updateUserProfileContext={updateUserProfile}
                />
            );
        }
    };

    return (
        <main>
            <Title level={isMobile ? 3 : 2}>Profile</Title>
            {renderUserProfileSection()}
            {/* <AccountLinkingSection /> */}
        </main>
    );
};
