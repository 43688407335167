import { ArrowLeftOutlined, CarOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Col, Collapse, Drawer, Form, Input, RadioChangeEvent, Row } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import { ConceptContext } from '../../contexts/concept-context';
import { RadioButtonGroup } from '../ui-components/RadioButtonGroup';
import { TemplateButton } from '../ui-components/template-button';
import { placesToAddress, updateAddress } from '../utils/address';
import { useMobileScreen } from '../utils/general';
import useSegment from '../utils/segment';
import { CheckoutForms } from './index';

const { Panel } = Collapse;
interface DPFormProps {
    isOpen: boolean;
    editButtonDisabled: boolean;
    onClickContinue: () => void;
    onEdit: (key: CheckoutForms) => void;
    onCancelEdit: (key: number) => void;
}

export const DeliveryPreferenceForm: FC<DPFormProps> = ({
    isOpen,
    editButtonDisabled,
    onClickContinue,
    onEdit,
    onCancelEdit,
}) => {
    const [form] = Form.useForm();
    const [expand, setExpand] = useState(isOpen);

    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);
    const {
        orderAddress,
        deliveryPreference,
        template,
        userProfile,
        updateDeliveryPreference,
        updateUserProfile,
    } = useContext(AppContext);

    const segment = useSegment();
    const isMobile = useMobileScreen();
    const { getAccessTokenSilently } = useAuth0();
    const showDeliveryPreference: boolean = !!template?.checkout_page?.show_delivery_preference_ui;

    useEffect(() => {
        setExpand(isOpen);
    }, [isOpen]);

    const onCancel = () => {
        setExpand(false);
        segment.checkoutCancelLinkClicked('Edit Delivery Preferences');
        onCancelEdit(CheckoutForms.DeliveryPreference);
    };

    const updateUserProfileAddressWithNewDeliveryPreference = (values: {
        dd_notes: string;
        radio_group: string;
    }) => {
        const addresses = [...(userProfile?.service_info || [])];

        if (orderAddress) {
            const orderAddressParts = placesToAddress(orderAddress);

            const addressMatchIndex = addresses.findIndex(address => {
                const address1Match = address.address1 === orderAddressParts?.address1;
                const postalcodeMatch = address.postalcode === orderAddressParts?.postalcode;

                return address1Match && postalcodeMatch;
            });

            if (addressMatchIndex !== -1) {
                const matchedAddress = addresses[addressMatchIndex];

                const updatedAddress = {
                    ...matchedAddress,
                    delivery_preference: values.radio_group,
                    special_instructions: values.dd_notes,
                };

                addresses[addressMatchIndex] = updatedAddress;

                updateAddress(
                    { service_info: addresses },
                    getAccessTokenSilently,
                    updateUserProfile,
                );
            }
        }
    };

    const onFinish = (values: { dd_notes: string; radio_group: string }) => {
        segment.updateCTAClicked('Update delivery preferences');
        updateDeliveryPreference({
            preference: isDinnerbell ? deliveryPreference?.preference : values.radio_group,
            text: values.dd_notes,
        });
        updateUserProfileAddressWithNewDeliveryPreference(values);
        setExpand(false);
        onCancelEdit(CheckoutForms.DeliveryPreference);
        onClickContinue();
    };

    const toggleExpand = (event: any) => {
        event.persist();
        if (expand) {
            segment.checkoutCancelLinkClicked('Edit Delivery Preferences');
            onCancelEdit(CheckoutForms.DeliveryPreference);
        } else {
            segment.checkoutStepViewed('Delivery Preferences');
            segment.checkoutEditDeliveryPreferenceLinkClicked();
            onEdit(CheckoutForms.DeliveryPreference);
        }
        setExpand(!expand);
    };

    const EditButton = () => (
        <Button
            type="link"
            onClick={event => toggleExpand(event)}
            data-testid="delivery-preference-edit-btn"
            className={`checkout-edit-link ${editButtonDisabled ? 'disabled-button' : ''}`}
            disabled={editButtonDisabled}
        >
            Edit
        </Button>
    );

    const CancelButton = () => (
        <Button
            type="default"
            size="large"
            className={isMobile ? '' : 'mb-10 mr-10'}
            block={isMobile}
            onClick={onCancel}
        >
            Cancel
        </Button>
    );

    const UpdateButton = () => (
        <TemplateButton
            type="primary"
            htmlType="submit"
            data-testid="delivery-preference-submit-btn"
            size="large"
            block={isMobile}
        >
            Update
        </TemplateButton>
    );

    const onChangedRadio = (e: RadioChangeEvent) => {
        segment.deliveryPreferencesOptionSelected(optionTypeMapping[e.target.value]);
    };

    const { isDinnerbell } = useContext(AppContext);
    const iconColor = isDinnerbell ? '#f1c143' : '#bbb';
    const iconStyles = {
        fontSize: '20px',
        color: iconColor,
    };

    const PreferenceForm = () => (
        <Form
            form={form}
            name="checkout_delivery_preference"
            className={expand ? '' : 'ant-collapse-content-hidden'}
            onFinish={onFinish}
        >
            {showDeliveryPreference && (
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="radio_group"
                            initialValue={deliveryPreference?.preference}
                            rules={[{ required: true, message: 'Please select an option!' }]}
                        >
                            <RadioButtonGroup
                                {...radioButtonProps}
                                onChange={(e: RadioChangeEvent) => onChangedRadio(e)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}
            <Row>
                <Col span={24}>
                    <Form.Item name="dd_notes" initialValue={deliveryPreference?.text}>
                        <Input.TextArea
                            rows={2}
                            placeholder="Add any additional notes to help your driver find you (optional)"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="action-row">
                {isMobile ? (
                    <>
                        <Col span={12} className="pr-5">
                            <CancelButton />
                        </Col>
                        <Col span={12} className="pl-5">
                            <UpdateButton />
                        </Col>
                    </>
                ) : (
                    <Col span={24}>
                        <CancelButton />
                        <UpdateButton />
                    </Col>
                )}
            </Row>
        </Form>
    );

    if (restaurantInfo == null || restaurantInfo.delivery == null) {
        return null;
    }

    return (
        <Collapse
            bordered={false}
            expandIconPosition={'right'}
            expandIcon={EditButton}
            activeKey={expand ? '2' : '0'}
        >
            <Panel
                className="checkout-form-panel"
                header={
                    <div className="checkout-ci-title" onClick={event => toggleExpand(event)}>
                        <CarOutlined style={iconStyles} />
                        <div>
                            {deliveryPreference ? (
                                <span className="checkout-subtext">
                                    {deliveryPreference?.preference}
                                </span>
                            ) : (
                                <span className="checkout-subtext">Delivery preferences</span>
                            )}
                        </div>
                    </div>
                }
                key="2"
            >
                {isMobile ? (
                    <Drawer
                        className="checkout-edit-drawer"
                        closable={true}
                        closeIcon={<ArrowLeftOutlined />}
                        onClose={onCancel}
                        placement="right"
                        title="Delivery Preferences"
                        width="100%"
                        visible={expand}
                    >
                        <PreferenceForm />
                    </Drawer>
                ) : (
                    <PreferenceForm />
                )}
            </Panel>
        </Collapse>
    );
};

const radioButtonProps = {
    options: [
        { label: 'Meet the driver at the door', value: 'Meet the driver at the door' },
        {
            label: 'Contactless delivery (leave food at door)',
            value: 'Contactless delivery (leave food at door)',
        },
    ],
};

const optionTypeMapping: { [k: string]: string } = {
    'Meet the driver at the door': 'Handoff',
    'Contactless delivery (leave food at door)': 'Contactless',
};
