import { Badge, Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { BadgeStatusType, ProgressType, SIndicatorProps } from './models';

const { Text } = Typography;

export const StatusIndicatorBadge: FC<SIndicatorProps> = ({ orderStatus }) => {
    return (
        <Row gutter={12}>
            {orderStatus.length > 0 &&
                orderStatus.map((status, index) => (
                    <Col className="mt-10" key={index} onClick={e => e.stopPropagation()}>
                        <Badge status={progressColorMapping[status]} />
                        <Badge />
                        <Text type="secondary">{status}</Text>
                    </Col>
                ))}
        </Row>
    );
};

export const progressColorMapping: { [key in ProgressType]: BadgeStatusType } = {
    'In progress': 'success',
    Scheduled: 'success',
    Complete: 'default',
    'Full Refund': 'default',
    'Partial Refund': 'default',
    Canceled: 'warning',
};
