import { Drawer, Modal } from 'antd';
import React from 'react';
import { useMobileScreen } from '../../utils/general';
import './index.scss';

interface BottomDrawerPopupProps {
    styleDrawer?: React.CSSProperties;
    styleModal?: React.CSSProperties;
    styleModalBody?: React.CSSProperties;
    visible: boolean;
    onClose: (e?: any) => void;
    width?: number | string;
    className?: string;
    closable?: boolean;
    useModal?: boolean;
    useDrawer?: boolean;
}

export const BottomDrawerPopup: React.FC<BottomDrawerPopupProps> = ({
    children,
    styleDrawer,
    styleModal,
    visible,
    onClose,
    width,
    closable = false,
    useModal = true,
    useDrawer = true,
    className = 'drawer-bottom',
    styleModalBody,
}) => {
    const isMobile = useMobileScreen();

    const drawerStyle = {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        padding: '5px',
        backgroundColor: 'white',
        height: 'unset',
        maxHeight: '90vh',
    };

    const modalBodyStyle = {
        overflow: 'auto',
    };

    return (
        <>
            {isMobile
                ? useDrawer && (
                      <Drawer
                          className={className}
                          contentWrapperStyle={Object.assign(drawerStyle, styleDrawer)}
                          placement="bottom"
                          visible={visible}
                          onClose={onClose}
                      >
                          {children}
                      </Drawer>
                  )
                : useModal && (
                      <Modal
                          centered
                          destroyOnClose
                          width={width}
                          style={styleModal}
                          bodyStyle={Object.assign(modalBodyStyle, styleModalBody)}
                          visible={visible}
                          onCancel={onClose}
                          footer={null}
                          closable={closable}
                      >
                          {children}
                      </Modal>
                  )}
        </>
    );
};
