import { ArrowLeftOutlined, ClockCircleOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Collapse, Drawer } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { OrderEvent } from '../restaurant/model';
import { FutureOrderPopup } from '../ui-components/future-order-components/future-order-popup';
import { futureOrderUtils } from '../ui-components/future-order-components/useFutureOrderPopup';
import { getEstimatedDeliveryWindow, useMobileScreen } from '../utils/general';
import useSegment from '../utils/segment';
import { CheckoutForms } from './index';

const { Panel } = Collapse;
interface DPFormProps {
    isOpen: boolean;
    editButtonDisabled: boolean;
    onEdit: (key: CheckoutForms) => void;
    onCancelEdit: (key: number) => void;
    orderPassedCutOffTime?: boolean;
}

export const FutureOrderForm: FC<DPFormProps> = ({
    isOpen,
    editButtonDisabled,
    onEdit,
    onCancelEdit,
    orderPassedCutOffTime,
}) => {
    const [expand, setExpand] = useState(isOpen);
    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);
    const { template, futureOrderDate } = useContext(AppContext);
    const { getTimeString } = futureOrderUtils;
    const segment = useSegment();
    const cdpLocation = useContext(CdpLocationContext);
    const isMobile = useMobileScreen();
    const { isDinnerbell } = useContext(AppContext);

    useEffect(() => {
        setExpand(isOpen);
    }, [isOpen]);

    const toggleExpand = (event: any) => {
        event.persist();
        if (expand) {
            onCancelEdit(CheckoutForms.FutureOrder);
        } else {
            onEdit(CheckoutForms.FutureOrder);
            segment.editOrderTimingLinkClicked(cdpLocation);
        }
        setExpand(!expand);
    };

    const EditBtn = () => (
        <Button
            type="link"
            onClick={event => toggleExpand(event)}
            className={`checkout-edit-link ${editButtonDisabled ? 'disabled-button' : ''}`}
            disabled={editButtonDisabled}
        >
            Edit
        </Button>
    );

    const toggleFutureOrderPopover = (event: OrderEvent) => {
        if (event.action === 'Cancel' && event.orderType) {
            segment.cancelOrderTimingLinkClicked(event.orderType, cdpLocation);
        }
        if (event.action === 'Update' && event.orderType) {
            segment.updateOrderTimingCTAClicked(event.orderType, cdpLocation);
        }
        setExpand(false);
        onCancelEdit(CheckoutForms.FutureOrder);
    };

    const estimateDeliveryWindow = getEstimatedDeliveryWindow(restaurantInfo);
    const isFutureOrderSupported = template?.future_order?.supported_order_type.scheduled;

    const iconColor = isDinnerbell ? '#f1c143' : '#bbb';
    const iconStyles = {
        fontSize: '20px',
        color: iconColor,
    };

    const PopupForm = () => (
        <FutureOrderPopup
            onCancel={toggleFutureOrderPopover}
            isPopupMode={false}
            useDropdownForTimeBox={true}
        />
    );

    if (restaurantInfo == null || restaurantInfo.delivery == null) {
        return null;
    }

    return (
        <Collapse
            bordered={false}
            expandIconPosition={'right'}
            expandIcon={isFutureOrderSupported ? EditBtn : () => null}
            activeKey={expand ? '2' : '0'}
        >
            <Panel
                className="checkout-form-panel"
                header={
                    <div
                        className={
                            isFutureOrderSupported
                                ? 'checkout-ci-title'
                                : 'checkout-ci-title force-default-cursor'
                        }
                        onClick={isFutureOrderSupported ? event => toggleExpand(event) : () => null}
                    >
                        {orderPassedCutOffTime ? (
                            <WarningFilled
                                style={{ fontSize: '20px', color: '#FAAD14' }}
                                className={expand ? '' : 'checkout-title-icon'}
                            />
                        ) : (
                            <ClockCircleOutlined style={iconStyles} />
                        )}
                        <div>
                            <span className="checkout-subtext">
                                {futureOrderDate ? (
                                    <>
                                        {futureOrderDate.date.format('dddd, M/D')}
                                        <br />
                                        {getTimeString(
                                            futureOrderDate.date,
                                            futureOrderDate.time.startTime,
                                        )}{' '}
                                        -{' '}
                                        {getTimeString(
                                            futureOrderDate.date,
                                            futureOrderDate.time.endTime,
                                        )}
                                    </>
                                ) : estimateDeliveryWindow ? (
                                    `ASAP (${estimateDeliveryWindow.from}-${estimateDeliveryWindow.to} min)`
                                ) : (
                                    'ASAP'
                                )}
                            </span>
                        </div>
                    </div>
                }
                key="2"
            >
                {isMobile ? (
                    <Drawer
                        className="checkout-edit-drawer"
                        closable={true}
                        closeIcon={<ArrowLeftOutlined />}
                        onClose={() => {
                            toggleFutureOrderPopover({ action: 'Cancel' });
                            segment.closeScreenLinkClicked('Date-Address Picker');
                        }}
                        placement="right"
                        title="Delivery Date"
                        width="100%"
                        visible={expand}
                    >
                        <PopupForm />
                    </Drawer>
                ) : (
                    <div style={{ margin: '20px 0px' }}>
                        <PopupForm />
                    </div>
                )}
            </Panel>
        </Collapse>
    );
};
