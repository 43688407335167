import { FacebookOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import { TemplateButton } from '../ui-components/template-button';
import { useMobileScreen } from '../utils/general';
import useSegment from '../utils/segment';

export const NoLocations: FC = () => {
    const { template } = useContext(AppContext);
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState('');
    const isMobile = useMobileScreen();
    const segment = useSegment();

    const onFinishedEmail = (values: { email: string }) => {
        setEmail(values.email);
    };

    const onSubmitEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        segment.submitEmailCTA(false);
        segment.emailCapturedAddressEntry(email, false);
        segment.IdentifyEmail(email);
        setEmailSent(true);
    };

    if (!template) {
        return null;
    }

    const { instagram, facebook, twitter } = template.general?.social_media || {};

    return (
        <div className="mt-10" data-testid="no-location">
            {!emailSent ? (
                <>
                    <h3 className="location-error">
                        Sorry, it looks like {template.metadata.brand.name} isn't available near you
                        (yet!). Share your email with us and we'll let you know when we open in your
                        area.
                    </h3>

                    <Form
                        className="width-100"
                        name="address_submit"
                        onFinish={onFinishedEmail}
                        onSubmitCapture={onSubmitEmail}
                    >
                        <Form.Item
                            style={{ marginTop: '20px' }}
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input
                                type="email"
                                placeholder="Enter your email address"
                                required
                                size="large"
                            />
                            <h5 className="mt-5">
                                By sharing my email address, I agree to receive marketing
                                communications from {template.metadata.brand.name}.
                            </h5>
                        </Form.Item>
                        <Form.Item className="address-form-button-wrap">
                            <TemplateButton
                                type="primary"
                                htmlType="submit"
                                block={isMobile}
                                size="large"
                                className={isMobile ? 'mt-20' : ''}
                            >
                                I'd like to stay in touch
                            </TemplateButton>
                        </Form.Item>
                    </Form>
                </>
            ) : (
                <>
                    <h3>
                        Thanks! Keep an eye on your inbox for the latest news & deals.
                        {(instagram || facebook || twitter) && (
                            <> In the meantime, see what we’re up to on social.</>
                        )}
                    </h3>

                    <div className="mt-10 social-icons blue-color">
                        {instagram && (
                            <Button
                                className="mb-10"
                                type="primary"
                                ghost
                                block
                                icon={<InstagramOutlined />}
                                href={instagram}
                                target="_blank"
                                onClick={() =>
                                    segment.linkClicked('www.instagram.com', 'Instagram')
                                }
                            >
                                Instagram
                            </Button>
                        )}
                        {facebook && (
                            <Button
                                className="mb-10"
                                type="primary"
                                ghost
                                block
                                icon={<FacebookOutlined />}
                                href={facebook}
                                target="_blank"
                                onClick={() => segment.linkClicked('www.facebook.com', 'Facebook')}
                            >
                                Facebook
                            </Button>
                        )}
                        {twitter && (
                            <Button
                                className="mb-10"
                                type="primary"
                                ghost
                                block
                                icon={<TwitterOutlined />}
                                href={twitter}
                                target="_blank"
                                onClick={() => segment.linkClicked('www.twitter.com', 'Twitter')}
                            >
                                Twitter
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
