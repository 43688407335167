import { LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Col, Divider, Space, Typography } from 'antd';
import log from 'loglevel';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { OrderList } from '../../../../services/OrderHistory';
import { PageNotFound } from '../../menu/page-not-found';
import { ItemList } from '../../order-summary/itemlist';
import {
    convertTimeStringToRestOffset,
    formatMoneyInteger,
    useMobileScreen,
} from '../../utils/general';
import useSegment from '../../utils/segment';
import { CartSummary } from './cart-summary';
import { CustomerOrderDetails } from './customer-order-details';
import { POrderContentProps } from './models';
import { OrderStatusTag } from './order-status-tag';
import { StatusIndicatorBadge } from './status-indicator-badge';

const { Title, Text } = Typography;

export const PanelOrderContent: FC<POrderContentProps> = ({
    order,
    orderStatus,
    estimatedDeliveryTime,
    droppedOffDateTime,
    refundDetails,
    trackOrderButton,
}) => {
    const isMobile = useMobileScreen();
    const segment = useSegment();
    const [deliveryStatus] = orderStatus;

    const { getAccessTokenSilently } = useAuth0();

    const orderDetailsInfoQuery = useQuery(
        ['orderDetailsInfoQuery', [order.order_id, order.location_id]],
        async () => {
            const token = await getAccessTokenSilently();
            return OrderList.getOrderDetails(
                {
                    order_id: order?.order_id,
                    location_id: order?.location_id,
                    app_id: order?.app_id,
                },
                token,
            );
        },
        {
            onError: error => {
                const errorMessage = 'An error occured while fetching order details';
                segment.errorShown('There has been an issue loading this page');
                log.error(`${errorMessage}: ${error}`);
            },
        },
    );
    const { data: orderDetails, isSuccess, isLoading, isError } = orderDetailsInfoQuery;

    log.debug('%c FETCH ORDER DETAILS ', 'background-color: red', orderDetails?.data);

    const locationInfoQuery = useQuery(
        ['locationInfoQuery', [order.app_id]],
        () => {
            return OrderList.getLocationInfo({
                app_id: order?.app_id,
            });
        },
        {
            onError: error => {
                const errorMessage = 'An error occured while fetching location details';
                segment.errorShown('There has been an issue loading this page');
                log.error(`${errorMessage}: ${error}`);
            },
        },
    );
    log.debug(
        '%c FETCH RESTAURANT LOCATION DETAILS ',
        'background-color: red',
        locationInfoQuery?.data,
    );

    const futureOrderdeliveryWindow = () => {
        const startTime = order.scheduled_order_start_time;
        const endTime = order.scheduled_order_end_time;
        const restaurantTimezoneOffset = order.timezone_offsets.restaurant_address;

        if (endTime == null) {
            return null;
        }
        return `${convertTimeStringToRestOffset(startTime, restaurantTimezoneOffset).format(
            'L, LT',
        )} - ${convertTimeStringToRestOffset(endTime, restaurantTimezoneOffset).format('LT')}`;
    };

    const DrawerOrderDetailsPreview = () => {
        return (
            <>
                <Title level={5}>{order.brand_name}</Title>
                <Text className="order-history-subtext">
                    <span>
                        {`Order #${order.order_id} • `}
                        {convertTimeStringToRestOffset(
                            order.created_at,
                            order.timezone_offsets.restaurant_address,
                        ).format('dddd, L')}
                    </span>
                    <span style={{ display: 'block' }}>
                        {`${formatMoneyInteger(order.total_amount)} (${
                            order.total_quantity
                        } items)`}
                    </span>
                </Text>
            </>
        );
    };

    return (
        <section>
            {isSuccess && locationInfoQuery.isSuccess && (
                <Space className="order-drawer-content width-100" size={24} direction="vertical">
                    {isMobile && (
                        <Col span={24} onClick={e => e.stopPropagation()}>
                            <DrawerOrderDetailsPreview />
                            <StatusIndicatorBadge orderStatus={orderStatus} />
                            {trackOrderButton}
                        </Col>
                    )}
                    <OrderStatusTag
                        orderStatus={orderStatus}
                        estimatedDelivery={estimatedDeliveryTime}
                        futureOrderDeliveryWindow={futureOrderdeliveryWindow}
                        deliveryAddress={orderDetails?.data.customer_info}
                        paymentMethodInfo={orderDetails?.data?.payment_info}
                        refundDetails={refundDetails}
                        restaurantTimezoneOffset={order.timezone_offsets.restaurant_address}
                    />

                    <ItemList
                        pageType="order-history"
                        cartItems={orderDetails?.data?.contents.line_items}
                    />
                    <Divider />

                    <CartSummary
                        subtotalInfo={orderDetails?.data?.subtotal_info}
                        refundDetails={refundDetails}
                    />
                    <Divider />

                    <CustomerOrderDetails
                        orderStatus={deliveryStatus}
                        restaurantAdddress={locationInfoQuery?.data?.data?.address}
                        placedOrderDate={order.created_at}
                        estimatedDeliveryDate={estimatedDeliveryTime}
                        droppedOffDateTime={droppedOffDateTime}
                        futureOrderDeliveryWindow={futureOrderdeliveryWindow}
                        paymentMethodInfo={orderDetails?.data?.payment_info}
                        deliveryAddress={orderDetails?.data.customer_info}
                        restaurantTimezoneOffset={order.timezone_offsets.restaurant_address}
                    />
                </Space>
            )}
            {isLoading && (
                <div className="spin-wrapper">
                    <LoadingOutlined style={{ fontSize: '2em' }} />
                </div>
            )}
            {isError || (locationInfoQuery.isError && <PageNotFound />)}
        </section>
    );
};
