import { useAuth0 } from '@auth0/auth0-react';
import { Button, Typography } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC, useContext } from 'react';
import { AppContext } from '../../../contexts/app-context';
import { generateAuth0RedirectOptions } from '../../utils/general';
import useSegment from '../../utils/segment';

const { Title } = Typography;

interface LoginProps {
    showInColumn?: boolean;
    signupTouchPoint: string;
}

export const Login: FC<LoginProps> = ({ showInColumn = false, signupTouchPoint }) => {
    const ldFlags = useFlags();
    const segment = useSegment();
    const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const { userProfile, template } = useContext(AppContext);

    const logoutRoute = ldFlags.enableLamv ? '/orders' : '/orders/address';
    const logoutRedirectUri = `${window.location.origin}${logoutRoute}`;

    if (!ldFlags.enableAccounts) {
        return null;
    }

    const rowStyle = !showInColumn
        ? { gridTemplateColumns: 'repeat(2, 1fr)', marginBottom: '0.5rem' }
        : {};
    const isUserNameEmail = userProfile && userProfile.name?.includes('@');

    const handleButtonClick = (isSignUp: boolean = false) => {
        const auth0Options = generateAuth0RedirectOptions(template);
        if (isSignUp) {
            auth0Options.mode = 'signUp';
        } else {
            auth0Options.mode = 'login';
        }
        localStorage.setItem('signupTouchpoint', signupTouchPoint);
        segment.accountAuthCTAClicked(isSignUp);
        loginWithRedirect(auth0Options);
    };
    const showName = userProfile?.name && !isUserNameEmail;

    return (
        <section style={{ cursor: 'initial', width: '100%' }}>
            {!isAuthenticated && (
                <>
                    <div style={{ ...rowStyle, display: 'grid', gridGap: '0.7rem', width: '100%' }}>
                        <Button
                            size="large"
                            block
                            style={{
                                borderRadius: '8px',
                                backgroundColor: template?.general?.primary_color,
                                border: 'none',
                                color: template?.general?.secondary_color || 'white',
                            }}
                            onClick={() => {
                                handleButtonClick(true);
                            }}
                            title="/login"
                        >
                            Sign up
                        </Button>
                        <Button
                            size="large"
                            block
                            style={{
                                borderRadius: '8px',
                                backgroundColor: '#F0F0F0',
                                border: 'none',
                            }}
                            onClick={() => {
                                handleButtonClick();
                            }}
                            title="/login"
                        >
                            Sign in
                        </Button>
                    </div>
                </>
            )}

            {isAuthenticated && !isLoading && (
                <>
                    <Title className="mr-10" level={4} style={{ width: '100%' }}>
                        <span style={{ fontWeight: 400 }}>Hello{showName ? ',' : '!'}</span>{' '}
                        {showName && `${userProfile?.name}!`}
                    </Title>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                            segment.signOutCTAClicked();
                            logout({ returnTo: logoutRedirectUri });
                            localStorage.clear();
                        }}
                    >
                        Sign out
                    </Button>
                </>
            )}
        </section>
    );
};
