import { RedirectLoginOptions } from '@auth0/auth0-react';
import { Capacitor } from '@capacitor/core';
import { Grid } from 'antd';
import currency from 'currency.js';
import Fuse from 'fuse.js';
import moment from 'moment-timezone';
import { useCallback, useRef, useState } from 'react';
import { BrandTemplate, FutureOrderDate } from '../../contexts/app-context';
import {
    Block,
    CartItemInterface,
    DayPartInterface,
    DaysOfWeek,
    DeliverableDate,
    FutureOrder,
    MenuItemInterface,
    MenuManagerInterface,
    MenuSectionInterface,
    ModifierGroupsItemInterface,
    Time,
    WeekWindows,
    findEntity,
    isFutureOrderType,
    matchNestedRule,
} from '../menu/model';
import { HolidayHour, LocationMatchResponse } from '../restaurant/model';
import request from './request';

export const _ = {
    cloneJSON(json: unknown) {
        return JSON.parse(JSON.stringify(json));
    },
    isEmpty(v: any): boolean {
        if (v == null) {
            return true;
        } else if (Array.isArray(v) && v.length === 0) {
            return true;
        } else if (v === false || v === '') {
            return true;
        } else if (Object.keys(v).length === 0 && v.constructor === Object) {
            return true;
        }
        return false;
    },
};

export const numToDay = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
] as const;

export function addMinToTime(time: Time, duration: number): Time {
    const totalMin = time.min + duration;
    const min = totalMin % 60;
    const hr = time.hr + Math.floor(totalMin / 60);
    return { hr, min };
}

//subract minutes from {hr, min}, make sure we subtract an hour if minutes are <0
export function subtractMinFromTime(time: Time, duration: number): Time {
    const mins = time.min - duration;
    const hr = mins >= 0 ? time.hr : time.hr - 1;
    const min = mins >= 0 ? mins : 60 - Math.abs(mins);
    return { hr, min };
}

export function convertTimeStrToObj(time: string): Time {
    const hrStr = time.substring(0, 2);
    const hr = parseInt(hrStr, 10);

    const minStr = time.substring(3, 5);
    const min = parseInt(minStr, 10);
    return { hr, min };
}

export function earlierThan(time1: Time, time2: Time) {
    if (time1.hr < time2.hr) {
        return true;
    } else if (time1.hr === time2.hr && time1.min < time2.min) {
        return true;
    }
    return false;
}

function sortWindows(weekWindows: WeekWindows) {
    const dayWindowsArray = Object.values(weekWindows);
    for (const dayWindows of dayWindowsArray) {
        if (dayWindows) {
            sortBlocks(dayWindows);
        }
    }
}

function sortBlocks(blocks: Block[]) {
    blocks.sort((first: Block, second: Block): number =>
        earlierThan(first.startTime, second.startTime) ? -1 : 1,
    );
}

// get adjusted windows of day of paused_until
export function getPauseAdjustedWindows(
    pausedUntil: string | undefined,
    deliverableDate: DeliverableDate,
    windows: WeekWindows | null,
    dayOfWeek: DaysOfWeek,
    template: BrandTemplate | null,
    menuInfo: MenuManagerInterface,
    restaurantInfo: LocationMatchResponse | null,
) {
    const onDayOfPausedUntil = deliverableDate.date.isSame(pausedUntil, 'day');
    const userArrivedBeforePausedUntil = moment().isBefore(pausedUntil);

    const futureOrder = template?.future_order;

    if (
        pausedUntil &&
        onDayOfPausedUntil &&
        userArrivedBeforePausedUntil &&
        windows &&
        restaurantInfo?.delivery?.timezone &&
        isFutureOrderType(futureOrder)
    ) {
        const dayOfPausedUntilSchedule = getMenuSchedule(menuInfo.menus[0].day_parts, dayOfWeek);

        const remainingWindowsAfterPause = removeInvalidWindows(
            windows[dayOfWeek] || [],
            futureOrder,
            dayOfPausedUntilSchedule,
            {
                date: new Date(pausedUntil),
                inRestaurantTimezone: true,
            },
            restaurantInfo.delivery.timezone,
        );
        return remainingWindowsAfterPause;
    } else {
        return null;
    }
}

export function getHolidayHourWindows(
    deliverableDate: DeliverableDate,
    selectedDateIndex: number,
    pausedUntil: string | undefined,
    restaurantInfo: LocationMatchResponse | null,
    template: BrandTemplate | null,
) {
    let holidayHours: HolidayHour | undefined;

    if (restaurantInfo?.delivery?.schedule?.holiday_hours) {
        holidayHours = restaurantInfo.delivery.schedule?.holiday_hours.find(x => {
            return x.date === deliverableDate.date.format('YYYY-MM-DD');
        });
    }

    const futureOrder = template?.future_order;
    if (
        holidayHours &&
        template?.future_order.delivery_window_duration &&
        isFutureOrderType(futureOrder)
    ) {
        const startTime = convertTimeStrToObj(holidayHours.start_time);
        const endTime = convertTimeStrToObj(holidayHours.end_time);

        const holidayDeliveryStart = addMinToTime(startTime, futureOrder?.delivery_buffer);
        const holidayDeliveryEnd = addMinToTime(endTime, futureOrder?.delivery_buffer);

        let holidayWindows = generateDeliveryWindowsPerDay(
            holidayDeliveryStart,
            holidayDeliveryEnd,
            futureOrder.delivery_window_duration,
        );
        const onDayOfPausedUntil = deliverableDate.date.isSame(pausedUntil, 'day');
        const userArrivedBeforePausedUntil = moment().isBefore(pausedUntil);
        if (
            pausedUntil &&
            onDayOfPausedUntil &&
            userArrivedBeforePausedUntil &&
            restaurantInfo?.delivery?.timezone
        ) {
            holidayWindows = removeInvalidWindows(
                holidayWindows,
                futureOrder,
                [{ startTime, endTime }],
                { date: new Date(pausedUntil), inRestaurantTimezone: true },
                restaurantInfo.delivery.timezone,
            );
        } else if (selectedDateIndex === 0 && restaurantInfo?.delivery?.timezone) {
            holidayWindows = removeInvalidWindows(
                holidayWindows,
                futureOrder,
                [{ startTime, endTime }],
                { date: new Date(), inRestaurantTimezone: false },
                restaurantInfo.delivery.timezone,
            );
        }
        return holidayWindows;
    }
    return null;
}

export function removeInvalidWindows(
    windows: Block[],
    futureOrder: FutureOrder,
    todaySchedule: Block[],
    dateUsedToFilterWindows: { date: Date; inRestaurantTimezone: boolean },
    restaurantTimezone: string,
) {
    // const validWindows: WeekWindows = {};

    // // delivery limit
    // const deliveryLimitDays = Math.floor(futureOrder?.delivery_limit / 1440);
    // const currentDayNum = date.getDay();

    // for (let dayNum = currentDayNum; dayNum < currentDayNum + deliveryLimitDays; dayNum++) {
    //     const currentDay = numToDay[dayNum % 7];
    //     const currentDayWindows = windows[currentDay];
    //     if (currentDayWindows) {
    //         validWindows[currentDay] = currentDayWindows;
    //     }
    // }

    // blackout
    let currentTime = {
        hr: dateUsedToFilterWindows.date.getHours(),
        min: dateUsedToFilterWindows.date.getMinutes(),
    };
    if (!dateUsedToFilterWindows.inRestaurantTimezone) {
        currentTime = getCurrentTimeInRestaurantTimezone(
            dateUsedToFilterWindows.date,
            restaurantTimezone,
        );
    }
    const windowsToBeFiltered = windows;

    let filteredWindows: Block[] = [];
    if (windowsToBeFiltered) {
        filteredWindows = [...windowsToBeFiltered];
        sortBlocks(todaySchedule);
        for (const block of todaySchedule) {
            const { startTime } = block;
            const beforeMenuBlock = earlierThan(currentTime, startTime);
            const cutOffToEliminateWindows = beforeMenuBlock
                ? currentTime
                : addMinToTime(currentTime, futureOrder.delivery_blackout_duration);

            filteredWindows = filteredWindows.filter((window: Block) => {
                if (earlierThan(window.startTime, cutOffToEliminateWindows)) {
                    return false;
                }
                return true;
            });
        }
    }

    return filteredWindows;
}

export function generateDeliveryWindowsPerDay(
    deliveryStart: Time,
    deliveryEnd: Time,
    windowDuration: number,
): Block[] {
    const windows = [];
    for (let windowStart = deliveryStart; earlierThan(windowStart, deliveryEnd); ) {
        const windowEnd = addMinToTime(windowStart, windowDuration);
        windows.push({ startTime: windowStart, endTime: windowEnd });
        windowStart = windowEnd;
    }
    return windows;
}

export function getMenuSchedule(dayParts: DayPartInterface[], day: DaysOfWeek) {
    const menuSchedule: Block[] = [];
    for (const dayPart of dayParts) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { days_of_week, start_time, end_time } = dayPart;

        if (start_time && end_time) {
            const startTime = convertTimeStrToObj(start_time);
            const endTime = convertTimeStrToObj(end_time);
            for (const currentDay of days_of_week) {
                if (currentDay === day) {
                    menuSchedule.push({ startTime, endTime });
                }
            }
        }
    }
    return menuSchedule;
}
/**
 * generate deliverable time windows 1 week ahead with a given date
 *
 * @param dayParts restaurant's menu schedule
 * @param futureOrder
 * @param date
 * @param timezone example: America/Los_Angeles
 *
 * @returns object > {@link WeekWindows}
 *
 */
export function generateFutureDeliveryTimeWindows(
    dayParts: DayPartInterface[],
    futureOrder: FutureOrder,
    date: Date,
    timezone: string,
): WeekWindows {
    const weeklyDeliveryWindows: WeekWindows = {};

    const today = numToDay[date.getDay()];

    const todaySchedule: Block[] = [];

    for (const dayPart of dayParts) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { days_of_week, start_time, end_time } = dayPart;

        if (start_time && end_time) {
            const startTime = convertTimeStrToObj(start_time);
            const endTime = convertTimeStrToObj(end_time);
            for (const day of days_of_week) {
                const deliveryStart = addMinToTime(startTime, futureOrder?.delivery_buffer);
                const deliveryEnd = subtractMinFromTime(endTime, futureOrder?.delivery_buffer);
                if (day === today) {
                    todaySchedule.push({ startTime: deliveryStart, endTime: deliveryEnd });
                }
                const windows = generateDeliveryWindowsPerDay(
                    deliveryStart,
                    deliveryEnd,
                    futureOrder?.delivery_window_duration,
                );

                if (weeklyDeliveryWindows[day]) {
                    weeklyDeliveryWindows[day] =
                        weeklyDeliveryWindows[day]?.concat(windows) || null;
                } else {
                    weeklyDeliveryWindows[day] = windows;
                }
            }
        }
    }

    sortWindows(weeklyDeliveryWindows);
    const todayWindows = removeInvalidWindows(
        weeklyDeliveryWindows[today] || [],
        futureOrder,
        todaySchedule,
        {
            date,
            inRestaurantTimezone: false,
        },
        timezone,
    );
    weeklyDeliveryWindows.today = todayWindows;
    return weeklyDeliveryWindows;
}

export const getCurrentTimeInRestaurantTimezone = (
    date: Date,
    restaurantTimezone: string,
): Time => {
    const restaurantTimezoneOffsetMin = getTimeZoneOffset(restaurantTimezone); // ex: -480
    const userTimezoneOffsetMin = date.getTimezoneOffset(); // 300
    const timezoneDiff = restaurantTimezoneOffsetMin + userTimezoneOffsetMin; // ex: -480 + 300 = -180
    const timezoneDiffMilliseconds = timezoneDiff * 60 * 1000;

    date.setTime(date.getTime() + timezoneDiffMilliseconds);
    return { hr: date.getHours(), min: date.getMinutes() };
};

export const sessionTimeout: number = 30 * 60000; // 30 min

export function runningLocally() {
    const host = window.location.hostname || '';
    return host === 'localhost' || host.includes('127.0.0');
}

export function formatMoneyInteger(value: number | string) {
    if (typeof value === 'string') {
        value = parseInt(value, 10);
    }
    return currency(value / 100, { precision: 2 }).format();
}

export function getTimeStamp(timeString?: string): number | null {
    const res = timeString ? new Date(timeString).getTime() : 0;
    return res && !isNaN(res) ? res : null;
}

export function getEstimatedDeliveryWindow(restaurantInfo: LocationMatchResponse | null): {
    from: number;
    to: number;
} | null {
    const quotedDelivery = getTimeStamp(restaurantInfo?.delivery?.quoted_delivery);
    const quotedPickup = getTimeStamp(restaurantInfo?.delivery?.quoted_pickup);
    if (!quotedDelivery || !quotedPickup) {
        return null;
    }
    const deliveryMinutes = (quotedDelivery - quotedPickup) / 1000 / 60;
    const prepTime = restaurantInfo?.delivery?.prep_time || 0;
    const fromEstimate = Math.floor(prepTime + deliveryMinutes - 5);
    const toEstimate = Math.ceil(prepTime + deliveryMinutes + 15);
    const fromRoundedDownToNearest5Min = Math.floor(fromEstimate / 5) * 5;
    const toRoundedUpToNearest5Min = Math.ceil(toEstimate / 5) * 5;
    return { from: fromRoundedDownToNearest5Min, to: toRoundedUpToNearest5Min };
}

export function useMobileScreen() {
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    return !screens.md;
}

export function withAppRoot(url: string) {
    return `${process.env.APP_ROOT}/${url}`;
}

export function sanitizeObject<T>(inputObject: Record<string, T>) {
    const outPutObject: Record<string, T> = {};
    Object.keys(inputObject).forEach(key => {
        const value = inputObject[key];
        if (value) {
            Object.assign(outPutObject, { [key]: value });
        }
    });
    return outPutObject;
}

export function timestampToHours(isoString: string) {
    const time = new Date(isoString);
    const hrs = time.getHours();
    const amOrPm = hrs >= 12 ? 'pm' : 'am';
    const min = time.getMinutes();
    return formatHours(hrs, min) + amOrPm;
}

export function formatHours(hrs: number, min: number) {
    if (hrs === 0 || hrs === 24) {
        hrs = 12;
    } else if (hrs > 24) {
        hrs -= 24;
    } else if (hrs > 12) {
        hrs -= 12;
    }
    const minStr = min.toString().padStart(2, '0');
    return `${hrs.toString()}:${minStr}`;
}

export function getCurrentHours() {
    const time = new Date();
    const hrs = time.getHours();
    const amOrPm = hrs >= 12 ? 'PM' : 'AM';
    const min = time.getMinutes();
    return `${formatHours(hrs, min)} ${amOrPm}`;
}

// Return the LOCAL time in an ISO8601 string, eg: 2021-07-08T03:49:33-07:00
export function getLocalTimeISOString(time: Date): string {
    function pad(s: any): string {
        return s.toString().padStart(2, 0);
    }

    const year = time.getFullYear();
    const month = pad(time.getMonth() + 1);
    const day = pad(time.getDate());
    const clockTime = time.toTimeString().split(' ')[0];
    const tzOffset = time.getTimezoneOffset(); // difference between local & UTC time, in minutes
    const tzSign = tzOffset < 0 ? '+' : '-';
    const tzHours = pad(Math.floor(tzOffset / 60));
    const tzMinutes = pad(tzOffset % 60);
    return `${year}-${month}-${day}T${clockTime}${tzSign}${tzHours}:${tzMinutes}`;
}

// Convert a timezone string like 'America/Los Angeles' into a timezone offset
// Offsets are negative if timezone is west / earlier / behind UTC, positive otherwise
// Note that this is the OPPOSITE +/- sign to what JS's getTimezoneOffset returns
export function getTimeZoneOffset(timeZone: string = 'UTC'): number {
    try {
        timeZone = timeZone.replace(/ /gi, '_'); // Some BE timezone names have spaces, which JS chokes on
        const date = new Date();
        const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
        const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
        return (tzDate.getTime() - utcDate.getTime()) / 60 / 1000;
    } catch (err) {
        console.log(`failed to convert timezone '${timeZone}' to timezone, e: ${err}`); // eslint-disable-line no-console
        return 0; // Should log an error too
    }
}

// Like getTimeZoneOffset, but input is a lat / lng
// Async because we need to hit google's timezone API to do the conversion
export async function getLatLngTimeZone(lat: number, lng: number): Promise<number> {
    try {
        const timestamp = Math.floor(new Date().getTime() / 1000);
        if (lat && lng) {
            const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=AIzaSyCACZK87yPQVB9l8JD_5-iqSmr6ECMPQKc`;
            const res = await request.get(url);
            if (res?.data?.rawOffset) {
                return (res.data.dstOffset + res.data.rawOffset) / 60;
            }
        }
    } catch (err) {
        console.log(`failed to convert lat '${lat}', lng '${lng}' to timezone, e: ${err}`); // eslint-disable-line no-console
        return 0; // Should log an error too
    }
    return 0;
}

export const convertTimeStringToRestOffset = (timestamp: string, timezoneOffset: number) => {
    const processedMomentObject = moment
        .parseZone(timestamp) // Parses the string, retaining the offset provided
        .utcOffset(timezoneOffset, false); // sets the offset to restaurant timezone
    return processedMomentObject;
};

export function resizedImagePath(url: string, width: number): string {
    // Most of our raw images are much wider than they are tall, but they're drawn in square-ish boxes.
    // If we ask Cloudflare to restrict the width of the image here, it will give us an image
    // that's the right width but too short, so the UI will scale it up to fit and make it fuzzy.
    // It's unintuitive, but we can use height={width} here instead of width={width} and it works, and looks better.
    return !runningLocally()
        ? `https://ordermark.com/cdn-cgi/image/height=${width},quality=90/${url}`
        : url;
}

export function getAnonymousId(): string {
    if (runningLocally()) {
        return 'fake_local_anonymous_id';
    }
    const cookieName = 'ajs_anonymous_id';
    const cookies = document.cookie.split(';').map(el => el.trim());
    for (const cookie of cookies) {
        if (cookie.startsWith(cookieName)) {
            return decodeURI(cookie.split('=')[1]).replace(/"/gi, '');
        }
    }
    return '';
}

interface IDHierarchyDictionary {
    [id: string]: ModifierGroupsItemInterface[];
}

interface ModGroupDictionary {
    [id: string]: ModifierGroupsItemInterface;
}

export const buildModGroupPayloadFromItem = (
    modifierGroupItems: ModifierGroupsItemInterface[],
): any => {
    // this is where the first mod group always is in the hierarchy since it's menu, section, item
    const hierarchyIndex: number = 3;
    // add all the mod group and item ids into our dictionaries for easy lookup
    const modGroupDictionary: ModGroupDictionary = {};
    modifierGroupItems.forEach((lineItem: ModifierGroupsItemInterface) => {
        modGroupDictionary[lineItem.modifier_group_id] = lineItem;
    });
    return traverseModGroupTree(hierarchyIndex, modifierGroupItems, modGroupDictionary);
};

const traverseModGroupTree = (
    hierarchyIndex: number,
    modifierGroupItems: ModifierGroupsItemInterface[],
    modGroupDictionary: ModGroupDictionary,
): any => {
    const uniqueIds: IDHierarchyDictionary = {};
    modifierGroupItems.forEach((lineItem: ModifierGroupsItemInterface) => {
        // get the unique id's from this level and combine them into one entity
        // if the hierarchyIndex is greater than the length. then we've already passed this level. filter it out
        if (hierarchyIndex <= lineItem.hierarchy.length) {
            const id: string = lineItem.hierarchy[hierarchyIndex];
            if (uniqueIds[id]) {
                // already added this before on the tree
                uniqueIds[id].push(lineItem);
            } else {
                // new child item.
                uniqueIds[id] = [lineItem];
            }
        }
    });

    return Object.keys(uniqueIds).map(id => {
        const modGroup: ModifierGroupsItemInterface = modGroupDictionary[id];
        return {
            modifier_group_id: modGroup.modifier_group_id,
            name: modGroup.name,
            items: modGroup.items.map((orderModifierLineItem: MenuItemInterface) => {
                return {
                    item_id: orderModifierLineItem.id,
                    name: orderModifierLineItem.title,
                    quantity: 1,
                    price:
                        matchNestedRule(orderModifierLineItem.price_rules, [
                            ...modGroup.hierarchy,
                            orderModifierLineItem.id,
                        ])?.price || 0,
                    // +2 for hierarchyIndex since you're jumping pass the items in the hierarchy tree.
                    modifier_groups: traverseModGroupTree(
                        hierarchyIndex + 2,
                        uniqueIds[id],
                        modGroupDictionary,
                    ),
                };
            }),
        };
    });
};

export const getPaymentResult = async (stripe: any, amount = 0, label = 'Test payment request') => {
    const paymentRequest = stripe?.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
            label,
            amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
    });
    const canMakePayment = await paymentRequest?.canMakePayment();
    return { paymentRequest, canMakePayment };
};

// get element dimensions dynamically on size changes
export const useResizeObserver = () => {
    const [dimensions, setdimensions] = useState({ width: 0, height: 0 });
    const observerRef = useRef<ResizeObserver>();
    const containerRef = useRef<HTMLElement | null>();

    const disconnect = useCallback(() => {
        if (observerRef.current) {
            observerRef.current.disconnect();
        }
    }, []);

    const observe = useCallback(
        node => {
            if (node && node !== containerRef.current) {
                disconnect();
                containerRef.current = node;
            }
            if (!observerRef.current) {
                observerRef.current = new ResizeObserver(([entry]) => {
                    const { width, height } = entry.contentRect;

                    if (dimensions.width === width && dimensions.height === height) {
                        return;
                    }
                    setdimensions({ width, height });
                });
            }

            if (observerRef.current && containerRef.current) {
                observerRef.current.observe(containerRef.current);
            }

            return () => {
                if (observerRef.current) {
                    observerRef.current.unobserve(node);
                }
            };
        },
        [disconnect, dimensions],
    );

    return { ...dimensions, observe };
};

export function getUnexpiredValueFromLocalStorage(key: string): any {
    const expiryStr = localStorage.getItem('expiry');
    const time = new Date();
    if (expiryStr && time.getTime() < JSON.parse(expiryStr)) {
        const value = localStorage.getItem(key);

        if (value) {
            return JSON.parse(value);
        }
    }
    return null;
}

export function getValueFromLocalStorage(key: string): any {
    const value = localStorage.getItem(key);

    if (value) {
        return JSON.parse(value);
    }
    return null;
}

export function updateLocalStorage(
    key: string,
    value?: string | null | undefined | object | boolean | number,
) {
    const stringifiedValue = JSON.stringify(value);

    if (stringifiedValue === 'null' || !stringifiedValue) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, stringifiedValue);
    }
}

export const formatStreetAddress = (address: any, apartmentNumber: string) => {
    const apartmentBlock = apartmentNumber ? `, ${apartmentNumber}` : '';
    return `${address?.main_text}${apartmentBlock}`;
};

export const formatAddress = (address: any, apartmentNumber: string) => {
    const streetAddress = formatStreetAddress(address, apartmentNumber);
    if (address) {
        return `${streetAddress}, ${address?.secondary_text}`;
    } else {
        return '';
    }
};

export const formatAddressDescription = (addressInfo: any, removeApt?: boolean) => {
    const { address1, address2, city, region, postalcode, zipcode, state } = addressInfo;

    if (address2 && !removeApt) {
        return `${address1}, ${address2}, ${city}, ${region || state} ${postalcode || zipcode}`;
    } else {
        return `${address1}, ${city}, ${region || state} ${postalcode || zipcode}`;
    }
};

export const formatPaymentInfo = (paymentMethodInfo: any) => {
    const testPaymentInfo = {
        card: {
            brand: null,
            wallet: 'test_card',
        },
    };

    if (paymentMethodInfo === 'test') {
        paymentMethodInfo = testPaymentInfo;
    }

    if (paymentMethodInfo?.card?.wallet) {
        const walletTypeString = paymentMethodInfo?.card?.wallet;
        const processedString = walletTypeString
            .replace(/(^|[_])+\S/g, (c: string) => c.toUpperCase())
            .replace(/_/g, ' ');
        return processedString;
    }

    const method = paymentMethodInfo.card.brand;
    const last4 = paymentMethodInfo.card.last4;
    return `${method.charAt(0).toUpperCase()}${method.slice(1)}  ${last4}`;
};

export const parsePaymentInfoData = (paymentInfo: any) => {
    if (paymentInfo?.data) {
        return formatPaymentInfo(JSON.parse(atob(paymentInfo?.data)));
    }
    // use mock data if payment_info object does not include an encoded string
    return formatPaymentInfo(paymentInfo.method);
};

export function isOfType<T>(varToBeChecked: any, propertyToCheckFor: keyof T): varToBeChecked is T {
    // eslint-disable-next-line no-undefined
    return (varToBeChecked as T)?.[propertyToCheckFor] !== undefined;
}

export function isTestAccount(appId?: string): boolean {
    const testAppIDs = [
        'api-test',
        'olo-v2-test1',
        'olo-v2-test2',
        'test-dtc-Bell-2',
        'test-dtc-twd-2',
        'test-dtc-fbw-2',
        'test-dtc-cta-2',
        'test-dtc-gcs-2',
        'test-dtc-hbw-2',
        'test-dtc-mmac-2',
        'test-dtc-mm-2',
        'test-dtc-ob-2',
        'test-dtc-tbm-2',
        'test-dtc-www-2',
        'test-dtc-cb-2',
        'test-dtc-Dilla-2',
        'test-dtc-Thrilled-2',
        'test-dtc-crk-2',
        'test-dtc-hh-2',
        'test-dtc-hvr-2',
        'test-dtc-ldfr-2',
        'test-dtc-pbwz-2',
        'test-dtc-tzki-2',
        'test-dtc-fff-2',
        'test-dtc-tfns-2',
        'test-dtc-twd-3',
        'test-dtc-fbw-3',
        'test-dtc-cta-3',
        'test-dtc-gcs-3',
        'test-dtc-hbw-3',
        'test-dtc-mmac-3',
        'test-dtc-mm-3',
        'test-dtc-ob-3',
        'test-dtc-tbm-3',
        'test-dtc-www-3',
        'test-dtc-cb-3',
        'test-dtc-Dilla-3',
        'test-dtc-Thrilled-3',
        'test-dtc-crk-3',
        'test-dtc-hh-3',
        'test-dtc-hvr-3',
        'test-dtc-ldfr-3',
        'test-dtc-pbwz-3',
        'test-dtc-tzki-3',
        'test-dtc-fff-3',
        'test-dtc-tfns-3',
        '7NOW-staging1',
        '7NOW-staging2',
    ];
    return appId != null && testAppIDs.includes(appId);
}

export const getDeliveryTimeMomentObject = (futureOrderDateObject?: FutureOrderDate | null) => {
    if (futureOrderDateObject) {
        const date = futureOrderDateObject?.date;
        const restaurantInfo: LocationMatchResponse = JSON.parse(
            localStorage.getItem('restaurantInfo') || 'null',
        );
        const timezone = restaurantInfo?.delivery?.timezone || null;

        const formattedFutureOrderDateObject = {
            date: timezone ? moment(date).tz(timezone) : moment(date),
            time: futureOrderDateObject?.time,
        };
        // Only apply .tz() to moment if timezone exists (not null)
        if (timezone && typeof formattedFutureOrderDateObject.date.tz === 'function') {
            formattedFutureOrderDateObject.date = formattedFutureOrderDateObject.date.tz(timezone);
        }
        return formattedFutureOrderDateObject;
    }
    return futureOrderDateObject;
};

const subnavScrollSpeed = 100;

export const scrollCategorySubnav = (scrollFoward: boolean): number => {
    const subnav = document.querySelector<HTMLDivElement>('.ant-tabs-nav-list');
    if (subnav) {
        const numRegex = /\d+/;
        const horizontalScroll = subnav.style.transform.match(numRegex);
        const subnavWrapper = document.querySelector('.ant-tabs-nav-wrap');
        if (horizontalScroll && subnavWrapper) {
            let nextHorizontalScroll: number;
            if (scrollFoward) {
                const rightEdge = subnav.clientWidth - subnavWrapper.clientWidth;
                const scrollToNext = parseInt(horizontalScroll[0], 10) + subnavScrollSpeed;
                nextHorizontalScroll = scrollToNext > rightEdge ? rightEdge : scrollToNext;
            } else {
                const leftEdge = 0;
                const scrollToNext = parseInt(horizontalScroll[0], 10) - subnavScrollSpeed;
                nextHorizontalScroll = scrollToNext < leftEdge ? leftEdge : scrollToNext;
            }
            subnav.style.transform = `translate(-${nextHorizontalScroll}px, 0px)`;
            return nextHorizontalScroll;
        }
    }
    return 0;
};

type PlatformType = 'web' | 'android' | 'ios';

export const getPlatform = () => {
    return Capacitor.getPlatform() as PlatformType;
};

// Look for 6 known UTM query parameters and store them in local storage for later
// Return true if we found at least one `utm_*` param, false otherwise
export function handleUTMQueries(): boolean {
    const params = new URLSearchParams(window.location.search);
    const payload = {
        id: params.get('utm_id'),
        name: params.get('utm_campaign'),
        term: params.get('utm_term'),
        source: params.get('utm_source'),
        medium: params.get('utm_medium'),
        content: params.get('utm_content'),
    };

    const res =
        payload.id != null ||
        payload.name != null ||
        payload.term != null ||
        payload.source != null ||
        payload.medium != null ||
        payload.content != null;
    if (res) {
        localStorage.setItem('utm_payload', JSON.stringify(payload));
    }
    return res;
}

export function getUTMQueries() {
    const payload = localStorage.getItem('utm_payload');
    if (payload) {
        return JSON.parse(payload);
    }
    return {
        id: null,
        name: null,
        source: null,
        medium: null,
        term: null,
        content: null,
    };
}

export function getUTMQueriesString(): string {
    const UTMparams = getUTMQueries();
    const result = [];
    if (UTMparams?.id) {
        result.push('utm_id=' + UTMparams.id);
    }
    if (UTMparams?.name) {
        result.push('utm_name=' + UTMparams.name);
    }
    if (UTMparams?.source) {
        result.push('utm_source=' + UTMparams.source);
    }
    if (UTMparams?.medium) {
        result.push('utm_medium=' + UTMparams.medium);
    }
    if (UTMparams?.term) {
        result.push('utm_term=' + UTMparams.term);
    }
    if (UTMparams?.content) {
        result.push('utm_content=' + UTMparams.content);
    }
    return encodeURIComponent(result.join('&'));
}

export function getLocationReopenDayTime(dateTimeStr: any): {
    reopenDay: string | null;
    reopenHour: string | null;
    pausedLongerThanAWeek: boolean;
} | null {
    if (!dateTimeStr) {
        return null;
    } else if (dateTimeStr === 'never') {
        return {
            reopenDay: null,
            reopenHour: null,
            pausedLongerThanAWeek: true,
        };
    }

    let reopenDay = null;
    let reopenHour = null;
    let pausedLongerThanAWeek = false;

    const today = moment().format('YYYY-MM-DD');
    const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
    const withinAWeek = moment().add(7, 'day').format('YYYY-MM-DD');
    const locationDateTime = moment.parseZone(dateTimeStr);

    /* If paused longer than a week, show unavailable text and if within the window, display weekday.
    Else if reopens today or tomorrow, show special text. Else, show future date and time */
    if (locationDateTime.isAfter(withinAWeek)) {
        pausedLongerThanAWeek = true;
    } else if (locationDateTime.isSame(today, 'day')) {
        reopenDay = 'today';
    } else if (locationDateTime.isSame(tomorrow, 'day')) {
        reopenDay = 'tomorrow';
    } else {
        reopenDay = `${locationDateTime.format('ddd. M/D')}`; // Mon. 9/9
    }
    reopenHour = locationDateTime.format('LT'); // 9:00 AM

    return {
        reopenDay,
        reopenHour,
        pausedLongerThanAWeek,
    };
}

export const getCartTotals = (cartItems: CartItemInterface[]) => {
    const tempCart = { price: 0, quantity: 0 };
    const totalSums = cartItems.reduce((acc, item) => {
        const { quantity, price } = item;
        return { ...acc, price: acc.price + price, quantity: acc.quantity + quantity };
    }, tempCart);
    return totalSums;
};

export const generateAuth0RedirectOptions = (template: BrandTemplate | null) => {
    const options: RedirectLoginOptions = {
        mode: 'login',
        redirectUri: `${window.location.origin}/orders/callback`,
        logo: template?.general?.logo?.website_logo,
        primary_color: template?.general?.primary_color,
        secondary_color: template?.general?.secondary_color,
        appState: { route: window.location.pathname },
        privacyPolicy: template?.general.legal.privacy_policy_link,
        termsAndConditions: template?.general.legal.terms_conditions_link,
    };
    return options;
};

export const handleScrollToElement = (element: any) => {
    if (element?.current) {
        element.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
};

export const hasUnavailableModGroup = (
    modGroupItemIds: string[],
    menuInfo: MenuManagerInterface,
    alwaysRequired: boolean,
    visitedModGroupDict?: Map<string, boolean>,
) => {
    if (modGroupItemIds.length < 1) {
        return false;
    }
    const checkModGroupForUnavailableItems = (modGroupItems: string[], modGroupId: string) => {
        const unavailableModItemList = modGroupItems.filter((id: string) => {
            const item = findEntity(menuInfo, 'item', id);
            const modGroupIds = item.modifier_group_ids || [];
            if (item.is_paused) {
                return true;
            } else if (modGroupIds.length > 0) {
                return hasUnavailableModGroup(modGroupIds, menuInfo, false, visitedModGroupDict);
            }
            return false;
        });
        const isModGroupUnavailable = unavailableModItemList.length === modGroupItems.length;
        visitedModGroupDict?.set(modGroupId, isModGroupUnavailable);
        return isModGroupUnavailable;
    };
    /* Traverse through each mod-group item-list for required groups to evaluate
       Only need one group to be 86ed entirely for item being unavailable */
    const isRequiredModUnavailable = modGroupItemIds.some((id: string) => {
        if (visitedModGroupDict?.has(id)) {
            return visitedModGroupDict?.get(id);
        }
        const modItem = findEntity(menuInfo, 'modifier_group', id);
        const minAllowed = modItem.quantity_rules[0].min_allowed || 0;
        const isRequired = minAllowed > 0;

        return alwaysRequired || isRequired
            ? checkModGroupForUnavailableItems(modItem.item_ids, id)
            : false;
    });
    return isRequiredModUnavailable;
};

export const sanitizeMenuPausedModGroups = (menuInfo: MenuManagerInterface) => {
    const visitedModGroupAvailability: Map<string, boolean> = new Map();
    const availableSectionIds: Set<string> = new Set();
    const tempAvailableSectionList: MenuSectionInterface[] = [];

    menuInfo.sections.forEach((section: MenuSectionInterface) => {
        const availableSectionItems = section.item_ids.filter((id: string) => {
            const item = findEntity(menuInfo, 'item', id);
            const modGroupIds = item.modifier_group_ids;
            const itemCanBeOrdered = !hasUnavailableModGroup(
                modGroupIds,
                menuInfo,
                false,
                visitedModGroupAvailability,
            );
            return itemCanBeOrdered && !item.is_paused;
        });
        if (availableSectionItems.length > 0) {
            tempAvailableSectionList.push({ ...section, item_ids: availableSectionItems });
            availableSectionIds.add(section.id);
        }
    });
    menuInfo.sections = tempAvailableSectionList;
    menuInfo.menus[0].section_ids = menuInfo.menus[0].section_ids.filter((id: string) =>
        availableSectionIds.has(id),
    );
    return menuInfo;
};

export const shorten = (s: string, maxLength: number, separator: string = '-') => {
    s = s.replace(/[^a-z0-9-]/g, '');
    if (s.length <= maxLength) {
        return s;
    }
    return s.substring(0, s.lastIndexOf(separator, maxLength)).replace(/[^0-9_-]/g, '');
};

const normalizeName = (s: string) => {
    return s
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-]/g, '');
};

export const storeAddressToUrlPath = normalizeName;

export const itemNameToUrlPath = normalizeName;

function getTopLevelItems(menuInfo: MenuManagerInterface): MenuItemInterface[] {
    const items = new Set<MenuItemInterface>();
    for (const sectionId of menuInfo.menus[0].section_ids) {
        const section = findEntity(menuInfo, 'section', sectionId);
        for (const itemId of section.item_ids) {
            const item = findEntity(menuInfo, 'item', itemId);
            items.add(item);
        }
    }
    return [...items];
}

export const itemUrlPathToID = (s: string, menu: MenuManagerInterface) => {
    s = s.replace(/-/g, ' ');
    const menuItems = getTopLevelItems(menu);
    const fuse = new Fuse(menuItems, { keys: ['title'] });
    const res = fuse.search(s);
    if (res?.[0]?.item?.title) {
        return res[0].item.id;
    }
    return '';
};

export const isVideo = (mediaUrl?: string) => {
    if (!mediaUrl) {
        return false;
    }
    const videos = ['mp4', '3gp', 'ogg'];

    try {
        const url = new URL(mediaUrl);
        const extension = url.pathname.split('.')[1];

        if (videos.includes(extension)) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};
