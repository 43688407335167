import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, Drawer, Row, Typography } from 'antd';
import log from 'loglevel';
import moment from 'moment-timezone';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { OrderList } from '../../../../services/OrderHistory';
import { TemplateButton } from '../../ui-components/template-button';
import {
    convertTimeStringToRestOffset,
    formatMoneyInteger,
    useMobileScreen,
} from '../../utils/general';
import useSegment from '../../utils/segment';
import { OListProps, OrderStatusInterface, PaymentStatusType, ProgressType } from './models';
import { PanelOrderContent } from './panel-order-content';
import { StatusIndicatorBadge } from './status-indicator-badge';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const isProduction = process.env.BUILD_ENV === 'prod';

export const OrderListDetails: FC<OListProps> = ({ order }) => {
    const isMobile = useMobileScreen();
    const segment = useSegment();
    const { location }: any = useHistory();

    const [trackOrderUrl, setTrackOrderUrl] = useState<string>('');
    const [isPollingStatusActive, setPollingStatusActive] = useState(true);
    const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState<string>('');
    const [droppedOffTime, setDroppedOffTime] = useState<string>('');
    const [statusUpdates, setStatusUpdates] = useState<ProgressType[]>([]);
    const [refundDetails, setrefundDetails] = useState<any>(null);
    const [refetchIntervalTime] = useState(() => (isProduction ? 30000 : 3000));
    const [currentStatusStep, setCurrentStatusStep] = useState<StatusUpdateSteps>(
        StatusUpdateSteps.OrderPlaced,
    );
    const [openPanel, setOpenPanel] = useState<boolean>(() => {
        return location?.state?.orderId === order.order_id ? true : false;
    });

    const orderStatusInfoQuery = useQuery(
        ['orderStatusInfoQuery', order.order_id, location?.state?.orderId, openPanel],
        () => {
            return OrderList.getOrderStatus({
                orderId: order.order_id,
            });
        },
        {
            onError: error => {
                const errorMessage = 'An error occured while tracking order status';
                log.error(`${errorMessage}: ${error}`);
                setPollingStatusActive(false);
            },
            refetchInterval: isPollingStatusActive ? refetchIntervalTime : 0,
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
        },
    );
    log.debug(
        '%c POLLING STATUS, ORDER ID ',
        'background-color: red',
        isPollingStatusActive,
        order.order_id,
    );

    const updatePaymentStatus = useCallback(
        (amount: number, issueDate: string) => {
            if (issueDate && amount) {
                setrefundDetails({ issueDate, amount });
            }
            const paymentStatus: PaymentStatusType =
                order.total_amount === amount ? 'Full Refund' : 'Partial Refund';
            if (statusUpdates.length >= 2) {
                setStatusUpdates([...statusUpdates.slice(0, 1), paymentStatus]);
            } else {
                setStatusUpdates(statusUpdates => [...statusUpdates, paymentStatus]);
            }
            setPollingStatusActive(false);
        },
        [order.total_amount, statusUpdates],
    );

    const setCurrentUpdates = useCallback(
        (orderStatus: ProgressType, statusStep: StatusUpdateSteps) => {
            if (orderStatus && statusStep && statusStep > currentStatusStep) {
                setStatusUpdates([orderStatus]);
                setCurrentStatusStep(statusStep);
            }
        },
        [currentStatusStep],
    );

    const updateOrderStatus = useCallback(
        (statusUpdatesArray: []) => {
            let orderStatus: any;
            let statusStep = 0;

            for (const {
                estimated_delivery_time: estimatedDeliveryUtcTime,
                track_order_url: trackOrderUrl,
                refund_amount: refundAmount,
                date_modified: dateModified,
                status,
            } of statusUpdatesArray) {
                log.debug(
                    '%c UPDATE ORDER STATUS, ORDER ID ',
                    'background-color: red',
                    status,
                    order.order_id,
                    orderStatusMapping[status]?.step,
                );
                const isFutureOrder = order.scheduled_order_end_time != null;
                const isPastDeliveryWindow =
                    order.scheduled_order_end_time != null &&
                    moment.utc().format() > order.scheduled_order_end_time;

                // set order delivery info to local state
                if (estimatedDeliveryUtcTime) {
                    setEstimatedDeliveryTime(estimatedDeliveryUtcTime);
                }
                if (trackOrderUrl) {
                    setTrackOrderUrl(trackOrderUrl);
                }
                if (status === 'driver_dropped_off') {
                    setDroppedOffTime(dateModified);
                }

                // set order status
                if (
                    isFutureOrder &&
                    !isPastDeliveryWindow &&
                    orderStatusMapping[status]?.step < StatusUpdateSteps.Complete &&
                    orderStatusMapping[status]?.step > statusStep
                ) {
                    orderStatus = 'Scheduled';
                    statusStep = StatusUpdateSteps.Scheduled;
                } else if (isPastDeliveryWindow && !trackOrderUrl) {
                    orderStatus = 'Complete';
                    statusStep = StatusUpdateSteps.Complete;
                } else if (
                    status in orderStatusMapping &&
                    orderStatusMapping[status].step > statusStep
                ) {
                    const { message, step } = orderStatusMapping[status];
                    orderStatus = message;
                    statusStep = step;
                }
                setCurrentUpdates(orderStatus, statusStep);

                // set payment status after order status is updated
                if (refundAmount && orderStatus) {
                    updatePaymentStatus(refundAmount, dateModified);
                }
            }
        },
        [order.scheduled_order_end_time, updatePaymentStatus, order.order_id, setCurrentUpdates],
    );

    log.debug('%c STATUS UPDATES ', 'background-color: red', statusUpdates, order.order_id);

    useEffect(() => {
        if (orderStatusInfoQuery?.data?.data && orderStatusInfoQuery.isSuccess) {
            log.debug(
                '%c TRACK ORDER STATUS ',
                'background-color: red',
                orderStatusInfoQuery?.data?.data,
            );
            updateOrderStatus(orderStatusInfoQuery?.data?.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatusInfoQuery?.data?.data, orderStatusInfoQuery?.isSuccess, isPollingStatusActive]);

    const handlePanelExpandedView = () => {
        if (openPanel) {
            setOpenPanel(false);
            segment.closeDetailsExpandedView();
        } else {
            setOpenPanel(true);
            segment.showOrderDetailsCTAClicked();
        }
    };

    const ShowDetailsButton = () => {
        return (
            <Button size="large" block={isMobile} disabled={!statusUpdates.length}>
                {openPanel ? 'Hide details' : 'Show details'}
            </Button>
        );
    };

    const isShowTrackingButton = () => {
        return trackOrderUrl && currentStatusStep < StatusUpdateSteps.Complete;
    };

    const TrackMyOrderButton = () => {
        return isShowTrackingButton() ? (
            <TemplateButton
                size="large"
                block={isMobile}
                onClick={e => {
                    e.stopPropagation();
                    segment.trackMyOrderCTAClicked();
                    window.open(trackOrderUrl);
                }}
            >
                Track my order
            </TemplateButton>
        ) : (
            <></>
        );
    };

    const HeaderContent = (
        <Col className="order-history-card" span={24} role="Card">
            <Row justify="space-between" onClick={e => e.stopPropagation()}>
                <Title level={5}>{order.brand_name}</Title>
            </Row>
            <Row className="order-card-content width-100" align="middle">
                <Col md={14} onClick={e => e.stopPropagation()} className="order-card-content-list">
                    <Text className="order-history-subtext">
                        Order #{order.order_id} •&nbsp;
                        {convertTimeStringToRestOffset(
                            order.created_at,
                            order.timezone_offsets?.restaurant_address,
                        ).format('dddd, L')}
                    </Text>
                    <Text className="order-history-subtext">{`${formatMoneyInteger(
                        order.total_amount,
                    )} (${order.total_quantity} items)`}</Text>
                    {isMobile && <StatusIndicatorBadge orderStatus={statusUpdates} />}
                </Col>
                <Col className="action-buttons" xs={24} md={10}>
                    <ShowDetailsButton />
                    <TrackMyOrderButton />
                </Col>
            </Row>
            {!isMobile && <StatusIndicatorBadge orderStatus={statusUpdates} />}
        </Col>
    );

    return (
        <>
            <Row className="order-history-list width-100" gutter={[0, 20]}>
                <Collapse
                    ghost={true}
                    bordered={false}
                    onChange={handlePanelExpandedView}
                    activeKey={openPanel ? '1' : '0'}
                >
                    <Panel key={'1'} showArrow={false} header={HeaderContent}>
                        {isMobile ? (
                            <Drawer
                                className="order-card-drawer"
                                closable={true}
                                closeIcon={<ArrowLeftOutlined />}
                                placement="right"
                                title={`Order #${order.order_id}`}
                                width="100%"
                                visible={openPanel}
                                onClose={() => {
                                    setOpenPanel(false);
                                    segment.closeDetailsExpandedView('Back');
                                }}
                            >
                                <PanelOrderContent
                                    order={order}
                                    orderStatus={statusUpdates}
                                    droppedOffDateTime={droppedOffTime}
                                    refundDetails={refundDetails}
                                    estimatedDeliveryTime={estimatedDeliveryTime}
                                    trackOrderButton={TrackMyOrderButton}
                                />
                            </Drawer>
                        ) : (
                            <PanelOrderContent
                                order={order}
                                orderStatus={statusUpdates}
                                droppedOffDateTime={droppedOffTime}
                                refundDetails={refundDetails}
                                estimatedDeliveryTime={estimatedDeliveryTime}
                                trackOrderButton={TrackMyOrderButton}
                            />
                        )}
                    </Panel>
                </Collapse>
            </Row>
            {isMobile && <Divider />}
        </>
    );
};

enum StatusUpdateSteps {
    OrderPlaced,
    Scheduled,
    InProgress,
    Complete,
    DriverCancel,
    CancelOrder,
}

const orderStatusMapping: { [k: string]: OrderStatusInterface } = {
    scheduled: { message: 'Scheduled', step: 1 },
    order_confirmed: { message: 'In progress', step: 2 },
    driver_dropped_off: { message: 'Complete', step: 3 },
    driver_canceled: { message: 'Canceled', step: 4 },
    order_canceled: { message: 'Canceled', step: 5 },
};
