import { Input } from 'antd';
import React, { useState } from 'react';
import { TemplateButton } from '../ui-components/template-button';
import useSegment from '../utils/segment';

export const EmailBlock = ({
    title,
    bodyText,
    isMobile,
    termsConditionsLink,
    privacyPolicyLink,
}: {
    title?: string;
    bodyText?: string;
    isMobile: boolean;
    termsConditionsLink: string;
    privacyPolicyLink: string;
}) => {
    const [emailInput, setEmailInput] = useState('');
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const segment = useSegment();

    const validateEmailAddress = (input: string) => {
        const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (pattern.test(input)) {
            return true;
        } else {
            return false;
        }
    };

    const handleEmailSubmit = () => {
        if (validateEmailAddress(emailInput)) {
            const n = 'Welcome - Place First Order';
            segment.IdentifyEmail(emailInput);
            segment.signUpEmailCTAClicked();
            segment.emailCapturedAddressEntry(emailInput, true, 'Welcome Promo Banner');
            segment.promotionClicked(
                emailInput,
                'Welcome Promo Banner',
                'Sign Up For Exclusive Offers',
                n,
                n,
            );
            setInvalidEmail(false);
            setEmailSubmitted(true);
        } else {
            setEmailSubmitted(false);
            setInvalidEmail(true);
        }
    };

    return emailSubmitted ? (
        <div className="feature-box" style={{ maxHeight: '134px', minHeight: '134px' }}>
            <div className="feature-child-box" style={{ minWidth: '80%' }}>
                <div
                    className="feature-child-text-box"
                    style={{
                        padding: '30px',
                        textAlign: 'center',
                        paddingRight: '30px',
                    }}
                >
                    <h1
                        className={'feature-text-title'}
                        style={{
                            padding: '0',
                            fontSize: '22px',
                        }}
                    >
                        we got it!
                    </h1>
                    <p className={'feature-text'} style={{ padding: 0, fontSize: '14px' }}>
                        Keep an eye out for our future emails!
                    </p>
                </div>
            </div>
        </div>
    ) : isMobile ? (
        <div className="email-mobile-box" style={isMobile ? { minWidth: 0 } : {}}>
            <div className="text-box">
                <h2 className={'title'}>{title}</h2>
                <p className={'text'}>{bodyText}</p>
            </div>
            <div
                style={{
                    color: '#FF3A00',
                    fontFamily: 'Poppins, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '10px',
                    lineHeight: '14px',
                    visibility: invalidEmail ? 'visible' : 'hidden',
                }}
            >
                Invalid Email
            </div>
            <Input
                placeholder="Enter email"
                required
                size="large"
                style={{
                    backgroundColor: ' #f0f0f0',
                    borderRadius: '8px',
                    width: '100%',
                    maxHeight: '41px',
                    alignSelf: 'flex-start',
                    margin: '5px',
                    borderColor: invalidEmail ? '#FF3A00' : '#d9d9d9',
                }}
                onChange={e => setEmailInput(e.target.value)}
            />
            <TemplateButton
                type="primary"
                style={{
                    borderRadius: '8px',
                    height: '41px',
                    width: '100%',
                    alignSelf: 'flex-start',
                    margin: '5px',
                }}
                onClick={handleEmailSubmit}
            >
                Sign Up
            </TemplateButton>
            <h5 className="mt-5">
                By signing up, you agree to receive emails and texts and accept our{' '}
                <a
                    href={privacyPolicyLink}
                    className="email-anchor"
                    target="_blank"
                    title={privacyPolicyLink}
                >
                    Privacy Policy{' '}
                </a>
                and{' '}
                <a
                    href={termsConditionsLink}
                    className="email-anchor"
                    target="_blank"
                    title={termsConditionsLink}
                >
                    Terms & Conditions
                </a>
            </h5>
        </div>
    ) : (
        <div className="feature-box" style={{ maxHeight: '134px', minHeight: '134px' }}>
            <div className="feature-child-box" style={{ minHeight: 'unset' }}>
                <div
                    className="feature-child-text-box"
                    style={{
                        padding: '30px',
                        paddingRight: 0,
                        paddingLeft: '60px',
                        height: 'inherit',
                    }}
                >
                    <h1
                        className={'feature-text-title'}
                        style={{
                            padding: '0',
                            fontSize: '22px',
                        }}
                    >
                        {title}
                    </h1>
                    <p className={'feature-text'} style={{ padding: 0, fontSize: '14px' }}>
                        {bodyText}
                    </p>
                </div>
                <div
                    className="feature-child-box"
                    style={{ padding: '0px 20px 10px 0', minHeight: 'unset', minWidth: '400px' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100px',
                            alignSelf: 'center',
                        }}
                    >
                        <div
                            style={{
                                color: '#FF3A00',
                                fontFamily: 'Poppins, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '14px',
                                marginBottom: '7px',
                                visibility: invalidEmail ? 'visible' : 'hidden',
                            }}
                        >
                            Invalid Email
                        </div>
                        <Input
                            placeholder="Enter email"
                            required
                            size="large"
                            style={{
                                backgroundColor: ' #f0f0f0',
                                borderRadius: '8px',
                                padding: '10px 15px',
                                maxWidth: '332px',
                                maxHeight: '41px',
                                alignSelf: 'flex-start',
                                borderColor: invalidEmail ? '#FF3A00' : '#d9d9d9',
                            }}
                            onChange={e => setEmailInput(e.target.value)}
                        />
                        <h5 className="mt-5">
                            By signing up, you agree to receive emails and texts and accept our{' '}
                            <a
                                href={privacyPolicyLink}
                                className="email-anchor"
                                target="_blank"
                                title={privacyPolicyLink}
                            >
                                Privacy Policy{' '}
                            </a>
                            and{' '}
                            <a
                                href={termsConditionsLink}
                                className="email-anchor"
                                target="_blank"
                                title={termsConditionsLink}
                            >
                                Terms & Conditions
                            </a>
                        </h5>
                    </div>
                    <TemplateButton
                        type="primary"
                        style={{
                            borderRadius: '8px',
                            minWidth: '134px',
                            height: '41px',
                            padding: '10px',
                            alignSelf: 'center',
                            marginLeft: '10px',
                            marginBottom: '15px',
                        }}
                        onClick={handleEmailSubmit}
                    >
                        Sign Up
                    </TemplateButton>
                </div>
            </div>
        </div>
    );
};
