import log from 'loglevel';
import { UserProfile } from '../../../services/UserProfile';
import { IUserProfile } from '../../contexts/app-context';
import { CustomerInfoInterface } from '../menu/model';
import useSegment from '../utils/segment';

type FieldType = 'email' | 'phone_number' | 'name';

export const saveContactInfoToUserProfile = async (
    values: any,
    userProfile: IUserProfile | null,
    getAccessTokenSilently: any,
    updateUserProfile: (input: IUserProfile) => void,
    segment: ReturnType<typeof useSegment>,
) => {
    if (!userProfile) {
        return null;
    }
    const { ci_name: name, ci_email: email, ci_phonenumber: phoneNumber } = values;

    const tempUserProfileCopy: IUserProfile = userProfile;
    const fieldTypes: FieldType[] = ['name', 'email', 'phone_number'];
    const fieldTypesToValuesDict: { [k: string]: FieldType } = {
        name,
        email,
        phone_number: phoneNumber,
    };
    for (const field of fieldTypes) {
        if (!tempUserProfileCopy[field]) {
            tempUserProfileCopy[field] = fieldTypesToValuesDict[field];
        }
    }
    try {
        const token = await getAccessTokenSilently();
        const userProfileResponse = await UserProfile.updateUserProfile(token, tempUserProfileCopy);
        log.debug('Updated user profile response', userProfileResponse);
        updateUserProfile(userProfileResponse);
    } catch (err) {
        segment.errorShown(`Error updating user profile in Checkout, ${err}`);
    }
    return segment.profileInfoIdentify(
        tempUserProfileCopy.name || '',
        tempUserProfileCopy.phone_number || '',
    );
};

export const hasContactInfoComplete = (customerDetails: CustomerInfoInterface | null) => {
    let result = false;
    if (customerDetails) {
        for (const value of Object.values(customerDetails)) {
            if (!value) {
                return result;
            }
        }
        result = true;
    }
    return result;
};

export const customerFormValidationError = (customerInfo: CustomerInfoInterface | null) => {
    if (!customerInfo || !hasContactInfoComplete(customerInfo)) {
        return true;
    }
    const { name, email, phonenumber } = customerInfo;
    const emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})*$/;
    const fullNamePattern = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)+$/; // checks for more than one word
    const validations: { [k: string]: boolean } = {
        name: fullNamePattern.test(name),
        email: emailPattern.test(email),
        phonenumber: phonenumber?.length >= 10,
    };
    for (const key in validations) {
        if (!validations[key as keyof typeof validations]) {
            return true;
        }
    }
    return false;
};
