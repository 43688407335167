import { get } from './config';

/**
 * @description Order Status service for fetching order status
 */

export const OrderStatus = {
    get: ({ orderId }: { orderId: string }) => {
        return get(`/order-status/${orderId}`);
    },
};
