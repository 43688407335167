import { RightOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { useContext, useEffect, useState } from 'react';
import './desktop-header-content.scss';
import { AppContext } from '../../../contexts/app-context';
import { useFutureOrder } from '../../../hooks/useFutureOrder';
import { AddressEntryModal } from '../../order-address-form/address-entry-modal';
import { FutureOrderPopup } from '../../ui-components/future-order-components/future-order-popup';
import '../../../modules/ui-components/future-order-components/future-order-header.scss';
import { ChangeAddressPopover } from '../change-address-popover';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DesktopHeaderContent = (props: { [key: string]: number }) => {
    const { orderAddress, template } = useContext(AppContext);

    const [isAddressModalVisible, setAddressModalVisible] = useState(false);

    const onCancelAddressModal = () => {
        setAddressModalVisible(false);
    };

    const handleSubmitAddressForm = () => {
        setAddressModalVisible(false);
    };

    const { Text } = Typography;
    const ldFlags = useFlags();

    const [allowFutureOrderEdit, setAllowFutureOrderEdit] = useState<boolean>(true);

    const primaryColor = template?.general?.primary_color;
    const secondaryColor = template?.general?.secondary_color;

    const {
        deliveryTime,
        setDeliveryTime,
        isFutureOrderPopoverVisible,
        toggleFutureOrderPopover,
        supportScheduleOrder,
        isAddressPopoverVisible,
    } = useFutureOrder();

    // const addressFormTitle =
    //     !allowFutureOrderEdit && !orderAddress
    //         ? 'Before you can schedule an order, we\'ll need to make sure that we can deliver to you. What is your delivery address?'
    //         : 'Enter your delivery address';

    const handleDateRoadblockClick = () => {
        setAddressModalVisible(true);
    };

    const PopoverAddressText = (
        <Text
            aria-label="Open input field to enter your delivery address"
            strong
            className="header-action-item mx-5"
        >
            {orderAddress?.structured_formatting?.main_text ?? `Your Address`}
            <RightOutlined style={{ fontSize: '11px', marginLeft: '8px' }} />
        </Text>
    );

    useEffect(() => {
        if (!isAddressPopoverVisible) {
            setAllowFutureOrderEdit(true);
        }
    }, [isAddressPopoverVisible, allowFutureOrderEdit]);

    log.debug('%c DesktopHeaderContent ', 'background-color: green', orderAddress);

    return (
        <div className="desktop-dynamic-content">
            <section className="header-items-center">
                {orderAddress ? (
                    <>
                        <>
                            {ldFlags.enableLamv ? (
                                <Text
                                    aria-label="Open input field to enter your delivery address"
                                    strong
                                    className="header-action-item mx-5"
                                    onClick={() => {
                                        handleDateRoadblockClick();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {orderAddress?.structured_formatting?.main_text ??
                                        `Your Address`}
                                    <RightOutlined style={{ fontSize: '11px' }} />
                                </Text>
                            ) : (
                                <ChangeAddressPopover
                                    children={PopoverAddressText}
                                    placement="bottom"
                                />
                            )}
                        </>
                        <br></br>
                        <div>
                            <Text
                                strong
                                onClick={() => {
                                    if (supportScheduleOrder) {
                                        handleDateRoadblockClick();
                                    }
                                }}
                                className={`header-action-item ${
                                    supportScheduleOrder ? 'future-order-avail' : ''
                                }`}
                                style={{ color: primaryColor }}
                            >
                                Deliver&nbsp;
                                {`${deliveryTime} `}
                                {supportScheduleOrder && (
                                    <RightOutlined
                                        style={{ fontSize: '11px', paddingRight: '5px' }}
                                    />
                                )}
                                &nbsp;
                            </Text>
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            backgroundColor: secondaryColor,
                            padding: '6px 8px',
                            borderRadius: '8px',
                        }}
                    >
                        <Text
                            strong
                            onClick={handleDateRoadblockClick}
                            className={`header-action-item ${
                                supportScheduleOrder ? 'future-order-avail' : ''
                            }`}
                            style={{ color: primaryColor }}
                        >
                            {` Add Delivery Address `}
                            {supportScheduleOrder && (
                                <RightOutlined style={{ fontSize: '11px', paddingRight: '5px' }} />
                            )}
                        </Text>
                    </div>
                )}
                <AddressEntryModal
                    isModalVisible={isAddressModalVisible}
                    formTitle="Enter your delivery address to check availability"
                    onCloseCallback={onCancelAddressModal}
                    handleSubmit={handleSubmitAddressForm}
                />
                {isFutureOrderPopoverVisible && (
                    <FutureOrderPopup
                        onCancel={toggleFutureOrderPopover}
                        setDeliveryTime={setDeliveryTime}
                    />
                )}
            </section>
        </div>
    );
};
