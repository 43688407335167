import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Affix, Col, Row, Tabs } from 'antd';
import log from 'loglevel';
import React, { useContext, useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useQuery } from 'react-query';
import scrollToElement from 'scroll-to-element';
import { Concepts } from '../../../services/Concepts';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext, ConceptContextInitValue } from '../../contexts/concept-context';
import { Footer } from '../footer';
import { ImageWithFallback } from '../ui-components/image-with-fallback';
import { scrollCategorySubnav, useMobileScreen } from '../utils/general';
import useSegment from '../utils/segment';
import { FeatureBlock } from './feature-block';
import { ItemCard } from './item-card';
import { MobilesSliderBlock } from './mobile-slider-block';
import { MenuItemInterface, MenuSectionInterface, findEntity } from './model';
import { PageNotFound } from './page-not-found';
import './index.scss';

const parentMenuInfo = {
    itemPrice: null,
    menuType: 'Parent',
};

export const ParentMenu = ({
    headerHeight = 200,
    handleItemDetailPopUp,
    setFetchingMenu,
}: {
    headerHeight: number;
    handleItemDetailPopUp: any;
    setFetchingMenu: (value: boolean) => void;
}) => {
    const { TabPane } = Tabs;
    const isMobile = useMobileScreen();
    const { updateConceptDetails } = useContext(ConceptContext);
    const { bannerShown, template } = useContext(AppContext);

    const [visibleTabs, setVisibleTabs] = useState<boolean[]>([]);
    const [activeTab, setActiveTab] = useState<string>('');
    const [subnavScroll, setSubnavScroll] = useState<number>(0);
    const [tabScrolling, setTabScrolling] = useState<boolean>(false);
    const [verticalScrollOffset, setVerticalScrollOffset] = useState<number>(-140);

    const segment = useSegment();
    const brandId = template?.metadata.brand.id || '';
    const nativeStatusBar = document.querySelector('#native-status-bar');
    const brandPrimaryColor = template?.general?.primary_color;

    const queryDefaultOptions = {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 86400000, // 24 hours
    };

    const hasStoreParam = location.pathname.includes('store');
    const hasItemParam = location.pathname.includes('item');

    const {
        data: menuInfo,
        isFetching,
        isError,
        isSuccess,
        isLoading,
    } = useQuery(
        ['menuInfo', brandId],
        async () => {
            try {
                if (hasStoreParam) {
                    let locationShortId = '';
                    let splittedPathnameLength = 0;

                    if (hasItemParam) {
                        const pathWithoutItem = location.pathname.split('/item')[0];
                        splittedPathnameLength = pathWithoutItem.split('/').length;
                    } else {
                        splittedPathnameLength = location.pathname.split('/').length;
                    }
                    locationShortId = location.pathname.split('/')[splittedPathnameLength - 1];
                    setFetchingMenu(true);

                    const res = await Concepts.getChildMenuSet({
                        locationShortId: locationShortId || '',
                    });
                    log.debug('%c GET CHILD MENU: ', 'background-color: orange', res);
                    const tempConceptDetails = ConceptContextInitValue.conceptDetails;
                    tempConceptDetails.menuInfo = res.data;

                    updateConceptDetails(tempConceptDetails);
                    setFetchingMenu(false);
                    return res.data;
                } else {
                    if (template?.metadata.brand.id && !hasStoreParam) {
                        log.debug('%c GET PARENT MENU: ', 'background-color: yellow', template);
                        setFetchingMenu(true);
                        const res = await Concepts.getParentMenuSet({
                            brandId: template?.metadata.brand.id || '',
                        });
                        const tempConceptDetails = ConceptContextInitValue.conceptDetails;
                        tempConceptDetails.menuInfo = res.data;
                        updateConceptDetails(tempConceptDetails);
                        setFetchingMenu(false);
                        return res.data;
                    }
                    return null;
                }
            } catch (error) {
                log.error('Failed to load Parent Menu', error);
                return setFetchingMenu(false);
            }
        },
        queryDefaultOptions,
    );

    log.debug('%c PARENT MENU INFO: ', 'background-color: yellow', menuInfo);

    const updateScrollPosition = () => {
        const subnav = document.querySelector<HTMLDivElement>('.ant-tabs-nav-list');
        if (subnav) {
            const scrollMatch = subnav.style.transform.match(/(?:\()(-*\d*)/);
            if (scrollMatch) {
                setSubnavScroll(Math.abs(parseInt(scrollMatch[1], 10)));
            }
        }
    };

    useEffect(() => {
        if (menuInfo?.app_id && template && menuInfo?.sections.length > 0) {
            segment.menuShown(parentMenuInfo.itemPrice, parentMenuInfo.menuType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuInfo, template]);

    useEffect(() => {
        let offset = -140;
        if (nativeStatusBar) {
            offset -= nativeStatusBar.clientHeight;
        }
        if (bannerShown) {
            const globalBanner = document.querySelector<HTMLDivElement>('.banner-wrapper');
            if (globalBanner) {
                offset -= globalBanner.clientHeight;
            }
        }
        setVerticalScrollOffset(offset);
    }, [bannerShown, nativeStatusBar]);

    useEffect(() => {
        let scrollTimeout: any;

        if (tabScrolling) {
            updateScrollPosition();
        } else {
            scrollTimeout = setTimeout(() => updateScrollPosition(), 100);
        }

        return () => clearTimeout(scrollTimeout);
    }, [tabScrolling]);

    if (menuInfo?.app_id == null || isFetching || isLoading) {
        return null;
    }

    const handleTabScroll = () => {
        setTabScrolling(true);
        setTabScrolling(false);
    };

    const menu = menuInfo.menus[0];
    const sections = menu.section_ids
        .map((sectionId: any) => {
            const section = findEntity(menuInfo, 'section', sectionId);
            const items = section.item_ids.map(el => findEntity(menuInfo, 'item', el));
            const unpausedItems = items.filter(el => el.is_paused !== true);
            return unpausedItems.length > 0 ? section : null;
        })
        .filter((el: any): el is MenuSectionInterface => el != null);

    const handleTabClick = (key: string) => {
        const elem = document.getElementById(key);
        scrollToElement(elem!, {
            offset: verticalScrollOffset,
            duration: 500,
        });
        setActiveTab(key);
        updateScrollPosition();
        const selectedSection = findEntity(menuInfo, 'section', key);
        const index = sections.findIndex((section: any) => section.id === selectedSection.id);
        segment.menuSectionClicked(
            selectedSection,
            index,
            sections.length,
            parentMenuInfo.menuType,
        );
    };
    const renderTabBar = (props: any, DefaultTabBar: any) => {
        return (
            <DefaultTabBar {...props} onTabClick={handleTabClick} onTabScroll={handleTabScroll} />
        );
    };

    const handleSubnavScroll = (disabled: boolean, scrollForward: boolean) => {
        if (!disabled) {
            const horizontalScroll = scrollCategorySubnav(scrollForward);
            setSubnavScroll(horizontalScroll);
        }
    };

    const changeView = (inView: any, entry: any) => {
        const splitId: string[] = entry.target.id.split('|');
        const sectionIds: string[] = sections.map((el: any) => el.id);
        const index: number = sectionIds.findIndex(id => id === splitId[1]);
        visibleTabs[index] = inView;
        setVisibleTabs(visibleTabs);
        const firstVisible: number = visibleTabs.findIndex(val => val);
        // if nothing is visible then don't set the nav. This can happen on cases where you've scrolled
        // past the last section and no visible tab headers. or if a section has a large number of items
        // then potentially the next section isn't visible yet.
        if (firstVisible >= 0) {
            setActiveTab(sectionIds[firstVisible]);
        }
        setTimeout(() => updateScrollPosition(), 100);
    };

    let hideArrowsInSubNav = false;
    const subnavWrapper = document.querySelector('.ant-tabs-nav-wrap');
    const subnavList = document.querySelector('.ant-tabs-nav-list');
    let maxScroll = 0;
    if (subnavWrapper && subnavList) {
        hideArrowsInSubNav = subnavList.clientWidth <= subnavWrapper.clientWidth;
        maxScroll = subnavWrapper.scrollWidth - subnavWrapper.clientWidth;
    }
    const isLeftArrowDisabled = subnavScroll <= 0;
    const isRightArrowDisabled = subnavScroll >= maxScroll;

    const leftArrowStyle = isLeftArrowDisabled
        ? { color: '#BFBFBF', cursor: 'auto' }
        : { color: '#262626' };
    const rightArrowStyle = isRightArrowDisabled
        ? { color: '#BFBFBF', cursor: 'auto' }
        : { color: '#262626' };

    const ArrowNavigation = {
        left: (
            <ArrowLeftOutlined
                style={leftArrowStyle}
                onClick={() => handleSubnavScroll(isLeftArrowDisabled, false)}
            />
        ),
        right: (
            <ArrowRightOutlined
                style={rightArrowStyle}
                onClick={() => handleSubnavScroll(isRightArrowDisabled, true)}
            />
        ),
    };

    const isHeroContentBlock =
        template?.menu_page?.hero_content_block?.title ||
        template?.menu_page?.hero_content_block?.image ||
        template?.menu_page?.hero_content_block?.image_description;
    const useOldHeroImage = template?.menu_page?.hero_image && !isHeroContentBlock;
    const isLeftImageContentBlock =
        template?.menu_page?.left_image_content_block?.title ||
        template?.menu_page?.left_image_content_block?.image ||
        template?.menu_page?.left_image_content_block?.image_description;
    const isRightImageContentBlock =
        template?.menu_page?.right_image_content_block?.title ||
        template?.menu_page?.right_image_content_block?.image ||
        template?.menu_page?.right_image_content_block?.image_description;

    const areRightLeftBlocks = isRightImageContentBlock && isLeftImageContentBlock;

    const showPrice = template?.menu_page?.show_parent_pricing === true;

    return (
        <>
            {isSuccess && (
                <CdpLocationContext.Provider value="Menu Item">
                    <div className="viewport-minus-header-height">
                        <Row className="menu-page">
                            {useOldHeroImage && (
                                <Col
                                    span={24}
                                    className="hero-image-box"
                                    style={{ width: '100%', height: 300, marginTop: 20 }}
                                >
                                    <div style={{ margin: 'auto', maxWidth: 1020 }}>
                                        <ImageWithFallback
                                            style={{
                                                top: '-80%',
                                                maxWidth: 1020,
                                                margin: 'auto',
                                            }}
                                            src={template?.menu_page?.hero_image}
                                            resize={1000}
                                        />
                                    </div>
                                </Col>
                            )}
                            {(isHeroContentBlock ||
                                isLeftImageContentBlock ||
                                isRightImageContentBlock) && (
                                <div
                                    className="feature-box-wrapper"
                                    style={isMobile ? { zIndex: 1 } : {}}
                                >
                                    {isMobile ? (
                                        <MobilesSliderBlock template={template} />
                                    ) : (
                                        <>
                                            {isHeroContentBlock && (
                                                <div
                                                    className="feature-box"
                                                    style={{ maxHeight: '328px' }}
                                                >
                                                    <FeatureBlock
                                                        title={
                                                            template?.menu_page?.hero_content_block
                                                                ?.title
                                                        }
                                                        text={
                                                            template?.menu_page?.hero_content_block
                                                                ?.image_description
                                                        }
                                                        mediaSrc={
                                                            template?.menu_page?.hero_content_block
                                                                ?.image
                                                        }
                                                        altTag={
                                                            template?.menu_page.hero_content_block
                                                                ?.alt_tag
                                                        }
                                                        big
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className="feature-box"
                                                style={
                                                    isMobile
                                                        ? { flexDirection: 'column', gap: '35px' }
                                                        : {}
                                                }
                                            >
                                                {isLeftImageContentBlock && (
                                                    <FeatureBlock
                                                        title={
                                                            template?.menu_page
                                                                ?.left_image_content_block?.title
                                                        }
                                                        text={
                                                            template?.menu_page
                                                                ?.left_image_content_block
                                                                ?.image_description
                                                        }
                                                        mediaSrc={
                                                            template?.menu_page
                                                                ?.left_image_content_block?.image
                                                        }
                                                        big={!areRightLeftBlocks}
                                                        bigNotHero={!areRightLeftBlocks}
                                                        altTag={
                                                            template?.menu_page
                                                                .left_image_content_block?.alt_tag
                                                        }
                                                    />
                                                )}
                                                {isRightImageContentBlock && (
                                                    <FeatureBlock
                                                        title={
                                                            template?.menu_page
                                                                ?.right_image_content_block?.title
                                                        }
                                                        text={
                                                            template?.menu_page
                                                                ?.right_image_content_block
                                                                ?.image_description
                                                        }
                                                        mediaSrc={
                                                            template?.menu_page
                                                                ?.right_image_content_block?.image
                                                        }
                                                        altTag={
                                                            template?.menu_page
                                                                .right_image_content_block?.alt_tag
                                                        }
                                                        big={!areRightLeftBlocks}
                                                        bigNotHero={!areRightLeftBlocks}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="tab-menu-container">
                                <div className="tab-container">
                                    <Affix
                                        className="width-100 affix-container"
                                        offsetTop={isMobile ? headerHeight + 10 : headerHeight + 30}
                                    >
                                        <Row className="tabs-row">
                                            <Tabs
                                                type="card"
                                                activeKey={activeTab}
                                                renderTabBar={renderTabBar}
                                                className="menu-tabs width-100 bg-white"
                                                tabBarExtraContent={
                                                    hideArrowsInSubNav ? null : ArrowNavigation
                                                }
                                            >
                                                {sections.map((section: MenuSectionInterface) => {
                                                    return (
                                                        <TabPane
                                                            tab={section.title}
                                                            key={section.id}
                                                        />
                                                    );
                                                })}
                                            </Tabs>
                                        </Row>
                                    </Affix>
                                </div>

                                <Row className="menu-container">
                                    {sections.map((section: MenuSectionInterface) => (
                                        <Row
                                            key={section.id}
                                            gutter={[24, 24]}
                                            id={section.id}
                                            style={{ width: '105%' }}
                                        >
                                            <Col xs={24}>
                                                <InView
                                                    key={`inview_${section.id}`}
                                                    onChange={changeView}
                                                >
                                                    {({ ref }) => {
                                                        return (
                                                            <div ref={ref} id={`div|${section.id}`}>
                                                                <h2 className="section-heading">
                                                                    {section.title}
                                                                </h2>
                                                            </div>
                                                        );
                                                    }}
                                                </InView>
                                            </Col>
                                            {section.item_ids
                                                .map((id: string) => {
                                                    return findEntity(menuInfo, 'item', id);
                                                })
                                                .map((item: MenuItemInterface) => (
                                                    <ItemCard
                                                        section={section}
                                                        key={item.id}
                                                        item={item}
                                                        idHierarchy={[menu.id, section.id, item.id]}
                                                        onSelect={handleItemDetailPopUp}
                                                        showPrice={showPrice}
                                                    />
                                                ))}
                                        </Row>
                                    ))}
                                    {!isMobile && <div className="footer-invisible-divider" />}
                                    <Row
                                        className={
                                            isMobile ? 'footer-section-mobile' : 'footer-section'
                                        }
                                        style={{ background: brandPrimaryColor }}
                                    >
                                        <Footer />
                                    </Row>
                                </Row>
                            </div>
                        </Row>
                    </div>
                </CdpLocationContext.Provider>
            )}
            {isError && <PageNotFound />}
            {isLoading && <>Loading...</>}
        </>
    );
};
