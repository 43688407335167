import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import log from 'loglevel';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/app-context';
import { ConceptContext } from '../../../contexts/concept-context';
import request from '../../utils/request';
import './index.scss';

export interface TemplateDetails {
    brand_id?: string;
    brand_name?: string;
    staging_url?: string;
    last_updated?: string;
}

export interface BrandSelectProps {
    clearAddress: (input: string) => void;
}

export const BrandSelect: FC<BrandSelectProps> = ({ clearAddress }) => {
    const [templateDetails, setTemplateDetails] = useState<TemplateDetails[]>([]);
    const [brandLoading, setBrandLoading] = useState<boolean>(true);
    const { updateBrandUrl, template } = useContext(AppContext);
    const { clearAddressAndCartSession } = useContext(ConceptContext);

    const handleMenuItemClick = (e: any) => {
        const templateDetail: TemplateDetails = templateDetails[e.key];

        log.debug('%c Template Details: ', 'background-color: green', templateDetail);

        if (templateDetail?.staging_url) {
            log.debug(
                '%c Template exists and staging url is available ',
                'background-color: green',
            );
            clearAddressAndCartSession();
            updateBrandUrl(templateDetail.staging_url);
            clearAddress(templateDetail.staging_url);
        }
    };

    useEffect(() => {
        request.get('brand-template/list-details/').then(({ data }) => {
            setTemplateDetails(data);
            setBrandLoading(false);
        });
    }, []);

    const menus = Object.entries(templateDetails).map(key => {
        return <Menu.Item key={key[0]}>{key[1].brand_name}</Menu.Item>;
    });
    const menu = () => {
        return <Menu onClick={handleMenuItemClick}>{menus}</Menu>;
    };
    return (
        <Dropdown overlay={menu} placement="bottomLeft">
            <Button loading={brandLoading}>
                {template?.metadata.brand.name} <DownOutlined />
            </Button>
        </Dropdown>
    );
};
