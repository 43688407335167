import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Col, Input, Row, Typography } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Controller, FieldValues, FormState } from 'react-hook-form';
import { AppContext } from '../../contexts/app-context';
import { ConceptContext } from '../../contexts/concept-context';
import { PlacesAutocomplete } from '../ui-components/PlacesAutocomplete';
import { TemplateButton } from '../ui-components/template-button';
import { noLocationsMessage, noLocationsMessageShort } from '../utils/error-messages';

const { Title } = Typography;

export const SignupForm: FC<ISignupFormProps> = ({
    onSubmit,
    formTitle,
    formState,
    control,
    onClearAddress,
}) => {
    const ldFlags = useFlags();
    const { userProfile } = useContext(AppContext);
    const { isConceptFetching } = useContext(ConceptContext);
    const [inputField, setInputField] = useState<string>('');
    const [isEmailValid, setEmailValid] = useState<boolean>(false);

    const validateEmailAddress = useCallback(
        input => {
            const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (pattern.test(input)) {
                setEmailValid(true);
            } else {
                setEmailValid(false);
            }
        },
        [setEmailValid],
    );

    const getMessageCopy = () => {
        return userProfile && ldFlags.enableAccounts ? noLocationsMessageShort : noLocationsMessage;
    };

    useEffect(() => {
        validateEmailAddress(inputField);
    }, [inputField, validateEmailAddress]);

    const AddressEntryInput = () => {
        return (
            <>
                <Title level={3} className="address-entry-title condensed-txt">
                    {formTitle}
                </Title>
                <Controller
                    name="orderAddress"
                    control={control}
                    render={({ field }) => {
                        return (
                            <>
                                <label htmlFor="orderAddress" className="sr-only">
                                    Order Address
                                </label>
                                <PlacesAutocomplete
                                    {...field}
                                    error={formState?.errors?.orderAddress}
                                    helperText={formState?.errors?.orderAddress?.message}
                                    placeholder="Address"
                                    id="orderAddress"
                                    onClear={onClearAddress}
                                    isAddressValid={false}
                                />
                            </>
                        );
                    }}
                />
            </>
        );
    };

    const SignupFormContent = () => {
        const [showEnterEmail, setShowEnterEmail] = useState(false);
        const [emailSubmitted, setEmailSubmitted] = useState(false);

        const handleEmailSubmit = (input: string) => {
            onSubmit(input);
            setEmailSubmitted(true);
        };

        // eslint-disable-next-line no-console
        console.log(formState, 'formmmmmmmmmmmm');

        return (
            <Row className="signup-form-wrapper">
                {userProfile && ldFlags.enableAccounts ? (
                    <Col span={24} className="extended-content width-100">
                        <Alert type="error" message={getMessageCopy()} showIcon></Alert>
                    </Col>
                ) : showEnterEmail && !emailSubmitted ? (
                    <>
                        <div className="no-locations-title">Enter Email</div>
                        <div className="no-locations-text">
                            You’ll be notified when we open up a location nearby.
                        </div>
                        <div className="no-location-buttons-container">
                            <Input
                                onChange={e => setInputField(e.target.value)}
                                placeholder="Enter your email address"
                                size="large"
                                type="email"
                                id="email"
                                value={inputField}
                                autoFocus={true}
                                required
                                className="input-email"
                            />
                            <TemplateButton
                                size="large"
                                block
                                type="primary"
                                data-testid="signup-button"
                                onClick={() => handleEmailSubmit(inputField)}
                                disabled={!isEmailValid}
                                style={{ width: '50%' }}
                            >
                                Submit
                            </TemplateButton>
                        </div>
                    </>
                ) : !emailSubmitted ? (
                    <>
                        <Col span={24} className="extended-content width-100">
                            <div className="no-locations-title">No Locations Nearby</div>
                            <div className="no-locations-text">{getMessageCopy()}</div>
                            <div className="no-location-buttons-container">
                                <TemplateButton
                                    size="large"
                                    block
                                    type="primary"
                                    data-testid="signup-button"
                                    onClick={() => setShowEnterEmail(true)}
                                >
                                    Enter Email
                                </TemplateButton>
                                <TemplateButton
                                    size="large"
                                    block
                                    type="primary"
                                    data-testid="signup-button"
                                    onClick={() => onClearAddress()}
                                    className="grey-button"
                                >
                                    Change Address
                                </TemplateButton>
                            </div>
                        </Col>
                    </>
                ) : (
                    <>
                        <div className="no-locations-title">Email Confirmed</div>
                        <div className="no-locations-text">
                            You’ll be notified when we open up a location nearby. For now, you can
                            explore the menu or change delivery address.
                        </div>
                        <div className="no-location-buttons-container">
                            <TemplateButton
                                size="large"
                                block
                                type="primary"
                                data-testid="signup-button"
                                onClick={() => onClearAddress()}
                                className="grey-button"
                            >
                                Change Address
                            </TemplateButton>
                        </div>
                    </>
                )}
            </Row>
        );
    };

    return (
        <>
            <AddressEntryInput />
            {isConceptFetching ? (
                <div className="spin-wrapper">
                    <LoadingOutlined style={{ fontSize: '2em' }} />
                </div>
            ) : (
                SignupFormContent()
            )}
        </>
    );
};

interface ISignupFormProps {
    control: any;
    formTitle: string;
    formState: FormState<FieldValues>;
    onSubmit: (key: string) => void;
    onClearAddress: () => void;
}
