import { CheckCircleFilled, CloseCircleFilled, TagOutlined } from '@ant-design/icons';
import { Button, Col, Input, Space } from 'antd';
import log from 'loglevel';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Orders } from '../../../services/Orders';
import { AppContext } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { OrderTotals } from '../menu/model';
import { formatMoneyInteger } from '../utils/general';
import { getOrderTotalPayload } from '../utils/order-totals';
import useSegment from '../utils/segment';

interface VIBProps {
    orderTotals: OrderTotals;
    otherTipValue: number;
    fetchDiscounts: boolean;
    setOrderTotals: (orderTotals: OrderTotals) => void;
    setFetchDiscounts: (value: boolean) => void;
}

export const VoucherInputBox: FC<VIBProps> = ({
    orderTotals,
    setOrderTotals,
    otherTipValue,
    setFetchDiscounts,
    fetchDiscounts,
}) => {
    const {
        tipValue,
        updateVouchers,
        cartItems,
        orderAddress,
        customerInfo,
        template,
        updatePromoId,
        orderTotalIsFetching,
        updateOrderTotalIsFetching,
    } = useContext(AppContext);
    const cdpLocation = useContext(CdpLocationContext);
    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);

    const [voucherInput, setVoucherInput] = useState<string>('');
    const [vouchers, setVouchers] = useState<string[]>([]);
    const [voucherErr, setVoucherErr] = useState<boolean>(false);
    const [isPromoLinkActive, setPromoLinkActive] = useState<boolean>(false);

    const segment = useSegment();

    const fetchDiscountedSubtotals = useCallback(async () => {
        setFetchDiscounts(false);
        const payload = getOrderTotalPayload(
            orderAddress,
            restaurantInfo,
            cartItems,
            otherTipValue,
            customerInfo,
            vouchers,
            template,
            'Checkout',
        );
        try {
            updateOrderTotalIsFetching(true);
            const { data } = await Orders.getTotals(payload);
            if (data) {
                setOrderTotals(data);
                let error = false;
                if (data?.promo_id) {
                    updatePromoId(data.promo_id);
                }
                const recentlyEnteredVoucher = vouchers[vouchers.length - 1];
                data.vouchers.forEach((voucher: any) => {
                    if (voucher.error) {
                        error = true;
                    }
                    if (voucher.code === recentlyEnteredVoucher && !voucher.error) {
                        const discountType = voucher.percent_off ? 'percent_off' : 'amount_off';
                        segment.couponApplied(voucher.code, voucher.discount, discountType);
                    } else if (voucher.code === recentlyEnteredVoucher && voucher.error) {
                        segment.couponDenied(voucher.code, voucher.discount, voucher.error);
                    }
                });
                setVoucherErr(error);
                const validVouchers = data?.vouchers
                    .filter((voucher: any) => !voucher.error)
                    .map((voucher: any) => voucher.code);
                updateVouchers(validVouchers);
                setVouchers(validVouchers);
            } else {
                setVouchers(vouchers.slice(0, -1));
            }
        } catch (err) {
            log.error('Error Fetching Order Total Discounts', err);
        } finally {
            updateOrderTotalIsFetching(false);
            setVoucherInput('');
        }
    }, [
        orderAddress,
        restaurantInfo,
        cartItems,
        customerInfo,
        template,
        vouchers,
        otherTipValue,
        setOrderTotals,
        updateVouchers,
        updatePromoId,
        segment,
        setFetchDiscounts,
        updateOrderTotalIsFetching,
    ]);

    useEffect(() => {
        const noPayloadYet = orderTotals.subtotal.length < 1;
        if (noPayloadYet) {
            setFetchDiscounts(true);
            fetchDiscountedSubtotals();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderTotals]);

    useEffect(() => {
        if (cdpLocation !== 'Checkout' || !fetchDiscounts || orderTotalIsFetching) {
            return;
        }
        fetchDiscountedSubtotals();
        /**
         * TODO: fetchDiscountSubtotals depends on way too many stuffs. And
         * modifying way too many things. We should simplify the function and,
         * rethink the fetching logic of this components.
         * As for now, adding fetchDiscountSubtotals as a dependency will cause infinite loop.
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vouchers, tipValue, cartItems]);

    const applyVoucher = () => {
        segment.couponEntered(voucherInput);
        setVouchers(prevVouchers => [...prevVouchers, voucherInput]);
        setVoucherInput('');
        setFetchDiscounts(true);
    };

    const removeVoucher = (voucherToBeRemoved: string, discount: number, percentOff: number) => {
        const discountType = percentOff ? 'percent_off' : 'amount_off';
        segment.couponRemoved(voucherToBeRemoved, discount, discountType);
        const updatedVoucherList = vouchers.filter(voucher => voucher !== voucherToBeRemoved);
        setVouchers(updatedVoucherList);
        setFetchDiscounts(true);
    };

    const HaveAPromoCodeLink = () => (
        <Space
            size={8}
            onClick={() => setPromoLinkActive(!isPromoLinkActive)}
            style={{ color: '#1890FF', cursor: 'pointer', margin: '12px 0' }}
        >
            <TagOutlined />
            <span data-testid="voucher-input-link">{`Have a promo code?`}</span>
        </Space>
    );

    const appliedPromo = () => {
        const { promo_discount: promoDiscount, promo_name: promoName } = orderTotals;
        if (promoDiscount && promoName) {
            return (
                <div className="voucher">
                    <div className="check-discount">
                        <CheckCircleFilled style={{ color: '#389E0D' }} />
                        <div>
                            {promoName} (-{formatMoneyInteger(promoDiscount)})
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <HaveAPromoCodeLink />
            {isPromoLinkActive ? (
                <Col span={24}>
                    <Col sm={16} xs={24} className="voucher-input">
                        <Input
                            type="text"
                            value={voucherInput}
                            onChange={({ target: { value } }) => setVoucherInput(value)}
                            className={voucherErr ? 'err-border' : ''}
                            suffix={(() =>
                                voucherErr && <CloseCircleFilled style={{ color: '#F5222D' }} />)()}
                            data-testid="voucher-input"
                            size="large"
                        />
                        <Button
                            onClick={applyVoucher}
                            disabled={voucherInput.length < 1}
                            className={`no-bg voucher-apply-btn ${
                                voucherInput.length < 1 ? 'btn-disabled' : 'btn-active'
                            }`}
                            data-testid="voucher-apply-btn"
                            size="large"
                        >
                            Apply
                        </Button>
                    </Col>
                    <Space className="py-5" direction="vertical" size={0}>
                        {appliedPromo()}
                        {orderTotals.vouchers.map(voucher => {
                            const { discount, code, error, percent_off: percentOff } = voucher;
                            if (error) {
                                return (
                                    <div key={code} className="voucher">
                                        <div style={{ color: '#F5222D' }}>{error}</div>
                                    </div>
                                );
                            }
                            const removedVoucher = !vouchers.includes(code);
                            if (removedVoucher) {
                                return null;
                            }
                            return (
                                <div key={code} className="voucher">
                                    <div className="check-discount">
                                        <CheckCircleFilled style={{ color: '#389E0D' }} />
                                        <div>
                                            {code} (-{formatMoneyInteger(discount)})
                                        </div>
                                    </div>
                                    <Button
                                        type="link"
                                        onClick={() => removeVoucher(code, discount, percentOff)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            );
                        })}
                    </Space>
                </Col>
            ) : (
                <></>
            )}
        </>
    );
};
