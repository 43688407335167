// import { DevTool } from '@hookform/devtools';
import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { RadioChangeEvent, Row, Space } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FieldValues, FormState, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { AppContext, FutureOrderDate } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { findClosestDate } from '../../hooks/useFutureOrder';
import { SavedAddresses } from '../ui-components/SavedAddresses';
import { TemplateButton } from '../ui-components/template-button';
import { saveAddressToUserProfile } from '../utils/address';
import {
    getExtendedPauseAndFOrderdMessage,
    getPausedAndNoFOrderMessage,
} from '../utils/error-messages';
import { getLocationReopenDayTime, storeAddressToUrlPath, useMobileScreen } from '../utils/general';
import useSegment from '../utils/segment';
import './index.scss';
import { OrderAddressEntries } from './order-address-entries';
import { SignupForm } from './signup-form';

/**
 * Component for collecting order address.
 * @component
 * @example
 *  const submitCallback = (formValues) => {
 *      setAddressPopoverVisible(false);
 *  }
 *
 *  const cancelCallback = (onCancel?: () => void) => {
 *       onCancel?.();
 *       setAddressPopoverVisible(false);
 *  };
 *  <OrderAddressForm onSubmit={submitCallback}
 *  formControls = onCancel => (
 *     <>
 *      <Button onClick={() => cancelCallback(onCancel)}>
 *          Cancel
 *      </Button>
 *      <Button type="primary" htmlType="submit">
 *          Save
 *      </Button>
 *     <>
 * />
 * @param onSubmit <additional submit callback to handle form steps or modal state. Call in addition
 * to default submit handler>
 * @param formControls <render function: supplies onCancel parameter at consumer to trigger
 * default form cancel behaviour. Resets form.>
 *
 */

const DEFAULT_PREFERENCE = '';

interface AddressFormData {
    deliveryInstructions: {
        preference?: string;
        text?: string;
    };
    orderAddress: any;
    orderAddressApt: string;
}

export const OrderAddressForm = ({
    currentFormStep,
    setCurrentFormStep,
    formTitle,
    formControls,
    onSubmit: onSubmitCallback,
    onCloseModal,
    handleMenuItemMatch,
}: {
    handleMenuItemMatch?: () => void;
    currentFormStep: AddressFormSteps;
    onCloseModal?: () => void;
    setCurrentFormStep: (value: AddressFormSteps) => void;
    formTitle: string;
    formControls: (
        onCancel?: () => void,
        formState?: FormState<FieldValues>,
        forceDisable?: boolean,
    ) => JSX.Element;
    onSubmit?: (values?: any) => void;
}) => {
    const isMobile = useMobileScreen();
    const segment = useSegment();
    const ldFlags = useFlags();
    const history = useHistory();

    const {
        updateCartItems,
        updateStateIds,
        orderAddress,
        orderAddressApt,
        deliveryPreference,
        updateOrderAddress,
        updateOrderAddressApt,
        updateDeliveryPreference,
        userProfile,
        updateUserProfile,
        template,
        updateFutureOrderDate,
        showAddressEntryPopover,
        updateShowAddressEntryPopover,
    } = useContext(AppContext);
    const {
        updateConceptDetails,
        updateRestaurantInfoTempQuery,
        clearAddressAndCartSession,
        isConceptFetching,
        isErrorFetching,
        updateIsErrorFetching,
        restaurantInfoTempQuery,
        conceptDetails,
    } = useContext(ConceptContext);
    const cdpLocation = useContext(CdpLocationContext);

    const [sortedAddressList, setSortedAddressList] = React.useState<any>([]);
    const [savedLocationResults, setSavedLocationResults] = React.useState<any>([]);
    const [isInputTouched, setInputTouched] = useState(false);
    const [forceDisableButton, setForceDisableButton] = useState(false);
    const deliveryDateRef = useRef<FutureOrderDate | null>(null);
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
    const isUserLoggedIn = isAuthenticated && !isLoading;

    const defaultValues = {
        orderAddress:
            userProfile?.service_info?.length && isUserLoggedIn && ldFlags.enableAccounts
                ? ''
                : orderAddress && conceptDetails.placeDetails
                ? { ...orderAddress, conceptDetails }
                : '',
        orderAddressApt,
        deliveryInstructions: { ...deliveryPreference },
    };

    const { control, handleSubmit, reset, watch, formState } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
        mode: 'all',
        reValidateMode: 'onBlur',
    });

    const goToMenu = () => {
        if (cdpLocation === 'Checkout') {
            history.push('/');
        }
    };

    const pausedLongerThanAWeek = (conceptDetails: any) => {
        const reopenTimeString = conceptDetails?.restaurantInfo?.delivery?.reopen_at;
        if (!reopenTimeString) {
            return null;
        }
        const kitchenOpenTimeObject = getLocationReopenDayTime(reopenTimeString);
        return kitchenOpenTimeObject?.pausedLongerThanAWeek;
    };

    const watchOrderAddress = watch('orderAddress');
    const locationStatus = watchOrderAddress?.conceptDetails?.locationStatus;
    const conceptDetailsData = watchOrderAddress?.conceptDetails;
    const supportsScheduledOrders = template?.future_order?.supported_order_type?.scheduled;
    const isPausedLongerThanAWeek = pausedLongerThanAWeek(conceptDetailsData);

    log.debug('%c watchOrderAddress ', 'background-color: green', watchOrderAddress);

    const onSubmit = (data: AddressFormData) => {
        log.debug('%c ADDRESS SUBMIT DATA ', 'background-color: green', data);

        if (!conceptDetailsData?.placeDetails) {
            return null;
        }
        if (isAuthenticated && !isLoading) {
            saveAddressToUserProfile(
                data.orderAddressApt,
                conceptDetailsData.placeDetails,
                userProfile?.service_info || [],
                getAccessTokenSilently,
                updateUserProfile,
                data.deliveryInstructions,
            );
        }
        const { placeDetails, restaurantInfo } = conceptDetailsData;
        const orderAddress = { ...placeDetails, ...data.orderAddress };

        const hasItemParam = location.pathname.includes('item');

        if (hasItemParam) {
            const item = location.pathname.split('/item')[1];

            history.push(
                `/store/${storeAddressToUrlPath(
                    restaurantInfo.delivery.address.address1,
                )}/${restaurantInfo.delivery.id.substring(0, 8)}/item${item}`,
            );
        } else {
            history.push(
                `/store/${storeAddressToUrlPath(
                    restaurantInfo.delivery.address.address1,
                )}/${restaurantInfo.delivery.id.substring(0, 8)}`,
            );
        }

        updateNewAddressDetailsInContext(data, orderAddress);
        segment.IdentifyAddress(placeDetails, data.orderAddressApt);
        segment.conceptSet(restaurantInfo, placeDetails, cdpLocation);
        segment.saveAddressCTAClicked(restaurantInfo, cdpLocation);
        return onSubmitCallback?.(data);
    };

    const updateNewAddressDetailsInContext = (formData: any, orderAddressObj: any) => {
        updateConceptDetails(conceptDetailsData);
        validateNewConceptMatched(formData);
        updateOrderAddress(orderAddressObj);
        updateOrderAddressApt(formData.orderAddressApt);
        updateFutureOrderDate(deliveryDateRef.current);
        updateDeliveryPreference({
            preference: formData.deliveryInstructions.preference ?? '',
            text: formData.deliveryInstructions.text ?? DEFAULT_PREFERENCE,
        });
    };

    const validateNewConceptMatched = (formData: any) => {
        if (locationStatus?.isNewConcept) {
            delete formData.orderAddress.conceptDetails.locationStatus;
            updateStateIds({ sessionId: '', orderId: '', cartId: '', checkoutId: '' });
            localStorage.removeItem('cartItems');
            updateCartItems([]);
            goToMenu();
        }
    };

    const onClearAddress = () => {
        updateIsErrorFetching(false);
        updateRestaurantInfoTempQuery(null);
        reset({
            orderAddress: '',
            orderAddressApt: '',
            deliveryInstructions: { preference: 'Meet the driver at the door' },
        });
        updateRestaurantInfoTempQuery(null);
        setInputTouched(false);
        setForceDisableButton(false);
        setCurrentFormStep(AddressFormSteps.AddressEntry);
    };

    const handleEmailSignupClick = (email: string) => {
        setCurrentFormStep(AddressFormSteps.EmailConfirmed);
        segment.submitEmailCTA(false, cdpLocation);
        segment.emailCapturedAddressEntry(email, false, cdpLocation);
        segment.IdentifyEmail(email);
        clearAddressAndCartSession();
        setTimeout(() => {
            onClearAddress();
        }, 5000);
    };

    const onCancel = () => {
        reset(defaultValues);
        setForceDisableButton(false);
    };

    const onChangedRadio = (e: RadioChangeEvent) => {
        segment.deliveryPreferencesOptionSelected(
            optionTypeMapping[e.target.value],
            conceptDetailsData?.restaurantInfo,
            cdpLocation,
        );
    };

    const closedAndNoFOrderSuppported = useCallback(() => {
        return !supportsScheduledOrders && !locationStatus?.isOpen && !isPausedLongerThanAWeek;
    }, [locationStatus, supportsScheduledOrders, isPausedLongerThanAWeek]);

    log.debug('%c delivery Ref ', 'background-color: green', deliveryDateRef.current);

    const generateDynammicMessage = useCallback(() => {
        const reopenAtTimeString = conceptDetailsData?.restaurantInfo?.delivery?.reopen_at;
        const kitchenTimeObject = getLocationReopenDayTime(reopenAtTimeString);

        if (
            kitchenTimeObject?.pausedLongerThanAWeek ||
            kitchenTimeObject?.reopenDay == null ||
            kitchenTimeObject?.reopenHour == null
        ) {
            return null;
        }

        if (!supportsScheduledOrders) {
            return getPausedAndNoFOrderMessage(
                kitchenTimeObject?.reopenDay,
                kitchenTimeObject?.reopenHour,
            );
        } else {
            const tempFutureOrderDate = null;
            const dateResults = findClosestDate(
                template,
                conceptDetailsData?.menuInfo,
                conceptDetailsData?.restaurantInfo,
                updateFutureOrderDate,
                tempFutureOrderDate,
                false,
                reopenAtTimeString,
            );
            if (dateResults && typeof dateResults === 'object') {
                const { message, window, date } = dateResults;
                deliveryDateRef.current = { date, time: window };
                return getExtendedPauseAndFOrderdMessage(message);
            } else {
                return getPausedAndNoFOrderMessage(
                    kitchenTimeObject?.reopenDay,
                    kitchenTimeObject?.reopenHour,
                );
            }
        }
    }, [conceptDetailsData, template, updateFutureOrderDate, supportsScheduledOrders]);

    const checkForUserProfileAndResetForm = useCallback(() => {
        const userProfileAddressList = userProfile?.service_info || [];
        if (currentFormStep === AddressFormSteps.UnavailableLocationSignup) {
            setCurrentFormStep(AddressFormSteps.UnavailableLocationSignup);
        } else if (currentFormStep === AddressFormSteps.SocialMedia) {
            setCurrentFormStep(AddressFormSteps.EmailConfirmed);
        } else if (currentFormStep === AddressFormSteps.EmailConfirmed) {
            setCurrentFormStep(AddressFormSteps.EmailConfirmed);
        } else if (userProfileAddressList.length > 0 && !isInputTouched && ldFlags.enableAccounts) {
            setCurrentFormStep(AddressFormSteps.SavedAddresses);
        } else {
            setCurrentFormStep(AddressFormSteps.AddressEntry);
        }
    }, [setCurrentFormStep, userProfile, isInputTouched, currentFormStep, ldFlags]);

    useEffect(() => {
        if (
            locationStatus?.noLocations &&
            currentFormStep !== AddressFormSteps.EmailConfirmed &&
            watchOrderAddress
        ) {
            setCurrentFormStep(AddressFormSteps.UnavailableLocationSignup);
        } else if (isInputTouched) {
            setCurrentFormStep(AddressFormSteps.AddressEntry);
        } else {
            checkForUserProfileAndResetForm();
        }
    }, [
        locationStatus,
        setCurrentFormStep,
        isInputTouched,
        checkForUserProfileAndResetForm,
        currentFormStep,
        watchOrderAddress,
    ]);

    useEffect(() => {
        if (
            isPausedLongerThanAWeek ||
            closedAndNoFOrderSuppported() ||
            locationStatus?.noLocations
        ) {
            setForceDisableButton(true);
        } else {
            setForceDisableButton(false);
        }
    }, [isPausedLongerThanAWeek, closedAndNoFOrderSuppported, locationStatus]);

    useEffect(() => {
        if (watchOrderAddress && showAddressEntryPopover) {
            updateShowAddressEntryPopover(false);
        }
    }, [showAddressEntryPopover, watchOrderAddress, updateShowAddressEntryPopover]);

    useEffect(() => {
        log.debug('%c FETCH RESTAURANT INFO IN ORDER ADDRESS FORM ', 'background-color: red');
        if (
            !isErrorFetching &&
            conceptDetailsData &&
            restaurantInfoTempQuery?.delivery?.app_id !==
                conceptDetailsData?.restaurantInfo?.delivery?.app_id
        ) {
            updateRestaurantInfoTempQuery({
                ...conceptDetailsData?.restaurantInfo,
                menuInfo: conceptDetailsData?.menuInfo,
            });
        }
    }, [
        watchOrderAddress,
        isErrorFetching,
        segment,
        conceptDetailsData,
        cdpLocation,
        updateRestaurantInfoTempQuery,
        restaurantInfoTempQuery,
    ]);

    useEffect(() => {
        const updateTouchedInput = () => {
            const addressInput = document.getElementById('orderAddress');
            if (addressInput?.id === document?.activeElement?.id) {
                setCurrentFormStep(AddressFormSteps.AddressEntry);
                setInputTouched(true);
                log.debug('Element has focus!');
                if (showAddressEntryPopover) {
                    updateShowAddressEntryPopover(false);
                }
                reset({
                    orderAddress: '',
                    orderAddressApt: '',
                    deliveryInstructions: { preference: 'Meet the driver at the door' },
                });
            } else if (document?.activeElement?.id === 'email') {
                setInputTouched(false);
            } else {
                log.debug(`Element is not focused.`);
                checkForUserProfileAndResetForm();
            }
        };
        document.addEventListener('click', updateTouchedInput);
        return () => {
            document.removeEventListener('click', updateTouchedInput);
        };
    });

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="address-form-popover"
            style={{
                minHeight: `${
                    isInputTouched && !watchOrderAddress?.conceptDetails && isMobile ? '18vh' : ''
                }`,
            }}
        >
            <Space direction="vertical" className="width-100">
                {currentFormStep === AddressFormSteps.SavedAddresses && !isInputTouched && (
                    <SavedAddresses
                        control={control}
                        formState={formState}
                        onClearAddress={onClearAddress}
                        onCancel={onCloseModal}
                        sortedAddressList={sortedAddressList}
                        updateSortedAddressList={setSortedAddressList}
                        locationResults={savedLocationResults}
                        updateLocationResults={setSavedLocationResults}
                        handleMenuItemMatch={handleMenuItemMatch}
                    />
                )}
                {currentFormStep === AddressFormSteps.AddressEntry ? (
                    <OrderAddressEntries
                        isPausedLongerThanAWeek={isPausedLongerThanAWeek}
                        formTitle={formTitle}
                        watchOrderAddress={watchOrderAddress}
                        control={control}
                        formState={formState}
                        onClearAddress={onClearAddress}
                        generateDynammicMessage={generateDynammicMessage}
                        onChangedRadio={onChangedRadio}
                        isInputTouched={isInputTouched}
                    />
                ) : currentFormStep === AddressFormSteps.UnavailableLocationSignup ? (
                    <>
                        <SignupForm
                            onSubmit={handleEmailSignupClick}
                            formTitle={formTitle}
                            formState={formState}
                            control={control}
                            onClearAddress={onClearAddress}
                        />
                        {userProfile && ldFlags.enableAccounts && !isPausedLongerThanAWeek && (
                            <Row>{formControls(onCancel, formState, forceDisableButton)}</Row>
                        )}
                    </>
                ) : currentFormStep === AddressFormSteps.EmailConfirmed ? (
                    <>
                        <div className="no-locations-title">Email Confirmed</div>
                        <div className="no-locations-text">
                            You’ll be notified when we open up a location nearby. For now, you can
                            explore the menu or change delivery address.
                        </div>
                        <div className="no-location-buttons-container">
                            <TemplateButton
                                size="large"
                                block
                                type="primary"
                                data-testid="signup-button"
                                onClick={() => onClearAddress()}
                                className="grey-button"
                            >
                                Change Address
                            </TemplateButton>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </Space>

            {(watchOrderAddress?.conceptDetails ||
                (isMobile && isInputTouched && !isConceptFetching)) &&
                currentFormStep !== AddressFormSteps.SavedAddresses &&
                (locationStatus?.noLocations ? (
                    <></>
                ) : isPausedLongerThanAWeek ? (
                    <></>
                ) : (
                    <Row>{formControls(onCancel, formState, forceDisableButton)}</Row>
                ))}
            {/*<DevTool control={control} />*/}
        </form>
    );
};

const schema = yup
    .object({
        orderAddress: yup
            .mixed()
            .required('Please enter delivery address')
            .notOneOf([''], 'Please enter delivery address'),
    })
    .required();

export enum AddressFormSteps {
    SavedAddresses,
    AddressEntry,
    UnavailableLocationSignup,
    SocialMedia,
    EmailConfirmed,
}

const optionTypeMapping: { [k: string]: string } = {
    'Meet the driver at the door': 'Handoff',
    'Contactless delivery (leave food at door)': 'Contactless',
};
