import { Col } from 'antd';
import React, { FC, useContext } from 'react';
import { ConceptContext } from '../../contexts/concept-context';
import { TemplateButton } from '../ui-components/template-button';
import { getLocationReopenDayTime } from '../utils/general';

export const LocationCLosed: FC<LocationClosedProps> = ({ onClearAddress }) => {
    const {
        conceptDetails: { restaurantInfo },
    } = useContext(ConceptContext);
    const reopenAtDateTime = restaurantInfo?.delivery?.reopen_at;
    const locationInfo = getLocationReopenDayTime(reopenAtDateTime);

    return (
        <>
            <Col span={24} className="extended-content width-100">
                <div className="no-location-text-container" style={{ marginTop: '-20px' }}>
                    <div className="no-locations-title">Currently Closed</div>
                    <div className="no-locations-text">
                        {
                            <>
                                Delivery is currently unavailable.{' '}
                                {locationInfo && (
                                    <>
                                        We open {locationInfo.reopenDay} at{' '}
                                        {locationInfo.reopenHour}.{' '}
                                    </>
                                )}
                            </>
                        }
                    </div>
                </div>
                <div className="no-location-buttons-container">
                    <TemplateButton
                        size="large"
                        block
                        type="primary"
                        data-testid="signup-button"
                        onClick={() => onClearAddress()}
                        className="grey-button"
                    >
                        Change Address
                    </TemplateButton>
                </div>
            </Col>
        </>
    );
};

interface LocationClosedProps {
    onClearAddress: () => void;
}
