import {
    CarOutlined,
    CheckCircleOutlined,
    CreditCardOutlined,
    LoadingOutlined,
    SmileOutlined,
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { Alert, Button, Col, Divider, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { produce } from 'immer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import moment from 'moment-timezone';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { OrderStatus } from '../../../services/OrderStatus';
import { AppContext, StateIdsProps } from '../../contexts/app-context';
import { CdpLocationContext } from '../../contexts/cdp-location-context';
import { ConceptContext } from '../../contexts/concept-context';
import { Header } from '../layout/header';
import { FutureOrder, OrderTotals, isFutureOrderType, orderTotalDefaultProps } from '../menu/model';
import { OrderSummaryForm } from '../order-summary';
import { DriverTipSelector } from '../order-summary/driverTipSelector';
import { BottomDrawerPopup } from '../ui-components/bottom-drawer-popup';
import { ErrorScreen } from '../ui-components/error-screen';
import { TemplateButton } from '../ui-components/template-button';
import {
    customerFormValidationError,
    hasContactInfoComplete,
    saveContactInfoToUserProfile,
} from '../utils/contact-info';
import {
    generateFutureDeliveryTimeWindows,
    getPaymentResult,
    handleScrollToElement,
    useMobileScreen,
} from '../utils/general';
import { digitOnlyPhoneNumberFormat } from '../utils/phone';
import useSegment from '../utils/segment';
import { CustomerInformationForm } from './customer-information-form';
import { DeliveryAddressForm } from './delivery-address-form';
import { DeliveryAddressFormLamvEnabled } from './delivery-address-form-lamv-enabled';
import { DeliveryPreferenceForm } from './delivery-preference-form';
import { FutureOrderForm } from './future-order-form';
import ItemUnavailablePopup from './item-unavailable-popup';
import { StripeIntegrationForm, overrideGlobalOrderPlaced } from './stripe-integration-form';
import './index.scss';

let timer: number;

const translateMsg: any = {
    payment_auth_failed: 'Payment auth failed',
    payment_card_declined: 'Your card was declined',
    payment_insufficient_funds: 'Insufficient funds',
    payment_incorrect_security_code: "Your card's security code is incorrect",
    payment_capture_failed:
        'Sorry, there was an issue with your payment. Please try a different payment method.',
};

const checkoutFailMessages = [
    'payment_capture_failed',
    'payment_auth_failed',
    'payment_card_declined',
    'payment_insufficient_funds',
    'payment_incorrect_security_code',
];

export const processingStepsText: any = {
    1: 'Authorizing payment',
    2: 'Confirming with restaurant',
    3: 'Finding a driver',
    4: 'Almost done!',
};

const oloErrorBanners: any = {
    order_discrepancy: {
        type: 'warning',
        message: 'Sorry, we had an issue processing your order. Please try again.',
    },
    order_duplicated: {
        type: 'warning',
        message: 'Sorry, we had an issue processing your order. Please try again.',
    },
    store_unavailable: {
        type: 'warning',
        message: 'This restaurant is currently closed, please try again later.',
    },
    store_unreachable: {
        type: 'error',
        message: "Sorry, we're unable to process your order at this time.",
    },
    internal_error: {
        type: 'error',
        message: "Sorry, we're unable to process your order at this time.",
    },
};

const orderConfirmationPageStatuses = [
    'order_confirmed',
    'driver_picked_up',
    'driver_confirmed_consumer_arrival',
    'driver_dropped_off',
    'no_courier_available',
    'driver_canceled',
    'undefined_rejection',
    'payment_capture_failed',
];
export interface ErrorBanner {
    type: 'error' | 'warning' | 'success' | 'info' | undefined;
    message: string;
}

interface OrderStatusType {
    status: string;
    logistic_event_time?: string;
    estimated_delivery_time?: string;
    status_metadata: {
        reason_code: string;
        detail: {
            code: number;
            messsage: string;
            item_id: string;
            pos_item_id: any;
            price_submitted: any;
            price_proposed: any;
        };
    }[];
}

interface COPageProps {
    location: { state: OrderTotals };
}

export const CheckoutPage: FC<COPageProps> = ({ location: { state } }) => {
    const {
        customerInfo,
        template,
        stateIds,
        paymentMethod,
        setPaymentMethod,
        futureOrderDate,
        cartItems,
        orderPlaced,
        updateOrderPlaced,
        updateCartItems,
        updateStateId,
        updateHandlingOrder,
        handlingOrder,
        selectedTipOption,
        promoId,
        orderTotal,
        deliveryPreference,
        addUtensils,
        orderAddress,
        orderPayload,
        orderAddressApt,
        vouchers,
        tipValue,
        userProfile,
        updateUserProfile,
        updateCustomerInfo,
    } = useContext(AppContext);
    const { conceptDetails, updateConceptDetails } = useContext(ConceptContext);
    const { menuInfo, restaurantInfo } = conceptDetails;
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    log.debug('%c CHECKOUT PAGE RERENDER', 'background-color: red');
    log.debug('%c CONTEXT', 'background-color: red', stateIds);

    const [paymentError, setPaymentError] = useState<string>('');
    const [topErrorBanner, setTopErrorBanner] = useState<null | ErrorBanner>(null);
    const [orderPassedCutOffTime, setOrderPassedCutOffTime] = useState<boolean>(false);
    const [logisticsError, setLogisticsError] = useState<boolean>(false);
    const [refetchIntervalMs, setRefetchIntervalMs] = useState<number | false>(false);
    const [isPlaceOrderButtonEnabled, setPlaceOrderButtonEnabled] = useState<boolean>(false);
    const [loadingStep, setLoadingStep] = useState<StatusToLoadingStep>(
        StatusToLoadingStep.process_order,
    );
    const [openForms, setOpenForms] = useState(
        new Set<CheckoutForms>([CheckoutForms.StripeIntegration]),
    );
    const [popup, setPopup] = useState(false);
    const [unavailableItemIds, setUnavailableItemIds] = useState<string[]>([]);
    const [orderTotals, setOrderTotals] = useState(state || orderTotalDefaultProps);
    const [fetchDiscounts, setFetchDiscounts] = useState<boolean>(false);
    const [hasContactFormErrors, setHasContactFormErrors] = useState<boolean>(
        () => !hasContactInfoComplete(customerInfo),
    );
    const [tabPosition, setTabPosition] = useState(() => {
        if (typeof selectedTipOption === 'number' && selectedTipOption < 4) {
            return selectedTipOption;
        } else {
            return 2;
        }
    });
    const [otherTipValue, setOtherTipValue] = useState<number>(() => {
        if (
            typeof selectedTipOption === 'number' &&
            typeof tipValue === 'number' &&
            selectedTipOption === 3
        ) {
            //Note: tipValue is in dollars and contextTipValue is in cents
            return tipValue / 100;
        } else {
            return 0;
        }
    });

    const stripe = useStripe();
    const history = useHistory();
    const isMobile = useMobileScreen();
    const ldFlags = useFlags();
    const segment = useSegment();
    const kitchen = restaurantInfo?.delivery;
    const placeOrderbtnRef = useRef<HTMLButtonElement>(null);
    const contactFormRef = useRef<FormInstance>(null);
    const openFormTopRef = useRef<HTMLDivElement>(null);

    const isUserAuthenticated = useCallback(() => {
        return ldFlags.enableAccounts && isAuthenticated && !isLoading;
    }, [isAuthenticated, isLoading, ldFlags]);

    const guestUserOrIncompleteContactInfo = useCallback(() => {
        return !isUserAuthenticated() || !hasContactInfoComplete(customerInfo);
    }, [customerInfo, isUserAuthenticated]);

    const contactAlternativeView = openForms.size === 2 && guestUserOrIncompleteContactInfo();
    const editButtonDisabled = openForms.size > 1 && !contactAlternativeView;

    const checkIfCurrentTimeHasPassedCutOff = useCallback(
        (futureOrder: FutureOrder) => {
            const deliveryWindows: any = generateFutureDeliveryTimeWindows(
                menuInfo?.menus[0].day_parts,
                futureOrder,
                new Date(),
                restaurantInfo?.delivery?.timezone!,
            );

            // Check if the current time is still within the delivery cutoff time
            const isOrderStillValidForToday = deliveryWindows?.today?.find(
                ({ startTime: { hr, min } }: any) =>
                    hr === futureOrderDate?.time.startTime.hr &&
                    min === futureOrderDate?.time.startTime.min,
            );

            setOrderPassedCutOffTime(!isOrderStillValidForToday);
        },
        [menuInfo, futureOrderDate, restaurantInfo],
    );

    const validateContactFormErrors = useCallback(() => {
        const form = contactFormRef.current;
        if (form) {
            const values = form.getFieldsValue();
            const hasEmptyFields = Object.values(form.getFieldsValue(true)).some(el => el === '');
            const hasFormErrors = form
                .getFieldsError()
                .some((field: any) => field.errors.length > 0);
            const phoneNumberFormatted = digitOnlyPhoneNumberFormat(values.ci_phonenumber);
            const validPhoneNumber = phoneNumberFormatted.length >= 10 ? phoneNumberFormatted : '';
            if (
                values &&
                !hasFormErrors &&
                !hasEmptyFields &&
                validPhoneNumber.length > 0 &&
                paymentMethod === PaymentMethod.CreditCard
            ) {
                updateCustomerInfo({
                    email: values.ci_email,
                    name: values.ci_name?.trim(),
                    phonenumber: validPhoneNumber,
                });
            }
            setHasContactFormErrors(hasFormErrors || hasEmptyFields);
        }
        return null;
    }, [contactFormRef, setHasContactFormErrors, updateCustomerInfo, paymentMethod]);

    useEffect(() => {
        let intervalId: any;
        const isOrderingOnSameDay =
            futureOrderDate?.date.dayOfYear() ===
            moment()
                .tz(restaurantInfo?.delivery?.timezone || '')
                ?.dayOfYear();

        const futureOrder = template?.future_order;

        if (futureOrderDate && menuInfo && isOrderingOnSameDay && isFutureOrderType(futureOrder)) {
            checkIfCurrentTimeHasPassedCutOff(futureOrder);
            intervalId = window.setInterval(
                () => checkIfCurrentTimeHasPassedCutOff(futureOrder),
                10000,
            );
        } else {
            setOrderPassedCutOffTime(false);
        }
        return () => {
            return window.clearInterval(intervalId);
        };
    }, [
        futureOrderDate,
        menuInfo,
        restaurantInfo,
        template?.future_order,
        checkIfCurrentTimeHasPassedCutOff,
    ]);

    useEffect(() => {
        if (loadingStep === StatusToLoadingStep.process_order) {
            const contactAltView = guestUserOrIncompleteContactInfo();
            updateOpenForms(contactAltView, paymentMethod, setOpenForms);
        }
    }, [paymentMethod, customerInfo, guestUserOrIncompleteContactInfo, loadingStep]);

    useEffect(() => {
        segment.pageViewed();
        window.scrollTo(0, 0);
        initializeCheckoutId(stateIds, updateStateId);
        if (orderPlaced) {
            updateOrderPlaced(false);
            updateHandlingOrder(false);
            overrideGlobalOrderPlaced(false);
            updateCartItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStateId, orderPlaced, updateCartItems, updateOrderPlaced, stateIds]);

    const goToMenu = () => {
        history.push('/');
    };

    useQuery(
        ['orderStatus', stateIds.orderId],
        async () => {
            if (hasOrderId()) {
                const res = await OrderStatus.get({ orderId: stateIds.orderId });

                const data: OrderStatusType[] = res.data ?? [];
                const statuses: any[] = [];
                let goToOrderConfirmation = false;

                for (const orderStatus of data) {
                    const { status } = orderStatus;
                    const paymentErrorMsg = checkPaymentError(status);
                    const logisticsErrorMsg = checkLogisticsError(status);

                    const orderValidationFailed = status === 'order_validation_failed';
                    const oloError = status in oloErrorBanners || orderValidationFailed;

                    if (paymentErrorMsg || logisticsErrorMsg || oloError) {
                        if (logisticsErrorMsg) {
                            setLogisticsError(true);
                        }
                        if (paymentErrorMsg) {
                            setPaymentError(paymentErrorMsg);
                        }
                        if (status in oloErrorBanners) {
                            setTopErrorBanner(oloErrorBanners[status]);
                        } else if (orderValidationFailed) {
                            const items86edStatusMetadata = orderStatus.status_metadata.filter(
                                metadata => metadata.reason_code === 'ITEM_UNAVAILABLE',
                            );
                            const unavailableItemIdList = items86edStatusMetadata.map(
                                metadata => metadata.detail.item_id,
                            );
                            setUnavailableItemIds(unavailableItemIdList);
                            if (unavailableItemIdList.length > 0) {
                                setPopup(true);
                            }
                        }

                        clearTimeout(timer);
                        updateStateId('orderId', '');
                        setLoadingStep(StatusToLoadingStep.process_order);
                        updateHandlingOrder(false);
                        overrideGlobalOrderPlaced(false);
                        segment.errorShown(paymentErrorMsg || logisticsErrorMsg);
                        break;
                    } else if (status in StatusToLoadingStep) {
                        if (status === 'order_confirmed') {
                            const couponIds = [promoId, ...vouchers].filter(el => !!el);
                            segment.orderComplete(
                                menuInfo,
                                orderPayload,
                                orderAddress,
                                orderAddressApt,
                                paymentMethod,
                                couponIds,
                            );
                            handleLoadingStep(StatusToLoadingStep.order_confirmed);
                            goToOrderConfirmation = true;
                            statuses.push(orderStatus);
                            break;
                        }
                    } else if (orderConfirmationPageStatuses.includes(status)) {
                        goToOrderConfirmation = true;
                        statuses.push(orderStatus);
                    }
                }

                if (goToOrderConfirmation) {
                    clearTimeout(timer);
                    history.push({
                        pathname: '/order-confirmation',
                        state: statuses,
                    });
                }
                return res;
            } else {
                return null;
            }
        },
        {
            refetchInterval: refetchIntervalMs,
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: false,
        },
    );

    const paymentResult: any = useQuery(
        [
            'paymentRequest',
            !!stripe,
            cartItems,
            kitchen,
            orderTotal,
            deliveryPreference,
            addUtensils,
            orderAddressApt,
            futureOrderDate,
            tipValue,
            vouchers,
            promoId,
        ],
        async () => {
            log.debug('%c Payment Request Query', 'background-color: green');
            if (orderTotal) {
                return getPaymentResult(stripe, orderTotal, 'Orders Total');
            } else {
                return null;
            }
        },
        { cacheTime: 0 },
    );

    log.debug('%c PAYMENT REQUEST: ', 'background-color: green', paymentResult);

    const hasOrderId = useCallback(() => {
        return stateIds?.orderId?.length > 0;
    }, [stateIds]);

    const handleLoadingStep = useCallback(
        (step: number) => {
            if (step > loadingStep && step <= StatusToLoadingStep.order_confirmed) {
                setLoadingStep(step);
            }
        },
        [loadingStep],
    );

    const incrementLoadingStep = useCallback(() => {
        if (loadingStep < StatusToLoadingStep.order_confirmed) {
            const nextStep = loadingStep + 1;
            setLoadingStep(nextStep);
            handleLoadingStep(nextStep);
        }
    }, [loadingStep, handleLoadingStep]);

    useEffect(() => {
        if (!hasOrderId() || loadingStep === StatusToLoadingStep.order_confirmed) {
            return () => clearInterval(timer);
        }
        const timer = setInterval(incrementLoadingStep, 7000);
        return () => clearInterval(timer);
    }, [incrementLoadingStep, loadingStep, hasOrderId]);

    useEffect(() => {
        if (hasOrderId()) {
            setRefetchIntervalMs(5000);
        } else {
            setRefetchIntervalMs(false);
        }
    }, [stateIds.orderId, hasOrderId]);

    useEffect(() => {
        // Kills the loading spinner after 5 minutes
        if (handlingOrder && hasOrderId() && loadingStep === StatusToLoadingStep.process_order) {
            log.debug('Starting Order Placed 5 minute timeout');
            const spinnerTimeout = 5 * 60000;
            setLoadingStep(StatusToLoadingStep.logistics_confirmed);
            timer = window.setTimeout(() => {
                updateHandlingOrder(false);
                updateStateId('orderId', '');
                setLoadingStep(StatusToLoadingStep.process_order);
                setTopErrorBanner({ type: 'error', message: 'Sorry, something went wrong' });
            }, spinnerTimeout);
        }
    }, [handlingOrder, loadingStep, updateHandlingOrder, hasOrderId, updateStateId]);

    const checkPaymentError = (status: string) => {
        let error = '';
        for (const failMessage of checkoutFailMessages) {
            if (status.toLowerCase().includes(failMessage)) {
                error = translateMsg[failMessage];
                break;
            }
        }
        return error;
    };

    const checkLogisticsError = (status: string) => {
        return status === 'no_courier_available' ? 'No courier available' : '';
    };

    const generateHeaderTempate = () => {
        if (loadingStep !== StatusToLoadingStep.process_order) {
            return 'noAction';
        }
        return isMobile ? 'mobileCheckOutPage' : 'checkOutPage';
    };

    const LoadingIcon = () => {
        switch (loadingStep) {
            case 1:
                return <CheckCircleOutlined className="loading-icon" />;
            case 2:
                return <CarOutlined className="loading-icon" />;
            case 3:
                return <CreditCardOutlined className="loading-icon" />;
            case 4:
                return <SmileOutlined className="loading-icon" />;
            default:
                return null;
        }
    };

    const handlePlaceOrderClicked = () => {
        if (customerFormValidationError(customerInfo)) {
            log.error('Contact Form Validation Error', customerInfo);
            setHasContactFormErrors(true);
            updateCustomerInfo(null);
            handleScrollToElement(openFormTopRef);
        } else {
            if (contactFormRef?.current && contactAlternativeView) {
                const values = contactFormRef.current.getFieldsValue();
                saveContactInfoToUserProfile(
                    values,
                    userProfile,
                    getAccessTokenSilently,
                    updateUserProfile,
                    segment,
                );
                segment.emailCapturedCheckout(values.ci_email);
                segment.contactInfoIdentify(values.ci_name, values.ci_email, values.ci_phonenumber);
                segment.contactInfoCheckoutStepCompleted(
                    values.ci_name,
                    values.ci_email,
                    values.ci_phonenumber,
                );
            }
            if (placeOrderbtnRef.current) {
                placeOrderbtnRef?.current?.click();
            }
        }
    };

    // Place order button is connected to ref button on the stripe page
    const PlaceOrderButton = () => (
        <Row className={isMobile ? '' : 'mb-40'}>
            <Col span={24} className="place-order-btn" onClick={handlePlaceOrderClicked}>
                <br />
                {paymentMethod === PaymentMethod.CreditCard ? (
                    <TemplateButton
                        className="width-100"
                        type="primary"
                        htmlType="submit"
                        disabled={hasContactFormErrors || !isPlaceOrderButtonEnabled}
                        data-testid="checkout-stripe-submit-btn"
                        size="large"
                        loading={handlingOrder}
                    >
                        Place Order
                    </TemplateButton>
                ) : paymentResult?.isLoading ? (
                    <Button disabled>Loading payment methods</Button>
                ) : paymentResult?.data?.paymentRequest ? (
                    <PaymentRequestButtonElement
                        options={{ paymentRequest: paymentResult?.data?.paymentRequest }}
                        onClick={() => trackPayWithDigitalWallet(segment, paymentMethod)}
                    />
                ) : null}
                <p>
                    {`By placing your order, you agree to receive delivery status updates via SMS and email marketing communications from ${template?.metadata?.brand.name}. You can opt out at any time.`}
                </p>
            </Col>
        </Row>
    );

    const onEdit = (form: CheckoutForms) => {
        setOpenForms(
            produce(draft => {
                [
                    CheckoutForms.DeliveryAddress,
                    CheckoutForms.DeliveryPreference,
                    CheckoutForms.FutureOrder,
                ].forEach(form => {
                    if (openForms.has(form)) {
                        draft.delete(form);
                    }
                });

                if (
                    openForms.has(CheckoutForms.CustomerInformation) &&
                    !guestUserOrIncompleteContactInfo()
                ) {
                    log.debug('inside');
                    draft.delete(CheckoutForms.CustomerInformation);
                }
                draft.add(form);
            }),
        );
    };

    const onCancelEdit = (form: CheckoutForms) => {
        setOpenForms(
            produce(draft => {
                draft.delete(form);
            }),
        );
    };

    const props = {
        cartItems,
        updateCartItems,
        conceptDetails,
        updateConceptDetails,
        unavailableItemIds,
        setPopup,
        setUnavailableItemIds,
        setFetchDiscounts,
        history,
    };

    const CustomerInformationSection = () => (
        <CustomerInformationForm
            isOpen={openForms.has(CheckoutForms.CustomerInformation)}
            onClickContinue={() => {
                setOpenForms(
                    produce(draft => {
                        draft.delete(CheckoutForms.CustomerInformation);
                        draft.add(CheckoutForms.StripeIntegration);
                    }),
                );
            }}
            contactFormRef={contactFormRef}
            contactFormAlternateView={guestUserOrIncompleteContactInfo()}
            editButtonDisabled={editButtonDisabled}
            onEdit={onEdit}
            onCancelEdit={onCancelEdit}
        />
    );

    if (cartItems.length < 1) {
        history.push('/');
    }

    return (
        <div>
            <Helmet>
                <title>{`Your ${template?.metadata?.brand?.name ?? ''} Cart`}</title>
                <meta name="description" content="Menu" />
                <link
                    rel="icon"
                    type="image/png"
                    href={template?.general?.favicon || ''}
                    sizes="32x32"
                ></link>
            </Helmet>
            <CdpLocationContext.Provider value="Checkout">
                <div
                    className={`checkout-wrapper ${logisticsError ? '' : 'extend-to-bottom'}`}
                    style={{
                        backgroundColor: `${
                            loadingStep !== StatusToLoadingStep.process_order || logisticsError
                                ? '#fff'
                                : '#fafafa'
                        }`,
                    }}
                >
                    <Header
                        templateName={generateHeaderTempate()}
                        backButton={{ handleBack: goToMenu }}
                        title={template?.metadata?.brand?.name ?? ''}
                        logoLink={'/'}
                    />
                    {logisticsError ? (
                        <ErrorScreen
                            noDriver={logisticsError}
                            onCancel={() => setLogisticsError(false)}
                        />
                    ) : loadingStep !== StatusToLoadingStep.process_order ? (
                        <Col span={24} className="checkout-loader">
                            <Row justify="center">
                                <LoadingOutlined style={{ fontSize: '120px' }} />
                                <LoadingIcon />
                            </Row>
                            <Row justify="center">
                                <div className="loading-text">
                                    {processingStepsText[loadingStep]}...
                                </div>
                            </Row>
                        </Col>
                    ) : (
                        <Form.Provider
                            onFormChange={name => {
                                if (
                                    name === 'checkout_contact_information' &&
                                    contactAlternativeView
                                ) {
                                    validateContactFormErrors();
                                }
                            }}
                        >
                            <div className="checkout">
                                <div className="checkout-title">
                                    <h2>Checkout</h2>
                                </div>
                                {topErrorBanner && (
                                    <Col span={24} className="generic-error-col">
                                        <Alert
                                            className="generic-error-alert"
                                            message={topErrorBanner.message}
                                            type={topErrorBanner.type}
                                            showIcon
                                        />
                                    </Col>
                                )}
                                {orderPassedCutOffTime && (
                                    <Col span={24} className="generic-error-col">
                                        <Alert
                                            className="generic-error-alert"
                                            message="Oh no, your selected delivery time is no longer available. Please make a new selection"
                                            type="warning"
                                        />
                                    </Col>
                                )}
                                <Row gutter={30}>
                                    <Col
                                        id="order-details"
                                        xs={24}
                                        md={{ order: 1, span: 12 }}
                                        lg={14}
                                        xl={16}
                                        order={2}
                                    >
                                        <div ref={openFormTopRef} className="inner">
                                            <div className="checkout-subtitle mt-0">
                                                ORDER DETAILS
                                            </div>
                                            <div className="checkout-border-box">
                                                {paymentMethod === PaymentMethod.CreditCard &&
                                                    isUserAuthenticated() &&
                                                    hasContactInfoComplete(customerInfo) && (
                                                        <div>
                                                            {CustomerInformationSection()}
                                                            <Divider />
                                                        </div>
                                                    )}

                                                {ldFlags.enableLamv ? (
                                                    <DeliveryAddressFormLamvEnabled
                                                        isOpen={openForms.has(
                                                            CheckoutForms.DeliveryAddress,
                                                        )}
                                                        onClickContinue={() => {
                                                            setOpenForms(
                                                                produce(draft => {
                                                                    draft.delete(
                                                                        CheckoutForms.DeliveryAddress,
                                                                    );
                                                                }),
                                                            );
                                                        }}
                                                        editButtonDisabled={editButtonDisabled}
                                                        onEdit={onEdit}
                                                        onCancelEdit={onCancelEdit}
                                                    />
                                                ) : (
                                                    <DeliveryAddressForm
                                                        isOpen={openForms.has(
                                                            CheckoutForms.DeliveryAddress,
                                                        )}
                                                        onClickContinue={() => {
                                                            setOpenForms(
                                                                produce(draft => {
                                                                    draft.delete(
                                                                        CheckoutForms.DeliveryAddress,
                                                                    );
                                                                }),
                                                            );
                                                        }}
                                                        editButtonDisabled={editButtonDisabled}
                                                        onEdit={onEdit}
                                                        onCancelEdit={onCancelEdit}
                                                    />
                                                )}
                                                <Divider />
                                                <FutureOrderForm
                                                    isOpen={openForms.has(
                                                        CheckoutForms.FutureOrder,
                                                    )}
                                                    orderPassedCutOffTime={orderPassedCutOffTime}
                                                    editButtonDisabled={editButtonDisabled}
                                                    onEdit={onEdit}
                                                    onCancelEdit={onCancelEdit}
                                                />
                                                <Divider />
                                                <DeliveryPreferenceForm
                                                    isOpen={openForms.has(
                                                        CheckoutForms.DeliveryPreference,
                                                    )}
                                                    onClickContinue={() => {
                                                        setOpenForms(
                                                            produce(draft => {
                                                                draft.delete(
                                                                    CheckoutForms.DeliveryPreference,
                                                                );
                                                            }),
                                                        );
                                                    }}
                                                    editButtonDisabled={editButtonDisabled}
                                                    onEdit={onEdit}
                                                    onCancelEdit={onCancelEdit}
                                                />
                                            </div>

                                            {paymentMethod === PaymentMethod.CreditCard &&
                                                guestUserOrIncompleteContactInfo() && (
                                                    <>
                                                        {openForms.has(
                                                            CheckoutForms.CustomerInformation,
                                                        ) && (
                                                            <div
                                                                className="checkout-subtitle"
                                                                style={{ marginTop: '30px' }}
                                                            >
                                                                Contact Information
                                                            </div>
                                                        )}
                                                        <div className="checkout-border-box">
                                                            {CustomerInformationSection()}
                                                        </div>
                                                    </>
                                                )}
                                            <>
                                                <div className="checkout-subtitle">PAYMENT</div>
                                                <div className="checkout-border-box">
                                                    <StripeIntegrationForm
                                                        isOpen={openForms.has(
                                                            CheckoutForms.StripeIntegration,
                                                        )}
                                                        onClickContinue={() => {
                                                            setOpenForms(
                                                                produce(draft => {
                                                                    draft.add(
                                                                        CheckoutForms.StripeIntegration,
                                                                    );
                                                                    return draft;
                                                                }),
                                                            );
                                                        }}
                                                        setPaymentMethod={setPaymentMethod}
                                                        placeOrderbtnRef={placeOrderbtnRef}
                                                        setPlaceOrderButtonEnabled={
                                                            setPlaceOrderButtonEnabled
                                                        }
                                                        paymentResult={paymentResult}
                                                        fetchDiscounts={fetchDiscounts}
                                                        setFetchDiscounts={setFetchDiscounts}
                                                        otherTipValue={otherTipValue}
                                                        setOrderTotals={setOrderTotals}
                                                        orderTotals={orderTotals}
                                                        paymentError={paymentError}
                                                        setPaymentError={setPaymentError}
                                                        setTopErrorBanner={setTopErrorBanner}
                                                        setLoadingStep={handleLoadingStep}
                                                        paymentMethod={paymentMethod}
                                                        hasFutureOrderPassedTimeErr={
                                                            orderPassedCutOffTime
                                                        }
                                                    />
                                                </div>
                                                <DriverTipSelector
                                                    tabPosition={tabPosition}
                                                    orderTotals={orderTotals}
                                                    setTabPosition={setTabPosition}
                                                    setOtherTipValue={setOtherTipValue}
                                                    setFetchDiscounts={setFetchDiscounts}
                                                />
                                                {!isMobile && <PlaceOrderButton />}
                                            </>
                                        </div>
                                        {isMobile && (
                                            <Col
                                                span={24}
                                                order={3}
                                                className="order-summary bottom"
                                            >
                                                <OrderSummaryForm
                                                    hideListItems={true}
                                                    tabPosition={tabPosition}
                                                    orderTotals={orderTotals}
                                                />
                                                <PlaceOrderButton />
                                            </Col>
                                        )}
                                    </Col>
                                    <Col
                                        xs={24}
                                        md={{ order: 2, span: 12 }}
                                        lg={10}
                                        xl={8}
                                        order={1}
                                        className="order-summary"
                                        id="order-summary"
                                    >
                                        {!isMobile && (
                                            <div className="inner">
                                                <div className="checkout-subtitle mt-0">
                                                    ORDER SUMMARY
                                                </div>
                                                <div className="checkout-border-box">
                                                    <OrderSummaryForm
                                                        tabPosition={tabPosition}
                                                        orderTotals={orderTotals}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <BottomDrawerPopup
                                    width="378px"
                                    styleModalBody={{ padding: 0 }}
                                    visible={popup}
                                    onClose={() => setPopup(false)}
                                    children={<ItemUnavailablePopup {...props} />}
                                />
                            </div>
                        </Form.Provider>
                    )}
                </div>
            </CdpLocationContext.Provider>
        </div>
    );
};

function initializeCheckoutId(
    stateIds: StateIdsProps,
    updateStateId: (name: string, value: string) => void,
) {
    if (stateIds == null || !stateIds.checkoutId) {
        const checkoutId = Math.round(new Date().getTime() / 1000).toString();
        updateStateId('checkoutId', checkoutId);
    }
}

// this is required to make sure open forms are updated when payment method is changed
function updateOpenForms(
    contactFormAlternateView: boolean,
    paymentMethod: PaymentMethod | PaymentMethod.CreditCard,
    setOpenForms: (
        value: ((prevState: Set<CheckoutForms>) => Set<CheckoutForms>) | Set<CheckoutForms>,
    ) => void,
) {
    if (paymentMethod !== PaymentMethod.CreditCard) {
        setOpenForms(
            produce(draft => {
                draft.delete(CheckoutForms.CustomerInformation);
                draft.delete(CheckoutForms.StripeIntegration);
            }),
        );
    } else if (paymentMethod === PaymentMethod.CreditCard && contactFormAlternateView) {
        setOpenForms(
            produce(draft => {
                draft.add(CheckoutForms.CustomerInformation);
                draft.add(CheckoutForms.StripeIntegration);
            }),
        );
    }
}

function trackPayWithDigitalWallet(
    segment: ReturnType<typeof useSegment>,
    paymentMethod: PaymentMethod,
) {
    log.debug('trackPaywithDigitalWallet');
    const ctaNameMap = {
        [PaymentMethod.ApplePay]: 'Pay with Apple Pay',
        [PaymentMethod.GooglePay]: 'Pay with Google Pay',
        [PaymentMethod.CreditCard]: 'Pay with Credit Card',
    };

    const ctaDestinationMap = {
        [PaymentMethod.ApplePay]: 'Apple Payment Sheet',
        [PaymentMethod.GooglePay]: 'Google Payment Sheet',
        [PaymentMethod.CreditCard]: 'Credit Card Payment',
    };
    const cta = { name: ctaNameMap[paymentMethod], destination: ctaDestinationMap[paymentMethod] };
    segment.payWithDigitalWalletCTAClicked(cta);
}

export enum PaymentMethod {
    CreditCard = 'CREDIT_CARD',
    GooglePay = 'GOOGLE_PAY',
    ApplePay = 'APPLE_PAY',
}

export enum CheckoutForms {
    DeliveryAddress,
    DeliveryPreference,
    FutureOrder,
    CustomerInformation,
    StripeIntegration,
}

enum StatusToLoadingStep {
    process_order,
    logistics_confirmed,
    payment_confirmed,
    order_placed,
    order_confirmed,
}
