import { Radio } from 'antd';
import React, { FC } from 'react';
import './index.scss';

interface RadioButtonProps {
    onBlur?: any;
    options?: any;
    onChange: (v: any) => void;
    value?: string;
}

const preferenceOptions = [
    { label: 'First Meaningful Message', value: '1' },
    { label: 'Second Meaningful Message', value: '2' },
];

export const RadioButtonGroup: FC<RadioButtonProps> = ({
    options = preferenceOptions,
    ...field
}) => {
    return (
        <div className="radio-button-group">
            <Radio.Group
                options={options}
                {...field}
                onChange={v => {
                    field.onBlur?.();
                    field.onChange(v);
                }}
            />
        </div>
    );
};
