import { get, post } from './config';

/**
 * @description Concepts service for finding nearby concepts, get Menu Info, get Parent Menu
 */

export const MockLdFlags = {
    get: () => {
        return get(`https://app.launchdarkly.com/sdk/1`);
    },
    post: (query: any) => {
        return post(``, query);
    },
};
