import log from 'loglevel';
import { rest } from 'msw';
import { getValueFromLocalStorage } from '../ts/modules/utils/general';

let ldFlags = getValueFromLocalStorage('ldFlags') || {
    enableVouchers: {
        value: true,
    },
    showDigitalWallet: {
        value: true,
    },
    enableLamv: {
        value: true,
    },
    enableAccounts: {
        value: true,
    },
    enableUpselling: {
        value: true,
    },
};

export const handlers = [
    // endpoint for mock LD flags
    rest.post('/mock-ld-flags', (req, res, ctx) => {
        // @ts-ignore
        log.debug(req.body);
        // @ts-ignore
        ldFlags = { ...ldFlags, ...req.body };
        localStorage.setItem('ldFlags', JSON.stringify(ldFlags));
        return res(ctx.json(ldFlags));
    }),
    rest.get('https://app.launchdarkly.com/sdk/*', (req, res, ctx) => {
        return res(ctx.json(ldFlags));
    }),
];
