import React from 'react';
import { BrandTemplate } from '../../contexts/app-context';
import { FeatureBlock } from './feature-block';

export const MobilesSliderBlock = ({ template }: { template?: BrandTemplate | null }) => {
    const isHeroContentBlock =
        template?.menu_page?.hero_content_block?.title ||
        template?.menu_page?.hero_content_block?.image ||
        template?.menu_page?.hero_content_block?.image_description;
    const isLeftImageContentBlock =
        template?.menu_page?.left_image_content_block?.title ||
        template?.menu_page?.left_image_content_block?.image ||
        template?.menu_page?.left_image_content_block?.image_description;
    const isRightImageContentBlock =
        template?.menu_page?.right_image_content_block?.title ||
        template?.menu_page?.right_image_content_block?.image ||
        template?.menu_page?.right_image_content_block?.image_description;

    return (
        <div className="mobile-slider-box">
            {isHeroContentBlock && (
                <div className="feature-mobile-box big-title">
                    <FeatureBlock
                        title={template?.menu_page?.hero_content_block?.title}
                        text={template?.menu_page?.hero_content_block?.image_description}
                        mediaSrc={template?.menu_page?.hero_content_block?.image}
                        isMobile
                        big
                    />
                </div>
            )}
            {isLeftImageContentBlock && (
                <div className="feature-mobile-box">
                    <FeatureBlock
                        title={template?.menu_page?.left_image_content_block?.title}
                        text={template?.menu_page?.left_image_content_block?.image_description}
                        mediaSrc={template?.menu_page?.left_image_content_block?.image}
                        isMobile
                    />
                </div>
            )}
            {isRightImageContentBlock && (
                <div className="feature-mobile-box">
                    <FeatureBlock
                        title={template?.menu_page?.right_image_content_block?.title}
                        text={template?.menu_page?.right_image_content_block?.image_description}
                        mediaSrc={template?.menu_page?.right_image_content_block?.image}
                        isMobile
                    />
                </div>
            )}
        </div>
    );
};
