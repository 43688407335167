import { enableMapSet } from 'immer';
import log from 'loglevel';
import React from 'react';
import Package from '../../package.json';
import '../assets/scss/ant.less';
import '../assets/scss/main.scss';
import { App } from './app';

const isFaviousBrand = window.location.hostname.includes('flaviosfabulousfalafel');
if (process.env.BUILD_ENV === 'prod' && !isFaviousBrand) {
    log.setDefaultLevel('warn');
} else {
    log.setDefaultLevel('debug');
}

const mswEnabled = JSON.parse(localStorage.getItem('mswEnabled') ?? 'false');

if (process.env.BUILD_ENV !== 'prod' && !window.Cypress && mswEnabled) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('../mocks/browser');
    log.debug('Worker: ', worker);
    worker
        .start()
        .then((v: any) => {
            log.debug('Worker started', v);
        })
        .catch((e: any) => {
            log.error('Worker error', e);
        });
}

enableMapSet();
declare global {
    interface Window {
        analytics: {
            track: (event: string, properties: any, integrations: any) => void;
            page: (name: string, properties: any, integrations: any) => void;
            identify: (...args: any[]) => void; // userEmail?, traits, integrations
        };
        gtag: (...args: any[]) => void;
        Cypress: any;
    }
}

// Always print version info, even in prod, so we can always know exactly what code base we're dealing with everywhere
console.log(`Version: ${Package.version} ${process.env.BUILD_ENV}`); // eslint-disable-line no-console

App();
