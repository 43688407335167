import { LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Spin, Tabs } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../layout/header';
import useSegment from '../utils/segment';
import { AccountAddress } from './address';
import { OrderHistory } from './order-history';
import { ProfilePage } from './profile';
import './index.scss';

const { TabPane } = Tabs;

export const AccountPage = () => {
    const history = useHistory();
    const segment = useSegment();
    const ldFlags = useFlags();
    const { isLoading, isAuthenticated } = useAuth0();
    const pathParams = useParams() as { page: 'profile' | 'addresses' | 'history' } | undefined;

    const [prevPath, setPrevPath] = useState<string>('');
    const [currentTabKey, setCurrentTabKey] = useState(() => {
        if (pathParams) {
            return AccountUtils.getIndexFromTabName(pathParams.page);
        }
        return '1';
    });

    useEffect(() => {
        const { pathname } = history.location;
        if (pathname !== `/account/${prevPath}`) {
            segment.pageViewed();
        }
    }, [pathParams, prevPath, history.location, segment]);

    useEffect(() => {
        if (pathParams) {
            const newTabIndex = AccountUtils.getIndexFromTabName(pathParams.page);
            setPrevPath(pathParams?.page);
            setCurrentTabKey(newTabIndex);
        }
    }, [pathParams]);

    if (isLoading) {
        const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        return (
            <Spin
                size="default"
                tip="Redirecting..."
                indicator={loadingIcon}
                className="auth-spinner"
            />
        );
    }

    if (!isAuthenticated) {
        return <Redirect to="/address" />;
    }

    const onTabChange = (key: string) => {
        const currentPath = AccountUtils.getTabNameFromIndex(currentTabKey);
        const destinationPath = AccountUtils.getTabNameFromIndex(key);
        segment.accountLinkClicked(currentPath, destinationPath);
        setCurrentTabKey(key);
        history.push(`/account/${destinationPath}`);
    };

    return ldFlags.enableAccounts ? (
        <>
            <Header templateName="accountPage" logoLink="/menu" />
            <main className="account-page-wrapper">
                <Tabs defaultActiveKey="1" onChange={onTabChange} activeKey={currentTabKey}>
                    <TabPane tab="Profile" key="1">
                        <ProfilePage />
                    </TabPane>
                    <TabPane tab="Addresses" key="2">
                        <AccountAddress />
                    </TabPane>
                    <TabPane tab="Order History" key="3">
                        <OrderHistory />
                    </TabPane>
                </Tabs>
            </main>
        </>
    ) : null;
};

const AccountUtils = {
    getIndexFromTabName: (tabName: 'profile' | 'addresses' | 'history' | undefined) => {
        const tabNameToIndex = {
            profile: '1',
            addresses: '2',
            history: '3',
        };
        if (!tabName) {
            return '1';
        }
        return tabNameToIndex[tabName];
    },
    getTabNameFromIndex: (index: '1' | '2' | '3' | string) => {
        const indexToTabName: { [k: string]: string } = {
            1: 'profile',
            2: 'addresses',
            3: 'history',
        };
        return indexToTabName[index];
    },
};
