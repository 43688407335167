import axios from 'axios';

const baseURL = process.env.BACKEND_URL;

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error && error.response) {
            // TODO: log error
        } else {
            // TODO: log error
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
