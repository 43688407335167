import { DownOutlined } from '@ant-design/icons';
import React from 'react';
import Select, { ControlProps, components } from 'react-select';
import { useMobileScreen } from '../../utils/general';

import './index.scss';

export interface DtcSelectProps {
    icon?: any;
    options: { value: number | boolean | string; label: string }[];
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    onChange?: (event: { value: number | boolean | string; label?: string }) => void;
    className?: string;
    placeholder?: string;
    value?: { value: number | boolean | string; label?: string };
}

const Control = ({ children, ...props }: ControlProps<any, false>) => {
    // @ts-ignore
    const { icon } = props.selectProps;

    return (
        <components.Control {...props}>
            <span>{icon ? icon : null}</span>
            {children}
        </components.Control>
    );
};

const IndicatorSeparator = () => {
    return null;
};

const MobileSelect = (props: DtcSelectProps) => {
    const Icon = props.icon;
    const optionsWithMappedIds = props.options.map((option, optionInx) => ({
        ...option,
        id: optionInx,
    }));

    const activeOption = optionsWithMappedIds.find(option => option.value === props.value?.value);
    const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = optionsWithMappedIds[Number(event.target.value)];
        if (props.onChange) {
            props.onChange({ value: selectedOption.value, label: selectedOption.label });
        }
    };
    const valueProp = activeOption ? { value: activeOption?.id || 0 } : {};

    return (
        <div className="select-wrapper">
            {props.icon && <Icon className="icon select-icon" />}
            <DownOutlined className="icon dropdown-icon" />
            <select
                placeholder={props.placeholder}
                className={`mobile-select`}
                onChange={handleOnChange}
                {...valueProp}
            >
                {optionsWithMappedIds.map(({ label, id }) => (
                    <option key={id} value={id}>
                        {label}
                    </option>
                ))}
            </select>
        </div>
    );
};

const DesktopSelect = (props: DtcSelectProps) => {
    const Icon = props.icon;
    const styles = {
        control: (css: any) => ({
            ...css,
            paddingLeft: '1rem',
            cursor: 'pointer',
            borderRadius: '2px',
            fontWeight: 400,
        }),
        option: (
            css: any,
            { isSelected, isFocused }: { isSelected: boolean; isFocused: boolean },
        ) => ({
            ...css,
            backgroundColor: isSelected ? 'black' : isFocused ? '#f5f5f5' : 'white',
            color: isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: isSelected ? 'black' : '#f5f5f5',
            },
        }),
    };
    const filteredProps = {
        options: props.options || [],
        onChange: props.onChange || (() => null),
        value: props.value,
        defaultValue: props.value,
        onMenuOpen: props.onDropdownOpen || (() => null),
        onMenuClose: props.onDropdownClose || (() => null),
        className: props.className || '',
        placeholder: props.placeholder || '',
    };

    return (
        <Select
            // @ts-ignore
            icon={<Icon />}
            maxMenuHeight={200}
            menuPlacement="auto"
            components={{ Control, IndicatorSeparator }}
            isSearchable
            name="emoji"
            size="large"
            styles={styles}
            classNamePrefix="react-select"
            {...filteredProps}
        />
    );
};

export const DtcSelect = (props: DtcSelectProps) => {
    const isMobile = useMobileScreen();

    if (isMobile) {
        return <MobileSelect {...props} />;
    } else {
        return <DesktopSelect {...props} />;
    }
};
