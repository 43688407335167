import { useAuth0 } from '@auth0/auth0-react';
import { Button, Row, Space, Typography } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../contexts/app-context';
import { TemplateButton } from '../ui-components/template-button';
import useSegment from '../utils/segment';
import './index.scss';

const { Title, Text } = Typography;
interface UserCOOptionsInterface {
    orderId: string;
}

export const UserCheckedOutOptions: FC<UserCOOptionsInterface> = ({ orderId }) => {
    const ldFlags = useFlags();
    const history = useHistory();
    const segment = useSegment();

    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const { updateCartItems, customerInfo, template } = useContext(AppContext);
    const redirectUri = `${window.location.origin}/orders/callback`;

    log.debug('%c CUSTOMER INFO @ CHECKOUT ', 'background-color: red', customerInfo);

    const SignedInUserOptions = () => {
        return (
            <Space
                className="width-100 mt-10 mb-10"
                role="redirect-options"
                size={12}
                direction="vertical"
            >
                <TemplateButton
                    onClick={() => {
                        history.push({ pathname: './account/history', state: { orderId } });
                        updateCartItems([]);
                    }}
                    size="large"
                    block
                >
                    See full order summary
                </TemplateButton>
                <Button onClick={() => history.push('/')} block size="large">
                    Go back to menu
                </Button>
            </Space>
        );
    };

    const CreateAnAccountCard = () => {
        return (
            <Row
                className="account-creation-card mt-10 mb-10"
                role="account-card"
                justify="space-between"
            >
                <Space className="width-100" size={4} direction="vertical">
                    <Title className="mb-0" level={4}>
                        Sign up for faster checkout!
                    </Title>
                    <Text>
                        Save your information, access your order history, and get exclusive offers.
                    </Text>
                    <TemplateButton
                        className="mt-10"
                        onClick={() => {
                            localStorage.setItem('signupTouchpoint', 'Order Confirmation');
                            loginWithRedirect({
                                mode: 'signUp',
                                redirectUri,
                                login_hint: customerInfo?.email,
                                logo: template?.general?.logo?.website_logo,
                                primary_color: template?.general?.primary_color,
                                secondary_color: template?.general?.secondary_color,
                                appState: { route: window.location.pathname },
                                privacyPolicy: template?.general.legal.privacy_policy_link,
                                termsAndConditions: template?.general.legal.terms_conditions_link,
                            });
                            segment.createAccountCTAClicked();
                        }}
                        block
                        size="large"
                    >
                        Create an account
                    </TemplateButton>
                </Space>
            </Row>
        );
    };

    return ldFlags.enableAccounts ? (
        isAuthenticated && !isLoading ? (
            <SignedInUserOptions />
        ) : (
            <CreateAnAccountCard />
        )
    ) : (
        <></>
    );
};
