import { Button, Modal, Popover } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { ConceptContext } from '../../contexts/concept-context';
import { TemplateButton } from '../ui-components/template-button';
import useSegment from '../utils/segment';

interface IChangeAddressProps {
    children: JSX.Element;
    placement: 'bottomRight' | 'bottom';
}

export const ChangeAddressPopover: FC<IChangeAddressProps> = ({ children, placement }) => {
    const { clearAddressAndCartSession } = useContext(ConceptContext);
    const [isShowModal, setShowModal] = useState<boolean>(false);
    const [isPopoverVisible, setPopoverVisible] = useState<boolean>(false);
    const segment = useSegment();

    useEffect(() => {
        const body = document.querySelector('body');
        if (body && isPopoverVisible) {
            body.classList.add('no-scroll');
        } else if (body && !isPopoverVisible) {
            body.classList.remove('no-scroll');
        }
    }, [isPopoverVisible]);

    return (
        <>
            {
                <Popover
                    placement={placement}
                    title={'Want to change your address?'}
                    trigger="click"
                    overlayStyle={{
                        width: '195px',
                    }}
                    visible={isPopoverVisible}
                    onVisibleChange={() => setPopoverVisible(!isPopoverVisible)}
                    content={
                        <>
                            <p>
                                This will remove everything from your cart and restart your order.{' '}
                                <a
                                    className="ant-btn-link no-bg"
                                    onClick={() => {
                                        setShowModal(true);
                                        setPopoverVisible(false);
                                        segment.changeAddressLinkClicked('Cart');
                                    }}
                                >
                                    Change address
                                </a>
                            </p>
                        </>
                    }
                >
                    {children}
                </Popover>
            }
            <Modal
                centered
                visible={isShowModal}
                onCancel={() => setShowModal(false)}
                className="clear-address-confirmation-popover"
                footer={[
                    <TemplateButton
                        type="primary"
                        size="large"
                        onClick={clearAddressAndCartSession}
                    >
                        Yes
                    </TemplateButton>,
                    <Button size="large" key="back" onClick={() => setShowModal(false)}>
                        No
                    </Button>,
                ]}
            >
                <h2 className="mt-20">Are you sure you want to change your address?</h2>
                <p>This will restart your order and anything saved in your cart will be lost.</p>
            </Modal>
        </>
    );
};
