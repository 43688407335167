import {
    FacebookFilled,
    InstagramFilled,
    MinusOutlined,
    PhoneFilled,
    PlusOutlined,
    RightOutlined,
    TwitterSquareFilled,
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Col, Collapse, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/app-context';
import { ConceptContext } from '../../contexts/concept-context';
import GoogleMap from '../googleMap';
import { DaysOfWeek } from '../menu/model';
import { useMobileScreen } from '../utils/general';
import { formatPhoneNumber } from '../utils/phone';
import useSegment from '../utils/segment';
import './index.scss';
import { Login } from './Login/login';

interface StoreHour {
    open: string | undefined;
    close: string | undefined;
}

const daysOfTheWeek: DaysOfWeek[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

const { Panel } = Collapse;

export const SideNavContent = ({ closeFlyOutDrawer }: { closeFlyOutDrawer: () => void }) => {
    const { template } = useContext(AppContext);
    const { conceptDetails } = useContext(ConceptContext);
    const [accountExpanded, setAccountExpanded] = useState(false);

    const segment = useSegment();
    const ldFlags = useFlags();
    const { isAuthenticated } = useAuth0();
    const isMobile = useMobileScreen();

    const addressMatchDone = conceptDetails?.restaurantInfo ? true : false;
    const lat = Number(conceptDetails?.restaurantInfo?.delivery?.location_lat);
    const lng = Number(conceptDetails?.restaurantInfo?.delivery?.location_lon);
    const customerServiceNumber = template?.general?.support?.customer_service_number;
    const brandName = template?.metadata?.brand?.name;

    const handleTelPrompt = () => {
        segment.callSupportPageLinkClicked();
        window.open(`tel:1-${customerServiceNumber}`, '_self');
    };

    const prettyHours = (open: moment.MomentInput, close: moment.MomentInput) => {
        const openHours: string = moment(open, 'HH:mm').format('h:mm A');
        const closingHours: string = moment(close, 'HH:mm').format('h:mm A');
        return openHours.concat(' - ', closingHours);
    };

    const getPrettyAddress = () => {
        return `${conceptDetails?.restaurantInfo?.delivery?.address?.address1}, 
                ${conceptDetails?.restaurantInfo?.delivery?.address?.city}`;
    };

    const renderStoreHours = () => {
        let storeStatus: string = 'Closed';
        const hoursMap = new Map<DaysOfWeek | string, StoreHour>(
            daysOfTheWeek.map(day => [day, { open: 'Closed', close: 'Closed' }]),
        );
        const dayParts = conceptDetails?.menuInfo?.menus[0]?.day_parts;
        for (const dayPart of dayParts) {
            for (const day of dayPart.days_of_week) {
                hoursMap.set(day, { open: dayPart.start_time, close: dayPart.end_time });
            }
        }
        //Get store status (Closed or Open)
        const today = moment().format('dddd');
        const todaysHours = hoursMap.get(today.charAt(0).toLowerCase() + today.slice(1));
        if (todaysHours && todaysHours.open !== 'Closed') {
            if (
                moment(todaysHours.open, 'HH:mm').isBefore(moment()) &&
                moment(todaysHours.close, 'HH:mm').isAfter(moment())
            ) {
                storeStatus = 'Open';
            }
        }

        const CollapseHeader = () => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <th
                        style={{
                            color: storeStatus === 'Open' ? '#26C866' : 'red',
                            fontSize: '18px',
                            textAlign: 'left',
                        }}
                    >
                        {' '}
                        {storeStatus}{' '}
                    </th>
                    {Array.from(hoursMap).map(([key, value]) => (
                        <tr key={key} style={{ fontWeight: 'bold' }}>
                            {key === today.toLocaleLowerCase() && <td> Today </td>}

                            {key === today.toLocaleLowerCase() && value.open !== 'Closed' ? (
                                <td style={{ textAlign: 'right', width: '100%' }}>
                                    {' '}
                                    {prettyHours(value.open, value.close)}{' '}
                                </td>
                            ) : (
                                key === today.toLocaleLowerCase() && (
                                    <td style={{ textAlign: 'right', width: '100%' }}> Closed </td>
                                )
                            )}
                        </tr>
                    ))}
                </div>
            );
        };

        return (
            <div style={{ width: '100%' }}>
                <table style={{ width: '100%' }}>
                    <Collapse bordered={false} expandIconPosition={'right'}>
                        <Panel header={<CollapseHeader />} key={1} style={{ width: '100%' }}>
                            {Array.from(hoursMap).map(([key, value]) => (
                                <tr key={key}>
                                    <td> {key.charAt(0).toUpperCase() + key.slice(1)} </td>

                                    {value.open !== 'Closed' ? (
                                        <td style={{ textAlign: 'right', width: '100%' }}>
                                            {' '}
                                            {prettyHours(value.open, value.close)}{' '}
                                        </td>
                                    ) : (
                                        key !== today.toLocaleLowerCase() && (
                                            <td style={{ textAlign: 'right', width: '100%' }}>
                                                {' '}
                                                Closed{' '}
                                            </td>
                                        )
                                    )}
                                </tr>
                            ))}
                        </Panel>
                    </Collapse>
                </table>
            </div>
        );
    };

    const handleSegment = (action: string) => {
        const path = window.location.pathname;
        let via = '';
        if (path.includes('menu')) {
            via = 'Menu';
        } else if (path.includes('profile')) {
            via = 'Profile Page';
        } else if (path.includes('addresses')) {
            via = 'Saved Addresses Page';
        } else if (path.includes('history')) {
            via = 'Order History Page';
        }
        segment.menuLinkClicked(action, via);
    };

    const renderAccountMenu = () => {
        return (
            ldFlags.enableAccounts &&
            isAuthenticated && (
                <Row className={`sideNavItem`} onClick={() => setAccountExpanded(!accountExpanded)}>
                    <Col span={22}>
                        <a className="fly-out-button">
                            <span>My Account</span>
                        </a>
                    </Col>
                    <Col span={2}>
                        <a className="fly-out-button">
                            {accountExpanded ? <MinusOutlined /> : <PlusOutlined />}
                        </a>
                    </Col>
                    {accountExpanded ? (
                        <div className="legal-section">
                            <Row>
                                <Link
                                    to="/account/profile"
                                    className="fly-out-button"
                                    onClick={() => {
                                        closeFlyOutDrawer();
                                        handleSegment('Profile');
                                    }}
                                >
                                    <h5>Profile</h5>
                                </Link>
                            </Row>
                            <Row>
                                <Link
                                    to="/account/addresses"
                                    className="fly-out-button"
                                    onClick={() => {
                                        closeFlyOutDrawer();
                                        handleSegment('Saved Addresses');
                                    }}
                                >
                                    <h5>Addresses</h5>
                                </Link>
                            </Row>
                            <Row>
                                <Link
                                    to="/account/history"
                                    className="fly-out-button"
                                    onClick={() => {
                                        closeFlyOutDrawer();
                                        handleSegment('Order History');
                                    }}
                                >
                                    <h5>Order History</h5>
                                </Link>
                            </Row>
                        </div>
                    ) : null}
                </Row>
            )
        );
    };

    if (!template) {
        return null;
    }

    const { instagram, facebook, twitter } = template.general?.social_media || {};
    const brand = template?.metadata?.brand?.name?.toLowerCase() || '';
    let rafLink = '';
    if (brand.includes('tender')) {
        rafLink = location.origin + '/info/refer-a-friend';
    } else {
        const referAFriendBrands = [
            'dilla',
            'thrilled',
            'packed',
            'crave',
            'firebelly',
            'hatch',
            'huevorito',
            'crack',
            'monster',
            'society',
            'dragon',
        ];
        for (const shortBrand of referAFriendBrands) {
            if (brand.includes(shortBrand)) {
                rafLink = location.origin + '/refer-a-friend';
                break;
            }
        }
    }

    return (
        <div className="sideNavInner">
            <div>
                {ldFlags.enableAccounts && (
                    <>
                        {!isAuthenticated && (
                            <Row style={{ paddingBottom: 0 }}>
                                <h1 style={{ marginBottom: 0 }}> {brandName} </h1>
                            </Row>
                        )}
                        <Row style={{ paddingTop: 0 }}>
                            {!isAuthenticated ? (
                                <p className="logInDescription">
                                    Create an account or sign in for faster checkout and exclusive
                                    offers!
                                </p>
                            ) : null}
                            <Login signupTouchPoint="Menu Flyout" />
                        </Row>
                    </>
                )}
                {renderAccountMenu()}
                {addressMatchDone && (
                    <>
                        <Row className={`sideNavItem`}>{renderStoreHours()}</Row>
                        <Row className={`sideNavItem`} style={{ padding: 0 }}>
                            <GoogleMap lat={lat} lng={lng} />
                            <h5> {getPrettyAddress()} </h5>
                        </Row>
                    </>
                )}
                <div className="social-section">
                    {facebook && (
                        <Col
                            className={`sideNavItem social-item`}
                            style={{ padding: isMobile ? '4px 6px' : '' }}
                        >
                            <a
                                href={facebook}
                                target="_blank"
                                onClick={() => segment.linkClicked(facebook, 'Facebook')}
                            >
                                <FacebookFilled
                                    style={{
                                        fontSize: '37px',
                                        color: '#1D9BF0',
                                    }}
                                />
                            </a>
                            Facebook
                        </Col>
                    )}
                    {instagram && (
                        <Col
                            className={`sideNavItem social-item`}
                            style={{ padding: isMobile ? '4px 6px' : '' }}
                        >
                            <a
                                href={instagram}
                                target="_blank"
                                onClick={() => segment.linkClicked(instagram, 'Instagram')}
                            >
                                <InstagramFilled
                                    style={{
                                        fontSize: '37px',
                                        color: '#a81ac4',
                                    }}
                                />
                            </a>
                            Instagram
                        </Col>
                    )}
                    {twitter && (
                        <Col
                            className={`sideNavItem social-item`}
                            style={{ padding: isMobile ? '4px 6px' : '' }}
                        >
                            <a
                                href={twitter}
                                target="_blank"
                                onClick={() => segment.linkClicked(twitter, 'Twitter')}
                            >
                                <TwitterSquareFilled
                                    style={{
                                        fontSize: '37px',
                                        color: '#1D9BF0',
                                    }}
                                />
                            </a>
                            Twitter
                        </Col>
                    )}
                </div>
                {rafLink && (
                    <a
                        className="fly-out-button"
                        target="_blank"
                        href={rafLink}
                        onClick={() => {
                            segment.linkClicked(
                                rafLink,
                                'Refer-a-Friend',
                                'Navigation',
                                addressMatchDone || null,
                            );
                        }}
                    >
                        <Row className="sideNavItem">
                            <Col
                                span={24}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{ fontWeight: 'bold' }}>
                                    Get $10 off when you refer a friend
                                </span>
                                <RightOutlined style={{ fontStyle: 'bold' }} />
                            </Col>
                        </Row>
                    </a>
                )}
                <Row className="sideNavItem" onClick={handleTelPrompt}>
                    <Col span={25}>
                        <h3 style={{ marginBottom: 15, fontWeight: 600, fontSize: '18px' }}>
                            Questions about your order?
                        </h3>
                    </Col>
                    <div>
                        <Col span={30}>
                            <PhoneFilled
                                style={{
                                    fontSize: '20px',
                                    color: 'black',
                                    paddingRight: '.5rem',
                                }}
                            />
                            <span
                                style={{ fontSize: '16px', letterSpacing: '1px', fontWeight: 600 }}
                            >
                                {formatPhoneNumber(customerServiceNumber)}
                            </span>
                        </Col>
                    </div>
                </Row>
                {template.general?.disclaimers?.description && (
                    <Row className="sideNavItem no-pointer">
                        <Col span={25}>
                            <div className="disclaimers">
                                <ReactMarkdown>
                                    {template.general?.disclaimers?.description}
                                </ReactMarkdown>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};
