import { ArrowLeftOutlined, CloseOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
    Checkbox,
    Col,
    Collapse,
    Drawer,
    Form,
    Input,
    RadioChangeEvent,
    Row,
    notification,
} from 'antd';
import log from 'loglevel';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { getDetails } from 'use-places-autocomplete';
import { v4 as uuidv4 } from 'uuid';
import { UserProfile } from '../../../../services/UserProfile';
import { AppContext, IUserProfile, UserProfileAddress } from '../../../contexts/app-context';
import { DeliveryPreferenceInterface } from '../../menu/model';
import { PlacesAutocomplete } from '../../ui-components/PlacesAutocomplete';
import { RadioButtonGroup } from '../../ui-components/RadioButtonGroup';
import { TemplateButton } from '../../ui-components/template-button';
import { placesToAddress } from '../../utils/address';
import { formatAddressDescription, useMobileScreen } from '../../utils/general';
import useSegment from '../../utils/segment';
import './index.scss';

type AddressItemProp = {
    customerAddress: UserProfileAddress;
    expandId: string;
    setExpandId: (id: string) => void;
    setIsNewAddress?: (isNewAddress: boolean) => void;
};

interface FormData {
    customerAddressApt: string;
    defaultAddress: boolean;
    delivery_notes: string;
    delivery_preference: string;
}

const DEFAULT_PLACE = {
    description: '',
};

const DEFAULT_PREFERENCE = 'Meet the driver at the door';
const ERROR_NOTIFICATION = 'Sorry, something went wrong';
const SAVE_SUCCESS_NOTIFICATION = 'Your address has been saved';
const DELETE_SUCCESS_NOTIFICATION = 'You address has been deleted';

export const AddressItem: FC<AddressItemProp> = ({
    customerAddress,
    setIsNewAddress,
    expandId,
    setExpandId,
}) => {
    const isMobile = useMobileScreen();
    const [expand, setExpand] = useState(false);
    const [show, setShow] = useState(true);
    const [placeId, setPlaceId] = useState('');
    const [dupIdx, setDupIdx] = useState(-1);
    const [addressFormData, setAddressFormData] = useState(customerAddress);
    const [addressExisted, setAddressExisted] = useState(false);
    const [place, setPlace] = useState(DEFAULT_PLACE);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {
        isDinnerbell,
        template,
        userProfile,
        updateUseDefaultAddress,
        updateUserProfile,
        orderAddress,
        updateDeliveryPreference,
        updateOrderAddressApt,
    } = useContext(AppContext);

    const addressList = useRef<any>();
    const { getAccessTokenSilently } = useAuth0();

    const [form] = Form.useForm();

    const iconColor = isDinnerbell ? '#f1c143' : '#bbb';
    const iconStyles = {
        fontSize: '20px',
        color: iconColor,
        marginLeft: '20px',
    };

    const segment = useSegment();

    const formatAddress = (address1: string, address2?: string): string => {
        const apartmentBlock = address2 ? `, ${address2}` : '';
        return `${address1}${apartmentBlock}`;
    };

    const clearAddress = () => {
        const newAddressFormData: UserProfileAddress = {
            address1: '',
            address2: form.getFieldValue('address2'),
            city: '',
            country: '',
            region: '',
            postalcode: '',
            id: addressFormData.id || uuidv4(),
            delivery_preference: form.getFieldValue('delivery_preference'),
            special_instructions: form.getFieldValue('delivery_notes'),
            default_address: form.getFieldValue('default_address'),
        };
        setAddressFormData(newAddressFormData);
    };

    const onChange = (input: any) => {
        if (input && input.place_id) {
            setError(false);
            setErrorMessage('');
            setPlaceId(input.place_id);
        } else {
            setPlaceId('');
            clearAddress();
        }
    };

    const placeDetailsQuery = useQuery(['placeDetails', placeId], () => {
        if (placeId) {
            return getDetails({
                placeId,
                fields: ['address_components', 'formatted_address', 'geometry', 'place_id'],
            });
        } else {
            return false;
        }
    });

    const checkAddressExisted = useCallback((address: UserProfileAddress) => {
        const idx = addressList.current
            ? addressList.current.findIndex((item: UserProfileAddress) => {
                  return (
                      item.address1 === address.address1 && item.postalcode === address.postalcode
                  );
              })
            : -1;
        if (idx === -1) {
            return false;
        } else {
            setDupIdx(idx);
        }
        return true;
    }, []);

    useEffect(() => {
        addressList.current = userProfile?.service_info;
    }, [userProfile]);

    useEffect(() => {
        setAddressFormData(customerAddress);
    }, [customerAddress]);

    useEffect(() => {
        if (addressExisted) {
            setShow(false);
        }
    }, [addressExisted]);

    useEffect(() => {
        if (expandId && expandId === customerAddress.id) {
            setExpand(true);
        }
    }, [expandId, customerAddress]);

    useEffect(() => {
        if (placeId && placeDetailsQuery.data) {
            const newAddressFormData: UserProfileAddress = {
                ...placesToAddress(placeDetailsQuery.data),
                id: addressFormData.id || uuidv4(),
                delivery_preference:
                    form.getFieldValue('delivery_preference') || DEFAULT_PREFERENCE,
                special_instructions: form.getFieldValue('delivery_notes'),
                default_address: form.getFieldValue('default_address'),
            };
            if (checkAddressExisted(newAddressFormData)) {
                setAddressExisted(true);
                setShow(false);
            } else {
                setShow(true);
                setAddressExisted(false);
            }
            setAddressFormData(newAddressFormData);
        }
    }, [placeId, placeDetailsQuery.data, form, addressFormData.id, checkAddressExisted]);

    const updateAddress = async (addressPayload: any, isDelete = false) => {
        const token = await getAccessTokenSilently();
        UserProfile.updateUserProfile(token, addressPayload)
            .then((data: IUserProfile) => {
                updateUserProfile(data);
                notification.success({
                    message: isDelete ? DELETE_SUCCESS_NOTIFICATION : SAVE_SUCCESS_NOTIFICATION,
                    placement: 'bottomRight',
                });
                setExpand(!expand);
            })
            .catch(err => {
                notification.error({
                    message: ERROR_NOTIFICATION,
                    placement: 'bottomRight',
                });
                log.error('Error saving address', err);
            });
    };

    const getAddressPayload = (formData: FormData) => {
        const addresses = [...(userProfile?.service_info || [])];
        addressFormData.id = addressFormData.id || uuidv4();
        addressFormData.address2 = formData.customerAddressApt;
        addressFormData.delivery_preference = formData.delivery_preference;
        addressFormData.special_instructions = formData.delivery_notes;
        addressFormData.default_address = formData.defaultAddress;

        segment.profileAddressEntered(addressFormData);
        // edit action
        const index = addresses.findIndex(item => item.id === addressFormData.id);

        if (addressFormData.default_address) {
            handleDefaultAddress(addresses, index);
        }
        if (index !== -1) {
            addresses[index] = addressFormData;
        } else {
            // add action
            addressFormData.id = uuidv4();
            addresses.push(addressFormData);
        }
        if (addresses.length === 1) {
            addresses[0].default_address = true;
        }
        return {
            service_info: [...addresses],
        };
    };

    const handleDefaultAddress = (addresses: UserProfileAddress[], currentIdx: number) => {
        for (let i = 0; i < addresses.length; i++) {
            if (i !== currentIdx || currentIdx === -1) {
                addresses[i].default_address = false;
            }
        }
    };

    useEffect(() => {
        if (!addressFormData || !addressFormData.id) {
            setExpand(true);
            setShow(false);
        }

        if (addressFormData.address1) {
            setPlace({
                description: `${addressFormData.address1}, ${addressFormData.city}, ${addressFormData.region}, ${addressFormData.country}`,
            });
        } else {
            setPlace(DEFAULT_PLACE);
        }
    }, [addressFormData]);

    const onAddressBlur = (e: any) => {
        if (!place.description || e.target.value !== place.description) {
            setError(true);
            setErrorMessage('Please enter a valid address');
        } else {
            setError(false);
            setErrorMessage('');
        }
    };

    const radioButtonProps = {
        options: [
            { label: 'Meet the driver at the door', value: 'Meet the driver at the door' },
            {
                label: 'Contactless delivery (leave food at door)',
                value: 'Contactless delivery (leave food at door)',
            },
        ],
    };

    const onClearAddress = () => {
        setError(true);
        setErrorMessage('Please enter a valid address');
    };

    const onCancel = () => {
        const action = setIsNewAddress ? 'Cancel Add Address' : 'Cancel Edit Address';
        segment.cancelProfileAddressClicked(action);
        if (setExpandId) {
            setExpandId('');
        }
        if (setIsNewAddress) {
            setIsNewAddress(false);
            return;
        }
        const originalAddressData = userProfile?.service_info?.find(item => {
            return item.id === addressFormData.id;
        });
        if (originalAddressData) {
            setAddressFormData(originalAddressData);
            setExpand(false);
        }
    };

    const onEditDuplicate = () => {
        if (setIsNewAddress) {
            setIsNewAddress(false);
        }
        if (userProfile && dupIdx > -1 && userProfile.service_info) {
            const tempAddress = userProfile.service_info.splice(dupIdx, 1);
            if (setExpandId) {
                setExpandId(tempAddress[0].id || '');
            }
            userProfile.service_info.unshift(tempAddress[0]);
            updateUserProfile(userProfile);
            setAddressExisted(false);
        }
    };

    const deleteAddress = () => {
        const addresses = [...(userProfile?.service_info || [])];
        const idx = addresses.findIndex(item => {
            return item.id === addressFormData.id;
        });
        let defaultAddress = addresses.find(item => {
            return item.default_address;
        });
        if (idx !== -1) {
            // delete default condition
            let isRemoveDefault = false;
            if (addresses[idx].default_address) {
                isRemoveDefault = true;
            }
            addresses.splice(idx, 1);
            if (addresses.length === 1 && isRemoveDefault) {
                addresses[0].default_address = true;
                defaultAddress = addresses[0];
            }
            updateAddress({ service_info: [...addresses] }, true);
            segment.identifyProfileAddress(defaultAddress, addresses.length);
        }
    };

    const onDelete = () => {
        deleteAddress();
        segment.deleteProfileAddressCTAClicked();
    };

    const onChangeDefaultAddress = (e: any) => {
        if (e.target.checked != null) {
            segment.defaultAddressSelected(e.target.checked);
        }
    };

    function onChangedRadio(e: RadioChangeEvent): void {
        if (e.target.value) {
            segment.addressDeliveryPreferenceSelected(e.target.value);
        }
    }

    const updateAddressDeliveryOptions = () => {
        const orderAddressParts = orderAddress && placesToAddress(orderAddress);

        /* If current address in session has the same address, update the selected preferences.  
        Checking the address parts and description is error prone and not the best ideal solutions, 
        since google autocomplete sometimes adds in cardinal directions (N, E, S, W) after street number. 
        We don't have a solution to uniquely identify the google places address to our BE saved addresses.
        Place_id's are not unique and might change overtime.
        */
        const isCurrentConceptMatched =
            orderAddress?.service_info_default_address ===
                formatAddressDescription(addressFormData, true) ||
            (addressFormData.address1 === orderAddressParts?.address1 &&
                addressFormData.postalcode === orderAddressParts?.postalcode);

        if (orderAddress && isCurrentConceptMatched) {
            const deliveryPreferenceDetails: DeliveryPreferenceInterface = {
                preference: addressFormData.delivery_preference,
                text: addressFormData.special_instructions,
            };
            updateDeliveryPreference(deliveryPreferenceDetails);
            updateOrderAddressApt(addressFormData.address2 || '');
        }
    };

    const CancelButton = () => (
        <Button
            type="default"
            className={isMobile ? '' : 'mt-10 mb-10 mr-10'}
            size="large"
            block={isMobile}
            onClick={onCancel}
        >
            Cancel
        </Button>
    );

    const EditExistedAddressButton = () => (
        <Button
            type="link"
            onClick={onEditDuplicate}
            className="edit-link-button no-bg"
            style={{ paddingLeft: '0' }}
        >
            Edit saved address
        </Button>
    );

    const DeleteButton = () => (
        <Button
            data-testid="delete-address-button"
            danger
            type="link"
            className={isMobile ? '' : 'mt-10 mb-10 mr-10'}
            size="large"
            block={isMobile}
            onClick={onDelete}
            style={{ paddingLeft: '0' }}
        >
            Delete Address
        </Button>
    );

    const UpdateButton = () => (
        <TemplateButton
            type="default"
            htmlType="submit"
            data-testid="save-address-button"
            size="large"
            block={isMobile}
            disabled={error}
        >
            Save
        </TemplateButton>
    );

    const onFinish = (formData: any) => {
        if (setIsNewAddress) {
            setIsNewAddress(false);
        }

        const addressPayload = getAddressPayload(formData);

        updateAddress(addressPayload);
        updateUseDefaultAddress(null);
        updateAddressDeliveryOptions();

        if (setExpandId) {
            setExpandId('');
        }

        const defaultAddress = addressPayload.service_info.find(item => {
            return item.default_address;
        });
        segment.identifyProfileAddress(defaultAddress, addressPayload.service_info.length);
        segment.saveProfileAddressCTAClicked();
    };

    const AddressForm = () => (
        <Form
            form={form}
            name="customer_delivery_address"
            className={expand ? '' : 'ant-collapse-content-hidden'}
            onFinish={onFinish}
        >
            {setIsNewAddress && <p className="add-address-title">Add your new address</p>}
            {show && !setIsNewAddress && <span className="content-title">Street address</span>}
            <Row gutter={10}>
                <Col xs={24} md={16}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your address!',
                            },
                        ]}
                        initialValue={place}
                        valuePropName="value"
                    >
                        <PlacesAutocomplete
                            error={error}
                            helperText={errorMessage}
                            placeholder="Address"
                            dataTestId="address-input"
                            id="customerAddress"
                            onClear={onClearAddress}
                            onChange={onChange}
                            value={place}
                            isAddressValid={false}
                            onInputBlur={onAddressBlur}
                            fetchDetails={false}
                        />
                        {addressExisted && (
                            <>
                                <div>
                                    <span>It looks like you've already saved this address. </span>
                                    <EditExistedAddressButton />
                                </div>
                            </>
                        )}
                    </Form.Item>
                </Col>
                {show && (
                    <>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="customerAddressApt"
                                initialValue={addressFormData.address2}
                            >
                                <Input
                                    placeholder="Apt, Suite, Unit"
                                    size="large"
                                    style={{ marginBottom: '-10px' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="defaultAddress"
                                initialValue={customerAddress.default_address ?? false}
                                valuePropName="checked"
                            >
                                <Checkbox onChange={onChangeDefaultAddress}>
                                    Make default address
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        {template?.checkout_page?.show_delivery_preference_ui && (
                            <>
                                <Col xs={24} md={24}>
                                    <span className="content-title">Delivery preferences</span>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                name="delivery_preference"
                                                initialValue={addressFormData?.delivery_preference}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select an option!',
                                                    },
                                                ]}
                                            >
                                                <RadioButtonGroup
                                                    {...radioButtonProps}
                                                    onChange={(e: RadioChangeEvent) =>
                                                        onChangedRadio(e)
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        )}

                        <Col xs={24} md={24}>
                            <span className="content-title">Delivery instructions</span>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="delivery_notes"
                                initialValue={addressFormData?.special_instructions}
                            >
                                <Input.TextArea
                                    rows={2}
                                    placeholder="Add any additional notes to help your driver find you (optional)"
                                />
                            </Form.Item>
                        </Col>
                    </>
                )}
                {!setIsNewAddress && <Col span={8}>{<DeleteButton />}</Col>}
                <Col span={24}>
                    <Row className="action-row">
                        {isMobile ? (
                            show && (
                                <>
                                    <Col span={12} className="pr-5">
                                        <CancelButton />
                                    </Col>

                                    <Col span={12} className="pl-5">
                                        <UpdateButton />
                                    </Col>
                                </>
                            )
                        ) : (
                            <Col span={24}>
                                <CancelButton />
                                {show && <UpdateButton />}
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Form>
    );

    const { Panel } = Collapse;

    const EditButton = () =>
        !expand && (
            <Button
                type="link"
                onClick={() => {
                    setExpand(!expand);
                }}
                data-testid="edit-address-button"
                className="edit-link-button no-bg"
            >
                Edit
            </Button>
        );

    return (
        <>
            <Collapse
                bordered={false}
                expandIconPosition={'right'}
                expandIcon={EditButton}
                activeKey={expand ? '1' : '0'}
                collapsible={expand ? 'header' : 'disabled'}
            >
                <Panel
                    className={expand ? 'no-header' : 'checkout-form-panel'}
                    header={
                        <>
                            {!expand && (
                                <>
                                    <div
                                        className="header-title"
                                        onClick={e => e.stopPropagation()}
                                    >
                                        {!isMobile && <EnvironmentOutlined style={iconStyles} />}
                                        <div>
                                            <span className="address-main-text">
                                                {formatAddress(
                                                    addressFormData.address1 || '',
                                                    addressFormData.address2 || '',
                                                )}
                                            </span>
                                            <br />
                                            {`${addressFormData.city}, ${addressFormData.region}, ${addressFormData.country}`}
                                        </div>
                                        {addressFormData.default_address && (
                                            <div className="default-indicator">• Default</div>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    }
                    key="1"
                >
                    {isMobile ? (
                        <>
                            <Drawer
                                className={
                                    show && !setIsNewAddress
                                        ? 'add-drawer-show'
                                        : 'add-drawer-no-show'
                                }
                                placement="right"
                                closeIcon={
                                    !setIsNewAddress ? <ArrowLeftOutlined /> : <CloseOutlined />
                                }
                                title={show && !setIsNewAddress ? 'Edit Address' : ''}
                                width="100%"
                                visible={expand}
                                destroyOnClose={true}
                                onClose={onCancel}
                            >
                                <AddressForm />
                            </Drawer>
                        </>
                    ) : (
                        <>
                            <AddressForm />
                        </>
                    )}
                </Panel>
            </Collapse>
        </>
    );
};
