import { Col } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';

export const MenuDisclaimer = () => {
    const { isDinnerbell } = useContext(AppContext);

    return (
        <>
            {isDinnerbell && (
                <Col span={24} className="disclaimers mt-40">
                    <p>We produce our food in a facility that handles nuts and shellfish.</p>
                    <p>
                        2,000 calories a day is used for general nutrition advice, but calorie needs
                        vary. Written nutrition information is available upon request.
                    </p>
                    <p>
                        WARNING: Certain foods and beverages sold or served here can expose you to
                        chemicals including acrylamide in many fried or baked foods, and mercury in
                        fish, which are known to the State of California to cause cancer and birth
                        defects or other reproductive harm. For more information go to{' '}
                        <a href="https://www.p65warnings.ca.gov/restaurant" target="_blank">
                            www.p65warnings.ca.gov/restaurant.
                        </a>
                    </p>
                    <p>
                        WARNING: Many food and beverage cans have linings containing bisphenol A
                        (BPA), a chemical known to the State of California to cause harm to the
                        female reproductive system. Jar lids and bottle caps may also contain BPA.
                        You can be exposed to BPA when you consume foods or beverages packaged in
                        these containers. For more information go to:{' '}
                        <a href="https://www.p65warnings.ca.gov/bpa" target="_blank">
                            www.p65warnings.ca.gov/bpa
                        </a>
                    </p>
                </Col>
            )}
        </>
    );
};
