import { AppBrand } from './types';

const tacosBrand: AppBrand = {
    app: {
        appId: 'io.nextbite.tacos',
        appName: 'George Lopez Tacos',
        appHost: 'lopeztacos.com',
    },
    styles: {
        'border-radius-base': '2px',
        'body-background': '#fff',
        'padding-md': '16px',
    },
};

export default tacosBrand;
