import { useFlags } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import { ConceptContext } from '../../contexts/concept-context';
import {
    CSummaryProps,
    MenuItemInterface,
    ModifierGroupsItemInterface,
    findEntity,
    matchNestedRule,
} from '../menu/model';
import useSegment from '../utils/segment';
import { Card } from './card/card';

type SectionIdOccurrenceType = [string, number];

interface IRecommendedForYou {
    item: MenuItemInterface;
    idHierarchy: string[];
}

interface SectionDetailValues {
    sectionItemIds: string[];
    sectionId: string;
    sectionList: SectionIdOccurrenceType[];
    i: number;
}

export const Recommendations: FC<CSummaryProps> = ({
    onCartItemEdit,
    addCartItem,
    closeDrawer,
    cartVisible,
}) => {
    const [recommendedList, setRecommendedList] = useState<IRecommendedForYou[]>([]);
    const [itemsAvailable, setItemsAvailable] = useState<boolean>(true);
    const { conceptDetails } = useContext(ConceptContext);
    const { cartItems } = useContext(AppContext);
    const ldFlags = useFlags();
    const segment = useSegment();

    const addItemsToRecommendedList = useCallback(
        (
            sectionDetails: SectionDetailValues,
            tempItemsAddedList: Set<string>,
            recommendedForYouList: IRecommendedForYou[],
        ) => {
            const { sectionList, sectionId, i: sectionIdx, sectionItemIds } = sectionDetails;
            const menuInfo = conceptDetails?.menuInfo;
            let sectionItemAdded = false;
            for (const itemId of sectionItemIds) {
                const itemAlreadyAdded = tempItemsAddedList.has(itemId);
                const item = findEntity(menuInfo, 'item', itemId);
                if (!itemAlreadyAdded && !item.is_paused) {
                    const menuInfoId = menuInfo?.menus[0].id;
                    const idHierarchy = [menuInfoId, sectionId, itemId];
                    recommendedForYouList.push({ item, idHierarchy });
                    tempItemsAddedList.add(itemId);
                    sectionItemAdded = true;
                    break;
                }
            }
            if (!sectionItemAdded) {
                sectionList.splice(sectionIdx, 1);
            }
        },
        [conceptDetails],
    );

    const searchForItemsBySection = useCallback(
        (
            sectionList: SectionIdOccurrenceType[],
            recommendedForYouList: IRecommendedForYou[],
            tempItemsAddedList: Set<string>,
        ) => {
            const menuInfo = conceptDetails?.menuInfo;
            for (let i = 0; i < sectionList.length; i++) {
                if (recommendedForYouList.length === 3) {
                    break;
                }
                const [sectionId] = sectionList[i];
                const sectionItemIds = findEntity(menuInfo, 'section', sectionId).item_ids;
                const sectionDetails = { sectionItemIds, sectionId, sectionList, i };
                addItemsToRecommendedList(
                    sectionDetails,
                    tempItemsAddedList,
                    recommendedForYouList,
                );
            }
            if (sectionList.length < 1) {
                setItemsAvailable(false);
            }
            if (recommendedForYouList.length < 3 && sectionList.length > 0) {
                searchForItemsBySection(sectionList, recommendedForYouList, tempItemsAddedList);
            }
            setRecommendedList(recommendedForYouList);
        },
        [conceptDetails, addItemsToRecommendedList, setRecommendedList],
    );

    const getRecommendations = useCallback(() => {
        const sectionIds = new Map<string, number>();
        const tempItemsAddedList = new Set<string>();
        const recommendedForYouList: IRecommendedForYou[] = [];

        cartItems.forEach(item => {
            const idOccurence = sectionIds.get(item.section_id) || 0;
            sectionIds.set(item.section_id, idOccurence + 1);
            tempItemsAddedList.add(item.item_id);
        });
        const numOfMenuSections = conceptDetails?.menuInfo?.sections?.length;
        const allMenuSectionsAdded = numOfMenuSections === sectionIds.size;

        if (allMenuSectionsAdded) {
            const sortedListAscOrder = Array.from(sectionIds.entries()).sort((a, b) => a[1] - b[1]);
            searchForItemsBySection(sortedListAscOrder, recommendedForYouList, tempItemsAddedList);
        } else {
            const noCartSectionIds: SectionIdOccurrenceType[] = [];
            conceptDetails?.menuInfo?.sections?.forEach(section => {
                if (!sectionIds.has(section.id)) {
                    noCartSectionIds.push([section.id, 0]);
                }
            });
            searchForItemsBySection(noCartSectionIds, recommendedForYouList, tempItemsAddedList);
        }
    }, [cartItems, conceptDetails, searchForItemsBySection]);

    log.debug('%c recommendedList ', 'background-color: pink', recommendedList);

    useEffect(() => {
        if (cartVisible) {
            setItemsAvailable(true);
        } else {
            setRecommendedList([]);
        }
    }, [cartVisible]);

    useEffect(() => {
        if (recommendedList.length < 3 && itemsAvailable && cartVisible) {
            getRecommendations();
        }
    }, [conceptDetails, getRecommendations, recommendedList, itemsAvailable, cartVisible]);

    const calculatePrice = (item: MenuItemInterface, idHierarchy: string[]) => {
        const price = matchNestedRule(item.price_rules, idHierarchy)?.price || 0;
        return price;
    };

    const handleItemAdd = async (item: MenuItemInterface, itemIdHierarchy: string[]) => {
        const itemPrice: number = calculatePrice(item, itemIdHierarchy);
        const section = findEntity(conceptDetails.menuInfo, 'section', itemIdHierarchy[1]);
        const via = 'Cart Upsell';

        if (item.modifier_group_ids.length > 0) {
            onCartItemEdit(section, item, itemIdHierarchy, itemPrice, via, -1, false);
            closeDrawer({ reason: via });
        } else {
            addSingleItemToCart(item, itemPrice, itemIdHierarchy, via);
            log.debug('%c Recommended Item added to Cart', 'background-color: pink', item.title);
        }
    };

    const addSingleItemToCart = (
        item: MenuItemInterface,
        itemPrice: number,
        itemIdHierarchy: string[],
        via: string,
    ) => {
        const quantity = 1;
        const modifiers: ModifierGroupsItemInterface[] = [];
        const selectedItems: Record<string, unknown> = {};
        const specialInstructions = '';
        const recommendedItemDetails = { itemIdHierarchy, item };

        addCartItem(
            modifiers,
            itemPrice,
            itemPrice,
            quantity,
            selectedItems!,
            false,
            -1,
            specialInstructions,
            recommendedItemDetails,
        );
        segment.productAdded(
            itemIdHierarchy,
            item,
            quantity,
            itemPrice,
            modifiers,
            specialInstructions,
            via,
        );
        setRecommendedList(prev => prev.filter(element => element.item.id !== item.id));
    };

    return ldFlags.enableUpselling && recommendedList.length > 0 ? (
        <>
            <div className="checkout-section-heading">Other Popular Items</div>
            <div data-testid="recommended-list" className="recommended-list">
                {recommendedList.map(element => {
                    const { item, idHierarchy } = element;
                    return (
                        <Card
                            key={item.id}
                            menuItem={item}
                            itemPrice={calculatePrice(item, idHierarchy)}
                            handleItemAdd={() => handleItemAdd(item, idHierarchy)}
                        />
                    );
                })}
            </div>
        </>
    ) : (
        <></>
    );
};
