import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { ReactNode } from 'react';
import { TemplateButton } from '../../ui-components/template-button';
import { useMobileScreen } from '../../utils/general';

import './expandable-section.scss';

type mobileViewProps =
    | {
          isShowingFullScreenMobile?: true;
          mobileFullScreenHeader?: string;
          handleMobileBackButton?: () => void;
      }
    | {
          isShowingFullScreenMobile?: false;
          mobileFullScreenHeader?: never;
          handleMobileBackButton?: never;
      };

interface ExpandableSectionStaticProps {
    isOpen?: boolean;
    description?: string;
    renderHeaderActionElement?: () => JSX.Element;
    renderHeaderTitle?: () => JSX.Element;
    expandedContentActions?: {
        label?: string;
        onClick?: () => void;
        disabled?: boolean;
        style?: React.CSSProperties;
        htmlType?: 'submit' | 'button';
    }[];
}

export type ExpandableSectionProps = ExpandableSectionStaticProps & mobileViewProps;

export const ExpandableSection: React.FC<ExpandableSectionProps> = ({
    isOpen,
    children,
    description,
    renderHeaderActionElement,
    renderHeaderTitle,
    isShowingFullScreenMobile = false,
    expandedContentActions,
    handleMobileBackButton,
    mobileFullScreenHeader,
}) => {
    const isMobile = useMobileScreen();

    const actionsButton =
        expandedContentActions &&
        expandedContentActions.length > 0 &&
        expandedContentActions.map((buttonAttributes, index) => {
            const { label, ...buttonProps } = buttonAttributes;

            return (
                <TemplateButton key={index} {...buttonProps}>
                    {label}
                </TemplateButton>
            );
        });

    const renderExpandedContent = () => {
        if (isShowingFullScreenMobile && isMobile && isOpen && Array.isArray(actionsButton)) {
            return (
                <FullScreenMobileView
                    headerText={mobileFullScreenHeader ?? ''}
                    expandedContent={children}
                    onBackButtonClick={() =>
                        handleMobileBackButton ? handleMobileBackButton() : null
                    }
                    expandedContentActionsButtons={actionsButton}
                />
            );
        } else if (isOpen) {
            return (
                <>
                    {children}
                    <div className="actions-container">{actionsButton}</div>
                </>
            );
        }
        return <p className="section-description">{description}</p>;
    };

    return (
        <section>
            <div className="section-header">
                <div>{renderHeaderTitle ? renderHeaderTitle() : null}</div>
                <div className="action-element">
                    {renderHeaderActionElement ? renderHeaderActionElement() : null}
                </div>
            </div>
            {renderExpandedContent()}
        </section>
    );
};

interface FullScreenMobileViewProps {
    headerText: string;
    expandedContent: ReactNode;
    onBackButtonClick: () => void;
    expandedContentActionsButtons: JSX.Element[];
}
const FullScreenMobileView: React.FC<FullScreenMobileViewProps> = ({
    headerText,
    expandedContent,
    onBackButtonClick,
    expandedContentActionsButtons,
}) => {
    const columnStyle = {
        gridTemplateColumns: expandedContentActionsButtons.length === 2 ? '1fr 1fr' : '1fr',
    };

    return (
        <aside className="fullscreen-mobile-wrapper">
            <header>
                <ArrowLeftOutlined className="fcm-back-icon" onClick={onBackButtonClick} />
                <h2>{headerText}</h2>
            </header>
            <main>{expandedContent}</main>
            <div className="fcm-actions-container" style={columnStyle}>
                {expandedContentActionsButtons}
            </div>
        </aside>
    );
};
