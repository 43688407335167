import { CartItemInterface, matchNestedRule } from '../menu/model';

export function calculateItemTaxes(menuInfo: any, cartItems: any) {
    let itemTaxes: number = 0;
    cartItems.map((item: CartItemInterface) => {
        itemTaxes += (item.base_price || 0) * item.quantity * item.tax_rate;
        for (const mod of item.modifier_groups) {
            for (const modItem of mod.items) {
                const priceRule = matchNestedRule(modItem.price_rules, item.idHierarchy);
                if (priceRule && priceRule.price > 0) {
                    const tax =
                        modItem.tax?.rate == null ? menuInfo?.tax || { rate: 0 } : modItem.tax;
                    const taxRate = tax.rate / Math.pow(10, tax.precision) / 100;
                    itemTaxes += priceRule.price * item.quantity * taxRate;
                }
            }
        }
    });
    return itemTaxes;
}
