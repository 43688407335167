import React from 'react';

function PlusCircled({
    background = 'white',
    signColor = 'black',
    onClick,
    className = '',
}: {
    background?: string;
    signColor?: string;
    onClick: () => void;
    className?: string;
}) {
    return (
        <svg
            width="53"
            height="51"
            viewBox="0 0 53 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            className={className}
        >
            <g filter="url(#filter0_d_16875_169727)">
                <rect x="10.5" y="8.91016" width="32" height="32" rx="16" fill={background} />
                <rect
                    x="24.793"
                    y="18.0801"
                    width="3.41518"
                    height="13.6607"
                    rx="1.70759"
                    fill={signColor}
                />
                <rect
                    x="33.3301"
                    y="23.2031"
                    width="3.41518"
                    height="13.6607"
                    rx="1.70759"
                    transform="rotate(90 33.3301 23.2031)"
                    fill={signColor}
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_16875_169727"
                    x="0.5"
                    y="0.910156"
                    width="52"
                    height="52"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_16875_169727"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_16875_169727"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}

export default PlusCircled;
