import { useAuth0 } from '@auth0/auth0-react';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import log from 'loglevel';
import md5 from 'md5';
import React, { FC, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch, useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { BrandTemplates } from '../services/BrandTemplates';
import { UserProfile } from '../services/UserProfile';
import { AppContext, IUserProfile } from './contexts/app-context';
import { ConceptContext } from './contexts/concept-context';
import { MenuPage } from './modules/Pages/MenuPage';
import { AboutPage } from './modules/about';
import { AccountPage } from './modules/account';
import { Loading } from './modules/account/auth';
import { AddressPage } from './modules/address';
import { CheckoutPage } from './modules/checkout';
import { OrderConfirmationPage } from './modules/order-confirmation';
// import { SharePage } from './modules/share';
import { handleUTMQueries, sessionTimeout, updateLocalStorage } from './modules/utils/general';
import useSegment from './modules/utils/segment';
import './app.scss';

let lastTimer: number | null = null;

const updateSessionId = ({
    stateIds,
    updateStateId,
    updateStateIds,
    updateShowSessionExpiredModal,
    segment,
}: any) => {
    const currentTime = new Date().getTime();
    const stateIdsStr = localStorage.getItem('stateIds') || '';
    const sessionIdFromLocalStorage = stateIdsStr && JSON.parse(stateIdsStr).sessionId;

    if (sessionIdFromLocalStorage && !stateIds.sessionId) {
        updateStateIds(JSON.parse(stateIdsStr));
    } else if (!sessionIdFromLocalStorage && !stateIds.sessionId) {
        const sId = Math.round(currentTime / 1000).toString();
        updateStateId('sessionId', sId);
    } else {
        const expiry = localStorage.getItem('expiry') ?? '0';
        if (currentTime > parseInt(expiry, 10)) {
            const sId = Math.round(currentTime / 1000).toString();
            updateStateId('sessionId', sId);
        }
    }

    if (lastTimer) {
        clearTimeout(lastTimer);
    }

    lastTimer = window.setTimeout(() => {
        const storedAddress = localStorage.getItem('orderAddress');
        if (storedAddress != null) {
            // Only clear state if an address was already picked
            updateShowSessionExpiredModal(true);
            segment.sessionExpiredCaptured();
        }
    }, sessionTimeout);

    const newExpiry = JSON.stringify(currentTime + sessionTimeout);
    localStorage.setItem('expiry', newExpiry);
};

const signUpNewUser = (
    token: string,
    kitchen: any,
    segmentCallback: (input: IUserProfile) => void,
    updateUserProfile: (input: IUserProfile) => void,
) => {
    log.debug('Try again: Signup new user');
    UserProfile.signUpNewUser(
        token,
        kitchen?.app_id ?? null,
        kitchen?.id ?? null,
        localStorage.getItem('signupTouchpoint'),
    )
        .then(userProfile => {
            log.debug('signed up new user', userProfile);
            updateUserProfile(userProfile);
            segmentCallback(userProfile);
        })
        .catch(err => {
            log.debug('sign up new user error', err);
        });
};

export function getCurrentPage() {
    const availablePaths: { [k: string]: string } = {
        '/': 'Menu',
        '/orders': 'Menu',
        '/checkout': 'Checkout',
        '/address': 'Address Entry',
        '/order-confirmation': 'Order Confirmation',
        '/about': 'About',
        '/share': 'Share',
        '/account': 'Account',
        '/history': 'Order History',
        '/profile': 'User Profile',
        '/addresses': 'User Addresses',
        '/callback': 'Callback',
    };
    const currLocation = window.location.pathname;
    const endOfPathIndex = currLocation.lastIndexOf('/');
    const isItemPage = currLocation.includes('/item/');
    const isLocationPage = currLocation.includes('/store/');
    const currentPage = currLocation.slice(endOfPathIndex);

    if (isItemPage && endOfPathIndex > 0) {
        return 'Menu Item';
    }

    if (isLocationPage && endOfPathIndex > 0) {
        return 'Menu Store';
    }

    if (currentPage in availablePaths) {
        return availablePaths[currentPage];
    }
    throw new Error(`path_not_found: ${currentPage}`);
}

export const Router: FC = () => {
    const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const segment = useSegment();
    const {
        template,
        stateIds,
        updateTemplate,
        updateStateId,
        isDinnerbell,
        updateIsDinnerbell,
        updateStateIds,
        brandUrl,
        orderAddress,
        updateOrderTiming,
        updateShowSessionExpiredModal,
        orderTiming,
        userProfile,
        customerInfo,
        updateCustomerInfo,
        updateUserProfile,
    } = useContext(AppContext);
    const {
        conceptDetails: { restaurantInfo },
        clearAddressAndCartSession,
    } = useContext(ConceptContext);

    const ldClient = useLDClient();
    const ldFlags = useFlags();
    const history = useHistory();

    //SETTING PRIMARY COLOR FROM TEMPLATE TO SCSS GLOBALLY
    const root = document.documentElement;
    root.style.setProperty('--primary-color', template?.general?.primary_color || '');

    ldClient?.identify({
        key: md5(orderAddress?.formatted_address ?? 'no_address'),
        custom: {
            deliveryAddress: orderAddress?.formatted_address ?? 'no_address',
            brandId: template?.metadata.brand.id ?? '',
        },
    });

    const haveUTMQuery = handleUTMQueries();
    if (haveUTMQuery) {
        // Remove UTM params from URL for better user experience. People still look at URLs, right?
        history.replace({ search: '' });
    }

    useEffect(() => {
        const currentTime = new Date().getTime();
        const expiry = parseInt(localStorage.getItem('expiry') ?? '0', 10);
        if (expiry > 0 && currentTime > expiry) {
            clearAddressAndCartSession();
            updateLocalStorage('showAddressEntry', true);
        }
    }, [clearAddressAndCartSession]);

    useEffect(() => {
        if (isAuthenticated && !isLoading && userProfile) {
            localStorage.setItem('currentUser', JSON.stringify(user));
        } else {
            localStorage.removeItem('currentUser');
            updateUserProfile(null);
        }
    }, [isLoading, isAuthenticated, user, userProfile, updateUserProfile]);

    // TODO: How to handle error (to trigger hide splash)
    const templateQuery = useQuery<{ data: any }>(['template', brandUrl], () => {
        return BrandTemplates.get({ url: brandUrl });
    });

    useEffect(() => {
        SplashScreen.hide();
        if (Capacitor.isPluginAvailable('StatusBar')) {
            StatusBar.setStyle({ style: Style.Dark });
        }
        if (Capacitor.isNativePlatform()) {
            segment.appOpened();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (templateQuery?.data?.data) {
            updateTemplate(templateQuery.data.data);
        }
        if (template?.future_order?.supported_order_type?.asap && !orderTiming) {
            updateOrderTiming('ASAP');
        } else if (template?.future_order?.supported_order_type?.scheduled && !orderTiming) {
            updateOrderTiming('Future');
        }
    }, [templateQuery, template, updateTemplate, orderTiming, updateOrderTiming]);

    useEffect(() => {
        // If fetch userProfile errored out during authentication on callback page, we want to silently fetch again
        if (
            ldFlags.enableAccounts &&
            isAuthenticated &&
            !isLoading &&
            user &&
            !userProfile &&
            !window.location.pathname.includes('/callback')
        ) {
            const kitchen = restaurantInfo?.delivery;
            getAccessTokenSilently().then(token => {
                UserProfile.getUserProfileData(token)
                    .then(userProfile => {
                        if (!userProfile) {
                            signUpNewUser(
                                token,
                                kitchen,
                                segment.IdentifySignUp,
                                updateUserProfile,
                            );
                        } else {
                            log.debug('signed in user', userProfile);
                            updateUserProfile(userProfile);
                            segment.IdentifyLogIn(userProfile);
                        }
                    })
                    .catch(err => {
                        log.debug('getUserProfile Error', err);
                    });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        getAccessTokenSilently,
        isAuthenticated,
        isLoading,
        user,
        userProfile,
        updateUserProfile,
        restaurantInfo,
        ldFlags.enableAccounts,
    ]);

    useEffect(
        () => updateIsDinnerbell(template?.metadata?.brand?.name === 'Dinnerbell'),
        [template, updateIsDinnerbell],
    );

    useEffect(() => {
        const updateSessionIdWithArgs = updateSessionId.bind(null, {
            stateIds,
            updateStateId,
            updateStateIds,
            updateShowSessionExpiredModal,
            segment,
        });

        updateSessionIdWithArgs();

        document.addEventListener('click', updateSessionIdWithArgs);
        return () => {
            document.removeEventListener('click', updateSessionIdWithArgs);
        };
    }, [stateIds, updateStateId, updateStateIds, updateShowSessionExpiredModal, segment]);

    useEffect(() => {
        if (userProfile && !customerInfo) {
            updateCustomerInfo({
                email: userProfile.email,
                name: userProfile.name || '',
                phonenumber: userProfile?.phone_number || '',
            });
        }
    }, [userProfile, updateCustomerInfo, customerInfo]);

    useEffect(() => {
        // delete old custom font classes
        const classList = document.body.classList;
        for (let i = classList.length - 1; i >= 0; i--) {
            const className = classList[i];
            if (className.startsWith('header-custom-') || className.startsWith('body-custom-')) {
                classList.remove(className);
            }
        }
        // set custom font class to the body when template is updated
        classList.add(`header-${template?.general?.custom_header_font || 'custom-font-fallback'}`);
        classList.add(`body-${template?.general?.custom_body_font || 'custom-font-fallback'}`);
    }, [template]);

    useEffect(() => {
        if (isDinnerbell) {
            document.body.classList.add('isDB');
        } else {
            document.body.classList.remove('isDB');
        }
    }, [isDinnerbell]);

    if (
        !restaurantInfo &&
        !window.location.pathname.includes('/address') &&
        !window.location.pathname.includes('/about') &&
        !window.location.pathname.includes('/account') &&
        !window.location.pathname.includes('/callback') &&
        !ldFlags.enableLamv
    ) {
        return <Redirect to="/address" />;
    }

    // TODO: redirect to Menu for LAMV with the exception of Dinnerbell. redirect to Address for non LAMV
    return (
        <Switch>
            <Route
                exact
                path="/store/:address/:location_short_id/item/:name"
                component={MenuPage}
            />
            <Route exact path="/store/:address/:location_short_id" component={MenuPage} />
            <Route exact path="/item/:name" component={MenuPage} />
            <Route exact path="/" component={MenuPage} />
            <Route path="/callback" component={Loading} />
            <Route exact path="/checkout" component={CheckoutPage} />
            {/* TODO: Uncomment once share functionality expectations confirmed */}
            {/* <Route path="/share" component={SharePage} /> */}
            <Route exact path="/order-confirmation" component={OrderConfirmationPage} />
            <Route exact path="/about" component={AboutPage} />
            {!ldFlags.enableLamv && !restaurantInfo && (
                <Route exact path="/address" component={AddressPage} />
            )}
            {ldFlags.enableAccounts && (
                <Route exact path="/account/:page" component={AccountPage} />
            )}
            <Redirect to="/" />
        </Switch>
    );
};
