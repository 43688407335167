import { get } from './config';

/**
 * @description Order history service for finding user's current and past orders
 */

export const OrderList = {
    getUserOrderList: (token: string) => {
        return get(`/accounts/order-list/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    },
    getOrderDetails: (
        params: { order_id: string; location_id: string; app_id: string },
        token: string,
    ) => {
        return get('/accounts/order-details/', {
            params,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    },
    getOrderStatus: ({ orderId }: { orderId: string }) => {
        return get(`/order-status/${orderId}`);
    },
    getLocationInfo: (params: { app_id: string }) => {
        return get(`/concepts/location/`, { params });
    },
};
